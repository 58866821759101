<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, itemIndex) in items" :key="itemIndex">
        <a v-on:click="$changeRoute(item.to)">{{item.text}}</a>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumbs',
  data() {
    return {
    };
  },
  props: {
    items: Array,
  },
};
</script>
