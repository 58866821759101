import {
  cloneDeep, find, map, assign, omit, findIndex,
} from 'lodash';
import slideModel from '../models/model-slide-box';
import listMixin from './list-mixin';
import slidesMixin from './slides-mixin';
import { setOnFolderChange, getOnFileSelect } from '../helpers/slide-upload';
import * as folderTypes from '../constants/constants-folder';
import SlideboxSocket from '../helpers/slidebox-socket';
import metadataModal from "../models/model-metadata";
import { mapActions,mapGetters } from 'vuex';

const socket = new SlideboxSocket();

export default {
  data() {
    return {
      ...folderTypes,
      searchDeep: false,
      folderSearch: '',
      checkAll: {},
      selectedSlides: [],
      slideData: cloneDeep(slideModel),
      folderData: null,
      slides: [],
      slideIds: [],
      dateRange: {},
      totalPages: null,
      totalElements: 0,
      tissueType: null,
      messages: {},
      activeSubscriptionId: 0,
      studies: [],
      progressStudies: [],
      allMetadata: [],
      allCases: [],
      isAllCasesLoaded: false,
      caseSlides: [],
      totalCasePages: null,
      totalCaseElements: 0,
      checkCaseAll: {},
      selectedCaseSlides: [],
      isMovedToEditorialBox: false,
      isMovedToSlideLibrary: false,
      isMovedToPrePublication: false,
      isSharedToEditor: false,
      isRecycle: false,
      sharedByUserId: 0,
      editorialBoxTumorSiteId: 0,
      userDetail: null,
      exportData: [],
    };
  },
  computed: {
    ...mapGetters({
      showCaseSlides: "getShowCaseSlidesStatus",
      selectedCaseSlideIds: "getSelectedCaseSlideIds",
  }),
    isfolderDeleted() {
      return this.folderData && this.folderData.viewStatus === this.FOLDER_RECYCLE;
    },
    mapSelectedSlides() {
      return map(this.selectedSlides, 'id');
    },
  },
  mixins: [listMixin, slidesMixin],
  mounted() {
    this.tissueType = this.$getTissueDomain === this.TISSUE_BOTH ? this.TISSUE_HUMAN : this.$getTissueDomain;
    setOnFolderChange(this.folderChange);
    this.connectWebSocket();
    window.addEventListener("callGetAllCases", this.callGetAllCases);
    window.addEventListener("callGetAllSlides", this.callGetAllSlides);
  },
  beforeDestroy() {
    setOnFolderChange(null);
    this.disconnectWebSocket();
    window.removeEventListener('callGetAllCases',this.callGetAllCases);
    window.removeEventListener("callGetAllSlides", this.callGetAllSlides);
  },
  methods: {
    ...mapActions([
      'setShowCaseSlides', 'setShowCaseView', 'setSelectedCaseNumber', 'setSelectedCaseSlideIds', 'setSelectedFolderView','setSelectedFolderUserRole','setSelectedFolderUserRegion'
    ]),
    callGetAllCases(data)
    {
      this.getCases(data.detail);
    },
    callGetAllSlides(data)
    {
      this.getSlideIdsByCaseNumber(data.detail.caseNumber);
    },
    getMappedSlide(slide) {
      return assign(slide, this.getPermissions(this.folderData), this.changeSlideData(slide));
    },
    connectWebSocket() {
      if (this.$hasUpdatedBatch) socket.connectSokect();
    },
    disconnectWebSocket() {
      if (this.$hasUpdatedBatch) socket.destroy();
    },
    folderChange(val) {
      this.setSelectedFolderUserRole(null);
      this.setSelectedFolderUserRegion(null);
      this.folderData = val || this.folderData;
      if (this.folderData.folderType==this.FOLDER_RECYCLEBIN) {
        this.getUserDetailsById();
        this.isRecycle = true;
        this.isMovedToEditorialBox = false;
        this.isMovedToSlideLibrary = false;
        this.isSharedToEditor = false;
        this.sharedByUserId = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_RECYCLE);
      }
      else if (this.folderData.folderType==this.FOLDER_MYFOLDER && this.folderData.isShared) {
        this.getUserDetailsById();
        this.isRecycle =false;
        this.isMovedToEditorialBox = false;
        this.isMovedToSlideLibrary = false;
        this.isMovedToPrePublication = false;
        this.isSharedToEditor = true;
        this.sharedByUserId = val.userId;      //SharedByUserId
        this.folderData.id = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_SHAREDBYCONTRIBUTOR);
      }
      else if (this.folderData.folderType==this.FOLDER_EDITORIALBOX) {        
        this.getUserDetailsById();
        this.isRecycle =false;
        this.isMovedToEditorialBox = true;
        this.isMovedToSlideLibrary = false;
        this.isMovedToPrePublication = false;
        this.isSharedToEditor = false;
        this.sharedByUserId = 0;
        this.folderData.id = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_EDITORIALBOX);
      }      
      else if (this.folderData.folderType==this.FOLDER_PUBLICBOX) {
        this.getUserDetailsById();
        this.isRecycle =false;
        this.isMovedToEditorialBox = false;
        this.isMovedToSlideLibrary = true;
        this.isMovedToPrePublication = false;
        this.isSharedToEditor = false;
        this.sharedByUserId = 0;
        this.folderData.id = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_PUBLICBOX);
      }      
      else if (this.folderData.folderType==this.FOLDER_PREPUBLICATIONBOX) {
        this.getUserDetailsById();
        this.isRecycle =false;
        this.isMovedToEditorialBox = false;
        this.isMovedToSlideLibrary = false;
        this.isMovedToPrePublication = true;
        this.isSharedToEditor = false;
        this.sharedByUserId = 0;
        this.folderData.id = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_PREPUBLICATIONBOX);
      }      
      else {
        this.getUserDetailsById();
        this.isRecycle =false;
        this.isMovedToEditorialBox = false;
        this.isMovedToSlideLibrary = false;
        this.isMovedToPrePublication = false;
        this.isSharedToEditor = false;
        this.sharedByUserId = 0;
        this.editorialBoxTumorSiteId = val.tumorSiteId;
        this.setSelectedFolderView(this.FOLDER_MYFOLDER);
      }
      if (val) this.$resetParamsUtil();
      this.dateRange = {};
      this.selectedSlides = [];
      this.checkAll = {};
      this.totalElements = 0;
      this.totalPages = 0;
      this.selectedCaseSlides = [];
      this.checkCaseAll = {};
      this.totalCaseElements = 0;
      this.totalCasePages = 0;
      this.slides = null;
      this.slideIds = null;
      this.allCases = null;
      this.setShowCaseSlides(false);
      
      if(this.folderData!=null &&(this.folderData.folderType===this.FOLDER_EDITORIALBOX||this.folderData.folderType===this.FOLDER_MYFOLDER||
        this.folderData.folderType===this.FOLDER_RECYCLEBIN))
      { 
          this.startLoading();
          this.setShowCaseView(true);
          this.getCases(this.paramsUtil);    
      }else if(this.folderData!=null &&(this.folderData.folderType===this.FOLDER_PUBLICBOX||this.folderData.folderType===this.FOLDER_PREPUBLICATIONBOX))
      {
        this.startLoading();
        this.getAllPublicBoxCases(this.paramsUtil);       
      }      
    },
    getRecycleFiles(data) {
      this.$api.getRecycleFilesFact(data).then(
        response => {
          if (!this.$handleResponse(response)) return;
          const { content, totalPages, totalElements } = response.data.payload;
          this.slides = map(content, item => this.getMappedSlide(item));
          this.totalPages = totalPages;
          this.totalElements = totalElements;
          this.slideIds = map(this.slides, item => assign(item.id));
            this.getAllMetadataBySlideIds(this.slideIds);
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
        },
      );
    },
    getSlides(data) {
      this.paramsUtil = {
        ...data,
        sort:this.paramsUtil.sort,
        sharedByUserId: this.sharedByUserId,
        movedToEditorialBox: this.isMovedToEditorialBox,
        movedToSlideLibrary: this.isMovedToSlideLibrary,
        sharedToEditor: this.isSharedToEditor,
        tumorSiteId: this.editorialBoxTumorSiteId,
        isRecycle: this.isRecycle
      };
      this.checkAll = {};
      this.listLoading = true;
      if (this.folderData.root && this.folderData.recycle) {
        this.selectedSlides = [];
        this.getRecycleFiles(this.paramsUtil);
      } else {
        this.getSlidesById(this.paramsUtil, this.folderData.id);
      }
    },
    getCases(data) {
      this.paramsUtil = {
        ...data,
        sort:this.paramsUtil.sort,// this.$sortBy ? this.$sortBy : this.paramsUtil.sort,
        sharedByUserId: this.sharedByUserId,
        movedToEditorialBox: this.isMovedToEditorialBox,
        movedToSlideLibrary: this.isMovedToSlideLibrary,
        movedToPrePublication: this.isMovedToPrePublication,
        sharedToEditor: this.isSharedToEditor,
        tumorSiteId: this.editorialBoxTumorSiteId,
        isRecycle: this.isRecycle
      };
      this.getAllCases(this.paramsUtil, this.folderData.id);
    },
    getSlidesByEmail(data) {
      data.search = data.search || ""; // eslint-disable-line
      this.$api.getFilesByEmailFact(data).then(
        response => {
          this.listLoading = false;
          if (!this.$handleResponse(response)) return;
          const { content, totalPages, totalElements } = response.data.payload;
          this.slides = map(content, item => {
            const permissions = find(item.members, user => this.$userData.id === user.user.id);
            return assign(
              item.slide,
              this.changeSlideData(item.slide),
              permissions
                ? {
                  editPermitted: permissions.editPermitted,
                  viewPermitted: permissions.viewPermitted,
                  deletePermitted: permissions.deletePermitted,
                  sharePermitted: permissions.sharePermitted,
                }
                : {},
            );
          });
          this.slideIds = map(this.slides, item => assign(item.id));
          this.totalPages = totalPages;
          this.totalElements = totalElements;
          this.getAllMetadataBySlideIds(this.slideIds);
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
        },
      );
    },
    getSlidesById(data, id) {
      this.$api.getFilesFact(id, data).then(
        response => {
          if (!this.$handleResponse(response)) return;
          if (response.data.payload != null) {
            const { content, totalPages, totalElements } = response.data.payload;
            this.slides = map(content, item => assign(item, this.getPermissions(this.folderData), this.changeSlideData(item)));
            this.totalPages = totalPages;
            this.totalElements = totalElements;
            this.slideIds = map(this.slides, item => assign(item.id));
            this.getAllMetadataBySlideIds(this.slideIds);
          }
          else
          {
            this.slides =null;
            this.totalPages = 0;
            this.totalElements = 0;
            this.listLoading = false;
          }
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
        },
      );
    },
    getAllMetadataBySlideIds(slideIds,paramsUtil) {
      this.$api.getAllMetadataBySlideIdsFact(slideIds).then(
        response => {
          this.allMetadata = response.data.payload;
          this.slides = map(this.slides, item => assign(item, { metadata: this.getMetadataFromList(item.id) }));
                    
          // Sort slides by fileOrder in ascending order
          if(paramsUtil.sort.includes("id,desc"))
          {
            this.slides.sort((a, b) => a.metadata.fileOrder - b.metadata.fileOrder);
          }

          // Sort slides based on the sortType received
          if (paramsUtil.sort.includes('slideMetadata.stainORstudy')) {
            this.slides.sort((a, b) => {
              const aStain = this.getStainStudyNameFromMetadata(a);
              const bStain = this.getStainStudyNameFromMetadata(b);
              if (paramsUtil.sort.includes('asc')) {
                return aStain.localeCompare(bStain);
              } else {
                return bStain.localeCompare(aStain);
              }
            });
          }

          this.listLoading = false;
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
        },
      );
    },
    getStainStudyNameFromMetadata(item) {
      // Assuming the structure is slides -> metadata -> stainStudies -> name
      if (item.metadata && item.metadata.stainStudies && item.metadata.stainStudies.length > 0) {
        return item.metadata.stainStudies[0].name;
      }
      return '';
    },
    getMetadataFromList(slideId) {
      if (findIndex(this.allMetadata, metadata => metadata.slideRefId === slideId) != -1)
        return find(this.allMetadata, metadata => metadata.slideRefId === slideId);
      else
        return metadataModal;
    },
    customSort(a, b) {
      if (a.systemCaseNumber !== null && b.systemCaseNumber !== null) {
        return a.systemCaseNumber.localeCompare(b.systemCaseNumber);
      } else if (a.systemCaseNumber !== null) {
        return -1;
      } else if (b.systemCaseNumber !== null) {
        return 1;
      } else {
        return a.caseNumber.localeCompare(b.caseNumber);
      }
    },
    sortAllCasesAscending() {
      this.allCases.sort(this.customSort);
    },
    sortAllCasesDescending() {
      this.allCases.sort((a, b) => this.customSort(b, a));
    },
    getAllCases(data, folderId) {
      this.setShowCaseSlides(false);
      this.setSelectedCaseSlideIds([]);
      this.listLoading = true;
      if (data.sort === "id,desc")
      {
        if(this.folderData.folderType==this.FOLDER_EDITORIALBOX) {
          data.sort = "cd.system_case_number,desc";
        }
        else
        {
         data.sort = "cd.case_number,desc";
        }        
      }
      this.$api.getAllCasesFact(folderId, data).then(
        response => {
          this.listLoading = false;
          if (response.data.payload != null) {
          const { content, totalPages, totalElements } = response.data.payload;
          this.allCases = content;
          this.totalCasePages = totalPages;
          this.totalCaseElements = totalElements;
          if (this.allCases != null) {
            this.isAllCasesLoaded = true;
          }
          else
            this.isAllCasesLoaded = false;

          if (data.sort.includes('case_number')) {
              if (data.sort.includes('asc')) {
                this.sortAllCasesAscending();
              } else {
                this.sortAllCasesDescending();
              }
          }
          this.stopLoading();
        }
        else{
          this.allCases = null;
          this.totalCasePages = 0;
          this.totalCaseElements = 0;
          this.isAllCasesLoaded = false;
          this.stopLoading();
        }
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
          this.stopLoading();
        },
      );
    },
    getSlideIdsByCaseNumber(caseNumber) {
      this.paramsUtil.sort = "id,desc";
      this.setSelectedCaseNumber(caseNumber);
      if (findIndex(this.allCases, c => c.caseNumber === caseNumber || c.systemCaseNumber === caseNumber) != -1) {
        let selectedCase = find(this.allCases, c => c.caseNumber === caseNumber || c.systemCaseNumber === caseNumber);
        if (selectedCase.caseSlideIds.length > 0) {
          let slideIds = [];
          let splitIds = selectedCase.caseSlideIds.split(',');
          splitIds.forEach(element => {
            slideIds.push(parseInt(element));
          });
          let params = {
            page: 0,
            size: slideIds.length,
            sort: this.paramsUtil.sort,
          };
          this.getSlidesByCaseSlideIds(slideIds,params);
        }
      }
    },
    getSlidesByCaseSlideIds(data,paramsUtil) {
      this.listLoading = true;
      let params = {
        page: paramsUtil.page,
        size: paramsUtil.size,
        sort: (paramsUtil.sort.includes("case_number") || paramsUtil.sort.includes("case_number,system_case_number")) ?"id,desc":paramsUtil.sort,
      };
      this.$api.getSlidesByCaseSlideIdsFact(data,params).then(
        response => {
          if (!this.$handleResponse(response)) return;
          const { content, totalPages, totalElements } = response.data.payload;
          if (this.folderData == null)
            this.slides = map(content, item => assign(item, this.changeSlideData(item)));
          else
            this.slides = map(content, item => assign(item, this.getPermissions(this.folderData), this.changeSlideData(item)));

          this.totalPages = totalPages;
          this.totalElements = totalElements;
          this.setShowCaseSlides(true);
          this.setSelectedCaseSlideIds(data);
          this.getAllMetadataBySlideIds(data,paramsUtil);
        },
        error => {
          this.listLoading = false;
          this.$handleError(error);
        },
      );
    },
    sortPublicBoxCases(type) {
      let sortType = type;
      if (!this.folderData.id) {
        sortType = `${type}`;
      }
      this.paramsUtil.sort = this.$sortList(sortType);
      this.getAllPublicBoxCases(this.paramsUtil);
    },
    getAllPublicBoxCases(data) {
      this.setShowCaseSlides(false);
      this.setShowCaseView(true);
      this.setSelectedCaseSlideIds([]);
      this.listLoading = true;
      //if (data.sort === "id,desc") 
      if (data.sort === "id,desc")
      {
         data.sort = "cd.system_case_number,desc";
      }
      this.paramsUtil = {
        ...data,
        sort:this.paramsUtil.sort,// this.$sortBy ? this.$sortBy : this.paramsUtil.sort,
        movedToSlideLibrary: this.isMovedToSlideLibrary,
        movedToPrePublication: this.isMovedToPrePublication,
        tumorSiteId: this.editorialBoxTumorSiteId,
      };
      this.$api.getAllSlideLibraryCasesFact(this.paramsUtil).then(
        (response) => {
          this.listLoading = false;
          if(response.data.payload!=null)
          {const { content, totalPages, totalElements } = response.data.payload;
          this.allCases = content;
          this.totalCasePages = totalPages;
          this.totalCaseElements = totalElements;

          if (data.sort.includes('cd.system_case_number')) {
            if (data.sort.includes('asc')) {
              this.sortAllCasesAscending();
            } else {
              this.sortAllCasesDescending();
            }
          }
          this.stopLoading();
        }
        else{
          this.allCases = null;
          this.totalCasePages = 0;
          this.totalCaseElements = 0;
          this.stopLoading();
        }
        },
        (error) => {
          this.listLoading = false;
          this.$handleError(error);
          this.stopLoading();
        }
      );
    },
    sortSlides(type) {
      this.listLoading = true;
      let sortType = type;
      if (!this.folderData.id) {
        sortType = `${type}`;
      }
      this.paramsUtil.sort = this.$sortList(sortType);
      if(this.showCaseSlides)
        this.getSlidesByCaseSlideIds(this.selectedCaseSlideIds,this.paramsUtil);
      else
          this.getSlides(this.paramsUtil, this.folderData.id);
    },
    sortCases(type) {
      let sortType = type;
      if (!this.folderData.id) {
        sortType = `${type}`;
      }
      this.paramsUtil.sort = this.$sortList(sortType);
      this.getCases(this.paramsUtil);
    },
    onChangeRange(range) {
      this.dateRange = range;
      this.paramsUtil['start-date'] = range.startDate
        ? this.$getUTCTime(new Date(range.startDate).setHours(0, 0, 0, 0))
        : null;
      this.paramsUtil['end-date'] = range.endDate
        ? this.$getUTCTime(new Date(range.endDate).setHours(23, 59, 59, 59))
        : null;
      this.getSlides(this.paramsUtil, this.folderData.id);
    },
    updateSlideData(data) {
      this.slides.splice(
        findIndex(this.slides, item => item.id === data.id),
        1,
        data,
      );
      this.$toastr('success', 'Slide updated successfully');
    },
    clearSelected() {
      this.checkAll = {};
    },
    reuploadSlide({ e, slide }) {
      let fileData = slide;
      const target = e.target || e.srcElement;
      const nonDigital = [
        this.SLIDE_DIGITALSLIDE,
        this.SLIDE_ZOOMIFY,
        this.SLIDE_RADIOLOGY,
        this.SLIDE_UNKNOWN,
      ];
      const file = e.target.files[0];
      const ext = this.$getFileExtension(file.name);
      const extType = this.setFileType(ext, file.type);
      if (!extType) {
        this.$toastr('error', this.$t('slidebox.fileNotSupported', { format: ext }));
        return;
      }
      const fileSize = file.size;
      fileData = assign(fileData, {
        name: file.name,
        fileExtension: ext,
        fileType: extType,
        fileSize,
        dziStatus: nonDigital.includes(extType) ? 0 : 1,
      });
      target.value = '';
      getOnFileSelect()({
        fileData: [
          {
            file,
            folderName: fileData.folder.name,
            folderData: fileData.folder,
            data: omit(fileData, 'meta'),
          },
        ],
      });
    },
    onShareSuccess() {
      this.selectedSlides = [];
      this.checkAll = {};
    },
    updateFileStatus() {
      this.dateRange = {};
      this.selectedSlides = [];
      this.checkAll = {};
      if(this.showCaseSlides)
      {
        if(this.selectedCaseSlideIds.length == 0)
        {
          this.selectedCaseSlideIds.push(0);
        }
        this.getSlidesByCaseSlideIds(this.selectedCaseSlideIds,this.paramsUtil);
      }
      else
      {
          this.getSlides(this.paramsUtil, this.folderData.id);
      }
    },
    getUserDetailsById()
    {
        const userId = this.folderData.userId;
            this.$api.getUserDetailFact(userId).then(
            (response) => {
                this.userDetail = response.data;
                this.setSelectedFolderUserRole(this.userDetail.userRole);
                this.setSelectedFolderUserRegion(this.userDetail.regionid);
            },
            (error) => {
                this.$handleError(error);
            }
        ); 
    },
    startLoading()
    {
      const eventSendStart = new CustomEvent("setFolderLoadingStart", {
        detail: {
            foldersLoading: true,
          }
        });
      window.dispatchEvent(eventSendStart);
    },
    stopLoading()
    {
      const eventSendStop = new CustomEvent("setFolderLoadingStop", {
        detail: {
            foldersLoading: false,
          }
        });
      window.dispatchEvent(eventSendStop);
    },

  },
};
