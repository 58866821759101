import { assign } from 'lodash';
import types from './dashboard-mutation-types';

export default {
  [types.SIDEBAR](state, data) {
    state.sidebar = assign(state.sidebar, data); //eslint-disable-line
  },
  [types.CRUMBS](state, data) {
    state.crumbs = data; //eslint-disable-line
  },
  [types.CURRENT_SIDEBAR](state, data) {
    state.currentSidebar = data; //eslint-disable-line
  },
  [types.LOADER](state, data) {
    state.loader = data; //eslint-disable-line
  },
  [types.SET_MODAL](state, data) {
    state.modalData = data; //eslint-disable-line
  },
  [types.SET_ROUTES](state, data) {
    state.routes = data; //eslint-disable-line
  },
  [types.SET_DASHBOARD_HEIGHT](state, data) {
    state.dashboardHeight = data; //eslint-disable-line
  },
  [types.SET_DASHBOARD_WIDTH](state, data) {
    state.dashboardWidth = data; //eslint-disable-line
  },
};
