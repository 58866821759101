<template>
  <b-card
    class="pp-slide-card fs-14 text-primary"
    body-class="p-0"
    @click.stop="$emit('card-click')"
  >
    <div>
      <slot :name="headerSlot"></slot>
    </div>
    <b-row
      class="mx-0 pt-10 pb-5"
      @click.stop="$emit('header-click')"
      v-if="!headerSlot && !hideHeader"
    >
      <b-col
        :title="name"
        :class="headerClass.name"
        class="pt-5 pr-0 pl-10 text-truncate fw-600"
      >
        {{ name }}
      </b-col>
      <b-col
        class="text-right px-5"
        :class="headerClass.toggle"
        v-if="showToggle || showToggleBadge"
      >
        <div v-if="headerRightSlot">
          <slot :name="headerRightSlot"></slot>
        </div>
        <div v-else>
          <b-form-checkbox
            size="sm"
            variant="primary"
            :disabled="disableToggle"
            :checked="toggle"
            @change="$emit('select-checkbox')"
            aria-label="checkbox"
            aria-labelledby ="checkbox"
          >
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <div>
      <slot :name="bodySlot"></slot>
    </div>
    <div
      @click.stop="$emit('img-click')"
      v-if="!bodySlot"
    >
      <wtr-img
        :resourceId="img.resourceId"
        :containerName="img.containerName"
        :key="imgKey"
        :styles="imgStyles"
        :src="img.src"
        :altImage="img.alt"
        alt="Thumbnail"
        aria-label="Thumbnail"
        arialabelledby="Thumbnail"
      />
    </div>
    <div
      class="p-2 text-center"
      @click.stop="!footerSlot && $emit('img-click')"
    >
      <slot :name="footerSlot"></slot>
    </div>
    <div v-if="!footerSlot"></div>
  </b-card>
</template>

<script>
import { assign, cloneDeep } from 'lodash';

export default {
  name: 'wtr-slide-card',
  data() {
    return {};
  },
  props: {
    headerSlot: String,
    headerRightSlot: String,
    name: String,
    showToggle: Boolean,
    disableToggle: Boolean,
    toggle: Boolean,
    bodySlot: String,
    footerSlot: String,
    showToggleBadge: Boolean,
    hideHeader: {
      type: Boolean,
      default: () => false,
    },
    img: {
      src: String,
      resourceId: String,
      containerName: String,
      styles: Object,
    },
    imgKey: {
      type: String,
    },
  },
  computed: {
    headerClass() {
      if (this.showToggleBadge) {
        return {
          name: 'col-xl-9 col-lg-9 col-md-9 col-sm-9',
          toggle: 'col-xl-3 col-lg-3 col-md-3 col-sm-3',
        };
      }
      return {
        name: this.showToggle
          ? 'col-xl-10 col-lg-10 col-md-10 col-sm-10'
          : 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
        toggle: this.showToggle ? 'col-xl-2 col-lg-2 col-md-2 col-sm-2' : '',
      };
    },
    imgStyles() {
      return assign({
        'background-color': '#eee',
        'border-top': '1px solid #eee',
        'border-bottom': '1px solid #eee',
        width: '100%',
        height: '100%',
        'max-height': '15vh',
        'min-height': '15vh',
        'pointer-events': 'none',
        'object-fit': 'contain',
      }, cloneDeep(this.img.styles));
    },
  },
};
</script>
<style scoped lang="scss">
.pp-slide-card {
  padding: 0px;
  margin-left: 5px;
  margin-bottom: 10px;
  // -webkit-box-shadow: 0 0.3rem 0.3rem rgba(99, 102, 102, 0.175);
  // box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.175);
  cursor: pointer;
  border: 1px solid #dee2e6 !important;
  width: 265px;

  &:hover {
    -webkit-box-shadow: 0 3rem 3rem rgba(99, 102, 102, 0.175);
    box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.175);
    transform: translatey(-5px);
  }

  .card-footer {
    background-color: #fff;
  }

  .card-header {
    font-size: 16px;
    background-color: #fff;
  }

  .card-body {
    padding: 0px;
    margin: 0px;
  }
}
</style>
