<template>
  <div v-if="!$loader">
    <div
      class="text-center text-muted jumbotron"
      :class="bgColor"
      :style="{ 'height': height }"
    >
      <wtr-img
        objectFit="contain"
        :height="imgHeight"
        :width="imgWidth"
        :src="$icons.NO_DATA_FOUND"
        alt="No data found"
        aria-label="No data found"
        aria-labelledby="No data found"
      />
      <div class="fs-24 fw-500 mt-3" style="color: #939393;">{{ message }}.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'no-data-found',
  props: {
    message: {
      type: String,
      default: () => 'No data found',
    },
    bgColor: {
      type: String,
      default: () => 'bg-transparent',
    },
    height: {
      type: String,
      default: () => 'inherit',
    },
    imgHeight: {
      type: String,
      default: () => '200px',
    },
    imgWidth: {
      type: String,
      default: () => '100%',
    },
  },
};
</script>

<style scoped lang="scss">
.no-data-img {
  object-fit: contain;
}
.jumbotron {
  height: 100%;
  margin-bottom: 0px;
}
</style>
