import {
  startCase,
  filter,
  map,
} from 'lodash';
import * as utils from './utilities';
import * as validations from './validations';
import alerts from './alerts';
import sessionService from './session-service';
import storage from './localStorage';
import i18n from './internationalization';

const imageExtensions = ['image/jpeg', 'image/bmp', 'image/png'];

// Modules
export const $util = utils;
export const $session = sessionService;
export const $storage = storage;
export const $validations = validations;
export const $i18n = i18n;

// Alerts
export const $toastr = alerts.toastr;
export const $modalAlert = alerts.modalAlert;
export const $deleteConfirmation = alerts.deleteConfirmation;
export const $workInProgress = alerts.workInProgress;

export const $responsive = ['sm', 'md', 'lg'];

export const $getUserName = ({ email, firstName, lastName }) => (firstName && lastName ? `${firstName} ${lastName}` : email);

export const $isImage = (extension, type) => imageExtensions.includes(type);

export const $getTitleCase = val => startCase(val);

export const $getFileExtension = filename => filename.substring(filename.lastIndexOf('.') + 1, filename.length);

export const $getSlideName = ({ name, slideMetadata }, disableDiagnosis) => (slideMetadata && slideMetadata.diagnosis && !disableDiagnosis
  ? slideMetadata.diagnosis
  : name);

export const $getUTCTimeStamp = date => {
  const utc = new Date(date).toUTCString();
  return new Date(utc).getTime();
};

export const $getLocalTimeStamp = date => {
  const local = new Date(date);
  return new Date(local).getTime();
};

export const $hasSubSpeciality = user => user
  && user.specialities
  && user.professions
  && user.specialities.length
  && user.professions.length
  && filter(user.specialities, item => item.showSubspeciality === 1).length
  && filter(user.professions, item => item.showSubspeciality === 1).length;

export const $isProfileCompleted = user => {
  if (!user) return false;
  const {
    firstName,
    lastName,
    country,
  } = user;
  return (
    firstName
    && lastName
    && country
  );
};

export const $getCommaSeperated = arr => map(arr, 'name').join(', ');
