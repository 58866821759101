<template>
  <div id="app">
    <div>
        <div v-if="appLoading">
          <div
            :style="{
              height: `100vh`,
            }"
            class="text-center fs-20 pt-100"
          >
            <wtr-loader
              class="text-primary"
              loadingText="Loading Page Content....."
              addText
            ></wtr-loader>
          </div>
    </div>
    <app-fail v-if="hasError" />
    <div v-else-if="canLoadApp">
      <router-view />
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import appFailVue from "@/App-Fail.vue";
import appMixin from "@/mixins/init-mixin";

export default {
  name: "app",
  data() {
    return {
      hasSeenAlert: false,
    };
  },
  created() {
    if (process.env.VUE_APP_ENVIRONMENT === 'Staging') {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
      console.log('Environment:', process.env.VUE_APP_ENVIRONMENT);
    }    
  },
  mounted() {
    this.hasSeenAlert = localStorage.getItem("has-seen-alert");
    this.initApp();
  },
  computed: {
    ...mapGetters({
        loader: 'getLoaderStatus',
      }),
    canLoadApp() {
      return this.$tenantLoaded;
    },
  },
  components: {
    "app-fail": appFailVue,
  },
  mixins: [appMixin],
  methods: {
    ...mapActions(['$setDashboardHeightAction']),
    initApp() {
      this.initializeApp();
      window.addEventListener('resize', this.onResize);
      this.initLocalStorageEvent();
      this.initInternetEvent();
    },
    onResize(evt) {
      this.$setDashboardHeightAction(evt.target.innerHeight - 90);
    },
    onClearAlert() {
      this.hasSeenAlert = false;
      localStorage.setItem("has-seen-alert", true);
    },
    initInternetEvent() {
      if (!window.navigator.onLine) {
        this.$toastr("warning", "Sorry! No internet connection.");
      }
      window.addEventListener("online", () => {
        window.location.reload();
        this.$toastr("success", "Welcome back");
      });
      window.addEventListener("offline", () => {
        this.$toastr("warning", "Sorry! No internet connection.");
      });
    },
    initLocalStorageEvent() {
      window.addEventListener("storage", (event) => {
        if (event.key === "logout") {
          this.$logout(null, null, true);
        } else if (event.key === "login") {
          const isLoggedIn = localStorage.getItem("login");
          if (isLoggedIn) {
            window.location.reload();
          }
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
