export default{
    id:0,
    name:null,
    caseNumber:null,
    caseNotes:null,
    geographicRegionId:null,
    geographicRegion:null,
    tumorSiteId:null,
    tumorSite:null,
    tumorTypeId:null,
    tumorType:null,
    tumorSubTypeId:null,
    tumorSubType:null,
    slideRefId:0,
    country:null,
    states:null,
    age:null,
    sex:null,
    familialDisease:null,
    environmentalSettings:null,
    stage:null,
    tCategory:null,
    nCategory:null,
    mCategory:null,
    cytology:null,
    immunohistochemistry:null,
    genetics:null,
    contributorId:0,
    editorId:0,
    systemCaseNumber:null,
    tenantId:null,
    folderId:null,
}