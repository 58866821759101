export default [
    { id: 0, name:  'Under 1', value:  0},
    { id: 1, name:  '1', value:  1},
    { id: 2, name:  '2', value:  2},
    { id: 3, name:  '3', value:  3},
    { id: 4, name:  '4', value:  4},
    { id: 5, name:  '5', value:  5},
    { id: 6, name:  '6', value:  6},
    { id: 7, name:  '7', value:  7},
    { id: 8, name:  '8', value:  8},
    { id: 9, name:  '9', value:  9},
    { id: 10, name: '10', value: 10},
    { id: 11, name: '11', value: 11},
    { id: 12, name: '12', value: 12},
    { id: 13, name: '13', value: 13},
    { id: 14, name: '14', value: 14},
    { id: 15, name: '15', value: 15},
    { id: 16, name: '16', value: 16},
    { id: 17, name: '17', value: 17},
    { id: 18, name: '18', value: 18},
    { id: 19, name: '19', value: 19},
    { id: 20, name: '20', value: 20},
    { id: 21, name: '21', value: 21},
    { id: 22, name: '22', value: 22},
    { id: 23, name: '23', value: 23},
    { id: 24, name: '24', value: 24},
    { id: 25, name: '25', value: 25},
    { id: 26, name: '26', value: 26},
    { id: 27, name: '27', value: 27},
    { id: 28, name: '28', value: 28},
    { id: 29, name: '29', value: 29},
    { id: 30, name: '30', value: 30},
    { id: 31, name: '31', value: 31},
    { id: 32, name: '32', value: 32},
    { id: 33, name: '33', value: 33},
    { id: 34, name: '34', value: 34},
    { id: 35, name: '35', value: 35},
    { id: 36, name: '36', value: 36},
    { id: 37, name: '37', value: 37},
    { id: 38, name: '38', value: 38},
    { id: 39, name: '39', value: 39},
    { id: 40, name: '40', value: 40},
    { id: 41, name: '41', value: 41},
    { id: 42, name: '42', value: 42},
    { id: 43, name: '43', value: 43},
    { id: 44, name: '44', value: 44},
    { id: 45, name: '45', value: 45},
    { id: 46, name: '46', value: 46},
    { id: 47, name: '47', value: 47},
    { id: 48, name: '48', value: 48},
    { id: 49, name: '49', value: 49},
    { id: 50, name: '50', value: 50},
    { id: 51, name: '51', value: 51},
    { id: 52, name: '52', value: 52},
    { id: 53, name: '53', value: 53},
    { id: 54, name: '54', value: 54},
    { id: 55, name: '55', value: 55},
    { id: 56, name: '56', value: 56},
    { id: 57, name: '57', value: 57},
    { id: 58, name: '58', value: 58},
    { id: 59, name: '59', value: 59},
    { id: 60, name: '60', value: 60},
    { id: 61, name: '61', value: 61},
    { id: 62, name: '62', value: 62},
    { id: 63, name: '63', value: 63},
    { id: 64, name: '64', value: 64},
    { id: 65, name: '65', value: 65},
    { id: 66, name: '66', value: 66},
    { id: 67, name: '67', value: 67},
    { id: 68, name: '68', value: 68},
    { id: 69, name: '69', value: 69},
    { id: 70, name: '70', value: 70},
    { id: 71, name: '71', value: 71},
    { id: 72, name: '72', value: 72},
    { id: 73, name: '73', value: 73},
    { id: 74, name: '74', value: 74},
    { id: 75, name: '75', value: 75},
    { id: 76, name: '76', value: 76},
    { id: 77, name: '77', value: 77},
    { id: 78, name: '78', value: 78},
    { id: 79, name: '79', value: 79},
    { id: 80, name: '80', value: 80},
    { id: 81, name: '81', value: 81},
    { id: 82, name: '82', value: 82},
    { id: 83, name: '83', value: 83},
    { id: 84, name: '84', value: 84},
    { id: 85, name: '85', value: 85},
    { id: 86, name: '86', value: 86},
    { id: 87, name: '87', value: 87},
    { id: 88, name: '88', value: 88},
    { id: 89, name: '89', value: 89},
    { id: 90, name: '89+', value: 90},
    { id: 100, name: 'Unknown', value: -1},
];