export default [
  {
    name: 'scroll',
    data: {
      inserted: (el, binding) => {
        if (binding.value && binding.value.active) {
          binding.value.cb(binding.value.id);
        }
      },
      update: (el, binding) => {
        if (binding.value && binding.value.active) {
          binding.value.cb(binding.value.id);
        }
      },
    },
  },
];
