<template>
  <div>
    <multi-select
      @search-input="search = $event"
      :searchValue="search"
      variant="outline-primary"
      :selected="selected"
      :options="filteredOptions"
      size="sm"
      class="profile-credential"
      placeholder="Academic Degree/Credentials"
      @on-select="onSelect"
    >
      <div slot="tags" :class="selected.length > 0 ? 'pt-2' : ''">
        <wtr-tags
          :tags="selected"
          @on-remove="onRemove"
          @on-edit="onEdit"
        ></wtr-tags>
      </div>
    </multi-select>
    <b-modal
      hide-footer
      scrollable
      id="profile-crenentials"
      @hidden="selectedValue = null"
      title="Credentials"
      size=sm
    >
      <b-form v-if="selectedValue">
        <b-form-group
          :state="!errors.has('credentials')"
          :invalid-feedback="errors.first('credentials')"
        >
          <b-form-input
            v-model="selectedValue.name"
            trim
            placeholder="type here"
            type="text"
            name="credentials"
            v-validate="'required'"
            size="sm"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-btn
            variant="outline-primary"
            size="sm"
            v-on:click="toggleModal(false, null)"
          ><i class="fa fa-times"></i> Cancel</b-btn>
          <b-btn
            type="submit"
            variant="primary"
            size="sm"
            class="ml-10"
            :disabled="errors.has('credentials') || !selectedValue.name  || isSaving"
            @click.stop.prevent="onSubmit(selectedValue)"
          >
             <i class="fa fa-check"></i> {{selectedValue.id ? 'Update' : 'Save'}} <i
              class="fa fa-spinner fa-spin"
              v-if="isSaving"
            ></i>
          </b-btn>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep,filter } from 'lodash';
import WTRMultiselect from '../../../components/common/wtr-multiselect.vue';
import WTRTags from '../../../components/common/wtr-tags.vue';
import { API_POST, API_PUT, API_DELETE } from '../../../constants/constants-api';

export default {
  name: 'profile-credentials',
  data() {
    return {
      search: '',
      isSaving: false,
      selectedValue: null,
      API_POST,
      API_PUT,
      API_DELETE,
    };
  },
  computed: {
    filteredOptions() {
      return  this.search? filter(this.options, item => item.name === this.search):this.options;
    },
  },
  props: {
    options: Array,
    selected: Array,
  },
  components: {
    'multi-select': WTRMultiselect,
    'wtr-tags': WTRTags,
  },
  methods: {
    onSubmit(data) {
      this.isSaving = true;
      if (data.id) {
        this.onUpdate(data);
      } else {
        this.onSave(data);
      }
    },
    toggleModal(enable, val) {
      if (enable) {
        this.selectedValue = cloneDeep(val);
        this.$bvModal.show('profile-crenentials');
      } else {
        this.selectedValue = null;
        this.$bvModal.hide('profile-crenentials');
      }
    },
    onRemove({ id }) {
      this.$api
        .profileCredentialsResource(this.API_DELETE, {
          path: {
            id,
          },
        })
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            const { selected } = this;
            const index = selected.findIndex(item => item.id === id);
            selected.splice(index, 1);
            this.onValChanged(selected);
          },
          error => {
            this.$handleError(error);
          },
        );
    },
    onEdit(data) {
      this.toggleModal(true, data);
    },
    onSave(option) {
      this.$api
        .profileCredentialsResource(this.API_POST, {
          data: {
            name: option.name,
            hasCustomvalue: option.hasCustomvalue,
          },
        })
        .then(
          response => {
            this.isSaving = false;
            if (!this.$handleResponse(response)) return;
            const { selected } = this;
            selected.push(response.data.payload);
            if (option.hasCustomvalue) {
              this.toggleModal(false, null);
            }
            this.onValChanged(selected);
          },
          error => {
            this.isSaving = false;
            this.$handleError(error);
          },
        );
    },
    onUpdate(data) {
      this.$api
        .profileCredentialsResource(this.API_PUT, {
          data,
        })
        .then(
          response => {
            this.isSaving = false;
            if (!this.$handleResponse(response)) return;
            const { selected } = this;
            const index = selected.findIndex(item => item.id === data.id);
            selected.splice(index, 1, data);
            this.toggleModal(false, null);
            this.onValChanged(selected);
          },
          error => {
            this.isSaving = false;
            this.$handleError(error);
          },
        );
    },
    onSelect(option) {
      if (option.hasCustomvalue) {
        this.toggleModal(true, {
          id: 0,
          name: option.name,
          hasCustomvalue: option.hasCustomvalue,
        });
      } else {
        this.onSave(option);
      }
    },
    onValChanged(selected) {
      this.$emit('updated', selected);
    },
  },
};
</script>

<style scoped lang="scss">


</style>