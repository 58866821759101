import metadataModal from "./model-metadata";

export default {
  adminStatus: 'PENDING',
  dziPath: null,
  dziStatus: null,
  folderId: null,
  id: 0,
  name: null,
  path: null,
  section: null,
  system: null,
  species: null,
  slideMetadata: {
    id: 0,
    age: null,
    clinicalInfo: null,
    diagnosis: null,
    gender: 'UNKNOWN',
    secondDiagnosis: null,
    siteOROrgan: null,
    stainORstudy: null,
    strainORbreed: null,
    tags: null,
  },
  thumbnailPath: null,
  viewStatus: 'ACTIVE',
  viewType: 'PRIVATE',
  rawFileName: null,
  dicomMetadata: null,
  tissueType: null,
  uploadType: 'WEB',
  metadata:metadataModal
};
