<template>
<div class="slide-library" style="margin-top: 13px;">
    <b-card no-body hide-footer>
        <div slot="header" class="fs-16 fw-600 py-5 text-light">
            <breadcrumbs v-if="typeof crumbs === 'object'" :items="crumbs" />
            <span v-else>{{crumbs}}
            </span>
        </div>
        <b-card-body :style="cardBodyStyles" class="dashboard-body-view">
            <slide-library-search :canSelect="{
                select: false,
              }" 
              :hideActions="false" 
              :showPublicSlides="false"></slide-library-search>
        </b-card-body>
    </b-card>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';
import BreadCrumbs from '../../../components/common/breadcrumbs.vue';
import SlideLibrarySearch from '../../slide-library/components/slide-library-search.vue';

export default {
    name: 'slide-library',
    data() {
        return {};
    },
    components: {
        'slide-library-search': SlideLibrarySearch,
        breadcrumbs: BreadCrumbs,
    },
    computed: {
        ...mapGetters({
            crumbs: 'getCrumbs',
        }),
        cardBodyStyles() {
            const height = this.$dashboardHeight - 60;
            return this.$getCardBodyStyle(height);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(['sidebarAction', 'renderSidebarAction']),
        init() {
            if (this.$canAccess('myslidebox', 'canAccessModule')) {
                this.sidebarAction({
                    show: true
                });
                this.renderSidebarAction('dashboard-sidebar');
                this.$setHeaderAction(this.$t('common.slideLibrary'));
            } else {
                this.$changeRoute(this.$redirectTo());
            }
        },
    },
};
</script>
