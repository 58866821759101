<template>
  <section id="login-section" :class="options.modal == $t('administration.resetPassword') ? `` : `hero hero-login sec-padding-y`">
    <div :class="options.modal == $t('administration.resetPassword') ? `d-flex justify-content-center` : `container`">
      <div class="card border-0 login-box" :class="options.modal == $t('administration.resetPassword') ? `` : `centered-axis-xy`">
        <div class="card-body">
          <div v-if="linkExpired && isFromMailToken" class="pb-15">
            <h3 class="text-warning fw-900 pt-20 text-center">
              {{ $t("auth.expired") }}
            </h3>
            <div class="text-warning text-center">
              <wtr-img
                alt="thumbnail"
                :src="$icons.WARNING"
                width="50px"
                height="50px"
              />
            </div>
            <div class="card-body">
              <h6 class="text-center text-secondary">
                {{ $t("notes.linkExpired") }}
              </h6>
              <label class="text-center text-primary"
                >Use
                <b-link
                  size="sm"
                  :class="`fs-14 pr-0 pt-5 text-${variant}`"
                  v-on:click="$changeRoute({ name: 'forgot-password' })"
                  :disabled="disable"
                  >{{ $t("auth.forgotPassword") }}</b-link
                >
                to activate the account</label
              >
            </div>
          </div>
          <div v-if="isActivatedUser && isFromMailToken" class="pb-15">
            <h3 :class="`text-${variant} fw-900 pt-20 text-center`">
              {{ $t("auth.activated") }}
            </h3>
            <div :class="`text-${variant} text-center`">
              <wtr-img
                alt="thumbnail"
                :src="$icons.SUCCESS"
                width="50px"
                height="50px"
              />
            </div>
            <div class="card-body">
              <h6 class="text-center text-secondary">
                {{ $t("notes.youHaveSetYourPassword") }}.
                {{ $t("notes.proceedHereToLogin") }}.
              </h6>

              <b-button
                class="my-20"
                block
                pill
                :variant="variant"
                @click="$changeRoute({ name: 'login' })"
                >{{ $t("auth.logIn") }}</b-button
              >
            </div>
          </div>
          <div v-if="!(isActivatedUser && isFromMailToken) && !linkExpired">
            <h3 class="sec-title" v-if="!this.options">
              {{ title }}
            </h3>
            <div class="card-body create-password test">
              <form>
                <div class="form-group">
                  <div class="list-group sec-title text-left fs-12">
                    <li v-for="(item, itemIndex) in validate" :key="itemIndex">
                      {{ item.title }}
                      <span v-if="user.password">
                        <i
                          class="fa"
                          :class="{
                            [`fa-check-circle text-${variant}`]: item.valid,
                            'fa-times-circle text-danger': !item.valid,
                          }"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                  </div>
                </div>
                <div class="form-group" v-if="options && options.id">
                  <b-form-input
                    type="password"
                    v-on:change="validateOldPassword(oldPassword)"
                    v-model="oldPassword"
                    :placeholder="$t('notes.enterOldPassword')"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="password"
                    v-on:input="validatePassword(user.password)"
                    v-model="user.password"
                    :placeholder="$t('notes.enterNewPassword')"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="password"
                    v-on:input="validateConfirmPassword(user.confirmPassword)"
                    v-model="user.confirmPassword"
                    :placeholder="$t('notes.reEnterPassword')"
                  >
                  </b-form-input>
                </div>
                <div class="form-group" v-if="!(options && options.id)">
                  <b-form-checkbox name="agreeToTerms" aria-label="agreeToTerms" aria-labelledby="agreeToTerms" size="sm" v-model="agreeToTerms">
                    <div class="text-primary">
                        I have read and agree to the 
                        <a class="text-primary text-underline" href="/terms" target="_blank">Terms of Use</a>
                    </div>
                  </b-form-checkbox>
                </div>
                <wtr-btn
                  aria-label="confirm"
                  aria-labelledby="confirm"
                  :variant="variant"
                  pill
                  class="btn-block"
                  type="submit"
                  :disabled="!validPassword"
                  v-on:click="submitPassword(user)"
                  :isLoading="isLoading"
                  :text="$t('common.confirm')"
                ></wtr-btn>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { forEach, map } from "lodash";
import * as validations from "../../../helpers/validations";

export default {
  name: "create-password",
  data() {
    return {
      isLoading: false,
      oldPassword: null,
      isActivatedUser: null,
      linkExpired: null,
      user: {
        password: "",
        confirmPassword: "",
      },
      validPassword: false,
      agreeToTerms: false
    };
  },
  computed: {
    title() {
      return this.options
        ? this.options.props.title
        : this.$t("auth.createPassword");
    },
    userId() {
      return this.$queryParams.token || this.options.id;
    },
    isFromMailToken() {
      return this.$queryParams.token && !this.$queryParams.fromForgot;
    },
    validate() {
      return [
        {
          key: validations.minCharsCase,
          title: this.$t("auth.min8chars"),
          valid: false,
        },
        {
          key: validations.validateUpperCase,
          title: this.$t("auth.oneUppercase"),
          valid: false,
        },
        {
          key: validations.validateLowerCase,
          title: this.$t("auth.oneLowercase"),
          valid: false,
        },
        {
          key: validations.validateNumberCase,
          title: this.$t("auth.oneNumber"),
          valid: false,
        },
        {
          key: validations.validateSpecialCase,
          title: this.$t("auth.oneSpecialChar"),
          valid: false,
        },
        {
          key: this.getPasswordMatch,
          title: this.$t("auth.passwordsMatch"),
          valid: false,
        },
      ];
    },
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    variant: String,
  },
  methods: {
    getUserStatus() {
      if (!this.isFromMailToken) return;
      this.$api.getUserStatusFact(this.userId).then(
        (response) => {
          if (response.data.statusCode === 401 && !response.data.payload) {
            this.linkExpired = true;
          } else {
            this.isActivatedUser = !response.data.payload;
          }
          this.getTermsDetailsByUserId();
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    validateOldPassword() {},
    validatePassword(val) {
      forEach(this.validate, (item, index) => {
        this.validate[index].valid = item.key(val, 8);
      });
      this.validPassword = !map(this.validate, "valid").includes(false);
    },
    validateConfirmPassword(val) {
      if (val.length >= 6) {
        this.validatePassword(val);
      }
    },
    getPasswordMatch() {
      return this.user.password === this.user.confirmPassword;
    },
    submitPassword() {
      if (!this.validPassword) return;
      
        if (this.options && this.options.id)
        {
          this.resetPassword();
        }
        else
        {
          if (this.agreeToTerms)
          {
            this.createPassword();
            this.updateTermsDetails();
          }
          else
          {
            this.$toastr("error",this.$t("Please agree to the Terms of Use."));
          }
        }      
    },
    createPassword() {
      this.isLoading = true;
      this.$api
        .createPasswordFact(
          {
            newPassword: this.user.password,
          },
          this.userId
        )
        .then(
          () => {
            this.isLoading = false;
            this.$changeRoute({ name: "login" });
            this.$toastr(
              "success",
              this.$t("notes.createSuccessMsg", {
                text: this.$t("common.password"),
              })
            );
          },
          (error) => {
            this.isLoading = false;
            this.$handleError(error);
          }
        );
    },
    resetPassword() {
      if (!this.oldPassword) {
        this.$toastr("error", this.$t("auth.addOldPassword"));
        return;
      }
      this.$api
        .resetPasswordFact({
          oldPassword: this.oldPassword,
          newPassword: this.user.password,
          id: this.userId,
        })
        .then(
          () => {
            this.$toastr("success", this.$t("auth.passwordChangedSuccessMsg"));
            this.$setModalAction({
              show: false,
            });
          },
          (error) => {
            this.$handleError(error);
          }
        );
    },
    updateTermsDetails() {
      this.$api.updateTermsFact(this.userId).then(
          response => {                
            console.log(response);
          },
          error => {
              this.$handleError(error);
          },
      );
    },
    async getTermsDetailsByUserId() 
    {
      return this.$api.getTermsDetailsByUserIdFact(this.userId).then(
          (response) => {
              this.agreeToTerms = response.data.termsuse;
          },
          (error) => {
              this.$handleError(error);
          }
      );
    },
    async init() {
    }
  },
  mounted() {
    this.getUserStatus();
  },
};
</script>
<style lang="scss" scoped>
.sec-padding-y {
  padding: 10% 0 20%;
}
.container {
  max-width: 30%;
}
#btnLogin {
  width: 200px;
}
.sec-title {
  text-align: center;
  color: #1c52ab;
}
.text-underline {
  text-decoration: underline !important;
}
</style>
