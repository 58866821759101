const lowerPattern = new RegExp('^(?=.*[a-z]).+$');
const upperPattern = new RegExp('^(?=.*[A-Z]).+$');
const numberPattern = new RegExp('^(?=.*[0-9]).+$');
const specialPattern = new RegExp('^(?=.*[!"@#$%&\'()*+,-./:;<=>?[\\]^_{|}~]).+$'); //eslint-disable-line
const validEmail = new RegExp('^([A-Za-z0-9_\\-\\+.])+@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,5})$'); //eslint-disable-line
const validTwitter = new RegExp('^(https?:\/\/)?((w{3}\.)?)twitter\.com\/(#!\/)?[a-z0-9_]+$'); //eslint-disable-line
const validFacebook = new RegExp('^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*'); //eslint-disable-line
const validLinkedin = new RegExp('http(s)?:\/\/([w]{3}\.)?linkedin\.com\/(pub|in|profile|company)\/([a-zA-Z0-9-]{5,30})\/?'); //eslint-disable-line

export const validateLowerCase = text => lowerPattern.test(text);
export const validateUpperCase = text => upperPattern.test(text);
export const validateNumberCase = text => numberPattern.test(text);
export const validateSpecialCase = text => specialPattern.test(text);
export const minCharsCase = (text, num) => text.length >= num;
export const isEmailCase = email => validEmail.test(email);
export const isTwitterCase = text => validTwitter.test(text);
export const isFacebookCase = text => validFacebook.test(text);
export const isLinkedinCase = text => validLinkedin.test(text);
