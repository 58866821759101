import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from '@/vuex/store';
import { $i18n } from '@/helpers';
import plugins from "@/plugins/wtr-plugins";
import { initializeAxios } from '@/api';

Vue.config.productionTip = false;

initializeAxios();

Vue.use(plugins);

new Vue({
  i18n: $i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
