import { assign } from 'lodash';
import localStorage from '../helpers/localStorage';

const listSize = {
  xl: 8,
  lg: 8,
  md: 6,
  sm: 12,
};

export default {
  data() {
    return {
      listId: null,
      defaultSize: 8,
      listLoading: false,
      paramsUtil: {
        page: 0,
        size: this.defaultSize,
        sort: 'id,desc',
        filtertype: null,
        filtervalue: null,
        userId : null,
      },
      $sortBy: '',
    };
  },
  mounted() {
    this.setDefaultSize(listSize[this.$mq]);
  },
  methods: {
    checkParams(id) {
      this.listId = id;
      const data = localStorage.getItem(this.listId);
      if (!data) return;
      this.paramsUtil = data;
    },
    setDefaultSize(size) {
      this.defaultSize = size;
      this.paramsUtil = assign(this.paramsUtil, {
        size,
      });
    },
    $resetParamsUtil() {
      this.paramsUtil = {
        page: 0,
        size: this.defaultSize,
        sort: 'id,desc',
        filtervalue: null,
        filtertype: null,
      };
      this.$sortBy = '';
      return this.paramsUtil;
    },
    $sortList(type) {
      if (this.$sortBy && this.$sortBy.includes(type)) {
        this.$sortBy = this.$sortBy.includes('asc') ? `${type},desc` : `${type},asc`;
      } else if (this.$sortBy || type) {
        this.$sortBy = `${type},asc`;
      } else {
        this.$sortBy = 'id,desc';
      }
      return this.$sortBy;
    },
  },
};
