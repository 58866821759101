export default 
[
    {
        "country": "Afghanistan",
        "latitude": 33.9391,
        "longitude": 67.7100,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "AF"
    },
    {
        "country": "Albania",
        "latitude": 41.1533,
        "longitude": 20.1683,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "AL"
    },
    {
        "country": "Algeria",
        "latitude": 28.0339,
        "longitude": 1.6596,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "DZ"
    },
    {
        "country": "Andorra",
        "latitude": 42.5063,
        "longitude": 1.5218,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "AD"
    },
    {
        "country": "Angola",
        "latitude": -11.2027,
        "longitude": 17.8739,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "AO"
    },
    {
        "country": "Antigua and Barbuda",
        "latitude": 17.0608,
        "longitude": -61.7964,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "AG"
    },
    {
        "country": "Argentina",
        "latitude": -38.4161,
        "longitude": -63.6167,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "AR"
    },
    {
        "country": "Armenia",
        "latitude": 40.0691,
        "longitude": 45.0382,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "AM"
    },
    {
        "country": "Australia",
        "latitude": -25.2744,
        "longitude": 133.7751,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "AU"
    },
    {
        "country": "Austria",
        "latitude": 47.5162,
        "longitude": 14.5501,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "AT"
    },
    {
        "country": "Azerbaijan",
        "latitude": 40.1431,
        "longitude": 47.5769,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "AZ"
    },
    {
        "country": "Bahamas",
        "latitude": 25.0343,
        "longitude": -77.3963,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "BS"
    },
    {
        "country": "Bahrain",
        "latitude": 26.0275,
        "longitude": 50.5500,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "BH"
    },
    {
        "country": "Bangladesh",
        "latitude": 23.6850,
        "longitude": 90.3563,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "BD"
    },
    {
        "country": "Barbados",
        "latitude": 13.1939,
        "longitude": -59.5432,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "BB"
    },
    {
        "country": "Belarus",
        "latitude": 53.709807,
        "longitude": 27.953389,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "BY"
    },
    {
        "country": "Belgium",
        "latitude": 50.5039,
        "longitude": 4.4699,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "BE"
    },
    {
        "country": "Belize",
        "latitude": 17.1899,
        "longitude": -88.4976,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "BZ"
    },
    {
        "country": "Benin",
        "latitude": 9.3077,
        "longitude": 2.3158,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "BJ"
    },
    {
        "country": "Bhutan",
        "latitude": 27.5142,
        "longitude": 90.4336,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "BT"
    },
    {
        "country": "Bolivia (Plurinational State of)",
        "latitude": -16.2902,
        "longitude": -63.5887,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "BO"
    },
    {
        "country": "Bosnia and Herzegovina",
        "latitude": 43.9159,
        "longitude": 17.6791,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "BA"
    },
    {
        "country": "Botswana",
        "latitude": -22.3285,
        "longitude": 24.6849,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "BW"
    },
    {
        "country": "Brazil",
        "latitude": -14.2350,
        "longitude": -51.9253,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "BR"
    },
    {
        "country": "Brunei Darussalam",
        "latitude": 4.5353,
        "longitude": 114.7277,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "BN"
    },
    {
        "country": "Bulgaria",
        "latitude": 42.7339,
        "longitude": 25.4858,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "BG"
    },
    {
        "country": "Burkina Faso",
        "latitude": 12.2383,
        "longitude": -1.5616,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "BF"
    },
    {
        "country": "Burundi",
        "latitude": -3.3731,
        "longitude": 29.9189,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "BI"
    },
    {
        "country": "Cabo Verde",
        "latitude": 15.1201,
        "longitude": -23.6052,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CV"
    },
    {
        "country": "Cambodia",
        "latitude": 12.5657,
        "longitude": 104.9910,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KH"
    },
    {
        "country": "Cameroon",
        "latitude": 7.3697,
        "longitude": 12.3547,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CM"
    },
    {
        "country": "Canada",
        "latitude": 56.130366,
        "longitude": -106.346771,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "CA"
    },
    {
        "country": "Cape Verde",
        "latitude": 16.0021,
        "longitude": -24.0132,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CV"
    },
    {
        "country": "Central African Republic",
        "latitude": 6.6111,
        "longitude": 20.9394,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CF"
    },
    {
        "country": "Chad",
        "latitude": 15.4542,
        "longitude": 18.7322,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "TD"
    },
    {
        "country": "Chile",
        "latitude": -35.6751,
        "longitude": -71.5430,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "CL"
    },
    {
        "country": "China",
        "latitude": 35.8617,
        "longitude": 104.1954,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "CN"
    },
    {
        "country": "Colombia",
        "latitude": 4.5709,
        "longitude": -74.2973,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "CO"
    },
    {
        "country": "Comoros",
        "latitude": -11.6455,
        "longitude": 43.3333,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "KM"
    },
    {
        "country": "Congo",
        "latitude": -0.2280,
        "longitude": 15.8277,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CG"
    },
    {
        "country": "Costa Rica",
        "latitude": 9.7489,
        "longitude": -83.7534,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "CR"
    },
    {
        "country": "Ivory Coast",
        "latitude": 7.53999,
        "longitude": -5.54708,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CI"
    },
    {
        "country": "Croatia",
        "latitude": 45.1000,
        "longitude": 15.2000,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "HR"
    },
    {
        "country": "Cuba",
        "latitude": 21.5218,
        "longitude": -77.7812,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "CU"
    },
    {
        "country": "Cyprus",
        "latitude": 35.1264,
        "longitude": 33.4299,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "CY"
    },
    {
        "country": "Czech Republic",
        "latitude": 49.8175,
        "longitude": 15.4730,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "CZ"
    },
    {
        "country": "Democratic People's Republic of Korea",
        "latitude": 40.3399,
        "longitude": 127.5101,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KP"
    },
    {
        "country": "Democratic Republic of the Congo",
        "latitude": 4.0383,
        "longitude": 21.7587,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "CD"
    },
    {
        "country": "Denmark",
        "latitude": 56.2639,
        "longitude": 9.5018,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "DK"
    },
    {
        "country": "Djibouti",
        "latitude": 11.8251,
        "longitude": 42.5903,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "DJ"
    },
    {
        "country": "Dominica",
        "latitude": 15.4148,
        "longitude": -61.3709,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "DM"
    },
    {
        "country": "Dominican Republic",
        "latitude": 18.7357,
        "longitude": -70.1627,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "DO"
    },
    {
        "country": "Ecuador",
        "latitude": -1.8312,
        "longitude": -78.1834,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "EC"
    },
    {
        "country": "Egypt",
        "latitude": 26.8206,
        "longitude": 30.8025,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "EG"
    },
    {
        "country": "El Salvador",
        "latitude": 13.7942,
        "longitude": -88.8965,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "SV"
    },
    {
        "country": "Equatorial Guinea",
        "latitude": 1.6508,
        "longitude": 10.2679,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GQ"
    },
    {
        "country": "Eritrea",
        "latitude": 15.1794,
        "longitude": 39.7823,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ER"
    },
    {
        "country": "Estonia",
        "latitude": 58.5953,
        "longitude": 25.0136,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "EE"
    },
    {
        "country": "Eswatini",
        "latitude": -26.5225,
        "longitude": 31.4659,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SZ"
    },
    {
        "country": "Ethiopia",
        "latitude": 9.1450,
        "longitude": 40.4897,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ET"
    },
    {
        "country": "Fiji",
        "latitude": -17.7134,
        "longitude": 178.0650,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "FJ"
    },
    {
        "country": "Finland",
        "latitude": 61.9241,
        "longitude": 25.7482,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "FI"
    },
    {
        "country": "France",
        "latitude": 46.2276,
        "longitude": 2.2137,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "FR"
    },
    {
        "country": "Gabon",
        "latitude": -0.803689,
        "longitude": 11.609444,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GA"
    },
    {
        "country": "Gambia",
        "latitude": 13.454,
        "longitude": -16.575,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GM"
    },
    {
        "country": "Georgia",
        "latitude": 42.3154,
        "longitude": 43.3569,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "GE"
    },
    {
        "country": "Germany",
        "latitude": 51.1657,
        "longitude": 10.4515,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "DE"
    },
    {
        "country": "Ghana",
        "latitude": 7.9465,
        "longitude": -1.0232,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GH"
    },
    {
        "country": "Greece",
        "latitude": 39.0742,
        "longitude": 21.8243,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "GR"
    },
    {
        "country": "Grenada",
        "latitude": 12.1165,
        "longitude": -61.6789,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "GD"
    },
    {
        "country": "Guatemala",
        "latitude": 15.7835,
        "longitude": -90.2308,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "GT"
    },
    {
        "country": "Guinea",
        "latitude": 9.9456,
        "longitude": -9.6966,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GN"
    },
    {
        "country": "Guinea-Bissau",
        "latitude": 11.8037,
        "longitude": -15.1804,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "GW"
    },
    {
        "country": "Guyana",
        "latitude": 4.8604,
        "longitude": -58.9302,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "GY"
    },
    {
        "country": "Haiti",
        "latitude": 18.9712,
        "longitude": -72.2852,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "HT"
    },
    {
        "country": "Honduras",
        "latitude": 15.2000,
        "longitude": -86.2419,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "HN"
    },
    {
        "country": "Hungary",
        "latitude": 47.1625,
        "longitude": 19.5033,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "HU"
    },
    {
        "country": "Iceland",
        "latitude": 64.9631,
        "longitude": -19.0208,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "IS"
    },
    {
        "country": "India",
        "latitude": 20.5937,
        "longitude": 78.9629,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "IN"
    },
    {
        "country": "Indonesia",
        "latitude": -0.7893,
        "longitude": 113.9213,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "ID"
    },
    {
        "country": "Iran",
        "latitude": 32.427908,
        "longitude": 53.688046,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "IR"
    },
    {
        "country": "Iraq",
        "latitude": 33.2232,
        "longitude": 43.6793,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "IQ"
    },
    {
        "country": "Ireland",
        "latitude": 53.1424,
        "longitude": -7.6921,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "IE"
    },
    {
        "country": "Israel",
        "latitude": 31.0461,
        "longitude": 34.8516,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "IL"
    },
    {
        "country": "Italy",
        "latitude": 41.8719,
        "longitude": 12.5674,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "IT"
    },
    {
        "country": "Jamaica",
        "latitude": 18.1096,
        "longitude": -77.2975,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "JM"
    },
    {
        "country": "Japan",
        "latitude": 36.2048,
        "longitude": 138.2529,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "JP"
    },
    {
        "country": "Jordan",
        "latitude": 30.5852,
        "longitude": 36.2384,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "JO"
    },
    {
        "country": "Kazakhstan",
        "latitude": 48.0196,
        "longitude": 66.9237,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KZ"
    },
    {
        "country": "Kenya",
        "latitude": -0.0236,
        "longitude": 37.9062,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "KE"
    },
    {
        "country": "Kiribati",
        "latitude": -3.3704,
        "longitude": -168.734,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KI"
    },
    {
        "country": "Kuwait",
        "latitude": 29.3117,
        "longitude": 47.4818,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KW"
    },
    {
        "country": "Kyrgyzstan",
        "latitude": 41.2044,
        "longitude": 74.7661,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KG"
    },
    {
        "country": "Laos",
        "latitude": 19.8563,
        "longitude": 102.4955,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "LA"
    },
    {
        "country": "Latvia",
        "latitude": 56.8796,
        "longitude": 24.6032,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "LV"
    },
    {
        "country": "Lebanon",
        "latitude": 33.8547,
        "longitude": 35.8623,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "LB"
    },
    {
        "country": "Lesotho",
        "latitude": -29.6100,
        "longitude": 28.2336,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "LS"
    },
    {
        "country": "Liberia",
        "latitude": 6.4281,
        "longitude": -9.4295,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "LR"
    },
    {
        "country": "Libya",
        "latitude": 26.3351,
        "longitude": 17.2283,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "LY"
    },
    {
        "country": "Liechtenstein",
        "latitude": 47.1660,
        "longitude": 9.5554,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "LI"
    },
    {
        "country": "Lithuania",
        "latitude": 55.1694,
        "longitude": 23.8813,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "LT"
    },
    {
        "country": "Luxembourg",
        "latitude": 49.8153,
        "longitude": 6.1296,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "LU"
    },
    {
        "country": "Madagascar",
        "latitude": -18.7669,
        "longitude": 46.8691,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MG"
    },
    {
        "country": "Malawi",
        "latitude": -13.2543,
        "longitude": 34.3015,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MW"
    },
    {
        "country": "Malaysia",
        "latitude": 4.2105,
        "longitude": 101.9758,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "MY"
    },
    {
        "country": "Maldives",
        "latitude": 3.2028,
        "longitude": 73.2207,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "MV"
    },
    {
        "country": "Mali",
        "latitude": 17.5707,
        "longitude": -3.9962,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ML"
    },
    {
        "country": "Malta",
        "latitude": 35.9375,
        "longitude": 14.3754,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "MT"
    },
    {
        "country": "Marshall Islands",
        "latitude": 7.1315,
        "longitude": 171.1845,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "MH"
    },
    {
        "country": "Mauritania",
        "latitude": 21.0079,
        "longitude": -10.9408,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MR"
    },
    {
        "country": "Mauritius",
        "latitude": -20.3484,
        "longitude": 57.5522,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MU"
    },
    {
        "country": "Mexico",
        "latitude": 23.6345,
        "longitude": -102.5528,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "MX"
    },
    {
        "country": "Micronesia",
        "latitude": 7.425554,
        "longitude": 150.550812,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "FM"
    },
    {
        "country": "Moldova",
        "latitude": 47.4116,
        "longitude": 28.3699,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "MD"
    },
    {
        "country": "Monaco",
        "latitude": 43.7384,
        "longitude": 7.4246,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "MC"
    },
    {
        "country": "Mongolia",
        "latitude": 46.8625,
        "longitude": 103.8467,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "MN"
    },
    {
        "country": "Montenegro",
        "latitude": 42.7087,
        "longitude": 19.3744,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "ME"
    },
    {
        "country": "Morocco",
        "latitude": 31.7917,
        "longitude": -7.0926,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MA"
    },
    {
        "country": "Mozambique",
        "latitude": -18.6657,
        "longitude": 35.5296,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "MZ"
    },
    {
        "country": "Myanmar (Burma)",
        "latitude": 21.9162,
        "longitude": 95.9560,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "MM"
    },
    {
        "country": "Namibia",
        "latitude": -22.9576,
        "longitude": 18.4904,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "NA"
    },
    {
        "country": "Nauru",
        "latitude": -0.5228,
        "longitude": 166.9315,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "NR"
    },
    {
        "country": "Nepal",
        "latitude": 28.3949,
        "longitude": 84.1240,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "NP"
    },
    {
        "country": "Netherlands",
        "latitude": 52.1326,
        "longitude": 5.2913,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "NL"
    },
    {
        "country": "New Zealand",
        "latitude": -40.9006,
        "longitude": 174.8859,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "NZ"
    },
    {
        "country": "Nicaragua",
        "latitude": 12.8654,
        "longitude": -85.2072,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "NI"
    },
    {
        "country": "Niger",
        "latitude": 17.6078,
        "longitude": 8.0817,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "NE"
    },
    {
        "country": "Nigeria",
        "latitude": 9.0820,
        "longitude": 8.6753,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "NG"
    },
    {
        "country": "North Korea",
        "latitude": 40.339852,
        "longitude": 127.510093,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KP"
    },
    {
        "country": "Norway",
        "latitude": 60.4720,
        "longitude": 8.4689,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "NO"
    },
    {
        "country": "Oman",
        "latitude": 21.4735,
        "longitude": 55.9754,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "OM"
    },
    {
        "country": "Pakistan",
        "latitude": 30.3753,
        "longitude": 69.3451,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "PK"
    },
    {
        "country": "Palau",
        "latitude": 7.5150,
        "longitude": 134.5825,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "PW"
    },
    {
        "country": "Panama",
        "latitude": 8.5379,
        "longitude": -80.7821,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "PA"
    },
    {
        "country": "Papua New Guinea",
        "latitude": -6.3146,
        "longitude": 143.9555,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "PG"
    },
    {
        "country": "Paraguay",
        "latitude": -23.4425,
        "longitude": -58.4438,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "PY"
    },
    {
        "country": "Peru",
        "latitude": -9.1900,
        "longitude": -75.0152,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "PE"
    },
    {
        "country": "Philippines",
        "latitude": 12.8797,
        "longitude": 121.7740,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "PH"
    },
    {
        "country": "Poland",
        "latitude": 51.9194,
        "longitude": 19.1451,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "PL"
    },
    {
        "country": "Portugal",
        "latitude": 39.3999,
        "longitude": -8.2245,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "PT"
    },
    {
        "country": "Qatar",
        "latitude": 25.3548,
        "longitude": 51.1839,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "QA"
    },
    {
        "country": "Romania",
        "latitude": 45.9432,
        "longitude": 24.9668,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "RO"
    },
    {
        "country": "Russia",
        "latitude": 61.5240,
        "longitude": 105.3188,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "RU"
    },
    {
        "country": "Rwanda",
        "latitude": -1.9403,
        "longitude": 29.8739,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "RW"
    },
    {
        "country": "Saint Kitts and Nevis",
        "latitude": 17.357822,
        "longitude": -62.782998,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "KN"
    },
    {
        "country": "Saint Lucia",
        "latitude": 13.9094,
        "longitude": -60.9789,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "LC"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "latitude": 13.2528,
        "longitude": -61.1971,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "VC"
    },
    {
        "country": "Samoa",
        "latitude": -13.7590,
        "longitude": -172.1046,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "WS"
    },
    {
        "country": "San Marino",
        "latitude": 43.9424,
        "longitude": 12.4578,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "SM"
    },
    {
        "country": "Sao Tome and Principe",
        "latitude": 0.1864,
        "longitude": 6.6131,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ST"
    },
    {
        "country": "Saudi Arabia",
        "latitude": 23.8859,
        "longitude": 45.0792,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SA"
    },
    {
        "country": "Senegal",
        "latitude": 14.4974,
        "longitude": -14.4524,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SN"
    },
    {
        "country": "Serbia",
        "latitude": 44.0165,
        "longitude": 21.0059,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "RS"
    },
    {
        "country": "Seychelles",
        "latitude": -4.6796,
        "longitude": 55.4920,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SC"
    },
    {
        "country": "Sierra Leone",
        "latitude": 8.4606,
        "longitude": -11.7799,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SL"
    },
    {
        "country": "Singapore",
        "latitude": 1.3521,
        "longitude": 103.8198,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "SG"
    },
    {
        "country": "Slovakia",
        "latitude": 48.6690,
        "longitude": 19.6990,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "SK"
    },
    {
        "country": "Slovenia",
        "latitude": 46.1512,
        "longitude": 14.9955,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "SI"
    },
    {
        "country": "Solomon Islands",
        "latitude": -9.6457,
        "longitude": 160.1562,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "SB"
    },
    {
        "country": "Somalia",
        "latitude": 5.1521,
        "longitude": 46.1996,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SO"
    },
    {
        "country": "South Africa",
        "latitude": -30.559482,
        "longitude": 22.937506,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ZA"
    },
    {
        "country": "South Korea",
        "latitude": 35.9078,
        "longitude": 127.7669,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "KR"
    },
    {
        "country": "South Sudan",
        "latitude": 6.8770,
        "longitude": 31.3070,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SS"
    },
    {
        "country": "Spain",
        "latitude": 40.4637,
        "longitude": -3.7492,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "ES"
    },
    {
        "country": "Sri Lanka",
        "latitude": 7.8731,
        "longitude": 80.7718,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "LK"
    },
    {
        "country": "Sudan",
        "latitude": 12.8628,
        "longitude": 30.2176,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "SD"
    },
    {
        "country": "Suriname",
        "latitude": 3.9193,
        "longitude": -56.0278,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "SR"
    },
    {
        "country": "Sweden",
        "latitude": 60.1282,
        "longitude": 18.6435,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "SE"
    },
    {
        "country": "Switzerland",
        "latitude": 46.8182,
        "longitude": 8.2275,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "CH"
    },
    {
        "country": "Syria",
        "latitude": 34.8021,
        "longitude": 38.9968,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "SY"
    },
    {
        "country": "Taiwan",
        "latitude": 23.6978,
        "longitude": 120.9605,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TW"
    },
    {
        "country": "Tajikistan",
        "latitude": 38.8610,
        "longitude": 71.2761,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TJ"
    },
    {
        "country": "Tanzania",
        "latitude": -6.3690,
        "longitude": 34.8888,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "TZ"
    },
    {
        "country": "Thailand",
        "latitude": 15.8700,
        "longitude": 100.9925,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TH"
    },
    {
        "country": "Timor-Leste",
        "latitude": -8.8742,
        "longitude": 125.7275,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TL"
    },
    {
        "country": "Togo",
        "latitude": 8.6195,
        "longitude": 0.8248,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "TG"
    },
    {
        "country": "Tonga",
        "latitude": -21.178986,
        "longitude": -175.198242,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TO"
    },
    {
        "country": "Trinidad and Tobago",
        "latitude": 10.6918,
        "longitude": -61.2225,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "TT"
    },
    {
        "country": "Tunisia",
        "latitude": 33.8869,
        "longitude": 9.5375,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "TN"
    },
    {
        "country": "Turkey",
        "latitude": 38.9637,
        "longitude": 35.2433,
        "region": "Europe and North Asia",
        "regionId": 4,
        "countryCode": "TR"
    },
    {
        "country": "Turkmenistan",
        "latitude": 38.9697,
        "longitude": 59.5563,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TM"
    },
    {
        "country": "Tuvalu",
        "latitude": -7.4783,
        "longitude": 178.6799,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "TV"
    },
    {
        "country": "Uganda",
        "latitude": 1.3733,
        "longitude": 32.2903,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "UG"
    },
    {
        "country": "Ukraine",
        "latitude": 48.3794,
        "longitude": 31.1656,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "UA"
    },
    {
        "country": "United Arab Emirates",
        "latitude": 23.4241,
        "longitude": 53.8478,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "AE"
    },
    {
        "country": "United Kingdom",
        "latitude": 55.3781,
        "longitude": -3.4360,
        "region": "Europe",
        "regionId": 4,
        "countryCode": "GB"
    },
    {
        "country": "United States",
        "latitude": 37.0902,
        "longitude": -95.7129,
        "region": "Northern America",
        "regionId": 5,
        "countryCode": "US"
    },
    {
        "country": "Uruguay",
        "latitude": -32.5228,
        "longitude": -55.7658,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "UY"
    },
    {
        "country": "Uzbekistan",
        "latitude": 41.3775,
        "longitude": 64.5853,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "UZ"
    },
    {
        "country": "Vanuatu",
        "latitude": -15.3767,
        "longitude": 166.9592,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "VU"
    },
    {
        "country": "Venezuela",
        "latitude": 6.4238,
        "longitude": -66.5897,
        "region": "Central and South America",
        "regionId": 3,
        "countryCode": "VE"
    },
    {
        "country": "Vietnam",
        "latitude": 14.0583,
        "longitude": 108.2772,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "VN"
    },
    {
        "country": "Yemen",
        "latitude": 15.552727,
        "longitude": 48.516388,
        "region": "Asia and Oceania",
        "regionId": 2,
        "countryCode": "YE"
    },
    {
        "country": "Zambia",
        "latitude": -13.1339,
        "longitude": 27.8493,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ZM"
    },
    {
        "country": "Zimbabwe",
        "latitude": -19.0154,
        "longitude": 29.1549,
        "region": "Africa And Middle East",
        "regionId": 1,
        "countryCode": "ZW"
    },
];


