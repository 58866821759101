// Slide Types
export const SLIDE_DIGITALSLIDE = 'DIGITALSLIDES';
export const SLIDE_ZOOMIFY = 'ZOOMIFY';
export const SLIDE_IMAGE = 'IMAGE';
export const SLIDE_VIDEO = 'VIDEO';
export const SLIDE_DOCUMENT = 'DOCUMENT';
export const SLIDE_RADIOLOGY = 'RADIOLOGY';
export const SLIDE_UNKNOWN = 'UNKNOWN';


// View Type
export const SLIDE_PUBLIC = 'PUBLIC';
export const SLIDE_PRIVATE = 'PRIVATE';

// Conversion status
export const SLIDE_PROCESSING = 2;
export const SLIDE_PROCESSED = 1;
export const SLIDE_CONVERTING = 5;
export const SLIDE_SYNCING = 6;
export const SLIDE_PENDING = 0;
export const SLIDE_UPLOAD_FAILED = 4;
export const SLIDE_PROCESSING_FAILED = 3;
export const SLIDE_READY_TO_PROCESS = 7;

// Approval status
export const SLIDE_REQUEST_PENDING = 'PENDING';
export const SLIDE_REQUEST_APPROVED = 'APPROVED';

// Gender
export const SLIDE_UPLOAD_UNKNOWN = 'Unknown';
export const SLIDE_UPLOAD_MALE = 'Male';
export const SLIDE_UPLOAD_FEMALE = 'Female';



