// Roles
export const ROLE_TENANT_ADMIN = 'TENANT ADMIN';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_GUEST = 'GUEST';
export const ROLE_EDITOR = 'EDITOR';
export const ROLE_PROJECT_ADMIN = 'PROJECT ADMIN';
export const ROLE_CHAIRPERSON = 'CHAIRPERSON';
export const ROLE_CHIEF_EDITOR = 'CHIEF EDITOR';
export const ROLE_ADVISOR = 'ADVISOR';
export const ROLE_CONTRIBUTOR = 'CONTRIBUTOR';
