const types = {    
  SHOW_CASE_SLIDES: 'SHOW_CASE_SLIDES',
  SHOW_CASE_VIEW: 'SHOW_CASE_VIEW',
  SELECTED_CASE_NUMBER: 'SELECTED_CASE_NUMBER',
  SELECTED_CASE_SLIDE_IDS: 'SELECTED_CASE_SLIDE_IDS',
  SELECTED_FOLDER_VIEW: 'SELECTED_FOLDER_VIEW',
  SELECTED_FOLDER_USER_ROLE: 'SELECTED_FOLDER_USER_ROLE',
  SELECTED_FOLDER_USER_REGION: 'SELECTED_FOLDER_USER_REGION',
};

export default {
  state: {
    showCaseSlides:false,
    showCaseView:false,
    selectedCaseNumber:'',
    selectedCaseSlideIds: [],
    selectedFolderView:'',
    selectedFolderUserRole:'',
  },
  mutations: {
    [types.SHOW_CASE_SLIDES](state, data) {
      state.showCaseSlides = data; //eslint-disable-line
    },
    [types.SHOW_CASE_VIEW](state, data) {
      state.showCaseView = data; //eslint-disable-line
    },
    [types.SELECTED_CASE_NUMBER](state, data) {
      state.selectedCaseNumber = data; //eslint-disable-line
    },
    [types.SELECTED_CASE_SLIDE_IDS](state, data) {
      state.selectedCaseSlideIds = data; //eslint-disable-line
    },
    [types.SELECTED_FOLDER_VIEW](state, data) {
      state.selectedFolderView = data; //eslint-disable-line
    },
    [types.SELECTED_FOLDER_USER_ROLE](state, data) {
      state.selectedFolderUserRole = data; //eslint-disable-line
    },
    [types.SELECTED_FOLDER_USER_REGION](state, data) {
      state.selectedFolderUserRegion = data; //eslint-disable-line
    },
  },
  actions: {
    setShowCaseSlides({ commit },data) {
      commit(types.SHOW_CASE_SLIDES, data);
    },
    setShowCaseView({ commit },data) {
      commit(types.SHOW_CASE_VIEW, data);
    },
    setSelectedCaseNumber({ commit },data) {
      commit(types.SELECTED_CASE_NUMBER, data);
    },
    setSelectedCaseSlideIds({ commit },data) {
      commit(types.SELECTED_CASE_SLIDE_IDS, data);
    },
    setSelectedFolderView({ commit },data) {
      commit(types.SELECTED_FOLDER_VIEW, data);
    },
    setSelectedFolderUserRole({ commit },data) {
      commit(types.SELECTED_FOLDER_USER_ROLE, data);
    },
    setSelectedFolderUserRegion({ commit },data) {
      commit(types.SELECTED_FOLDER_USER_REGION, data);
    },
  },
  getters: {
    getShowCaseSlidesStatus: state => state.showCaseSlides,
    getShowCaseViewStatus: state => state.showCaseView,
    getSelectedCaseNumber: state => state.selectedCaseNumber,
    getSelectedCaseSlideIds: state => state.selectedCaseSlideIds,
    getSelectedFolderView: state => state.selectedFolderView,
    getSelectedFolderUserRole: state => state.selectedFolderUserRole,
    getSelectedFolderUserRegion: state => state.selectedFolderUserRegion,
  },
};
