<template>
  <div class="pt-1 position-relative">
    <div v-if="foldersLoadingParent" class="position-absolute d-flex justify-content-center py-20 w-100">
      <wtr-loader class="d-block" variant="primary" addText></wtr-loader>
    </div>
    <div :class="{ 'fetch-class': foldersLoadingParent }">
      <v-jstree v-if="folders"
        :data="folders"
        :draggable="!disableActions"            
        dragOverBackgroundColor="#4d7189"
      >
        <template slot-scope="_">
          <div :title="_.model.name" v-if="canShowFolder(_.model)">
            <div v-on:click.prevent="selectNode(_.model)" :class="getActiveClass(_.model)">
              <span><img v-if="!_.model.loading" class="folder-icon-img-size" :src="require('@/assets/icons-new/'+getFolderIcon(_.model)+'.svg')" alt="Icon" /></span>
              <span>&nbsp;{{_.model.name}}</span>
            </div>
          </div>
        </template>
      </v-jstree>
    </div>

    <!-- <b-btn-group
      class="ml-10"
      v-if="!hideNewFolderCreation"
    >
      <b-btn
        aria-label="create item"
        aria-labelledby="create item"
        variant="link text-light"
        size="sm"
        :title="$t('common.createItem', { item: $t('slidebox.folder')})"
        v-on:click="createFolder(activeFolder, 'add')"
      >
        <i class="fa fa-plus-circle"></i> {{$t('slidebox.newFolder')}}
      </b-btn>
    </b-btn-group> -->
    <v-jstree
      class="custom-tree d-none"
      v-if="folders"
      :data="folders"
      :draggable="!disableActions"            
      dragOverBackgroundColor="#4d7189"
    >
      <template slot-scope="_">
        <div @contextmenu.prevent="onRightClick($event, _.model)">
          <div 
            :title="_.model.name"
            v-if="canShowFolder(_.model)"
          >
            <!-- <i
              class="fs-10 pr-5 text-primary"
              :class="getExpansionIcon(_.model)"
              v-on:click="openFolder(_.model)"
            >
            </i> -->
            <span
              v-on:click.prevent="selectNode(_.model)"
              :class="getActiveClass(_.model)"
            >
              <!-- <i
                :class="getRootFolderIcon(_.model)"
                class="fa fa-folder pr-5 text-primary"
                v-if="!_.model.loading"
              >
              </i> -->
              <img  v-if="!_.model.loading" class="folder-icon-img-size" :src="require('@/assets/icons-new/'+getFolderIcon(_.model)+'.svg')" alt="Icon" />
               {{_.model.name}}
            </span>
          </div>
        </div>
        <!-- <span
          :title="$t('common.actions')"
          v-if="!disableActions"
          class="pl-5"
          :class="{'d-none': !$userAgent.isMobile,'d-inline-block':$userAgent.isMobile}"
          v-on:click.stop="onRightClick($event, _.model)"
        >
          <i class="fa fa-ellipsis-v"></i>
        </span> -->
      </template>
    </v-jstree>
    <!-- <b-modal
      v-if="!disableActions"
      centered
      size="sm"
      hide-footer
      title="Folder Options"
      id="context-actions"
    >
      <folder-actions
        v-if="selectedFolder"
        :selectedFolder="selectedFolder"
        :copiedFolder="copiedFolder"
        @create-folder="createFolder(selectedFolder, 'add')"
        @update-folder="createFolder(selectedFolder, 'update')"
        @copy-folder="cutAction(null, selectedFolder, null, 'copy')"
        @cut-folder="cutAction(null, selectedFolder, null, 'cut')"
        @paste-folder="pasteFolder(null, selectedFolder)"
        @delete-folder="deleteFolder(selectedFolder)"
        @restore-folder="restoreFolder(selectedFolder)"
      ></folder-actions>
    </b-modal> -->
    <!-- <create-folder
      :folderData="$modalData.data"
      :isSaving="isSaving"
      @create-folder="saveFolder"
      v-if="$modalData && $modalData.data && $modalData.route === 'create-folder'"
    ></create-folder> -->
  </div>
</template>

<script>
import VJstree from 'vue-jstree';
import {
  cloneDeep, omit,
} from 'lodash';
// Components
// import createFolderComponent from './create-folder.vue';
// import FoldersActionsComponent from './folder-actions.vue';
// constants
import * as folderTypes from '../../../constants/constants-folder';

export default {
  name: 'slide-box-sidebar',
  data() {
    return {
      ...folderTypes,
      isSaving: false,
      pasteType: '',
      foldersLoadingParent: false,
    };
  },
  components: {
    // 'create-folder': createFolderComponent,
    // 'folder-actions': FoldersActionsComponent,
    VJstree,
  },
  props: {
    foldersLoading: Boolean,
    rootFolder: Object,
    selectedFolder: Object,
    copiedFolder: Object,
    activeFolder: Object,
    folders: Array,
    disableActions: {
      type: Boolean,
      default: () => false,
    },
    hideNewFolderCreation: {
      type: Boolean,
      default: () => false,
    },
    hideRecycleBin: {
      type: Boolean,
      default: () => false,
    },
    hideSharedByOthers: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    // isPermitted(type) {
    //   return type === undefined ? true : type;
    // },
    // canShowActions(folder) {
    //   const ifNotRoot = folder.id === this.activeFolder.id && !folder.root;
    //   const ifRoot = folder.root && folder.name === this.activeFolder.name;
    //   return ifRoot || ifNotRoot;
    // },
    // getExpansionIcon(node) {
    //   return {
    //     'fa fa-plus': !node.opened,
    //     'fa fa-minus': node.opened,
    //     invisible: !node.children || node.children.length <= 0,
    //     'text-primary': this.disableActions,
    //   };
    // },
    // getRootFolderIcon({
    //   viewStatus, root, opened,folderType,
    // }) {
    //   return {
    //     'fa fa-folder-open': opened,
    //     'fa fa-user': folderType===this.FOLDER_CONTRIBUTORS,
    //     'fa fa-users': folderType===this.FOLDER_SHAREDBYCONTRIBUTOR,
    //     'fa-folder-open-o': root && viewStatus === this.FOLDER_ACTIVE,
    //     'fa fa-trash': folderType===this.FOLDER_RECYCLEBIN,
    //     'fa fa-sitemap':folderType===this.FOLDER_EDITORIALBOX,
    //     'fa fa-globe':folderType===this.FOLDER_PUBLICBOX,
    //     'fa fa-calendar-check-o':folderType===this.FOLDER_PREPUBLICATIONBOX,
    //   };
    // },
    getFolderIcon({opened, folderType }) {
      switch (true) {
        case (opened && (folderType === this.FOLDER_REGIONEDITORS||folderType === this.FOLDER_EDITOR
         ||folderType === this.FOLDER_CHAIRPERSON
         || folderType === this.FOLDER_TUMORSITE)):
          return 'Icons_OpenedFolder';
        case folderType === this.FOLDER_CONTRIBUTORS:
          return 'Icons_Folder';
        case folderType === this.FOLDER_MYFOLDER:
          return 'Icons_PersonalSlideBox';
        case folderType === this.FOLDER_SHAREDBYCONTRIBUTOR:
          return 'Icons_SharedByContributors';
        // case root && viewStatus === this.FOLDER_ACTIVE:
        //   return 'Icons_OpenedFolder';
        case folderType === this.FOLDER_RECYCLEBIN:
          return 'Icons_Delete';
        case folderType === this.FOLDER_EDITORIALBOX:
          return 'Icons_EditorialBoardBox';
        case folderType === this.FOLDER_PUBLICBOX:
          return 'Icons_PublicAccessBox';
        case folderType === this.FOLDER_PREPUBLICATIONBOX:
          return 'Icons_PrePublicationBox';
        default:
          return 'Icons_Folder';
      }
    },
    canShowFolder(folder) {
      if (this.hideRecycleBin && this.hideSharedByOthers) {
        return (
          folder.name !== 'Shared by others' && folder.name !== 'Recycle Bin'
        );
      } if (this.hideRecycleBin) {
        return folder.name !== 'Recycle Bin';
      }
      return true;
    },
    getActiveClass(node) {
      const forRoot = node && !node.id && node.name === this.activeFolder.name;
      const forChild = node && node.id && node.id === this.activeFolder.id;
      return forRoot || forChild ? 'fw-700' : '';
    },
    // clearFolder() {
    //   this.pasteType = null;
    //   this.$emit('reset');
    // },
    openFolder(folder, cb) {
      if (!folder.id || folder.userId !== this.$userData.id) {
        folder.opened = !folder.opened; //eslint-disable-line
        return;
      }
      let data = cloneDeep(folder);
      data.opened = cb ? true : !data.opened;
      data = omit(data, "children"); //eslint-disable-line

      if(folder.parentId == 0){
        this.foldersLoadingParent = true;
      }

      folder.opened = !folder.opened; //eslint-disable-line
      this.foldersLoadingParent = false;
      if (cb) {
        cb();
      }
      
    },
    selectNode(data) {
      let node = data;
      // node = assign(node, {
      //   viewPermitted: this.isPermitted(node.viewPermitted),
      //   editPermitted: this.isPermitted(node.editPermitted),
      //   deletePermitted: this.isPermitted(node.deletePermitted),
      //   sharePermitted: this.isPermitted(node.sharePermitted),
      // });
      this.openFolder(data);
      this.$emit('selected', node);
    },
    // onRightClick(e, data) {
    //   if (this.disableActions) return;
    //   this.$bvModal.show('context-actions');
    //   this.$emit('temp-selected', data);
    // },
    // createFolder(folder, type) {
    //   if (this.hideNewFolderCreation) return;
    //   if (folder.viewStatus !== this.FOLDER_ACTIVE) {
    //     this.$toastr(
    //       'error',
    //       this.$t('slidebox.uploadFoldersInsideMySlidebox'),
    //     );
    //     return;
    //   }
    //   const payload = type === 'add'
    //     ? {
    //       id: 0,
    //       name: '',
    //       parentId: folder.id,
    //       userId: this.$userData.id,
    //       viewStatus: this.FOLDER_ACTIVE,
    //       viewPermitted: true,
    //       editPermitted: true,
    //       deletePermitted: true,
    //       sharePermitted: true,
    //       edit: true,
    //     }
    //     : folder;
    //   this.$setModalAction({
    //     show: true,
    //     route: 'create-folder',
    //     title: `${
    //       type === 'add' ? this.$t('slidebox.createFolderInto') : this.$t('common.rename')
    //     } ${folder.name}`,
    //     data: {
    //       newFolder: cloneDeep(payload),
    //       parentFolder: folder,
    //     },
    //   });
    // },
    // restoreFolder(data) {
    //   this.$deleteConfirmation(
    //     this.$t('notes.areYouSureWarningMsg', { event: this.$t('slidebox.restore_sm'), text: this.$t('slidebox.folder_sm') }),
    //     () => {
    //       this.deleteFolderStatus(data, this.FOLDER_ACTIVE, () => {
    //         this.$toastr('success', this.$t('slidebox.restoredFolder'));
    //       });
    //     },
    //   );
    // },
    // deleteFolderStatus(data, status, successCb) {
    //   this.$api.deleteFolderFact(data.id, status).then(
    //     response => {
    //       if (!this.$handleResponse(response)) return;
    //       if (successCb) successCb(response.data.payload);
    //       this.clearFolder();
    //       this.$emit('get-folders');
    //     },
    //     error => {
    //       this.$handleError(error);
    //     },
    //   );
    // },
    // deleteFolder(data) {
    //   const buttons = {
    //     cancel: true,
    //     recycle: {
    //       text: this.$t('slidebox.MoveRecycleBin'),
    //       value: this.FOLDER_RECYCLE,
    //       className: 'bg-secondary',
    //     },
    //     delete: {
    //       text: this.$t('common.delete'),
    //       value: this.FOLDER_DELETE,
    //       className: 'bg-danger',
    //     },
    //   };
    //   this.$deleteConfirmation(
    //     this.$t('slidebox.foldersPermanentDeleteMsg'),
    //     val => {
    //       const cb = size => {
    //         let msg = null;
    //         if (data.viewStatus === this.FOLDER_RECYCLE || (val && val === this.FOLDER_DELETE)) {
    //           this.$updateSubscriptionStorageData(this.$storageConsumed - size);
    //           msg = this.$t('slidebox.PermanentlyDeletedFolder');
    //         } else {
    //           msg = this.$t('slidebox.MovedRecyclebin');
    //         }
    //         this.$toastr('success', msg);
    //       };
    //       this.deleteFolderStatus(data, data.viewStatus === this.FOLDER_ACTIVE ? val : this.FOLDER_DELETE, cb);
    //     }, '', data.viewStatus === this.FOLDER_ACTIVE ? buttons : null,
    //   );
    // },
    // saveFolder(data) {
    //   const { newFolder, parentFolder } = data;
    //   this.isSaving = true;
    //   if (newFolder.id) {
    //     this.$api
    //       .updateFolderFact(newFolder, {
    //         actionType: 'rename',
    //       })
    //       .then(
    //         () => {
    //           this.clearFolder();
    //           this.$emit('get-folders');
    //           this.$setModalAction({ show: false });
    //           this.isSaving = false;
    //         },
    //         error => {
    //           this.isSaving = false;
    //           this.$handleError(error);
    //         },
    //       );
    //   } else {
    //     this.$api.createFolderFact(newFolder).then(
    //       response => {
    //         if (!this.$handleResponse(response)) return;
    //         this.isSaving = false;
    //         if (
    //           parentFolder.id
    //           && parentFolder.viewStatus === this.FOLDER_ACTIVE
    //         ) {
    //           this.openFolder(parentFolder, () => {
    //             const { payload } = response.data;
    //             this.$emit('selected', payload);
    //             this.$emit('get-folders');
    //           });
    //         } else {
    //           this.clearFolder();
    //           this.$emit('get-folders');
    //         }
    //         this.$setModalAction({ show: false });
    //       },
    //       error => {
    //         this.isSaving = false;
    //         this.$handleError(error);
    //       },
    //     );
    //   }
    // },
    // checkPaste(copied, current, cb) {
    //   if (copied.parentId === current.id || copied.id === current.id) {
    //     this.$toastr('error', this.$t('slidebox.cannotMoveOrCopyToSameFolder'));
    //   } else {
    //     this.$api.getParentsPathFact(current.id).then(
    //       response => {
    //         const path = map(response.data.payload, 'id');
    //         if (path.includes(copied.id)) {
    //           this.$toastr(
    //             'error',
    //             this.$t('slidebox.cannotMoveOrCopyToChildFolder'),
    //           );
    //         } else {
    //           cb();
    //         }
    //       },
    //       error => {
    //         this.$handleError(error);
    //       },
    //     );
    //   }
    // },
    // pasteFolder(node, item) {
    //   if (!this.copiedFolder) {
    //     this.$toastr('error', this.$t('slidebox.cutOrCopyBeforePasting'));
    //   } else {
    //     this.checkPaste(this.copiedFolder, item, () => {
    //       if (this.pasteType === 'copy') {
    //         this.copyFolder(node, item);
    //       } else {
    //         this.moveFolder({
    //           folderId: this.copiedFolder.id,
    //           toFolderId: item.id,
    //         });
    //       }
    //     });
    //   }
    // },
    // moveFolder(data) {
    //   this.$api.moveFoldersFact(data).then(
    //     () => {
    //       this.$emit('get-folders');
    //       this.$bvModal.hide('context-actions');
    //       this.clearFolder();
    //     },
    //     error => {
    //       this.$handleError(error);
    //     },
    //   );
    // },
    // copyFolder(node, item) {
    //   this.$api
    //     .copyFoldersFact({
    //       folderId: this.copiedFolder.id,
    //       toFolderId: item.id,
    //     })
    //     .then(
    //       () => {
    //         this.$emit('get-folders');
    //         this.clearFolder();
    //       },
    //       error => {
    //         this.$handleError(error);
    //       },
    //     );
    // },
    // cutAction(node, item, e, type) {
    //   if (
    //     item.viewStatus !== this.FOLDER_ACTIVE
    //     || this.activeFolder.userId !== item.userId || item.dragDisabled
    //   ) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     return;
    //   }
    //   this.pasteType = type;
    //   this.$emit('cut-action', item);
    //   this.$bvModal.hide('context-actions');
    // },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
// H_CHANGED
// .slide-box-sidebar {
//   position: initial;
//   padding-bottom: 10px;
// }
// .show-folder {
//   max-width: 200px;
// }

// .shared-folder {
//   width: 160px;
//   max-width: 160px;
// }

// .rename-folder {
//   max-width: 200px;
// }
// .rename-input {
//   width: 100px;
//   height: 20px;
// }
// .right-item {
//   border-bottom: 1px solid #eee;
//   border-top: 1px solid #eee;
//   // padding: 5px 0px 5px 5px !important;
//   color: #333;
// }
// .right-item:hover {
//   color: #333 !important;
//   background-color: #ccc !important;
// }
// .disabled {
//   pointer-events: none;
//   opacity: 0.5;
// }
</style>
