<template>
  <b-img-lazy
    :blank-src="disableLazy ? '' : $icons.LAZY_LOAD"
    :style="imgStyle"
    :class="`${imgClass} ${variant}`"
    :src="imgSource"
    @error.native="imgUrlAlt"
    @load.native="imgLoaded"
  >
  </b-img-lazy>
</template>

<script>
import { assign, forEach } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'wtr-img',
  data() {
    return {
      hasError: false,
    };
  },
  props: {
    resourceId: [Number, String],
    rounded: Boolean,
    styles: Object,
    containerName: String,
    src: {
      default: '',
    },
    imgClass: {
      type: String,
      default: () => '',
    }, // should be a global class
    variant: String, // xs-thumbnail, sm-thumbnail, md-thumnail, lg-thumbnail, xl-thumbnail
    disableLazy: Boolean,
    width: String,
    height: String,
    objectPosition: String,
    objectFit: String,
    transparent: Boolean,
    altImage: {
      type: String,
    },
    imageKey: String,
  },
  computed: {
    ...mapGetters({
      sasTokens: 'getSassTokens',
    }),
    imgSource() {
      const source = this.src;
      const altImage = this.altImage || this.$icons.NO_THUMBNAIL_FOUND;
      if (source && !source.includes('http')) {
        return source;
      }
      return this.resourceId && this.containerName
        ? this.getImage(source, this.resourceId, this.containerName)
        : source || altImage;
    },
    imgStyle() {
      return assign(
        {
          'object-fit': this.objectFit || undefined,
          'object-position': this.objectPosition || 'center',
          'border-radius': this.rounded ? '50%' : undefined,
          width: this.width || undefined,
          height: this.height || undefined,
          'background-color': this.transparent ? 'transparent' : undefined,
        },
        this.styles,
      );
    },
  },
  methods: {
    imgUrlAlt(event) {
      this.$emit('loaded', false);
      if (this.hasError) {
        event.target.src = this.$icons.NO_THUMBNAIL_FOUND; //eslint-disable-line
        return;
      }
      if (this.resourceId && this.containerName) {
        event.target.src = //eslint-disable-line
          this.getImage(this.altImage, this.resourceId, this.containerName)
          || this.$icons.NO_THUMBNAIL_FOUND;
        this.hasError = true;
      } else {
        event.target.src = this.altImage || this.$icons.NO_THUMBNAIL_FOUND; //eslint-disable-line
      }
    },
    getImage(src, resourceId, containerName) {
      if (!src) return this.$icons.NO_THUMBNAIL_FOUND;
      const token = this.getToken(resourceId, containerName);
      return token ? `${src}${token}` : src;
    },
    getToken(resourceId, containerName) {
      let token = null;
      forEach(this.sasTokens, item => {
        if (
          item.resourceId === resourceId
          && item.containerName === containerName
        ) {
          token = `?${item.sasToken}`;
        }
      });
      return token;
    },
    imgLoaded(x) {
      if (this.imgSource === x.target.src) {
        this.$emit('loaded', true);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pp-img {
  width: inherit;
  height: inherit;
}

@for $i from 1 through 20 {
  .pp-thumbnail-#{$i} {
    width: #{$i * 10}px;
    height: #{$i * 10}px;
    object-fit: contain;
    object-position: center;
    background-color: #eee;
  }
}
</style>
