import * as slideTypes from '../constants/constants-slide';
import {
  getSlideProcessingStatus,
  getSlideTypeStyle,
} from '../helpers/utilities';
import metadataModal from "../models/model-metadata";

export default {
  data() {
    return {
      ...slideTypes,
      supportedFormats: [
        {
          type: 'Digital Slides',
          formats: ['svs', 'scn', 'ndpi', 'tif', 'tiff', 'zvi', 'bif', 'isyntax','avs'], // 'svslide'
        },
        {
          type: 'Images',
          formats: ['jpeg', 'jpg', 'bmp', 'png', 'jfif'],
        },
        {
          type: 'Videos',
          formats: ['mp4'],
        },
        {
          type: 'Documents',
          formats: ['pdf'],
        },
      ],
    };
  },
  methods: {
    getProcessingStatus(slide) {
      return getSlideProcessingStatus(slide);
    },
    getFileTypeClass(type) {
      return getSlideTypeStyle(type);
    },
    getPermissions(folder) {
      return {
        editPermitted: folder.editPermitted,
        viewPermitted: folder.viewPermitted,
        deletePermitted: folder.deletePermitted,
        sharePermitted: folder.sharePermitted,
      };
    },
    readFile(file, cb) {
      const filereader = new FileReader();
      filereader.onloadend = evt => {
        const uint = new Uint8Array(evt.target.result);
        const bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();
        const typeData = {
          filename: file.name,
          filetype: file.type ? file.type : 'Unknown/Extension missing',
          binaryFileType: this.getMimetype(hex),
          hex,
        };
        const valid = typeData.filetype === typeData.binaryFileType;
        if (cb) cb(valid);
      };
      const blob = file.slice(0, 4);
      filereader.readAsArrayBuffer(blob);
    },
    getMimetype(signature) {
      switch (signature) {
        case '89504E47':
          return 'image/png';
        case '25504446':
          return 'application/pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'image/jpeg';
        case '504B0304':
          return 'application/zip';
        default:
          return 'Unknown filetype';
      }
    },
    changeSlideData(slide, disableDiagnosis) {
      const progressStates = [
        this.SLIDE_PENDING,
        this.SLIDE_PROCESSING,
        this.SLIDE_SYNCING,
      ];
      const fileType = this.getFileTypeClass(slide.fileType);
      const processStatus = this.getProcessingStatus(slide);
      const time = slide.createdDateTime;
      const { src, alt } = this.$getSlideThumbnail(slide);
      const hasProgress = this.$hasUpdatedBatch && progressStates.includes(slide.dziStatus);
      return {
        meta: {
          thumbnail: src,
          thumbnailAlt: alt,
          fileType,
          hasProgress,
          pending: this.pendingSlide(slide),
          processStatus,
          createdTime: this.$getBrowserTime(time),
          name: this.$getSlideName(slide, disableDiagnosis),
          isUploadFailed: slide.dziStatus === 4,
          isProcessingFailed: slide.dziStatus === 3,
        },
      };
    },
    pendingSlide({ fileType, path, dziStatus }) {
      if (fileType === this.SLIDE_UNKNOWN) {
        return !path || dziStatus !== 1;
      }
      if (fileType === this.SLIDE_DIGITALSLIDE || fileType === this.SLIDE_ZOOMIFY) {
        return dziStatus !== 1 || !path;
      }
      return false;
    },
    isDzi(extension) {
      const types = ['svs', 'scn', 'ndpi', 'tif', 'tiff', 'zvi', 'bif', 'isyntax','avs']; // 'svslide'
      return types.includes(extension.toLowerCase());
    },
    isVideo(type) {
      const types = ['video/mp4'];
      return types.includes(type);
    },
    isDocument(type) {
      const types = ['application/pdf'];
      return types.includes(type);
    },
    isImage(type) {
      const types = ['image/jpeg', 'image/bmp', 'image/png'];
      return types.includes(type);
    },
    isZip(extension) {
      const types = ['zip', 'dcm'];
      return types.includes(extension.toLowerCase());
    },
    setFileType(extension, type) {
      if (this.isDzi(extension)) return this.SLIDE_DIGITALSLIDE;
      if (this.isImage(type)) return this.SLIDE_IMAGE;
      if (this.isDocument(type)) return this.SLIDE_DOCUMENT;
      if (this.isVideo(type)) return this.SLIDE_VIDEO;
      if (this.isZip(extension)) return this.SLIDE_UNKNOWN;
      return false;
    },
    viewSlide({ id, meta, metadata }, query) {
      // var caseNumber= (metadata.systemCaseNumber ? metadata.systemCaseNumber : metadata.caseNumber);
      var caseDetailId= metadata.caseDetailId;
      var movedToEditorialBox= (metadata.isMovedToEditorialBox ? metadata.isMovedToEditorialBox :false);
      var movedToSlideLibrary= (metadata.isMovedToSlideLibrary ? metadata.isMovedToSlideLibrary : false);
      var sharedToEditor= (metadata.isSharedToEditor ? metadata.isSharedToEditor : false);
      var isPublicSite= false;
      var caseSlideIds;
      var selectedFolderView = query.selectedFolderView;
      var selectedFolderUserRole = query.selectedFolderUserRole;
      var selectedFolderUserRegion = query.selectedFolderUserRegion;
      var selectedCaseRegion = metadata.geographicRegion.name;
      if (meta.isProcessingFailed) {
        this.$toastr('error', 'Selected slide failed while processing');
      } else if (meta.isUploadFailed) {
        this.$toastr('error', 'Selected slide failed while uploading');
      } else if (meta.pending) {
        this.$toastr('info', 'Selected slide is pending for conversion');
      } else {
        const route = 'display-viewer';
        this.$changeRouteToTab({
          name: route,
          params: {
            id,
            caseDetailId,
            movedToEditorialBox,
            movedToSlideLibrary,
            sharedToEditor,
            isPublicSite,
            caseSlideIds,
            selectedFolderView,
            selectedFolderUserRole,
            selectedFolderUserRegion,
            selectedCaseRegion
          },
          query: query ? query.query : null,
        });
      }
    },
    viewCaseSlides(caseNumber) {
      this.getSlideIdsByCaseNumber(caseNumber);
    },
    getMetadataBySlideId(slideId) {
      const slideIds = [slideId];
      this.$api.getAllMetadataBySlideIdsFact(slideIds).then(
        (response) => {
          if (response.data.payload[0] != null) {
            return response.data.payload[0];
          }
          else
            return metadataModal;
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    groupedTumorType(tumorTypes) {
      const groups = {};
  
      tumorTypes.forEach(data => {
          let groupId;
          if (data.tumorTypeCategoryId === 1) {
              groupId = "Common";
          } else if (data.tumorTypeCategoryId === 2) {
              groupId = "Uncommon";
          } else {
              groupId = "Non-neoplastic";
          }
  
          if (!groups[groupId]) {
              groups[groupId] = [];
          }
  
          groups[groupId].push(data);
      });
  
      // Define the custom order
      const customOrder = ["Common", "Uncommon", "Non-neoplastic"];
  
      // Sort the groups based on the custom order
      const sortedGroups = customOrder.filter(groupId => groups[groupId]);
  
      // convert the sorted groups to an array of group objects with child items
      const groupArray = [];
      sortedGroups.forEach(groupId => {
          const groupObj = { label: groupId, items: groups[groupId] };
          groupArray.push(groupObj);
      });
  
      return groupArray;
    },
  },
};
