export const FOLDER_ACTIVE = 'ACTIVE';
export const FOLDER_RECYCLE = 'RECYCLE';
export const FOLDER_DELETE = 'DELETE';
export const FOLDER_GENERAL = 'GENERAL';
export const FOLDER_MYFOLDER = 'MYFOLDER';
export const FOLDER_TUMORSITE = 'TUMORSITE';
export const FOLDER_PUBLICBOX = 'PUBLICBOX';
export const FOLDER_PREPUBLICATIONBOX = 'PREPUBLICATIONBOX';
export const FOLDER_EDITORIALBOX = 'EDITORIALBOX';
export const FOLDER_CHAIRPERSON = 'CHAIRPERSON';
export const FOLDER_REGIONEDITORS = 'REGIONEDITORS';
export const FOLDER_EDITOR = 'EDITOR';
export const FOLDER_CONTRIBUTORS = 'CONTRIBUTORS';
export const FOLDER_SHAREDBYCONTRIBUTOR = 'SHAREDBYCONTRIBUTOR';
export const FOLDER_RECYCLEBIN = 'RECYCLEBIN';


