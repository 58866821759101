<template>
<div class="slide-box">
    <div v-if="folderData">
        <b-badge class="fs-16 white-space-normal mt-5" v-if="canSelect && canSelect.select && $userAgent.isMobile"><i class="fa text-warning fa-folder"></i>
            {{ folderData.name }}
        </b-badge>
        <wtr-case-list 
            v-if="(slides!=null || allCases!=null) && selectedFolderUserRole !=null"
            :params="paramsUtil" 
            :subFilters="subFilters"
            :configSubFilters="configSubFilters"
            :listLoading="listLoading"
            :folderData="folderData"
            :options="{
                searchHolder: $t('common.searchFiles', {
                    fileType: $t('common.slides'),
                }),
                list: slides,
                allCases: allCases,
                pagination: true,
                totalPages,
                totalElements,
                totalCasePages,
                totalCaseElements,
                menuToggle: true,
                maxHeight,          
                hasRangeFilter: false,
                multiView:true,
                hideSearch:ishideSearch,
                selectedFolderView:selectedFolderView,
                canExportTSV:canExportTSV,
                toolbar: {
                    search: 'col-12 px-0'
                },
            }" 
            @update-list="getSlides" 
            @update-case-list="getCases" 
            @search="$emit('clear-selected')" 
            @filter="$emit('clear-selected')" 
            @size="$emit('clear-selected')" 
            @export-click="$emit('export-cases')"
        >          
            <!-- Buttons will be rendered in list component -->
            <b-button-group slot="list-buttons" v-if="Number(folderData.id) && !showCaseSlides">
                <b-btn :aria-label="$t('common.upload')" :aria-labelledby="$t('common.upload')" variant="primary" :title="$t('common.upload')" v-if="!recycle && canUpload && this.selectedFolderView == this.FOLDER_MYFOLDER && !this.showCaseSlides && isColumnVisible(VISIBLE_COLUMNS.UPLOADFILES)" size="sm" :disabled="$stopUpload" v-on:click="updateSlide(slideData)">
                    <i class="btn-icon-size fa fa-upload" aria-hidden="true"></i> Upload Case
                </b-btn>
            </b-button-group>

            <!--Slide Cards will be rendered in list component -->
            <div slot="list-card" class="row m-0">
                <div v-for="slide in slides" :key="slide.id">
                    <wtr-slide-card 
                        :showToggle="false" 
                        :disableToggle="slide.meta.pending && canSelect.select" 
                        :toggle="mapSelectedSlides.includes(slide.id)" 
                        headerSlot="header-slot" 
                        footerSlot="footer-slot"
                        :img="{
                            resourceId: slide.resourceId,
                            containerName: slide.containerName,
                            src: slide.meta.thumbnail,
                            alt: slide.meta.thumbnailAlt,
                        }" 
                        :imgKey="`${slide.id}-card-${slide.meta.thumbnail}`" 
                        @img-click="$emit('view-slide', slide,{selectedFolderView,selectedFolderUserRole,selectedFolderUserRegion})"
                    >
                        <div v-if="navigate" slot="header-slot">
                            <div class="p-2">
                                <div class="single-line-text">
                                    <span class="fw-600">Case #:</span>
                                    <span style="color: black;">
                                        &nbsp;{{ slide.metadata ? slide.metadata.caseNumber : ''}}
                                    </span>
                                </div>
                                <div class="single-line-text">
                                    <span class="fw-600">Slide name:</span>
                                    <span style="color: black;">&nbsp;{{slide.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="navigate" slot="footer-slot">
                            <div class="double-line-text mb-1" style="color: #666666 !important;">
                                {{slide.meta.name}}
                            </div>
                            <table-actions 
                                :key="slide.id" 
                                :viewIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO)),variant:'text-primary' }" 
                                :editIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.EDIT))}" 
                                :deleteIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.DELETE))}"        
                                :restoreIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.RESTORE)}"                          
                                :caseReviewCommentIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.COMMENTS)) }"
                                @view="$emit('view-slide',slide,{selectedFolderView,selectedFolderUserRole,selectedFolderUserRegion})" 
                                @edit="updateSlide(slide)" 
                                @delete="deleteSlides(slide)" 
                                @restore="restoreSlides(slide)"  
                                @caseReviewComment="caseReviewComment(slide.metadata.caseDetailId)"                           
                            ></table-actions>
                        </div>
                    </wtr-slide-card>
                </div>
            </div>

            <!--Slide Table will be rendered in list component -->
            <b-table thead-class="bg-primary text-light" primary-key="id" slot="list-table" outlined responsive hover :fields="tableFields" :items="slides">
                <template v-slot:head(caseNumber)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                    </div>
                </template>
                <template v-slot:head(thumbnailPath)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                    </div>
                </template>
                <template v-slot:head(slideMetadata.diagnosis)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                    </div>
                </template>
                <template v-slot:head(name)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-slides', 'name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(stainStudy)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-slides', 'slideMetadata.stainORstudy')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(createdDateTime)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-slides', 'createdDate')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(dziStatus)="data">
                    <div class="text-nowrap text-center">
                        <span>{{ data.label }}</span>
                    </div>
                </template>
                <template v-slot:head(actions)>
                    <div class="text-nowrap text-center">
                        {{ navigate ? $t('common.actions') : null }}
                    </div>
                </template>

                
                <template v-slot:cell(caseNumber)="row">
                    <div class="fs-13 ew-10" v-if="row.item.metadata" :title="row.item.metadata.systemCaseNumber?row.item.metadata.systemCaseNumber:row.item.metadata.caseNumber">
                        {{ row.item.metadata.systemCaseNumber?row.item.metadata.systemCaseNumber:row.item.metadata.caseNumber}}
                    </div>
                </template>
                <template v-slot:cell(name)="row">
                    <div class="slide-name fs-13" :title="row.item.name">
                        {{ row.item.name }}
                    </div>
                </template>
                <template v-slot:cell(stainStudy)="row">
                    <div v-if="row.item.metadata" class="fs-13">
                        {{getValueCascadeSelection(row.item.metadata.stainStudies)}} 
                    </div>
                </template>
                <template v-slot:cell(thumbnailPath)="row">
                    <div v-on:click.stop="$emit('view-slide', row.item,{selectedFolderView,selectedFolderUserRole,selectedFolderUserRegion})" :title="$t('slidebox.clickToViewSlide')">
                        <wtr-img :resourceId="row.item.resourceId" :key="`${row.item.id}-table-${row.item.meta.thumbnail}`" :containerName="row.item.containerName" variant="pp-thumbnail-4" alt="Thumbnail" aria-label="Thumbnail" arialabelledby="Thumbnail" :src="row.item.meta.thumbnail" :altImage="row.item.meta.thumbnailAlt" />
                    </div>
                </template>
                <template v-slot:cell(createdDateTime)="row">
                    <div class="fs-13 text-truncate" :title="row.item.meta.createdTime">
                        {{ row.item.meta.createdTime }}
                    </div>
                </template>
                <template v-slot:cell(slideMetadata.diagnosis)="row">
                    <div class="fs-13" :title="row.item.slideMetadata.diagnosis">
                        {{ row.item.slideMetadata.diagnosis }}
                    </div>
                </template>
                <template v-slot:cell(actions)="row" v-if="navigate">
                    <div class="text-center">
                        <table-actions 
                            :key="row.item.id" 
                            :viewIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO)),variant:'text-primary' }" 
                            :editIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.EDIT)) }" 
                            :deleteIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.DELETE)) }" 
                            :restoreIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.RESTORE)}"
                            :caseReviewCommentIcon="{ show: (isColumnVisible(VISIBLE_COLUMNS.COMMENTS)) }"
                            @view="$emit('view-slide', row.item,{selectedFolderView,selectedFolderUserRole,selectedFolderUserRegion})" 
                            @edit="updateSlide(row.item)" 
                            @delete="deleteSlides(row.item)" 
                            @restore="restoreSlides(row.item)"
                            @caseReviewComment="caseReviewComment(row.item.metadata.caseDetailId)"
                        ></table-actions>  
                    </div>                  
                </template>
                <template v-slot:cell(dziStatus)="row">
                    <div class="text-center">
                        <b-badge class="fs-13" :variant="row.item.meta.processStatus.variant">
                            <i v-if="!row.item.dziStatus && $hasUpdatedBatch" class="fa fa-spinner fa-spin"></i>
                            <span v-else>
                                <span v-if="row.item.meta.hasProgress">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </span>                          
                            </span>
                            {{ row.item.meta.processStatus.label }}
                        </b-badge>
                        <div title="Re upload" class="file btn btn-sm btn-block text-left pl-0 btn-link btn-file" v-if="row.item.meta.isUploadFailed">
                            {{ $t('notes.pleaseUploadAgain') }}.
                            <input type="file" name="file" aria-label="file" aria-labelledby="file" v-on:change="reuploadSlide($event, row.item)" />
                        </div>
                    </div>
                </template>
            </b-table>

            <!--Case Cards will be rendered in list component -->
            <div slot="case-list-card" class="row m-0">
                <div v-for="caseDetail in allCases" :key="caseDetail.caseDetailId">
                    <wtr-case-card 
                        footerSlot="footer-slot"
                        :name="getCaseNumber(caseDetail)"
                        :totalSlides="caseDetail.totalFiles"
                        :img="{
                            resourceId: caseDetail.resourceId,
                            containerName: caseDetail.containerName,
                            src: caseDetail.thumbnailPath,
                            alt: caseDetail.thumbnailPath,
                        }"
                        :imgKey="`${caseDetail.caseDetailId}-card-${caseDetail.thumbnailPath}`"
                        @img-click="viewSlide(caseDetail)"
                    >
                        <div v-if="navigate" slot="footer-slot">
                            <div class="double-line-text" style="color: #666666 !important;">{{getTumorName(caseDetail)}}</div>
                            <table-actions 
                                key="caseDetail.caseDetailId"
                                :viewCaseIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO) }"
                                :viewSlideIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWSLIDESINFO) }"
                                :moveToSlideLibraryIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPUBLICACCESSBOX) }" 
                                :caseReviewCommentIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.COMMENTS)  }"
                                :editIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.EDIT,caseDetail.geographicRegion)}" 
                                :moveToPrePublicationIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPREPUBLICATIONBOX,caseDetail.geographicRegion) }" 
                                :moveBackForRevisionIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVEBACKFORREVISION,caseDetail.geographicRegion) }" 
                                :moveToEditorialBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOEDITORIALBOARDBOX) }"                         
                                :moveToEditorBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOEDITORBOX) }" 
                                :reviewStatusIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.CHANGEREVIEWSTATUS,caseDetail.geographicRegion) }" 
                                :deleteIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.DELETE,caseDetail.geographicRegion) }"
                                :restoreIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.RESTORE)}"
                                @viewCaseInfo="viewCaseInfo(caseDetail.caseSlideIds,caseDetail.caseDetailId,caseDetail.geographicRegion)"                        
                                @view="$emit('view-case-slides', (caseDetail.systemCaseNumber?caseDetail.systemCaseNumber:caseDetail.caseNumber))" 
                                @moveToSlideLibrary="moveToSlideLibrary(caseDetail.caseDetailId,caseDetail.caseSlideIds)"
                                @caseReviewComment="caseReviewComment(caseDetail.caseDetailId)"
                                @edit="updateSlide(caseDetail)" 
                                @moveToPrePublication="moveToPrePublication(caseDetail.caseSlideIds,caseDetail.caseDetailId)"
                                @moveBackForRevision="moveBackForRevision(caseDetail.caseSlideIds,caseDetail.caseDetailId)"
                                @moveToEditorialBox="moveToEditorialBox(caseDetail.caseDetailId,caseDetail.caseSlideIds,caseDetail.caseNumber)"                        
                                @sharedToEditor="sharedToEditor(caseDetail.caseSlideIds)" 
                                @reviewStatus="openModal(caseDetail.caseDetailId)"                         
                                @delete="deleteCaseData(caseDetail)"
                                @restore="restoreCaseData(caseDetail)"     
                            ></table-actions>
                        </div>
                    </wtr-case-card>
                </div>
            </div>

            <!-- Case view in list component -->
            <b-table thead-class="bg-primary text-light"  slot="case-list-table" outlined @row-clicked="$emit('show-slides', $event)" responsive hover :fields="casetableFields" :items="allCases">
                <template v-slot:head(caseNumber)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'cd.case_number,cd.system_case_number')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(tumorSite)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'ts.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(tumorType)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'tt.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(tumorSubType)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'tst.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(specificCohort)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'sc.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(geographicRegion)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'gr.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(editor)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'eu.first_name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(reviewStatus)="data">
                    <div class="text-nowrap">
                        <span>{{ data.label }}</span>
                        <span class="cursor-pointer" v-on:click="$emit('sort-cases', 'rs.name')">
                            &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
                <template v-slot:head(actions)>
                    <div class="text-nowrap text-center">
                        {{ $t('common.actions') }}
                    </div>
                </template>
                <template v-slot:cell(caseNumber)="row">
                    <div class="caseNumber fs-13" :title="row.item.systemCaseNumber ?row.item.systemCaseNumber:row.item.caseNumber">
                        {{ row.item.systemCaseNumber ?row.item.systemCaseNumber:row.item.caseNumber }}
                    </div>
                </template>
                <template v-slot:cell(tumorSite)="row">
                    <div class="tumorSite fs-13" :title="row.item.tumorSite">
                        {{ row.item.tumorSite }}
                    </div>
                </template>
                <template v-slot:cell(tumorType)="row">
                    <div class="tumorType fs-13" :title="row.item.tumorType">
                        {{ row.item.tumorType }}
                    </div>
                </template>
                <template v-slot:cell(tumorSubType)="row">
                    <div class="tumorSubType fs-13" :title="row.item.tumorSubType">
                        {{ row.item.tumorSubType }}
                    </div>
                </template>
                <template v-slot:cell(specificCohort)="row">
                    <div class="specificCohort fs-13" :title="row.item.specificCohort">
                        {{ row.item.specificCohort }}
                    </div>
                </template>
                <template v-slot:cell(geographicRegion)="row">
                    <div class="geographicRegion fs-13" :title="row.item.geographicRegion">
                        {{ row.item.geographicRegion }}
                    </div>
                </template>
                <template v-slot:cell(editor)="row">
                    <div class="editor fs-13" :title="row.item.editor">
                        {{ row.item.editor }}
                    </div>
                </template>
                <template v-slot:cell(reviewStatus)="row">
                    <div class="reviewStatus fs-13" :title="row.item.reviewStatus">
                        {{ row.item.reviewStatus }}
                    </div>
                </template>
                <template v-slot:cell(totalFiles)="row">
                    <div class="totalFiles text-center fs-13" :title="row.item.totalFiles">
                        {{ row.item.totalFiles }}
                    </div>
                </template>
                <template v-slot:cell(actions)="row">
                    <div class="text-center">
                        <table-actions :key="row.item.id"                         
                            :viewCaseIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO) }"
                            :viewSlideIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWSLIDESINFO) }"
                            :moveToSlideLibraryIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPUBLICACCESSBOX) }" 
                            :caseReviewCommentIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.COMMENTS) }"
                            :editIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.EDIT,row.item.geographicRegion)}" 
                            :moveToPrePublicationIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPREPUBLICATIONBOX,row.item.geographicRegion) }" 
                            :moveBackForRevisionIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVEBACKFORREVISION,row.item.geographicRegion) }" 
                            :moveToEditorialBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOEDITORIALBOARDBOX) }"                         
                            :moveToEditorBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOEDITORBOX) }" 
                            :reviewStatusIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.CHANGEREVIEWSTATUS,row.item.geographicRegion) }" 
                            :deleteIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.DELETE,row.item.geographicRegion) }"                        
                            :restoreIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.RESTORE)}"
                            :folderData="folderData"
                            @viewCaseInfo="viewCaseInfo(row.item.caseSlideIds,row.item.caseDetailId,row.item.geographicRegion)"
                            @view="$emit('view-case-slides', (row.item.systemCaseNumber?row.item.systemCaseNumber:row.item.caseNumber))" 
                            @moveToSlideLibrary="moveToSlideLibrary(row.item.caseDetailId,row.item.caseSlideIds)"
                            @caseReviewComment="caseReviewComment(row.item.caseDetailId)"
                            @edit="updateSlide(row.item)" 
                            @moveToPrePublication="moveToPrePublication(row.item.caseSlideIds,row.item.caseDetailId)"
                            @moveBackForRevision="moveBackForRevision(row.item.caseSlideIds,row.item.caseDetailId)"
                            @moveToEditorialBox="moveToEditorialBox(row.item.caseDetailId,row.item.caseSlideIds,row.item.caseNumber)"                        
                            @sharedToEditor="sharedToEditor(row.item.caseSlideIds)" 
                            @reviewStatus="openModal(row.item.caseDetailId)"                         
                            @delete="deleteCaseData(row.item)"
                            @restore="restoreCaseData(row.item)"     
                        ></table-actions>
                    </div>
                </template>
            </b-table>
        </wtr-case-list>
        <b-modal id="slide-info" size="md" centered scrollable @hidden="showSlideInfo(null, false)" hide-footer hide-header>
            <slide-info :slideData="selectedSlide" @close="showSlideInfo(null, false)"></slide-info>
        </b-modal>
        <upload-slide :slideContent="$modalData.data" :folderData="folderData" @update-tissue-type="$emit('on-tissue-change', $event)" 
            v-if="$modalData && $modalData.data && $modalData.route === 'upload-slide'" @update-slide="$emit('update-slides', $event)" 
            :params="paramsUtil" 
        ></upload-slide>
        <case-review-comment 
            v-if="$modalData.show && $modalData.route === 'case-review-comment'"
            :modalDataInfo="$modalData.data" 
        ></case-review-comment>
        <review-status
            v-if="$modalData.show && $modalData.route === 'review-status'"
            :modalDataInfo="$modalData.data" :params="paramsUtil" 
    >   </review-status>
        <case-copy
            v-if="$modalData.show && $modalData.route === 'case-copy'"
            :modalDataInfo="$modalData.data" :params="paramsUtil" 
    >   </case-copy>
    </div>
</div>
</template>

<script>
import {
    cloneDeep,
    isArray,
    filter,
    concat,
    findIndex,
    map,
    assign,
} from "lodash";
// Components
import WTRCaseListComponent from "../../../components/common/wtr-case-list.vue";
import TableActionsComponent from "../../../components/common/table-actions.vue";
import WTRSlideCardComponent from "../../../components/common/wtr-slide-card.vue";
import WTRCaseCardComponent from "../../../components/common/wtr-case-card.vue";
import SlideInfoComponent from "../../../components/common/wtr-slide-info.vue";
import UploadSlideComponent from "./upload-slide.vue";
import CaseReviewCommentComponent from "../../../components/common/case-review-comment.vue";
import ReviewStatusComponent from "../../../components/common/review-status.vue";
import CaseCopyComponent from "../../../components/common/case-copy.vue";
// Constants
import * as folderTypes from "../../../constants/constants-folder";
import * as slideTypes from "../../../constants/constants-slide";
import { mapActions,mapGetters } from 'vuex';
import { orderBy } from "lodash";
import * as EDITORIALBOX_ROLES from "../../../constants/constants-visiblecolumns";
import * as MYFOLDER_ROLES from "../../../constants/constants-visiblecolumns";
import * as RECYCLE_ROLES from "../../../constants/constants-visiblecolumns";
import { VISIBLE_COLUMNS } from "../../../constants/constants-visiblecolumns";
import * as roleTypes from "../../../constants/constants-role";

export default {
    name: "slide-box",
    data() {
        return {
            ...folderTypes,
            ...slideTypes,
            ...EDITORIALBOX_ROLES,
            ...MYFOLDER_ROLES,
            ...RECYCLE_ROLES,
            ...roleTypes,
            selectedSlide: null,
            slidesCaseView: [],
            tumorSiteId: null,

            configSubFilters:[
                {optionLabel: 'name', optionValue: 'id', placeholder: 'By Cohort', searchParam: 'searchSpecificCohortId'},
                {optionLabel: 'name', optionValue: 'id', placeholder: 'By Region', searchParam: 'searchGeographicRegionId'},
                {optionLabel: 'name', optionValue: 'id', placeholder: 'By Editor', searchParam: 'searchEditorId'},
                {optionLabel: 'name', optionValue: 'id', placeholder: 'By Status', searchParam: 'searchReviewStatusId'}
            ],
            indexSearchSpecificCohortId: 0,
            indexGeographicRegionId: 1,
            indexEditorId: 2,
            indexReviewStatusId: 3,
            subFilters: [[], [], [], []],
            VISIBLE_COLUMNS: VISIBLE_COLUMNS,
        };
    },
    components: {
        "wtr-case-list": WTRCaseListComponent,
        "table-actions": TableActionsComponent,
        "wtr-slide-card": WTRSlideCardComponent,
        "wtr-case-card": WTRCaseCardComponent,
        "upload-slide": UploadSlideComponent,
        "slide-info": SlideInfoComponent,      
        "case-review-comment" : CaseReviewCommentComponent,  
        "review-status" : ReviewStatusComponent,
        "case-copy" : CaseCopyComponent,  
    },
    computed: {
        ...mapGetters({
            showCaseSlides: "getShowCaseSlidesStatus",
            showCaseView: "getShowCaseViewStatus",
            selectedCaseSlideIds: "getSelectedCaseSlideIds",
            selectedFolderView: "getSelectedFolderView",
            selectedFolderUserRole: "getSelectedFolderUserRole",
            selectedFolderUserRegion: "getSelectedFolderUserRegion",
        }),
        tableFields() {
            return [   
                {
                    key: "caseNumber",
                    label: this.$t("common.caseNumber")
                },
                {
                    key: "thumbnailPath",
                    label: this.$t("common.slidePreview")
                }, 
                {
                    key: "slideMetadata.diagnosis",
                    label: this.$t("common.diagnosis"),
                    thClass: "",
                },
                {
                    key: "name",
                    label: this.$t("common.slideName"),
                    thClass: "",
                },
                 {
                    key: "stainStudy",
                    label: this.$t("common.stainStudy")
                },
                {
                    key: "createdDateTime",
                    label: this.$t("slidebox.uploadTime"),
                    thClass: "min-w-px-10",
                },
                {
                    key: "dziStatus",
                    label: this.$t("common.conversionStatus")
                },
                // {
                //     key: "info",
                //     label: this.$t("common.info")
                // },
                {
                    key: "actions",
                    label: this.$t("common.actions")
                },
            ];
        },
        casetableFields() {
            if (this.selectedFolderView == this.FOLDER_EDITORIALBOX) {
                return [{
                        key: "caseNumber",
                        label: this.$t("common.caseNumber"),
                        thClass: "",
                    },
                    {
                        key: "tumorSite",
                        label: this.$t("common.tumorSite"),
                        thClass: "",
                    },
                    {
                        key: "tumorType",
                        label: this.$t("common.tumorType"),
                        thClass: "",
                    },
                    {
                        key: "tumorSubType",
                        label: this.$t("common.tumorSubType"),
                        thClass: "",
                    },
                     {
                        key: "specificCohort",
                        label: this.$t("common.specificCohort"),
                        thClass: "",
                    },
                    {
                        key: "geographicRegion",
                        label: this.$t("common.geographicRegion"),
                        thClass: "",
                    },
                    {
                        key: "editor",
                        label: this.$t("common.editor"),
                        thClass: "",
                    },
                    {
                        key: "reviewStatus",
                        label: this.$t("common.reviewStatus"),
                        thClass: "",
                    },
                    // {
                    //     key: "totalFiles",
                    //     label: this.$t("common.totalFiles"),
                    //     thClass: "",
                    // },
                    {
                        key: "actions",
                        label: this.$t("common.actions")
                    },
                ];
            } else {
                return [{
                        key: "caseNumber",
                        label: this.$t("common.caseNumber"),
                        thClass: "",
                    },
                    {
                        key: "tumorSite",
                        label: this.$t("common.tumorSite"),
                        thClass: "",
                    },
                    {
                        key: "tumorType",
                        label: this.$t("common.tumorType"),
                        thClass: "",
                    },
                    {
                        key: "tumorSubType",
                        label: this.$t("common.tumorSubType"),
                        thClass: "",
                    },
                    {
                        key: "specificCohort",
                        label: this.$t("common.specificCohort"),
                        thClass: "",
                    },
                    {
                        key: "geographicRegion",
                        label: this.$t("common.region"),
                        thClass: "",
                    },
                    {
                        key: "editor",
                        label: this.$t("common.editor"),
                        thClass: "",
                    },
                    {
                        key: "reviewStatus",
                        label: this.$t("common.reviewStatus"),
                        thClass: "",
                    },
                    // {
                    //     key: "totalFiles",
                    //     label: this.$t("common.totalFiles"),
                    //     thClass: "",
                    // },
                    {
                        key: "actions",
                        label: this.$t("common.actions")
                    },
                ];
            }
        },
        ishideSearch() {
            return this.showCaseSlides;
        },
        canSharedToEditor() {
            return (this.$canAccess("myslidebox", "canSendToEditor") &&  this.selectedFolderView != this.FOLDER_RECYCLE);
        },
        canMoveToEditorialBox() {
            if (
                (this.$canAccess("myslidebox", "canMoveToEditorialBox") == true ||
                    this.$canAccess("myslidebox", "canMoveToSlideLibrary") == true) &&
                this.selectedFolderView != this.FOLDER_EDITORIALBOX
            ) {
                return true;
            } else return false;
        },
        canMoveToSlideLibrary() {
            return (
                this.$canAccess("myslidebox", "canMoveToSlideLibrary") &&
                this.selectedFolderView == this.FOLDER_EDITORIALBOX
            );
        },
        hideUploadSlides() {
            if (this.showCaseView && this.allCases == null) {
                return false;
            } else if (!this.showCaseView) {
                return false;
            } else {
                return true;
            }
        },
        isEditorialBoxFolder() {
            if (this.selectedFolderView == this.FOLDER_EDITORIALBOX) return true;
            else return false;
        },
        canExportTSV()
        {
            const loginUserRole = localStorage.getItem("userRole");
            const result = (this.selectedFolderView == this.FOLDER_EDITORIALBOX && 
            (loginUserRole === this.ROLE_TENANT_ADMIN || loginUserRole === this.ROLE_PROJECT_ADMIN));

            return result;
        },
    },
    props: {
        canUpload: {
            type: Boolean,
            default: () => false,
        },
        canSelect: {
            select: Boolean,
            single: Boolean,
            selectType: String,
            default: () => ({
                select: false,
                single: false,
                selectType: "",
            }),
        },
        navigate: {
            type: Boolean,
            default: () => false,
        },
        maxHeight: Number,
        paramsUtil: Object,
        listLoading: Boolean,
        totalPages: Number,
        totalElements: Number,
        recycle: Boolean,
        selectedSlides: Array,
        mapSelectedSlides: Array,
        slideData: Object,
        folderData: null,
        slides: Array,
        allCases: Array,
        totalCasePages: Number,
        totalCaseElements: Number,
        isMovedToEditorialBox:Boolean,    
        isSharedToEditor:Boolean,    
        isMovedToSlideLibrary:Boolean,        
    },
    mounted() {
        this.getUserDetails();
    },
    updated() {
     this.$nextTick(() => {
      this.$setWCAGVueEditorFixes();
    }); 
    },
    methods: {
        ...mapActions(['setSelectedCaseSlideIds']),
        getUserDetails() {

            this.$api.getUserDetailFact(localStorage.getItem("userId")).then(
                (response) => {
                    this.userDetail = response.data;

                    if(this.userDetail.userTumorSites != null){
                        this.tumorSiteId = this.userDetail.userTumorSites.id;
                    }else{
                        this.tumorSiteId = 0;
                    }  
                                      
                    this.getAllProperty();
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        }, 
        getCaseNumber({ caseNumber, systemCaseNumber }) {
        return systemCaseNumber ? systemCaseNumber : caseNumber;
        },
        getTumorName({ tumorSite,tumorType, tumorSubType }) {
        return tumorSite+"-"+tumorType+"-"+tumorSubType;
        },
        getAllProperty(){
            this.getAllSpecificCohorts(this.tumorSiteId);
            this.getAllGeographicRegions();
            this.getAllEditorByTumorSite(this.tumorSiteId);
            this.getAllCaseReviewStatus();
        },
        getValuesMetadata(objValue, objKey){
            const ids = new Set(); 
            this.slides.forEach(item => {
                const metadata = item?.metadata;
                if (metadata && Object.prototype.hasOwnProperty.call(metadata, objKey)) {
                    const region = metadata[objKey];
                    
                    if (region && !ids.has(region.id)) {
                        ids.add(region.id);
                        objValue.push(region);
                    }
                }                
            });
        },
        getAllSpecificCohorts(tumorSiteId) {
            this.$api.getAllSpecificCohortsByTumorFact(tumorSiteId).then(
                (response) => {
                    this.subFilters[this.indexSearchSpecificCohortId] = orderBy(response.data.payload,["name"],["asc"]);
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getAllGeographicRegions() {
            this.$api.getAllGeographicregionsFact().then(
                (response) => {
                    this.subFilters[this.indexGeographicRegionId] = orderBy(response.data.payload,["name"],["asc"]);
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getAllEditorByTumorSite(tumorSiteId) {
            this.$api.getAllEditorByTumorSiteFact(tumorSiteId).then(
                (response) => {
                    this.subFilters[this.indexEditorId] = orderBy(response.data,["name"],["asc"]);
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getAllCaseReviewStatus() {
            this.$api.getAllCaseReviewStatusFact().then(
                (response) => {
                    this.subFilters[this.indexReviewStatusId] = orderBy(response.data,["name"],["asc"]);
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        showSlideInfo(data, show) {
            this.selectedSlide = cloneDeep(data);
            if (show) {
                this.$bvModal.show("slide-info");
            } else {
                this.$bvModal.hide("slide-info");
            }
        },
        reuploadSlide(e, slide) {
            this.$emit("re-upload", {
                e,
                slide,
            });
        },
        updateSlide(data) {
            data.folderId = this.folderData.id; //eslint-disable-line
            this.$setModalAction({
                show: true,
                route: "upload-slide",
                title: this.$t(data.caseDetailId ? "common.editItem" : "common.createItem", {
                    item: this.$t("common.case"),
                }),
                size: "lg",
                data,
                folderData: data.id ? data.folder : this.folderData,
            });
        },
        deleteFileStatus(data, status, successCb,type) {
            this.$api.deleteFilesFact(data, status).then(
                (response) => {
                    if (!this.$handleResponse(response)) return;
                    if (successCb) successCb(response.data.payload);
                    if(type == 'bySlide')
                    {
                        this.$emit("update-slide-status");
                    }                    
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        deleteSlides(slides) {
            let buttons = {};
            if(this.selectedFolderUserRole === this.ROLE_GUEST)
            {
                buttons =
                {
                    cancel: true,
                    delete:
                    {
                        text: this.$t("common.delete"),
                        value: this.FOLDER_DELETE,
                        className: "bg-danger",
                    },
                }
            }
            else
            {
                buttons =
                {
                    cancel: true,
                    recycle:
                    {
                        text: this.$t("slidebox.MoveRecycleBin"),
                        value: this.FOLDER_RECYCLE,
                        className: "bg-secondary",
                    },
                    delete:
                    {
                        text: this.$t("common.delete"),
                        value: this.FOLDER_DELETE,
                        className: "bg-danger",
                    },
                };
            }
            const {
                pending,
                processed
            } = this.constructSlides(slides);
            const data = concat(pending, processed);
            if (!data || !data.length) return;
            this.$deleteConfirmation(
                data.length > 1 ?
                this.$t("slidebox.slidesPermanentDeleteMsg") :
                this.$t("slidebox.slidePermanentDeleteMsg"),
                (val) => {
                    const cb = (size) => {
                        let msg = null;
                        if (this.recycle || (val && val === this.FOLDER_DELETE)) {                                                   
                            this.$updateSubscriptionStorageData(this.$storageConsumed - size);                            
                            msg = this.$t("slidebox.PermanentlyDeletedSlide");
                            this.deleteMetadata(slides.metadata.id,{isRecycle:false });
                        } else {
                            msg = this.$t("slidebox.MovedRecyclebin");
                            this.deleteMetadata(slides.metadata.id,{isRecycle:true });
                        }
                        this.$toastr("success", msg);
                        this.selectedCaseSlideIds.splice(findIndex(this.selectedCaseSlideIds, item => item === slides.id),1,);
                        this.setSelectedCaseSlideIds(this.selectedCaseSlideIds);
                    };
                    this.deleteFileStatus(
                        data,
                        !this.recycle ? val : this.FOLDER_DELETE,
                        cb,
                        'bySlide'
                    );
                },
                "",
                !this.recycle ? buttons : null
            );
        },
        deleteMetadata(id,data) {
            this.$api.deleteMetadataFact(id,data).then(
                response => {
                    if (!this.$handleResponse(response)) return;
                },
                error => {
                    this.$handleError(error);
                },
            );
        },
        restoreSlides(slides) {
            const {
                pending,
                processed
            } = this.constructSlides(slides);
            const data = concat(pending, processed);
            if (!data || !data.length) return;
            this.$deleteConfirmation(
                data.length > 1 ?
                this.$t("slidebox.selectedSlidesWarningMsg", {
                    event: this.$t("slidebox.restore_sm"),
                }) :
                this.$t("notes.areYouSureWarningMsg", {
                    event: this.$t("slidebox.restore_sm"),
                    text: this.$t("common.slide"),  
                }),
                () => {
                    this.restoreMataData(slides.metadata.id);                    
                    this.selectedCaseSlideIds.splice(findIndex(this.selectedCaseSlideIds, item => item === slides.id),1,);
                    this.setSelectedCaseSlideIds(this.selectedCaseSlideIds);
                    this.deleteFileStatus(data, this.FOLDER_ACTIVE,"",'bySlide');
                }
            );
        },
        restoreMataData(id) {
            this.$api.restoreMetadataFact(id).then(
                response => {                    
                    if (!this.$handleResponse(response)) return;
                },
                error => {
                    this.$handleError(error);
                },
            );
        },
        selectAlert(slides) {
            if (slides.length <= 0) {
                const title = !(this.slides && this.slides.length > 0) ?
                    this.$t("slidebox.noSlidesAvailable") :
                    this.$t("slidebox.selectAtleastOneSlide");
                this.$modalAlert("warning", title);
            }
            return slides.length <= 0;
        },
        constructSlides(data) {
            let processed = [];
            let pending = [];
            if (isArray(data)) {
                if (this.selectAlert(data)) return null;
                processed = filter(data, (item) => !item.meta.pending);
                pending = filter(data, (item) => item.meta.pending);
            } else if (data.meta.pending) {
                pending.push(data);
            } else {
                processed.push(data);
            }
            return {
                pending,
                processed,
            };
        },
        getSlides(data) {
            this.$emit("get-slides", data);
        },
        getCases(data) {
            this.$emit("get-cases", data);
        },
        getSlideIdsList(selectedCaseSlideIds) {
            let slideIds = [];
            let splitIds = selectedCaseSlideIds.split(",");
            splitIds.forEach((element) => {
                slideIds.push(parseInt(element));
            });
            return slideIds;
        },
        async sharedToEditor(caseSlideIds) {
            if(this.selectedCaseSlideIds.length == 0)
            {
                await this.getSlidesByCaseSlideIds(caseSlideIds);
            }
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);
            if(validSlide)
            {
                let slideIds =
                    this.selectedCaseSlideIds.length == 0 ?
                    this.getSlideIdsList(caseSlideIds) :
                    this.selectedCaseSlideIds;

                if (!slideIds || !slideIds.length) return;
                this.$deleteConfirmation(
                    this.$t("slidebox.caseSharedToEditorConfirmMsg"),
                    () => {
                        this.$api.saveSharedToEditorFact(slideIds).then(
                            (response) => {
                                if (response.data.status) {
                                    this.$toastr("success", response.data.payload);
                                    this.getCases(this.paramsUtil);
                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        async shareBackToEditor(caseSlideIds) {
            if(this.selectedCaseSlideIds.length == 0)
            {
               await this.getSlidesByCaseSlideIds(caseSlideIds);
            }      
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);
            if(validSlide)
            {
                let slideIds =
                    this.selectedCaseSlideIds.length == 0 ?
                    this.getSlideIdsList(caseSlideIds) :
                    this.selectedCaseSlideIds;

                if (!slideIds || !slideIds.length) return;
                this.$deleteConfirmation(
                    this.$t("slidebox.caseShareBackToEditorConfirmMsg"),
                    () => {
                        this.$api.shareBackToEditorFact(slideIds).then(
                            (response) => {
                                if (response.data.status) {
                                    this.$toastr("success", response.data.payload);
                                    this.getCases(this.paramsUtil);
                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        async moveToEditorialBox(caseDetailId,caseSlideIds,caseNumber) {
            if(this.selectedCaseSlideIds.length == 0)
            {
                await this.getSlidesByCaseSlideIds(caseSlideIds);   
            }
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);
            if(validSlide)
            {
                // let slideIds =
                //     this.selectedCaseSlideIds.length == 0 ?
                //     this.getSlideIdsList(caseSlideIds) :
                //     this.selectedCaseSlideIds;

                // if (!slideIds || !slideIds.length) return;
                let caseIds = [];
                caseIds.push(caseDetailId);
                this.$deleteConfirmation(
                    this.$t("slidebox.caseMoveToEditorialBoxConfirmMsg"),
                    () => {
                        this.$api.movedToEditorialBoxFact(caseIds).then(
                            (response) => {
                                if (response.data.status) {
                                    this.copyCaseNumber(response.data.payload,caseNumber);
                                    this.getCases(this.paramsUtil);

                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        async moveToSlideLibrary(caseDetailId,caseSlideIds) {
            if(this.selectedCaseSlideIds.length == 0)
            {
              await this.getSlidesByCaseSlideIds(caseSlideIds);
            }             
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);            
            if(validSlide)
            {
                // let slideIds =
                // this.selectedCaseSlideIds.length == 0 ?
                // this.getSlideIdsList(caseSlideIds) :
                // this.selectedCaseSlideIds;

                // if (!slideIds || !slideIds.length) return;
                let caseIds = [];
                caseIds.push(caseDetailId);
                this.$deleteConfirmation(
                    this.$t("slidebox.caseMoveToSlideLibraryConfirmMsg"),
                    () => {
                        this.$api.movedToSlideLibraryFact(caseIds).then(
                            (response) => {
                                if (response.data.status) {
                                    this.$toastr("success", response.data.payload);
                                    this.getCases(this.paramsUtil);
                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        async getSlidesByCaseSlideIds(caseSlideIds)
        {
            let params = {
            //...data,
            page: this.paramsUtil.page,
            size: this.paramsUtil.size,
            sort: "id,desc",
            };
            return this.$api.getSlidesByCaseSlideIdsFact(caseSlideIds,params).then(
                response => {
                if (!this.$handleResponse(response)) return;
                const { content } = response.data.payload;
                if (this.folderData == null)
                {
                     this.slidesCaseView = map(content, item => assign(item, this.changeSlideData(item)));
                }
                else
                {
                    this.slidesCaseView = map(content, item => assign(item, this.getPermissions(this.folderData), this.changeSlideData(item)));
                }
                },
                error => {
                this.$handleError(error);
                },
            );
        },
        async moveBackForRevision(caseSlideIds,caseDetailId) {
            if(this.selectedCaseSlideIds.length == 0)
            {
                await this.getSlidesByCaseSlideIds(caseSlideIds);
            }
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);
            if(validSlide)
            {
                let slideIds =
                    this.selectedCaseSlideIds.length == 0 ?
                    this.getSlideIdsList(caseSlideIds) :
                    this.selectedCaseSlideIds;

                if (!slideIds || !slideIds.length) return;
                this.$deleteConfirmation(
                    this.$t("slidebox.caseMoveBackForRevisionConfirmMsg"),
                    () => {
                        this.$api.moveCaseBackForRevisionFact(caseDetailId).then(
                            (response) => {
                                if (response.data.status) {
                                    this.$toastr("success", response.data.payload);
                                    this.getCases(this.paramsUtil);
                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        async moveToPrePublication(caseSlideIds,caseDetailId) {
            if(this.selectedCaseSlideIds.length == 0)
            {
                await this.getSlidesByCaseSlideIds(caseSlideIds);
            }
            const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
            const validSlide = this.checkFileValidOrNot(slideList);
            if(validSlide)
            {
                let slideIds =
                    this.selectedCaseSlideIds.length == 0 ?
                    this.getSlideIdsList(caseSlideIds) :
                    this.selectedCaseSlideIds;

                if (!slideIds || !slideIds.length) return;
                this.$deleteConfirmation(
                    this.$t("slidebox.caseMoveToPrePublicationBoxConfirmMsg"),
                    () => {
                        this.$api.moveCaseToPrepublicationBoxFact(caseDetailId).then(
                            (response) => {
                                if (response.data.status) {
                                    this.$toastr("success", response.data.payload);
                                    this.getCases(this.paramsUtil);
                                } else {
                                    this.$toastr("error", response.data.statusMessage);
                                }
                            },
                            (error) => {
                                this.$handleError(error);
                            }
                        );
                    }
                );
            }
            else
            {
                this.$toastr('error', 'Please wait until all the files got processed.');
            }
        },
        changeSlideData(slide) {
            return {
                meta: {
                pending: this.pendingSlide(slide),
                isUploadFailed: slide.dziStatus === 4,
                isProcessingFailed: slide.dziStatus === 3,
                },
            };
        },
        getPermissions(folder) {
            return {
                editPermitted: folder.editPermitted,
                viewPermitted: folder.viewPermitted,
                deletePermitted: folder.deletePermitted,
                sharePermitted: folder.sharePermitted,
            };
        },
        pendingSlide({ fileType, path, dziStatus }) {
            if (fileType === this.SLIDE_UNKNOWN) {
                return !path || dziStatus !== 1;
            }
            if (fileType === this.SLIDE_DIGITALSLIDE || fileType === this.SLIDE_ZOOMIFY) {
                return dziStatus !== 1 || !path;
            }
            return false;
        },
        checkFileValidOrNot(slideList)
        {
            let validSlide = true;  
            for (let i = 0; i < slideList.length; i++) {
                const element = slideList[i];
                validSlide = !element.meta.pending
                    && !element.meta.isProcessingFailed
                    && !element.meta.isUploadFailed;  
                    if (!validSlide) {
                        break;
                    }
            }
            return validSlide;
        },
        caseReviewComment(caseDetailId){
            var data = {};
            data.caseDetailId = caseDetailId;

            if(data){
                this.$setModalAction({ 
                    show: true, 
                    route: "case-review-comment", 
                    title: "Case Comments", 
                    data 
                });
            }
        },  
        deleteCaseData(casedata)     
        {
            if (casedata.caseSlideIds.length > 0) {
            let slideIds = [];
            let splitIds = casedata.caseSlideIds.split(',');
            splitIds.forEach(element => {
                slideIds.push(parseInt(element));
            });
            let params = {
                page: 0,
                size: slideIds.length,
                sort: this.paramsUtil.sort,
            };
            this.getSlidesDataByCaseSlideIds(slideIds,params,casedata);
            }
        },        
        getSlidesDataByCaseSlideIds(data,paramsUtil,casedata) {
            let params = {
                page: paramsUtil.page,
                size: paramsUtil.size,
                sort: (paramsUtil.sort.includes("case_number") || paramsUtil.sort.includes("case_number,system_case_number")) ?"id,desc":paramsUtil.sort,
            };
            this.$api.getSlidesByCaseSlideIdsFact(data,params).then(
                response => {
                if (!this.$handleResponse(response)) return;                 
                const slideData = response.data.payload.content;

                if (this.folderData.folderType === this.FOLDER_EDITORIALBOX)
                {   
                    let buttons = {};
                    
                    buttons = 
                    {
                        cancel: true,
                        delete:
                        {
                            text: this.$t("common.delete"),
                            value: this.FOLDER_DELETE,
                            className: "bg-danger",
                        },
                    }
                    this.$deleteConfirmation(                   
                        this.$t("slidebox.casePermanentDeleteMsg"),
                        (val) => {
                            let msg = null;
                            slideData.forEach(result => {
                                if (this.recycle || (val && val === this.FOLDER_DELETE)) {                                        
                                    this.$updateSubscriptionStorageData(this.$storageConsumed - result.fileSize);                            
                                    msg = this.$t("slidebox.PermanentlyDeletedCase");
                                    this.deleteCasedata(casedata.caseDetailId,'',{isRecycle:false });
                                } else {
                                    msg = this.$t("slidebox.MovedRecyclebin");
                                    this.deleteCasedata(casedata.caseDetailId,'',{isRecycle:true });
                                }
                                this.deleteFileStatus(data,!this.recycle ? val : this.FOLDER_DELETE,"",'byCase');
                            });
                            this.$toastr("success", msg);    
                            setTimeout(() => {
                                this.refreshCaseData();       
                                }, 2000); 
                            },
                        "",
                        !this.recycle ? buttons : null
                        );
                }
                else
                {
                    let buttons = {};
                    if(this.selectedFolderUserRole === this.ROLE_GUEST)
                    {
                        buttons = 
                        {
                            cancel: true,
                            delete:
                            {
                                text: this.$t("common.delete"),
                                value: this.FOLDER_DELETE,
                                className: "bg-danger",
                            },
                        }
                    }
                    else
                    {
                        buttons =
                        {
                            cancel: true,
                            recycle:
                            {
                                text: this.$t("slidebox.MoveRecycleBin"),
                                value: this.FOLDER_RECYCLE,
                                className: "bg-secondary",
                            },
                            delete:
                            {
                                text: this.$t("common.delete"),
                                value: this.FOLDER_DELETE,
                                className: "bg-danger",
                            },
                        };
                    }
                    this.$deleteConfirmation(
                        this.$t("slidebox.casePermanentDeleteMsg"),
                        (val) => {
                            let msg = null;
                                slideData.forEach(result => {                                
                                    this.$updateSubscriptionStorageData(this.$storageConsumed - result.fileSize);                                
                                });
                                if (this.recycle || (val && val === this.FOLDER_DELETE)) {                                
                                    msg = this.$t("slidebox.PermanentlyDeletedCase");
                                    this.deleteCasedata(casedata.caseDetailId,casedata.caseSlideIds,{isRecycle:false });
                                } 
                                else {
                                    msg = this.$t("slidebox.MovedRecyclebin");
                                    this.deleteCasedata(casedata.caseDetailId,casedata.caseSlideIds,{isRecycle:true });
                                }
                                this.deleteFileStatus(data,!this.recycle ? val : this.FOLDER_DELETE,"",'byCase');                        
                                this.$toastr("success", msg);    
                                setTimeout(() => {
                                    this.refreshCaseData();       
                                    }, 2000); 
                                },
                            "",
                            !this.recycle ? buttons : null
                            );                      
                }
            },
            (error) => {
              this.$handleError(error);
            });              
        },
        deleteCasedata(id,caseSlideIds,data) {
            if (this.folderData.folderType === this.FOLDER_RECYCLEBIN) 
            {
                 let slideIds = [];
                    let splitIds = caseSlideIds.split(",");
                    splitIds.forEach((element) => {
                        slideIds.push(parseInt(element));
                 });
                this.$api.deleteMetadataFromRecycleFact(slideIds,data).then(
                    response => {
                        if (!this.$handleResponse(response)) return;
                    },
                    error => {
                        this.$handleError(error);
                    },
                );
            }
            else
            {
                this.$api.deleteCasedataFact(id,data).then(
                    response => {
                        if (!this.$handleResponse(response)) return;
                    },
                    error => {
                        this.$handleError(error);
                    },
                );
            }
        },
        restoreCaseData(casedata)     
        {           
            if (casedata.caseSlideIds.length > 0) {
            let slideIds = [];
            let splitIds = casedata.caseSlideIds.split(',');
                splitIds.forEach(element => {
                    slideIds.push(parseInt(element));
                });
                
                this.$deleteConfirmation(                
                    this.$t("slidebox.selectedCaseWarningMsg", {
                        event: this.$t("slidebox.restore_sm"),
                    }),
                    () => {
                        this.restoreCaseDataById(casedata.caseDetailId);                                        
                        this.deleteFileStatus(slideIds, this.FOLDER_ACTIVE,"",'byCase');
                        setTimeout(() => {
                        this.refreshCaseData();       
                        }, 2000); 
                    }
                );      
            }      
        },
        restoreCaseDataById(id) {
            this.$api.restoreCasedataFact(id).then(
                response => {                    
                    if (!this.$handleResponse(response)) return;
                },
                error => {
                    this.$handleError(error);
                },
            );
        },
        viewCaseInfo(caseSlideIds,caseDetailId,regionName)
        {
            let id = 0;
            if (caseSlideIds.indexOf(",") > -1) {
                let splitIds = caseSlideIds.split(",");
                id = splitIds[0];
            } else {
                id = caseSlideIds;
            }
            var query;
            var movedToEditorialBox = this.isMovedToEditorialBox;
            var movedToSlideLibrary = this.isMovedToSlideLibrary;
            var sharedToEditor = this.isSharedToEditor;
            var selectedFolderView = this.selectedFolderView;
            var selectedFolderUserRole = this.selectedFolderUserRole;
            var selectedFolderUserRegion = this.selectedFolderUserRegion;
            var selectedCaseRegion = regionName;
            var isPublicSite = false;

            const route = 'display-viewer';
            this.$changeRouteToTab({
            name: route,
            params: {
                id,
                caseDetailId,
                movedToEditorialBox,
                movedToSlideLibrary,
                sharedToEditor,
                isPublicSite,
                caseSlideIds,
                selectedFolderView,
                selectedFolderUserRole,
                selectedFolderUserRegion,
                selectedCaseRegion
            },
            query: query ? query.query : null,
            });
        },
        openModal(caseDetailId) {
        var data = {};
        data.caseDetailId = caseDetailId;
        this.$setModalAction({
            show: true,
            route: "review-status",
            title: this.$t("common.reviewStatus"),
            data
        });
        },
        copyCaseNumber(systemCaseNumber,caseNumber)
        {
            var data = {};
            data.systemCaseNumber = systemCaseNumber;
            data.caseNumber = caseNumber;
            this.$setModalAction({
                show: true,
                route: "case-copy",
                title: this.$t("common.caseMovedToEB"),
                data
            });
        },
        refreshCaseData()
        {
            const eventSend = new CustomEvent("callGetAllCases", {
            detail: {
                filtertype: null,
                filtervalue: null,
                page: 0,
                size:8,
                sort:"id,desc"          }
            });
            window.dispatchEvent(eventSend);
        },
        isColumnVisible(column,geographicRegion)
        {
            const loginUserRole = localStorage.getItem("userRole");
            let userRoleSelectedFolder = this.selectedFolderUserRole;
            userRoleSelectedFolder = userRoleSelectedFolder === this.ROLE_CHIEF_EDITOR ? 'CHIEFEDITOR' : userRoleSelectedFolder === this.ROLE_PROJECT_ADMIN ? 'PROJECTADMIN' :
                                    userRoleSelectedFolder === this.ROLE_TENANT_ADMIN ? 'TENANTADMIN' : userRoleSelectedFolder;

            if (this.folderData.folderType === this.FOLDER_EDITORIALBOX)
            {
                const loginUserRole = localStorage.getItem("userRole");
                if (loginUserRole === this.ROLE_CHIEF_EDITOR)
                {
                    if (geographicRegion)
                    {  
                        if(column === 'Change review status' || column === 'Edit' || column === 'Move back for revision' || column === 'Move to Pre-publication box' || column === 'Delete')
                        {
                            let userRegionSelectedFolder = this.selectedFolderUserRegion;
                            const regionName = this.subFilters[this.indexGeographicRegionId].filter(t => t.id === userRegionSelectedFolder)[0].name;
                            if (regionName !== geographicRegion)
                            {
                                if (this.EDITORIALBOX_ROLES['CHIEFEDITORDIFFREGION'] && this.EDITORIALBOX_ROLES['CHIEFEDITORDIFFREGION'].includes(column))
                                {
                                    return true;
                                }
                            }   
                            else
                            {
                               if (this.EDITORIALBOX_ROLES[userRoleSelectedFolder] && this.EDITORIALBOX_ROLES[userRoleSelectedFolder].includes(column))
                                {
                                    return true;
                                } 
                            }

                        }
                    }
                    else
                    {
                        if (this.EDITORIALBOX_ROLES[userRoleSelectedFolder] && this.EDITORIALBOX_ROLES[userRoleSelectedFolder].includes(column))
                        {
                            return true;
                        }
                    }
                }
                else
                {
                    if (this.EDITORIALBOX_ROLES[userRoleSelectedFolder] && this.EDITORIALBOX_ROLES[userRoleSelectedFolder].includes(column))
                    {
                        return true;
                    }
                }
            }
            if (this.folderData.folderType === this.FOLDER_MYFOLDER) 
            {
                if(loginUserRole === this.ROLE_GUEST && userRoleSelectedFolder === this.ROLE_GUEST)
                {
                    if (this.MYFOLDER_ROLES[userRoleSelectedFolder] && this.MYFOLDER_ROLES[userRoleSelectedFolder].includes(column)) {
                           return true;
                    }
                }
                if(loginUserRole !== this.ROLE_GUEST && userRoleSelectedFolder === this.ROLE_GUEST)
                {                    
                    if(this.folderData.isShared)
                    {
                        if (this.MYFOLDER_ROLES['GUESTSHARED'] && this.MYFOLDER_ROLES['GUESTSHARED'].includes(column)) {
                           return true;
                        }
                    }
                    else
                    {
                        if (this.MYFOLDER_ROLES[this.ROLE_GUEST] && this.MYFOLDER_ROLES[this.ROLE_GUEST].includes(column)) {
                           return true;
                        }
                    }
                }
                if(userRoleSelectedFolder === this.ROLE_EDITOR || userRoleSelectedFolder === this.ROLE_CHAIRPERSON 
                    || userRoleSelectedFolder === 'CHIEFEDITOR')
                {
                    if (this.MYFOLDER_ROLES[userRoleSelectedFolder] && this.MYFOLDER_ROLES[userRoleSelectedFolder].includes(column)) {
                           return true;
                    }
                }
            }
            if (this.folderData.folderType === this.FOLDER_MYFOLDER && column == VISIBLE_COLUMNS.UPLOADFILES && 
                (userRoleSelectedFolder === this.ROLE_EDITOR || userRoleSelectedFolder === this.ROLE_CHAIRPERSON || userRoleSelectedFolder === this.ROLE_GUEST
                || userRoleSelectedFolder === 'CHIEFEDITOR')) 
            {    
                let uploadFiles = '';
                if(userRoleSelectedFolder === this.ROLE_EDITOR && loginUserRole === this.ROLE_EDITOR)
                {
                    uploadFiles = VISIBLE_COLUMNS.UPLOADFILES;
                }
                if(userRoleSelectedFolder === this.ROLE_CHAIRPERSON && loginUserRole === this.ROLE_CHAIRPERSON)
                {
                    uploadFiles = VISIBLE_COLUMNS.UPLOADFILES;
                }                
                if(userRoleSelectedFolder === this.ROLE_GUEST  && loginUserRole === this.ROLE_GUEST)
                {
                    uploadFiles = VISIBLE_COLUMNS.UPLOADFILES;
                }   
                if(userRoleSelectedFolder === 'CHIEFEDITOR' && loginUserRole === this.ROLE_CHIEF_EDITOR)
                {
                    if(this.folderData.userId === parseInt(localStorage.getItem("userId")))
                    uploadFiles = VISIBLE_COLUMNS.UPLOADFILES;
                }            
                switch (column)
                {
                    case uploadFiles :
                    return true;
                        default:
                        return false;
                }
            }
            if (this.folderData.folderType === this.FOLDER_RECYCLEBIN) 
            {
                if (this.RECYCLE_ROLES[userRoleSelectedFolder] && this.RECYCLE_ROLES[userRoleSelectedFolder].includes(column)) {
                           return true;
                    }
            }
        },
        getValueCascadeSelection(mainObj)
        {
            if (mainObj.length === 1)
            {
                return mainObj[0].name;
            }
            else
            {
                var parentString = mainObj.filter(obj => obj.parentId === null)[0].name;
                var childString = mainObj.filter(obj => obj.parentId !== null).map(obj => obj.name).join('/');
                return parentString + ", " + childString;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-body {
    img {
        pointer-events: none;
    }

    padding: 0px;
}

.card-footer {
    padding: 0px;
    text-align: center;
}

.folder-card:hover {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.btn-file {
    position: relative;
    overflow: hidden;

    input {
        position: absolute;
        cursor: pointer;
        font-size: 50px;
        opacity: 0;
        right: 0;
        top: 0;
    }
}
.fs-13{
    font-size: 13px !important;
}
.slide-name {
    width: 150px;
    word-break: break-all;
}
</style>
