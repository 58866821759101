<template>
  <div class="h-100 pp-list" v-if="options && toolbarClass">
    <div class="row m-0 px-2" v-if="!options.disableToolbar">
      <div :class="`${toolbarClass.search}`">
        <b-form
          v-on:submit.prevent="searchChange(searchInput, 'change')"
          class="row m-0"
        >
          <div class="col-12 col-xl form-group m-0 p-0" v-if="!options.customSearch && !options.hideSearch">
            <div class="row m-0">
              <div class="form-group pl-md-0 col-12 col-md-4 col-lg-3 col-xl-2">
                <input
                  type="text"
                  class="form-control form-control-sm form-control-muted"
                  :disabled="disableSearch"
                  :value="params.search"
                  :title="$t('notes.searchFromRecords', { total: options.totalElements })"
                  v-on:keyup="searchChange($event.target.value, 'input')"
                  :placeholder="$t('common.search')"
                  aria-label="Search"
                  aria-labelledby="Search"
                />           
              </div>
              <template v-for="(subFilter, index) in statusList">
                <div class="form-group pl-md-0 col-auto" style="width: 150px;" :key="index" v-if="isColumnVisible(configSubFilters[index].placeholder)">
                  <p-dropdown 
                    :aria-label="configSubFilters[index].placeholder"               
                    :aria-labelledby="configSubFilters[index].placeholder" :placeholder="configSubFilters[index].placeholder" 
                    :optionLabel="configSubFilters[index].optionLabel" :title="configSubFilters[index].placeholder"
                    v-model="selectedSubFilter[index]"
                    v-on:change="searchItem(searchInput)"
                    class="p-dropdown-custom form-control form-control-sm" 
                    :options="subFilter">
                  </p-dropdown>
                </div>
              </template>
              <div class="form-group pl-md-0 col-auto">
                <button type="submit" title="search" v-on:click="searchChange(searchInput, 'change')" class="btn p-0 text-body">
                  <span class="material-symbols-outlined fs-22 p-1">search</span>
                </button>
                <button title="All Clear" class="btn p-0 text-body" v-on:click="clearSearch()">
                  <span class="material-symbols-outlined fs-22 p-1">filter_alt_off</span>
                </button>
              </div>
            </div>
          </div>
          <slot v-else name="custom-search"></slot>
          <div class="col-12 col-xl-auto form-group m-0 p-0">
            <div class="btn-group pb-10" v-if="showCaseSlides">
              <b-btn variant="outline-primary" size="sm" v-on:click="backToCaseView()" :title="$t('common.backToCaseView')">
                <i class="fa fa-arrow-left"></i> {{ $t('common.backToCaseView') }}
              </b-btn>
            </div>
          </div>
          <div class="col-12 col-xl-auto form-group ml-auto p-0">
            <div class="d-flex justify-content-end" style="column-gap: 5px;">
              <slot name="list-buttons"></slot>
              <div class="btn-group" v-if="options.canExportTSV && allCases && allCases.length > 0">
                <b-btn variant="primary" size="sm" :title="$t('common.tsv')" v-on:click="exportToTSV()">
                  <img src="../../assets/icons-new/Icons_ExportTSV.svg" alt="TSV" style="width:45px"/>
                </b-btn>
              </div>
              <div class="btn-group" v-if="!showCaseSlides && menuToggle && (allCases && allCases.length > 0)">
                <b-btn variant="primary" size="sm" v-on:click="changeCaseGridMode(false)" :title="$t('common.list')">
                  <img  style="width: 20px;" :src="require('@/assets/icons-new/'+getCaseGridVariant('table')+'.svg')" alt="Icon" />
                </b-btn>
                <b-btn variant="primary" size="sm" v-on:click="changeCaseGridMode(true)" :title="$t('common.grid')">
                  <img  style="width: 18px;" :src="require('@/assets/icons-new/'+getCaseGridVariant('card')+'.svg')" alt="Icon" />
                </b-btn>            
              </div>
              <div class="btn-group" v-else-if="showCaseSlides && menuToggle && (list && list.length > 0)">
                <b-btn variant="primary" size="sm" v-on:click="changeGridMode(false)" :title="$t('common.list')">
                  <img  style="width: 20px;" :src="require('@/assets/icons-new/'+getGridVariant('table')+'.svg')" alt="Icon" />
                </b-btn>
                <b-btn variant="primary" size="sm" v-on:click="changeGridMode(true)" :title="$t('common.grid')">
                  <img  style="width: 18px;" :src="require('@/assets/icons-new/'+getGridVariant('card')+'.svg')" alt="Icon" />
                </b-btn>            
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <div
        v-if="hasFilters"
        :class="`${toolbarClass.filter}`"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-info-light border-none"><i class="fa fa-filter text-muted"></i></span>
          </div>
          <select
            class="form-control form-control-muted"
            aria-label="Change Filter"
            v-on:change="changeFilter($event.target.value)"
          >
            <option
              :value="JSON.stringify({ filtertype: null, filtervalue: null })"
              :selected="params.filtertype === null"
            >
              {{$t('common.all')}}
            </option>
            <option
              :value="
                JSON.stringify({
                  filtertype: item.type,
                  filtervalue: item.value
                })
              "
              v-for="(item, itemIndex) in filterOptions"
              :selected="params.filtervalue === item.value"
              :key="itemIndex"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div
        :class="`${toolbarClass.rangeFilter}`"
        v-if="options.hasRangeFilter"
      >
        <slot name="range-filter"></slot>
      </div>
    </div>
    <slot name="static-header-content"> </slot>
    <slot name="content"> </slot>
    <!-- @scroll="onScroll" -->
    <div class="container-fluid p-0 position-relative">
      <div class="position-absolute w-100">
        <wtr-loader v-if="showLoader" containerClass="align-items-center d-flex fs-18 justify-content-center case-view-table" containerStyle="column-gap: 5px;" addText variant="success"></wtr-loader>
      </div>
      <div v-if="(list && list.length > 0)||(allCases && allCases.length > 0)" class="h-100" :class="{ 'fetch-class': showLoader }">
        <transition-group name="fade" mode="in-out">
          <div v-if="(showCaseSlides && gridMode)" key="gridMode" class="case-view-table">
            <slot name="list-card"></slot>          
          </div>
          <div v-show="(showCaseSlides && !gridMode)" key="listMode" class="case-view-table">
            <slot name="list-table"></slot>
          </div>
          <div v-show="!showCaseSlides && gridCaseMode" key="caseGridMode" class="case-view-table">
            <slot name="case-list-card"></slot>
          </div>
          <div v-show="!showCaseSlides && !gridCaseMode" key="caseListMode" class="case-view-table">
            <slot name="case-list-table"></slot>
          </div>
        </transition-group>
      </div>
      <no-data-found :message="emptyListMessage" v-else-if="!options.disableNoData && !showLoader"></no-data-found>
      <!-- <intersect key="intesector" @enter="onScroll">
        <div key="intesector-div">
          <wtr-loader
            containerClass="text-center fs-18 d-block"
            key="intesector-loader"
            addText
            v-if="showScrollLoader"
            variant="success"
          ></wtr-loader>
        </div>
      </intersect> -->
    </div>
    <div v-if="showPagination && !showCaseSlides && allCases && allCases.length > 0" class="row m-0 mt-5 align-items-center px-2" style="row-gap: 10px;">
      <div class="col-auto px-md-0">
        <select aria-label="Change Size" v-on:change="changeSize(Number($event.target.value))"
          class="form-control form-control-sm form-control-muted" style="width: 200px;"
        >
          <option :value="null" selected class="fs-14">Case {{$t('common.perPage')}}</option>
          <!-- <option :value="options.totalCaseElements" :selected="params.size === options.totalCaseElements">  {{$t('common.all')}} </option> -->
          <option class="fs-14" :value="item" :key="itemIndex" v-for="(item, itemIndex) in totalCaseElements" :selected="params.size === item"> {{ item }} </option>
        </select>
      </div>
      <div class="col-auto">
        <b-pagination size="sm" pills @change="pageChange" class="m-0"
          :value="params.page + 1" :limit="3" :per-page="params.size" :total-rows="options.totalCaseElements"
        ></b-pagination>
      </div>
      <div class="col-auto" v-if="options.totalCaseElements > 1000">
        <div class="info-text"><i class="fa fa-info-circle"></i>{{$t('notes.maximumOf1000Records')}}</div>
      </div>
    </div>
    <div v-else-if="(showPagination || !showCaseSlides) && list && list.length > 0" class="row m-0 mt-5 align-items-center px-2" style="row-gap: 10px;">
      <div class="col-auto px-md-0">
        <select aria-label="Change Size" v-on:change="changeSize(Number($event.target.value))"
          class="form-control form-control-sm form-control-muted" style="width: 200px;"
        >
          <option :value="null" selected class="fs-14">{{$t('common.perPage')}}</option>
          <!-- <option :value="options.totalElements" :selected="params.size === options.totalElements"> {{$t('common.all')}} </option> -->
          <option class="fs-14" :value="item" :key="itemIndex" v-for="(item, itemIndex) in totalElements" :selected="params.size === item"> {{ item }} </option>
        </select>
      </div>
      <div class="col-auto">
        <b-pagination size="sm" pills @change="pageChange" class="m-0"
          :value="params.page + 1" :limit="3" :per-page="params.size" :total-rows="options.totalElements"
        ></b-pagination>
      </div>
      <div class="col-auto" v-if="options.totalElements > 1000">
        <div class="info-text"><i class="fa fa-info-circle"></i>{{$t('notes.maximumOf1000Records')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, assign } from "lodash";
// import Intersect from "vue-intersect";
import localStorage from "../../helpers/localStorage";
import noDataFound from "./no-data-found.vue";
import { mapGetters, mapActions } from "vuex";
import * as folderTypes from '../../constants/constants-folder';
import { VISIBLE_COLUMNS } from "../../constants/constants-visiblecolumns";

export default {
  name: "wtr-case-list",
  data() {
    return {
      ...folderTypes,
      gridCaseMode: this.options.gridCaseMode ? this.options.gridCaseMode : false,
      gridMode: this.options.gridMode ? this.options.gridMode : false,
      paramsCopy: this.params,
      searchInput: "",
      toolbarClass: null,
      caseView: this.options.multiView ? this.options.multiView : false,
      selectedSubFilter: [],
      VISIBLE_COLUMNS: VISIBLE_COLUMNS,
      statusList : []
    };
  },
  props: {
    options: Object,
    id: String,
    canScrollDown: Boolean,
    params: Object,
    resetParams: Function,
    listLoading: Boolean,
    emptyListMessage: {
      type: String,
      default: () => "No data found",
    },
    subFilters: {
      type: Array,
      default: () => []
    },
    configSubFilters: {
      type: Array,
      default: () => []
    },
    folderData: {
      type: Object,
      default: null
    }
  },
  components: {
    "no-data-found": noDataFound,
    // Intersect,
  },
  computed: {
    ...mapGetters({
      showCaseSlides: "getShowCaseSlidesStatus",
      showCaseView: "getShowCaseViewStatus",
      selectedCaseNumber: "getSelectedCaseNumber",
      selectedFolderUserRole: "getSelectedFolderUserRole",
    }),
    list() {
      return this.options.list ? this.options.list : [];
    },
    allCases() {
      return this.options.allCases ? this.options.allCases : [];
    },
    filterOptions() {
      return this.options.filterOptions;
    },
    showPagination() {
      return this.options.pagination;
    },
    totalPages() {
      return this.options.totalPages ? Number(this.options.totalPages - 1) : 0;
    },
    totalElements() {
      const percentile = this.options.totalElements > 100 ? 10 : 5;
      const diff = this.options.totalElements % percentile;
      let total = this.options.totalElements + (percentile - diff);
      total = total > 1000 ? 1000 : total;
      let x = percentile;
      const arr = [];
      while (x <= total) {
        arr.push(x);
        x += percentile;
      }
      return arr;
    },
    totalCasePages() {
      return this.options.totalCasePages
        ? Number(this.options.totalCasePages - 1)
        : 0;
    },
    totalCaseElements() {
      const percentile = this.options.totalCaseElements > 100 ? 10 : 5;
      const diff = this.options.totalCaseElements % percentile;
      let total = this.options.totalCaseElements + (percentile - diff);
      total = total > 1000 ? 1000 : total;
      let x = percentile;
      const arr = [];
      while (x <= total) {
        arr.push(x);
        x += percentile;
      }
      return arr;
    },
    menuToggle() {
      return this.options.menuToggle;
    },
    disableList() {
      return this.options.disableList;
    },
    hasFilters() {
      return (
        this.options.filterOptions && this.options.filterOptions.length > 0
      );
    },
    disableSearch() {
      return this.options.disableSearch;
    },
    contentStyles() {
      const maxHeight =
        this.options.maxHeight ||
        this.$dashboardHeight - (this.showPagination ? 230 : 180);
      return this.$getCardBodyStyle(maxHeight);
    },
    showLoader() {
      return this.listLoading;
    },
    showScrollLoader() {
      return this.listLoading && !this.showPagination;
    },
    multiView() {
      return this.options.multiView;
    },
    caseNumber() {
      return this.selectedCaseNumber;
    },
    // isSmallScreen(){
    //   const screenWidth = window.screen.width;
    //   console.log(' screenWidth ',  screenWidth);
    //   if(screenWidth < 950){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // },
  },
  methods: {
    ...mapActions(["setShowCaseSlides", "setShowCaseView"]),
    getToolbarClass() {
      const hasToolbar =
        this.options && this.options.toolbar ? this.options.toolbar : null;
      return {
        /* eslint-disable  */
        search:
          hasToolbar && hasToolbar.search
            ? hasToolbar.search
            : this.hasFilters || this.options.hasRangeFilter
            ? "col-xl-3 col-lg-4 col-md-4 col-sm-12"
            : "col-xl-3 col-lg-7 col-md-7 col-sm-12",
        /* eslint-disable */
        filter:
          hasToolbar && hasToolbar.filter
            ? hasToolbar.filter
            : this.hasFilters
            ? this.options.hasRangeFilter
              ? "col-xl-3 col-lg-4 col-md-4 col-sm-12"
              : "col-xl-2 col-lg-5 col-md-5 col-sm-12"
            : null,
        actions:
          hasToolbar && hasToolbar.actions
            ? hasToolbar.actions
            : this.hasFilters && this.options.hasRangeFilter
            ? "col-xl-3 col-lg-12 col-md-12 col-sm-12"
            : this.hasFilters || this.options.hasRangeFilter
            ? "col-xl-7 col-lg-12 col-md-12 col-sm-12"
            : "col-xl-9 col-lg-12 col-md-12 col-sm-12",
        /* eslint-disable */
      };
    },
    onScroll(evt) {
      if (
        this.showPagination ||
        this.totalPages <= 0 ||
        this.totalCasePages <= 0
      )
        return;
      const params = this.params; //eslint-disable-line
      params.page += 1;
      this.$emit("scroll-bottom", params);
    },
    searchChange(text, type) {
      this.searchInput = text;
      if (type === "input" && text) return;
      if (type === "change" && !text) return;
      this.searchItem(text);
    },
    searchItem(text) {
      var params = this.params; //eslint-disable-line
      
      if(text.trim()){
        params.search = text.trim();
      }else{
        delete params.search;
      }
        
      this.configSubFilters.forEach((subFilter, index) => {
        if(this.selectedSubFilter[index]?.id){
          params[subFilter.searchParam] = this.selectedSubFilter[index][this.configSubFilters[index].optionValue];
        }else{
          delete params[subFilter.searchParam];
        }          
      });

      this.updated("search", params);
      this.$emit("search", text);
    },
    clearSearch(){
      this.selectedSubFilter = []; 
      this.searchChange('', 'input');
    },
    changeSize(size) {
      const params = this.params; //eslint-disable-line
      params.size = size > 1000 ? 1000 : size;
      if (this.options.enableFilterCb) {
        this.$emit("size", size, (paramsData) => {
          this.updated("size", paramsData);
        });
      } else {
        this.$emit("size", size);
        this.updated("size", params);
      }
    },
    changeFilter(data) {
      let params = this.params; //eslint-disable-line
      params = assign(params, JSON.parse(data));
      if (this.options.enableFilterCb) {
        this.$emit("filter", JSON.parse(data), (paramsData) => {
          this.updated("filter", paramsData);
        });
      } else {
        this.$emit("filter", JSON.parse(data));
        this.updated("filter", params);
      }
    },
    pageChange(item) {
      const params = this.params; //eslint-disable-line
      params.page = cloneDeep(item);
      this.updated("page", params);
      this.$emit("page-change", params.page);
    },
    updated(type, data) {
      if (this.showCaseView) {
        data.page = type !== "page" ? 0 : data.page - 1; //eslint-disable-line
        if (this.id) localStorage.setItem(this.id, data);
        this.$emit("update-case-list", data);
      } else {
        data.page = type !== "page" ? 0 : data.page - 1; //eslint-disable-line
        if (this.id) localStorage.setItem(this.id, data);
        this.$emit("update-list", data);
      }
    },
    getCaseGridVariant(type) {
      if (type === "card") {
        return this.gridCaseMode ? "Icons_PreviewModeSelected" : "Icons_PreviewMode";
      }
      return !this.gridCaseMode ? "Icons_ListModeSelected" : "Icons_ListMode";
    },
    changeCaseGridMode(type) {
      this.gridCaseMode = type;
      this.$emit("grid-case-mode", this.params, type);
    },
    getGridVariant(type) {
      if (type === "card") {
        return this.gridMode ? "Icons_PreviewModeSelected" : "Icons_PreviewMode";
      }
      return !this.gridMode ? "Icons_ListModeSelected" : "Icons_ListMode";
    },
    changeGridMode(type) {
      this.gridMode = type;
      this.$emit("grid-mode", this.params, type);
    },
    getCaseViewVariant(type) {
      if (type === "slide") {
        return !this.showCaseView ? "primary" : "outline-primary";
      }
      return this.showCaseView ? "primary" : "outline-primary";
    },
    backToCaseView() {
      this.setShowCaseSlides(false);
      this.setShowCaseView(true);
      let params = this.params; //eslint-disable-line
      params.sort="cd.case_number,desc";
      this.$emit("update-case-list", params);
    },
    backToSlideView() {
      let params = this.params; //eslint-disable-line
      params.sort="id,desc";
      this.$emit("update-list", params);
      this.setShowCaseView(false);
    },
    init() {
      if (this.menuToggle && this.$responsive.includes(this.$mq)) {
        this.gridMode = true;
        this.gridCaseMode = true;
        this.setShowCaseView(false);
      }
      this.toolbarClass = this.getToolbarClass();

      this.subFilters.forEach((subFilter) => {
        if(!subFilter.find(obj => obj.id === 0)){
          subFilter.unshift({id:0, name: "All"});
        }
      });
      this.statusList =  [...this.subFilters];
      if(this.folderData.folderType === this.FOLDER_EDITORIALBOX)
      {        
        this.statusList[3] = this.subFilters[3].filter(item => [3, 4, 7, 8, 9].includes(item.id));
        this.statusList[3].unshift({id:0, name: "All"});
      }
      else if(this.selectedFolderUserRole === 'GUEST' && !this.folderData.isShared)
      {
        this.statusList[3] = this.subFilters[3].filter(item => [1,6].includes(item.id));
        this.statusList[3].unshift({id:0, name: "All"});
      }
      else
      {
        this.statusList[3] = this.subFilters[3].filter(item => [2,5].includes(item.id));
        this.statusList[3].unshift({id:0, name: "All"});
      }
    },
    isColumnVisible(column) {
      if (this.folderData.folderType === this.FOLDER_MYFOLDER) 
      {
          switch (column)
          {
            case VISIBLE_COLUMNS.BYCOHORT:
            case VISIBLE_COLUMNS.BYREVIEWSTATUS:
            return true;
            default:
            return false;
          }
      } 
      if(this.folderData.folderType === this.FOLDER_EDITORIALBOX)
      {
        return true;
      }    
      if(this.folderData.folderType === this.FOLDER_PUBLICBOX || this.folderData.folderType === this.FOLDER_PREPUBLICATIONBOX)
      {
        switch (column)
          {
            case VISIBLE_COLUMNS.BYCOHORT:
            case VISIBLE_COLUMNS.BYREGION:
            case VISIBLE_COLUMNS.BYEDITOR:
            return true;
            default:
            return false;
          }
      }   
    },
    exportToTSV() {
      this.$emit("export-click");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.section {
  overflow-y: auto;
  overflow-x: hidden;
}
.form-control-muted,
.form-control-muted:focus {
  background-color: #f3f9ff;
}
.form-control-muted {
  border-color: #f3f9ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
