import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import sessionService from './session-service';
import urls from '../api/urls';

function getHeaders() {
  return {
    authorization: sessionService.getToken(),
  };
}

export default class SlideboxSocket {
  constructor() {
    this.stompClient = null;
    this.socket = null;
    this.connected = false;
    this.messagesSubscription = null;
  }

  connectSokect = (success, error) => {
    const headers = getHeaders();
    const endPoint = process.env.VUE_APP_URL;
    if (!endPoint) return;
    this.socket = new SockJS(`${endPoint}/slidebox/file-process`);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.debug = process.env.NODE_ENV === 'production' ? null : this.stompClient.debug;
    this.stompClient.connect(
      headers,
      () => {
        this.connected = true;
        if (success) success();
      },
      message => {
        if (error) error(message);
      },
    );
  };

  disconnectSokect = () => {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    this.connected = false;
  };

  subscribeMessages = (id, success) => {
    const { topic } = urls;
    this.unsubscribe(this.messagesSubscription);
    const headers = getHeaders();
    this.messagesSubscription = this.stompClient.subscribe(
      `${topic}/messages/${id}`,
      data => {
        if (data.body === 'NEW-DICOM-STUDIES') {
          success(data.body);
        } else {
          success(JSON.parse(data.body));
        }
      },
      headers,
    );
  };

  // Helpers

  unsubscribe = subscription => {
    if (!this.connected) return;
    if (subscription) {
      subscription.unsubscribe(2, getHeaders());
      subscription = null; //eslint-disable-line
    }
  };

  destroy = () => {
    this.disconnectSokect();
    this.stompClient = null;
    this.socket = null;
    this.connected = false;
    this.messagesSubscription = null;
  };

  checkConnection = () => this.connected;
}
