<template>
  <div class="slide-library">
    <wtr-case-list
      v-if="allCases!=null"
      :params="paramsUtil"
      :subFilters="subFilters"
      :configSubFilters="configSubFilters"
      :listLoading="listLoading"
      :folderData="folderData"
      :options="{
        allCases: allCases,
        searchHolder: $t('common.searchFiles',{fileType: $t('common.slides')}),
        pagination: true,
        customSearch: false,
        totalCasePages,
        totalCaseElements,
        menuToggle: true,
        disableNoData: false,
        hasRangeFilter: false,
        multiView:true,
        maxHeight:  maxHeight,
        toolbar: toolbarClass,
        canExportTSV:canExportTSV,
        selectedFolderView:selectedFolderView,
        toolbar: {
          search: 'col-12 px-0'
        },
      }"
      @update-case-list="getCases"
      @search="$emit('clear-selected')" 
      @filter="$emit('clear-selected')" 
      @size="$emit('clear-selected')" 
      @export-click="$emit('export-cases')"
    >
     <!-- Buttons will be rendered in list component -->
     <div v-if="canSelectCase" slot="list-buttons">
      <div v-if="getSelectedSlides" class="btn-group" style="margin-right: 5px;">
        <b-btn :class="isMoveCaseProcessing?'disabled':''" :aria-label="$t('common.moveSelectedCaseToPublicBox')" :aria-labelledby="$t('common.moveSelectedCaseToPublicBox')" variant="primary" :title="$t('common.moveSelectedCaseToPublicBox')" v-if="this.selectedFolderView == this.FOLDER_PREPUBLICATIONBOX" size="sm" v-on:click="validateSelectedCase('PUBLICBOX')">
          <wtr-loader v-if="isMoveCaseProcessing" class="text-white" loadingText="" addText ></wtr-loader>
            {{$t('common.moveSelectedCaseToPublicBox')}}
        </b-btn>
      </div>
      <div v-if="getSelectedSlides" class="btn-group" style="margin-right: 5px;">
        <b-btn :class="isMoveCaseProcessing?'disabled':''" :aria-label="$t('common.moveSelectedCaseToEditorialBoardBox')" :aria-labelledby="$t('common.moveSelectedCaseToEditorialBoardBox')" variant="primary" :title="$t('common.moveSelectedCaseToEditorialBoardBox')" v-if="this.selectedFolderView == this.FOLDER_PUBLICBOX" size="sm" v-on:click="validateSelectedCase('EDITORIALBOX')">
          <wtr-loader v-if="isMoveCaseProcessing" class="text-white" loadingText="" addText ></wtr-loader>
            {{$t('common.moveSelectedCaseToEditorialBoardBox')}}
        </b-btn>
      </div>
      <div v-if="allCases.length>0" class="btn-group" style="column-gap: 5px;">
        <b-btn :class="isMoveCaseProcessing?'disabled':''" :aria-label="$t('common.selectUnselectAllCases')" :aria-labelledby="$t('common.selectUnselectAllCases')" variant="primary" :title="$t('common.selectUnselectAllCases')"  size="sm" v-on:click="selectAllCases()">
            {{ isSelectAllCases ? $t('common.unselectAllCases'):$t('common.selectAllCases')}}
        </b-btn>
      </div>
      </div>

      <!-- Case view in list component -->
      <div slot="case-list-card" class="row m-0">
        <div v-for="caseDetail in allCases" :key="caseDetail.caseDetailId">
          <wtr-case-card
            footerSlot="footer-slot"
            :name="getCaseNumber(caseDetail)"
            :totalSlides="caseDetail.totalFiles"
            :img="{
                resourceId: caseDetail.resourceId,
                containerName: caseDetail.containerName,
                src: caseDetail.thumbnailPath,
                alt: caseDetail.thumbnailPath,
              }"
            :imgKey="`${caseDetail.caseDetailId}-card-${caseDetail.thumbnailPath}`"
            @img-click="viewSlide(caseDetail)"
          >
            <div v-if="!canSelect.select" slot="footer-slot">
              <div class="double-line-text mb-1" style="color: #666666 !important;">{{getTumorName(caseDetail)}}</div>
              <div v-if="!hideActions">
                <table-actions
                  v-if="!hideActions"
                  :key="caseDetail.caseDetailId"
                  :viewCaseIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO) }"
                  :caseReviewCommentIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.COMMENTS) }"
                  :moveBackToEditorialBoardBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVEBACKTOEDITORIALBOARDBOX)  }"
                  :moveToSlideLibraryIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPUBLICACCESSBOX) }"                          
                  @viewCaseInfo="viewCaseInfo(caseDetail.caseSlideIds,caseDetail.caseDetailId,caseDetail.geographicRegion)"
                  @caseReviewComment="caseReviewComment(caseDetail.caseDetailId)"                  
                  @moveToEditorialBox="moveBackToEditorialBox(caseDetail.caseDetailId,caseDetail.caseSlideIds)"
                  @moveToSlideLibrary="moveToSlideLibrary(caseDetail.caseDetailId,caseDetail.caseSlideIds)"
                ></table-actions>
              </div>
            </div>
          </wtr-case-card>
        </div>
      </div>
      <b-table
        thead-class="bg-primary text-light"
        primary-key="caseDetailId"
        slot="case-list-table"
        outlined
        @row-clicked="$emit('show-slides', $event)"
        responsive
        hover
        :fields="casetableFields"
        :items="allCases"
      >
        <template v-if="canSelectCase" v-slot:cell(select)="row">
          <div class="cursor-pointer">
            <div
              class="cursor-pointer"
              v-on:click.stop.prevent="selectCases(row.item)"
            >
              <b-form-checkbox
                aria-label="check-all"
                aria-labelledby="check-all"
                size="sm"
                variant="primary"
                :checked="selectedCaseIds.includes(row.item.caseDetailId)"
              >
              </b-form-checkbox>
            </div>
          </div>
        </template>
        <template v-slot:head(caseNumber)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','cd.case_number,cd.system_case_number')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(tumorSite)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','ts.name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(tumorType)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','tt.name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(tumorSubType)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','tst.name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(specificCohort)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','sc.name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(geographicRegion)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','gr.name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(editor)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','eu.first_name')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(reviewStatus)="data">
          <div class="text-nowrap">
            <span>{{ data.label }}</span>
            <span class="cursor-pointer" v-on:click="$emit('sort-cases','reviewStatus')">
              &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </span>
          </div>
        </template>
        <template v-slot:head(actions)="data">
          <div class="text-nowrap text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>
        <template v-slot:cell(caseNumber)="row">
          <div class="caseNumber fs-13" :title="row.item.systemCaseNumber ?row.item.systemCaseNumber:row.item.caseNumber">
            {{ row.item.systemCaseNumber ?row.item.systemCaseNumber:row.item.caseNumber }}
          </div>
        </template>
        <template v-slot:cell(tumorSite)="row">
          <div class="tumorSite fs-13" :title="row.item.tumorSite"> {{ row.item.tumorSite }} </div>
        </template>
        <template v-slot:cell(tumorType)="row">
          <div class="tumorType fs-13" :title="row.item.tumorType"> {{ row.item.tumorType }} </div>
        </template>
        <template v-slot:cell(tumorSubType)="row">
          <div class="tumorSubType fs-13" :title="row.item.tumorSubType"> {{ row.item.tumorSubType }} </div>
        </template>
        <template v-slot:cell(specificCohort)="row">
          <div class="specificCohort fs-13" :title="row.item.specificCohort"> {{ row.item.specificCohort }} </div>
        </template>
        <template v-slot:cell(geographicRegion)="row">
          <div class="geographicRegion fs-13" :title="row.item.geographicRegion"> {{ row.item.geographicRegion }} </div>
        </template>
        <template v-slot:cell(editor)="row">
          <div class="editor fs-13" :title="row.item.editor"> {{ row.item.editor }} </div>
        </template>
        <template v-slot:cell(reviewStatus)="row">
          <div class="reviewStatus fs-13" :title="row.item.reviewStatus"> {{ row.item.reviewStatus }} </div>
        </template>
        <template v-slot:cell(actions)="row">
          <div class="text-center">
            <table-actions
              :key="row.item.caseDetailId"            
              :viewCaseIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.VIEWHISTOLOGYANDCASEINFO) }"            
              :caseReviewCommentIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.COMMENTS) }"            
              :moveBackToEditorialBoardBoxIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVEBACKTOEDITORIALBOARDBOX) }"
              :moveToSlideLibraryIcon="{ show: isColumnVisible(VISIBLE_COLUMNS.MOVETOPUBLICACCESSBOX) }"            
              @viewCaseInfo="viewCaseInfo(row.item.caseSlideIds,row.item.caseDetailId,row.item.geographicRegion)"
              @caseReviewComment="caseReviewComment(row.item.caseDetailId)"
              @moveToEditorialBox="moveBackToEditorialBox(row.item.caseDetailId,row.item.caseSlideIds)"
              @moveToSlideLibrary="moveToSlideLibrary(row.item.caseDetailId,row.item.caseSlideIds)"
            ></table-actions>
          </div>
        </template>
      </b-table>
    </wtr-case-list>
    <slide-info
      :slideData="$modalData.data"
      v-if="$modalData && $modalData.data && $modalData.route ==='slide-info'"
    ></slide-info>
    <b-modal id="slide-info" size="md" centered scrollable hide-footer hide-header
      @hidden="showSlideInfo(null, false)"
    >
      <slide-info :slideData="selectedSlide" @close="showSlideInfo(null, false)" ></slide-info>
    </b-modal>
    <case-review-comment v-if="$modalData.show && $modalData.route === 'case-review-comment'" :modalDataInfo="$modalData.data"></case-review-comment>
  </div>
</template>
<script>
import {
  map,
  assign,
  cloneDeep,
  orderBy,
  forEach,
  findIndex,
} from "lodash";
import { mapActions,mapGetters } from 'vuex';
// Components
import SlideInfoComponent from "../../../components/common//wtr-slide-info.vue";
import WTRCaseListComponent from "../../../components/common/wtr-case-list.vue";
import WTRCaseCardComponent from "../../../components/common/wtr-case-card.vue";
import TableActionsComponent from "../../../components/common/table-actions.vue";
import CaseReviewCommentComponent from "../../../components/common/case-review-comment.vue";
import * as PUBLICACCESS_ROLES from "../../../constants/constants-visiblecolumns";
import * as PREPUBLICATION_ROLES from "../../../constants/constants-visiblecolumns";
import * as folderTypes from "../../../constants/constants-folder";
import { VISIBLE_COLUMNS } from "../../../constants/constants-visiblecolumns";
import * as roleTypes from "../../../constants/constants-role";

// Mixins
import { SlidesMixin, ListMixin } from "../../../mixins/mixins";

export default {
  name: "slide-library-search",
  data() {
    return {
      ...folderTypes,
      ...PUBLICACCESS_ROLES,
      ...PREPUBLICATION_ROLES,
      selectedSlide: null,
      showDiagnosis: true,      
      tumorSiteId: null,
      ...roleTypes,
      configSubFilters:[
          {optionLabel: 'name', optionValue: 'id', placeholder: 'By Cohort', searchParam: 'searchSpecificCohortId'},
          {optionLabel: 'name', optionValue: 'id', placeholder: 'By Region', searchParam: 'searchGeographicRegionId'},
          {optionLabel: 'name', optionValue: 'id', placeholder: 'By Editor', searchParam: 'searchEditorId'},
          {optionLabel: 'name', optionValue: 'id', placeholder: 'By Status', searchParam: 'searchReviewStatusId'}
      ],
      indexSearchSpecificCohortId: 0,
      indexGeographicRegionId: 1,
      indexEditorId: 2,
      indexReviewStatusId: 3,
      subFilters: [[], [], [], []],
      VISIBLE_COLUMNS: VISIBLE_COLUMNS,
      isSelectAllCases: false,
      selectedCaseIds: [],
      selectedCases: [],
      isMoveCaseProcessing: false,
    };
  },
  props: {
    maxHeight: Number,
    paramsUtil: Object,
    listLoading: Boolean,
    hideMsg: Boolean,
    canSelect: {
      select: Boolean,
      single: Boolean,
      selectType: String,
      default: () => ({
        select: false,
        single: false,
        selectType: "",
      }),
    },
    navigate: {
      type: Boolean,
      default: () => false,
    },
    showPublicSlides: Boolean,
    hideActions: {
      type: Boolean,
      default: () => false,
    },
    allCases: Array,
    totalCasePages: Number,
    totalCaseElements: Number,
    folderData: {
    type: Object,
    default: null
    },
    isMovedToEditorialBox:Boolean,    
    isSharedToEditor:Boolean,    
    isMovedToSlideLibrary:Boolean,
  },
  mixins: [SlidesMixin, ListMixin],
  components: {
    "slide-info": SlideInfoComponent,
    "table-actions": TableActionsComponent,
    "wtr-case-card": WTRCaseCardComponent,
    "wtr-case-list": WTRCaseListComponent,
    "case-review-comment" : CaseReviewCommentComponent,  
  },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      showCaseSlides: "getShowCaseSlidesStatus",
      selectedCaseSlideIds: "getSelectedCaseSlideIds",
      selectedFolderUserRole: "getSelectedFolderUserRole",
      selectedFolderView: "getSelectedFolderView",
    }),
    toolbarClass() {
      return {
        search: "col-xl-10 col-lg-12 col-md-12 col-sm-12",
        actions: "col-xl-2 col-lg-12 col-md-12 col-sm-12",
      };
    },
    tableFields() {
      return [
        { key: "thumbnailPath", label: this.$t("common.slide") },
        {
          key: "name",
          label: this.$t("common.name"),
          thClass: "",
        },
        {
          key: "createdDateTime",
          label: this.$t("slidebox.uploadTime"),
          thClass: "min-w-px-10",
        },
        {
          key: "slideMetadata.diagnosis",
          label: this.$t("common.diagnosis"),
          thClass: "",
        },
        { key: "caseNumber", label: this.$t("common.caseNumber") },
        { key: "fileType", label: this.$t("common.slideType") },
        { key: "dziStatus", label: this.$t("common.conversionStatus") },
        { key: "info", label: this.$t("common.info") },
        { key: "actions", label: this.$t("common.actions") },
      ];
    },
    casetableFields() {
      return [
        {
          key: "caseNumber",
          label: this.$t("common.caseNumber"),
          thClass: "",
        },
        {
          key: "tumorSite",
          label: this.$t("common.tumorSite"),
          thClass: "",
        },
        {
          key: "tumorType",
          label: this.$t("common.tumorType"),
          thClass: "",
        },
        {
          key: "tumorSubType",
          label: this.$t("common.tumorSubType"),
          thClass: "",
        },
        {
          key: "specificCohort",
          label: this.$t("common.specificCohort"),
          thClass: "",
        },
        {
          key: "geographicRegion",
          label: this.$t("common.region"),
          thClass: "",
        },
        {
          key: "editor",
          label: this.$t("common.editor"),
          thClass: "",
        },
        {
          key: "reviewStatus",
          label: this.$t("common.reviewStatus"),
          thClass: "",
        },
        { key: 'select', label: '' },
        { key: "actions", label: this.$t("common.actions") },
      ];
    },
    getSelectedSlides(){
      return (this.selectedCaseIds && this.selectedCaseIds.length>0) ?true :false;
    },
    canExportTSV() {
       const loginUserRole = localStorage.getItem("userRole");
       const result = (this.selectedFolderView == this.FOLDER_PUBLICBOX || this.selectedFolderView == this.FOLDER_PREPUBLICATIONBOX) && 
       (loginUserRole === this.ROLE_TENANT_ADMIN || loginUserRole === this.ROLE_PROJECT_ADMIN || loginUserRole === this.ROLE_CHAIRPERSON);
       return result;
    },
    canSelectCase() {
       const loginUserRole = localStorage.getItem("userRole");
       const result = (this.selectedFolderView == this.FOLDER_PUBLICBOX || this.selectedFolderView == this.FOLDER_PREPUBLICATIONBOX) && 
       (loginUserRole === this.ROLE_TENANT_ADMIN || loginUserRole === this.ROLE_PROJECT_ADMIN || loginUserRole === this.ROLE_CHAIRPERSON);
       return result;
    },
  },
  mounted() {   
    this.getUserDetails();
  },
  updated() {
      this.$nextTick(() => {
          this.$setWCAGVueEditorFixes();
      }); 
  },
  methods: {
    ...mapActions([
      "setShowCaseSlides",
      "setShowCaseView",
      "setSelectedCaseNumber",
      "setSelectedCaseSlideIds",
      "setSelectedFolderView",
    ]),
    getUserDetails() {
        this.$api.getUserDetailFact(this.$userData.id).then(
            (response) => {
                this.userDetail = response.data;
                
                if(this.userDetail.userTumorSites != null){
                  this.tumorSiteId = this.userDetail.userTumorSites.id;
                }else{
                  this.tumorSiteId = 0;
                }

                this.getAllProperty();
            },
            (error) => {
                this.$handleError(error);
            }
        );
    }, 
    getAllProperty(){
            this.getAllSpecificCohorts(this.tumorSiteId);
            this.getAllGeographicRegions();
            this.getAllEditorByTumorSite(this.tumorSiteId);
            this.getAllCaseReviewStatus();
    },
    getAllSpecificCohorts(tumorSiteId) {
        this.$api.getAllSpecificCohortsByTumorFact(tumorSiteId).then(
            (response) => {
                this.subFilters[this.indexSearchSpecificCohortId] = orderBy(response.data.payload,["name"],["asc"]);
            },
            (error) => {
                this.$handleError(error);
            }
        );
    },
    getAllGeographicRegions() {
        this.$api.getAllGeographicregionsFact().then(
            (response) => {
                this.subFilters[this.indexGeographicRegionId] = orderBy(response.data.payload,["name"],["asc"]);
            },
            (error) => {
                this.$handleError(error);
            }
        );
    },
    getAllEditorByTumorSite(tumorSiteId) {
        this.$api.getAllEditorByTumorSiteFact(tumorSiteId).then(
            (response) => {
                this.subFilters[this.indexEditorId] = orderBy(response.data,["name"],["asc"]);
            },
            (error) => {
                this.$handleError(error);
            }
        );
    },
    getAllCaseReviewStatus() {
        this.$api.getAllCaseReviewStatusFact().then(
            (response) => {
                this.subFilters[this.indexReviewStatusId] = orderBy(response.data,["name"],["asc"]);
            },
            (error) => {
                this.$handleError(error);
            }
        );
    },
    getSlideName({ name, slideMetadata }) {
      return this.showDiagnosis ? slideMetadata.diagnosis || name : name;
    },
    getCaseNumber({ caseNumber, systemCaseNumber }) {
      return systemCaseNumber ? systemCaseNumber : caseNumber;
    },
    getTumorName({ tumorSite,tumorType, tumorSubType }) {
      return tumorSite+"-"+tumorType+"-"+tumorSubType;
    },
    showSlideInfo(data, show) {
      const KEY = "slide-info";
      this.selectedSlide = cloneDeep(data);
      if (show) {
        this.$bvModal.show(KEY);
      } else {
        this.$bvModal.hide(KEY);
      }
    },
    showSlide(data) {
      this.viewSlide(data, {
        query: {
          type: `slide-library-${this.showPublicSlides ? "public" : "private"}`,
        },
      });
    },
    resetPage() {
      this.$resetParamsUtil();
      this.getSlides(this.paramsUtil);
    },
    getCases(data) {
      this.$emit("get-cases", data);
    },    
    caseReviewComment(caseDetailId){
        var data = {};
        data.caseDetailId = caseDetailId;

        if(data){
            this.$setModalAction({ show: true, route: "case-review-comment", title: "Case Comments", data });
        }
    },
    async moveBackToEditorialBox(caseDetailId,caseSlideIds) {
      this.isMoveCaseProcessing= true;
        if(this.selectedCaseSlideIds.length == 0)
        {
            await this.getSlidesByCaseSlideIds(caseSlideIds);   
        }
        const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
        const validSlide = this.checkFileValidOrNot(slideList);
        if(validSlide)
        {
            // let slideIds =
            //     this.selectedCaseSlideIds.length == 0 ?
            //     this.getSlideIdsList(caseSlideIds) :
            //     this.selectedCaseSlideIds;

            // if (!slideIds || !slideIds.length) return;
            let caseIds = [];
            caseIds.push(caseDetailId);
            this.$deleteConfirmation(
                this.$t("slidebox.caseMoveBackToEditorialBoxConfirmMsg"),
                () => {
                    this.$api.movedToEditorialBoxFact(caseIds).then(
                        (response) => {
                            if (response.data.status) {
                                this.$toastr("success", "Case moved to editorial board box");
                                this.getCases(this.paramsUtil);
                            } else {
                                this.$toastr("error", response.data.statusMessage);
                            }
                            this.isMoveCaseProcessing= false;
                        },
                        (error) => {
                            this.$handleError(error);
                        }
                    );
                }
            );
        }
        else
        {
            this.$toastr('error', 'Please wait until all the files got processed.');
        }
    },
    isColumnVisible(column) {       
       let userRoleSelectedFolder = this.selectedFolderUserRole;
            userRoleSelectedFolder = userRoleSelectedFolder === this.ROLE_CHIEF_EDITOR ? 'CHIEFEDITOR' : userRoleSelectedFolder === this.ROLE_PROJECT_ADMIN ? 'PROJECTADMIN' :
                                    userRoleSelectedFolder === this.ROLE_TENANT_ADMIN ? 'TENANTADMIN' : userRoleSelectedFolder;

       if (this.folderData.folderType === this.FOLDER_PUBLICBOX) {
         if (this.PUBLICACCESS_ROLES[userRoleSelectedFolder] && this.PUBLICACCESS_ROLES[userRoleSelectedFolder].includes(column)) {
                return true;
          }
       }
       if (this.folderData.folderType === this.FOLDER_PREPUBLICATIONBOX) {
         if (this.PREPUBLICATION_ROLES[userRoleSelectedFolder] && this.PREPUBLICATION_ROLES[userRoleSelectedFolder].includes(column)) {
             return true;
        }
       }
    },
    async moveToSlideLibrary(caseDetailId,caseSlideIds) {
      this.isMoveCaseProcessing= true;
          if(this.selectedCaseSlideIds.length == 0)
          {
            await this.getSlidesByCaseSlideIds(caseSlideIds);
          }             
          const slideList = this.selectedCaseSlideIds.length == 0 ? this.slidesCaseView : this.slides;
          const validSlide = this.checkFileValidOrNot(slideList);            
          if(validSlide)
          {
              // // let slideIds =
              // // this.selectedCaseSlideIds.length == 0 ?
              // // this.getSlideIdsList(caseSlideIds) :
              // // this.selectedCaseSlideIds;

              // if (!slideIds || !slideIds.length) return;
              let caseIds = [];
              caseIds.push(caseDetailId);
              this.$deleteConfirmation(
                  this.$t("slidebox.caseMoveToSlideLibraryConfirmMsg"),
                  () => {
                      this.$api.movedToSlideLibraryFact(caseIds).then(
                          (response) => {
                              if (response.data.status) {
                                  this.$toastr("success", response.data.payload);
                                  this.getCases(this.paramsUtil);
                              } else {
                                  this.$toastr("error", response.data.statusMessage);
                              }
                              this.isMoveCaseProcessing= false;
                          },
                          (error) => {
                              this.$handleError(error);
                          }
                      );
                  }
              );
          }
          else
          {
              this.$toastr('error', 'Please wait until all the files got processed.');
          }
    },
    viewCaseInfo(caseSlideIds,caseDetailId,regionName)
  {        
      let id = 0;
      if (caseSlideIds.indexOf(",") > -1) {
          let splitIds = caseSlideIds.split(",");
          id = splitIds[0];
      } else {
          id = caseSlideIds;
      }
      var query;
      var movedToEditorialBox = this.isMovedToEditorialBox;
      var movedToSlideLibrary = this.isMovedToSlideLibrary;
      var sharedToEditor = this.isSharedToEditor;
      var selectedFolderView = this.selectedFolderView;
      var selectedFolderUserRole = this.selectedFolderUserRole;
      var selectedFolderUserRegion = this.selectedFolderUserRegion;
      var selectedCaseRegion = regionName;
      var isPublicSite = false;

      const route = 'display-viewer';
      this.$changeRouteToTab({
      name: route,
      params: {
          id,
          caseDetailId,
          movedToEditorialBox,
          movedToSlideLibrary,
          sharedToEditor,
          isPublicSite,
          caseSlideIds,
          selectedFolderView,
          selectedFolderUserRole,
          selectedFolderUserRegion,
          selectedCaseRegion
      },
      query: query ? query.query : null,
      });
      
    },
    async getSlidesByCaseSlideIds(caseSlideIds)
    {
        let params = {
        //...data,
        page: this.paramsUtil.page,
        size: this.paramsUtil.size,
        sort: "id,desc",
        };
        return this.$api.getSlidesByCaseSlideIdsFact(caseSlideIds,params).then(
            response => {
            if (!this.$handleResponse(response)) return;
            const { content } = response.data.payload;
            if (this.folderData == null)
            {
                  this.slidesCaseView = map(content, item => assign(item, this.changeSlideData(item)));
            }
            else
            {
                this.slidesCaseView = map(content, item => assign(item, this.getPermissions(this.folderData), this.changeSlideData(item)));
            }
            },
            error => {
            this.$handleError(error);
            },
        );
    },
    checkFileValidOrNot(slideList)
    {
        let validSlide = true;  
        for (let i = 0; i < slideList.length; i++) {
            const element = slideList[i];
            validSlide = !element.meta.pending
                && !element.meta.isProcessingFailed
                && !element.meta.isUploadFailed;  
                if (!validSlide) {
                    break;
                }
        }
        return validSlide;
    },
    getSlideIdsList(selectedCaseSlideIds) {
      let slideIds = [];
      let splitIds = selectedCaseSlideIds.split(",");
      splitIds.forEach((element) => {
          slideIds.push(parseInt(element));
      });
      return slideIds;
    },
    moveSelectedCaseToSlideLibrary(caseIds) {
      this.$deleteConfirmation(
            this.$t("slidebox.caseMoveToSlideLibraryConfirmMsg"),
            () => {
                this.$api.movedToSlideLibraryFact(caseIds).then(
                    (response) => {
                        if (response.data.status) {
                            this.$toastr("success", response.data.payload);
                            this.getCases(this.paramsUtil);                            
                        } else {
                            this.$toastr("error", response.data.statusMessage);
                        }
                    },
                    (error) => {
                        this.$handleError(error);
                    }
                );
            }
        );
    },
    moveSelectedCaseToEditorialBox(caseIds) {
      this.$deleteConfirmation(
          this.$t("slidebox.caseMoveBackToEditorialBoxConfirmMsg"),
          () => {
              this.$api.movedToEditorialBoxFact(caseIds).then(
                  (response) => {
                      if (response.data.status) {
                          this.$toastr("success", "Case moved to editorial board box");
                          this.getCases(this.paramsUtil);
                      } else {
                          this.$toastr("error", response.data.statusMessage);
                      }
                  },
                  (error) => {
                      this.$handleError(error);
                  }
              );
          }
      );
    },
    async validateSelectedCase(moveTo) {
      this.isMoveCaseProcessing= true;
      let processedSlideIds = [];
      let failedCases = [];
      for (const item of this.selectedCases) {
        let caseSlidesData=null;
        let params = {
        //...data,
        page: this.paramsUtil.page,
        size: this.paramsUtil.size,
        sort: "id,desc",
        };
        try {
          const response = await this.$api.getSlidesByCaseSlideIdsFact(item.caseSlideIds, params);
          if (!this.$handleResponse(response)) continue;
          const { content } = response.data.payload;
          caseSlidesData = content.map((item) => Object.assign(item, this.changeSlideData(item)));

          if (caseSlidesData) {
            const validSlide = this.checkFileValidOrNot(caseSlidesData);
            if (validSlide) {
              processedSlideIds = processedSlideIds.concat(item.caseSlideIds.split(','));
            } else {
              failedCases.push(item.systemCaseNumber);
            }
          }
        } catch (error) {
          this.$handleError(error);
        }       
      }

      if(failedCases.length===0)
      {
       // this.listLoading =false;
        let caseIds = this.selectedCaseIds;
        if (!caseIds || !caseIds.length) return;
        if(moveTo===this.FOLDER_PUBLICBOX){
          this.moveSelectedCaseToSlideLibrary(caseIds);
        }
        else if(moveTo===this.FOLDER_EDITORIALBOX){
          this.moveSelectedCaseToEditorialBox(caseIds);
        }
        this.selectedCaseIds = [];
        this.selectedCases = [];
        this.isSelectAllCases= false;
        this.isMoveCaseProcessing= false;
      }
      else{
        this.$toastr('error', 'Please wait until all the files got processed these cases.<br>'+ failedCases.join('<br>'));
        this.isMoveCaseProcessing= false;
      }
    },
    selectAllCases() {
      this.isSelectAllCases= !this.isSelectAllCases;
      this.selectedCaseIds = [];
      this.selectedCases = [];
      if(this.isSelectAllCases) {
        forEach(this.allCases, item => {
            this.selectedCaseIds.push(item.caseDetailId);
            this.selectedCases.push(item);
        });
      }
      console.log('this.selectedCases ', this.selectedCases);
    },
    selectCases(item) {
      if (this.selectedCases.includes(item)) {
        this.selectedCases.splice(
          findIndex(this.selectedCases, o => o.caseDetailId === item.caseDetailId),
          1,
        );
        this.selectedCaseIds.splice(
          findIndex(this.selectedCaseIds, o => o === item.caseDetailId),
          1,
        );
      } else {
        this.selectedCaseIds.push(item.caseDetailId);
        this.selectedCases.push(item);
      }
    },
  },
};
</script>

<style scoped lang="scss">
// .slide-library {
//   min-height: 60vh;
// }
.section {
  min-height: 50vh;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.fs-13{
    font-size: 13px !important;
}
.slide-name {
  width: 150px;
  text-align: now-wrap;
}
</style>
