export default {
  SIDEBAR: 'SIDEBAR',
  CURRENT_SIDEBAR: 'CURRENT_SIDEBAR',
  LOADER: 'LOADER',
  SET_ROUTES: 'SET_ROUTES',
  CRUMBS: 'CRUMBS',
  SET_MODAL: 'SET_MODAL',
  SET_DASHBOARD_HEIGHT: 'SET_DASHBOARD_HEIGHT',
  SET_DASHBOARD_WIDTH: 'SET_DASHBOARD_WIDTH',
};
