<template>
  <b-btn
    :variant="variant"
    :block="block"
    :size="size"
    :pill="pill"
    :type="type"
    :disabled="disabled"
    @click.prevent="$emit('click')"
  >
    <i
      :class="[$mq !== 'sm' ? `${icon} pr-5`: `${icon} pr-5` ]"
    ></i>
    <span>
      <span v-if="!textSlot">{{text}}</span>
      <slot
        name="textSlot"
        v-else
      ></slot>
    </span>
    <i
      :class="spinner"
      class="ml-5"
      v-show="isLoading"
    ></i>
  </b-btn>
</template>

<script>

export default {
  name: 'wtr-btn',
  data() {
    return {};
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'sm',
    },
    type: {
      type: String,
      default: 'button',
    },
    icon: {
      type: String,
      default: 'fa fa fa-check-square-o',
    },
    textSlot: {
      type: String,
      deafault: 'text',
    },
    text: {
      type: String,
    },
    spinner: {
      type: String,
      default: 'fa fa-spin fa-spinner',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
