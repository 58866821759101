<template>
  <span :class="`${containerClass} text-${variant}`" :style="`${containerStyle}`">
    <i
      class="fa fa-spin"
      :class="`${fontVariant}`"
    ></i> <span v-if="addText"> {{loadingText}}</span>
  </span>
</template>

<script>
export default {
  name: 'wtr-loader',
  props: {
    addText: Boolean,
    containerClass: {
      type: String,
    },
    containerStyle: {
      type: String,
    },
    variant: {
      type: String,
      default: () => 'primary',
    },
    loadingText: {
      type: String,
      default: () => 'Loading...',
    },
    fontVariant: {
      type: String,
      default: () => 'fa-circle-o-notch',
    },
  },
};
</script>
