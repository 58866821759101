<template>
    <div class="w-100 h-100" :title="`${placeholder}`">
        <button class="btn btn-outline dropdown-toggle d-flex align-items-center w-100 h-100 p-dropdown-custom" 
            :class="checkValidation?'invalid':''"
            type="button" data-toggle="dropdown" aria-expanded="false"
            @click="selectedtab = null"
        >
            <template v-if="selectedItems.length === 0">
                <span class="p-dropdown-label p-placeholder">{{ placeholder }}</span>
            </template>
            <template v-else>
                <span class="selected-items">
                    {{ getValueCascadeSelection(selectedItems) }}
                </span>
            </template>
            <div class="multiselect-trigger">
                <span class="multiselect-trigger-icon pi pi-chevron-down"></span>
            </div>
        </button>
        <ul class="dropdown-menu">
            <div v-if="dropdownName != 'Immunohisto'">
                <li v-for="item in sortedItemsAsc(allParentOptions)" :key="item[compairKey]" id="all-item-field-cascade-selection" style="cursor: default;"
                    :class="{ 'select-tab': selectedtab === item.name }" 
                >
                    <div class="dropdown-item" @click.stop="toggleItem(item)" @mouseover="handleItemClick(item)">
                        <div class="form-check d-flex">
                            <input 
                                class="form-check-input cursor-pointer" :type=selectionType :name="groupName" 
                                style="width: 14px; height: 14px;"
                                :checked="selectedItems.find(obj => obj[compairKey] === item[compairKey])"
                                @click.stop="toggleItem(item)"
                            >
                            <span class="form-check-label pl-1 fs-14" 
                                :class="checkParentValidation(item)?'text-danger fw-700 text-underline':''"
                            >
                                {{ item[optionLabel] }}
                            </span>
                            <a class="ml-auto pl-3 fs-14" title="Delete" 
                                v-if="item[removeOptionFlag] === true"
                                @click.stop="removeItem(item)"
                            >
                                <i class="pi pi-trash text-danger"></i>
                            </a>
                        </div>
                    </div>
                    <ul class="dropdown-submenu" :class="{ 'case-details-dropdown': dropdownName === 'genetic' }" 
                        v-if="isSubFieldsOpen(item)">
                        <wtr-selection-dropdown
                            :placeholder=placeholder
                            :options="subFields(item)" :optionLabel=optionLabel 
                            :addOptionFlag=addOptionFlag :removeOptionFlag=removeOptionFlag :onlyNumericFlag=onlyNumericFlag
                            :selection=selectionSecondLevel
                            :isCheckValidation=isCheckValidation
                            :selectedFields="selectedSubFields(item)"
                            :cascadeMode="true"
                            :addFieldTumorSiteId="addFieldTumorSiteId"
                            :parentId="item[compairKey]"
                            :createdChildOptions = "createdChildOptions"
                            @selected-items="selectedChieldFields($event, item)"
                            @is-validation-error="$event => isValidationErrorChild($event, item)"
                            :dropdownName=dropdownName
                        ></wtr-selection-dropdown>
                    </ul>
                </li> 
            </div>
            <div v-if="dropdownName == 'Immunohisto'">
                <div v-for="group in groupedOptions" :key="group.name">
                    <li class="dropdown-divider" v-if="group.name.length==0" @click.stop="() => {}"></li>
                    <div class="d-flex align-items-center justify-content-between" style="margin: 0px 14px 0px 12px; cursor: default;" 
                        v-if="group.name.length>0" @click.stop="toggleCollapse(group.name)"
                    >
                        <div class="el-select-group__title">
                            {{ group.name }}
                        </div>
                        <i style="cursor: pointer;" class="collapse-icon" :class="{'pi pi-chevron-up': isOpenGroup(group.name), 
                            'pi pi-chevron-down': !isOpenGroup(group.name)}"                            
                        ></i>
                    </div>
                    <div :class="group.name.length>0 ? 'collapse' : ''" :id="'collapse_' + group.name.replace(/\s+/g, '_')">
                        <li v-for="item in group.options" :key="item[compairKey]" id="all-item-field-cascade-selection" style="cursor: default;"
                            :class="{ 'select-tab': selectedtab === item.name }">
                            <div class="dropdown-item" @click.stop="toggleItem(item)" @mouseover="handleItemClick(item)">
                                <div class="form-check d-flex">
                                    <input 
                                        class="form-check-input cursor-pointer" :type=selectionType :name="groupName" 
                                        style="width: 14px; height: 14px;"
                                        :checked="selectedItems.find(obj => obj[compairKey] === item[compairKey])"
                                        @click.stop="toggleItem(item)"
                                    >
                                    <span class="form-check-label pl-1 fs-14" 
                                        :class="checkParentValidation(item)?'text-danger fw-700 text-underline':''"
                                        style="padding-top: 2px;"
                                    >
                                        {{ item[optionLabel] }}
                                    </span>
                                    <a class="ml-auto pl-3 fs-14" title="Delete" 
                                        v-if="item[removeOptionFlag] === true"
                                        @click.stop="removeItem(item)"
                                        style="padding-top: 3px;"
                                    >
                                        <i class="pi pi-trash text-danger"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="dropdown-submenu" 
                                v-if="isSubFieldsOpen(item)">
                                <wtr-selection-dropdown
                                    :placeholder=placeholder
                                    :options="subFields(item)" :optionLabel=optionLabel 
                                    :addOptionFlag=addOptionFlag :removeOptionFlag=removeOptionFlag :onlyNumericFlag=onlyNumericFlag
                                    :selection=selectionSecondLevel
                                    :isCheckValidation=isCheckValidation
                                    :selectedFields="selectedSubFields(item)"
                                    :cascadeMode="true"
                                    :addFieldTumorSiteId="addFieldTumorSiteId"
                                    :parentId="item[compairKey]"
                                    :createdChildOptions = "createdChildOptions"
                                    @selected-items="selectedChieldFields($event, item)"
                                    @is-validation-error="$event => isValidationErrorChild($event, item)"
                                    :dropdownName=dropdownName
                                ></wtr-selection-dropdown>
                            </ul>
                        </li>
                    </div>
                </div> 
            </div>
            <li class="dropdown-divider" v-if="addOption && (hideFlag? !this.selectedItems.some(item => item.onFlyEntry === true && item.parentId === null): true)" @click.stop="() => {}"></li>
            <li class="dropdown-item" id="add-item-field-cascade-selection" v-if="addOption && (hideFlag? !this.selectedItems.some(item => item.onFlyEntry === true && item.parentId === null): true)" @mouseover="handleItemClick(null)">
                <div v-if="!isEnableAddField" class="cursor-pointer" @click.stop="EnableAddField()">
                    <span><i class="pi pi-plus-circle mr-2 fs-14"></i></span>
                    <span class="fs-14">{{addOptionObj[optionLabel]}}</span>
                </div>
                <div v-if="isEnableAddField" class="d-flex align-items-center">
                    <span class="input-group">
                        <input type="text" v-model="newItem" 
                            class="form-control border-right-0 form-control-sm width-1" :placeholder= "addOptionObj[optionLabel]">
                        <div class="input-group-append">
                            <button class="btn btn-outline border-left-0 d-flex align-items-center" type="button" title= "Add"
                                @click.stop="addItem">
                                <i class="pi pi-plus-circle fs-14"></i>
                            </button>
                        </div>
                    </span>
                    <span class="pl-2 text-right">
                        <a @click.stop="() => isEnableAddField=false" title="Close">
                            <i class="pi pi-times"></i>
                        </a>
                    </span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import WTRSelectionDropdown from './wtr-selection-dropdown.vue'

export default {
    name: 'wtr-cascade-selection',
    components: {
        "wtr-selection-dropdown": WTRSelectionDropdown,
    },
    data() {
        return {
            selectionMode: ['checkbox','radio'],
            selectionType: '',
            newItem: '',
            isEnableAddField: false,
            selectedItems: [],
            generatedId: -1,
            isItemValidate: [],
            selectedtab: null,
            createdChild: [],
            isCollapsed: {},
            openGroup: null,
        }
    },
    props: {
        options: Array,
        optionLabel: String,
        addOptionFlag: String,
        removeOptionFlag: String,
        levelKey: String,
        compairKey: String,
        placeholder: String,
        onlyNumericFlag: String,
        addFieldTumorSiteId: Number,
        isCheckValidation: Boolean,
        selectedFields: Array,
        selectionFirstLevel: String,
        selectionSecondLevel: String,
        createdChildOptions : Array,
        hideFlag: Boolean,
        dropdownName: String,
        isFreetextChild: Boolean,
        isNotCheckChildValidation: Boolean
    },
    watch: {
        selectedFields(newVal) {
            if(newVal){
                this.selectedItems = newVal;
            }else{
                this.selectedItems = [];
            }
        }
    },
    mounted() {
        if(this.selectedFields){
            this.selectedItems = [...this.selectedFields];
        }
        if(this.selectionFirstLevel=='multiple'){
            this.selectionType = this.selectionMode[0];
        }else if(this.selectionFirstLevel=='single'){
            this.selectionType = this.selectionMode[1];
        }
    },
    updated() {
        if(this.isCheckValidation){
            this.emitValidation();
        }
    },
    computed: {
        checkValidation(){
            return this.isCheckValidation ? 
                this.selectedItems.length===0 || this.isItemValidate.some(obj => obj.error === true) 
                : false;
        },
        groupedOptions() {
            const groups = {};
            this.sortedItemsAsc(this.allParentOptions).forEach(option => {
                if (option.immunoHistoChemistryCategoryId !== 3){
                    let groupName = '';

                    if (option.immunoHistoChemistryCategoryId === 1) {
                        groupName = 'IHC stains';
                    } else if (option.immunoHistoChemistryCategoryId === 2) {
                        groupName = 'HC stains';
                    } else {
                        groupName = 'Others';
                    }

                    if (!groups[groupName]) {
                        groups[groupName] = { name: groupName, options: [] };
                    }
                    
                    groups[groupName].options.push(option);
                }
            });
            this.sortedItemsAsc(this.allParentOptions).forEach(option => {
                if (option.immunoHistoChemistryCategoryId === 3){
                    let groupName = '';
                    if (!groups[groupName]) {
                        groups[groupName] = { name: groupName, options: [] };
                    }                    
                    groups[groupName].options.push(option);
                }
            });
            return Object.values(groups);
        },
        allItems(){
            const map = new Map();
            [...this.options, ...this.selectedItems].forEach((obj) => {
                const combinedKey = `${obj[this.compairKey]}-${obj[this.levelKey]}`;
                if (!map.has(combinedKey)) {
                    map.set(combinedKey, obj);
                }
            });
            return Array.from(map.values());
        },
        allParentOptions(){
            const map = new Map();
            var result = [];

            if (this.addOptionFlag !== undefined) {
                result = this.allItems.filter(obj => 
                    obj[this.addOptionFlag] === false && obj[this.levelKey] === null);
            }else{
                result = this.allItems.filter(obj => 
                    obj[this.levelKey] === null);
            }
            
            result.forEach((obj) => {
                if (!map.has(obj[this.optionLabel])) {
                    map.set(obj[this.optionLabel], obj);
                }
            });

            return Array.from(map.values());
        },
        allChildOptions(){
            return this.allItems.filter(obj => obj[this.levelKey] > 0);
        },
        addOption(){
            return this.allItems.some(obj => obj[this.addOptionFlag] === true && obj[this.levelKey] === null);
        },
        addOptionObj(){
            return this.allItems.find(obj => obj[this.addOptionFlag] === true && obj[this.levelKey] === null);
        },
        groupName() {
            return 'group-' + Math.random().toString(36).substr(2, 9);
        },
        allItemsParentChild(){
            return  this.allItems.filter(obj => obj.parentId === null)
                        .map(objParent => {
                            return {
                                'id': objParent[this.compairKey],
                                'childs': this.options.filter(objChild => objChild[this.levelKey] === objParent[this.compairKey]).length
                            }
                        });
        },
        selectedItemsParentChild(){
            return  this.selectedItems.filter(obj => obj.parentId === null)
                        .map(objParent => {
                            return {
                                'id': objParent[this.compairKey],
                                'childs': this.selectedItems.filter(objChild => objChild[this.levelKey] === objParent[this.compairKey]).length
                            }
                        });
        },
    },
    methods: {
        toggleCollapse(groupName) {
            const targetId = 'collapse_' + groupName.replace(/\s+/g, '_');
            const $targetElement = window.$('#' + targetId);
            const isOpen = $targetElement.hasClass('show');

            window.$('.collapse').removeClass('show');

            if (!isOpen) {
                this.openGroup = groupName;
                $targetElement.addClass('show');
            } else {
                this.openGroup = null;
            }
        },
        isOpenGroup(groupName) {
            return this.openGroup === groupName;
        },
        checkParentValidation(item){
            const selectObj = this.isItemValidate.find(obj => obj.id === item[this.compairKey]);
            if(selectObj){
                return selectObj.error;
            }
            return false;
        },
        subFields(item){
            return [...this.allChildOptions.filter(obj => obj[this.levelKey] === item[this.compairKey] && obj[this.removeOptionFlag]===false), 
            ...this.createdChild.filter(obj => obj[this.levelKey] === item[this.compairKey] && obj[this.removeOptionFlag]===false)];
        },
        selectedSubFields(item){
            return this.selectedItems.filter(obj => 
                obj[this.levelKey] === item[this.compairKey]);
        },
        isSubFieldsOpen(item){
            if(this.selectedItems.some(obj => obj[this.compairKey] === item[this.compairKey])){
                if(this.selectedSubFields(item).some(obj => obj[this.addOptionFlag] === true)){
                    return true;
                }else if(this.selectedSubFields(item).some(obj => obj[this.removeOptionFlag] === true)){
                    return true;
                }else if(this.subFields(item).length>0){
                    return true;
                }
            }
            return false;
        },
        toggleItem(item) {
            if(this.selectionType == this.selectionMode[0]){
                if (this.selectedItems.find(obj => obj[this.optionLabel] === item[this.optionLabel])) {
                    this.selectedItems = this.selectedItems.filter(obj => obj[this.optionLabel] !== item[this.optionLabel]);
                    this.selectedItems = this.selectedItems.filter(obj => obj[this.levelKey] !== item[this.compairKey]);
                } else {
                    this.selectedItems.push(item);
                }
            }
            else if(this.selectionType == this.selectionMode[1]){
                if (this.selectedItems.find(obj => obj[this.optionLabel] === item[this.optionLabel])) {
                    this.selectedItems = [];
                } else{
                    this.selectedItems = [item];
                }
            }
            this.emitSelectedItems();
            this.emitValidation();
        },
        selectedChieldFields(payload, item){
            payload.forEach( obj => {
                if(obj[this.levelKey] !== item[this.compairKey]){
                    obj[this.levelKey] = item[this.compairKey];
                }
            });

            this.selectedItems = 
                this.selectedItems.filter(obj => obj[this.levelKey] !== item[this.compairKey]);

            this.selectedItems = [...this.selectedItems, ...payload];
            
            this.emitSelectedItems();
        },
        EnableAddField(){
            this.isEnableAddField=true;
        },
        removeItem(item) {
            this.selectedItems = this.selectedItems.filter(obj => obj[this.compairKey] !== item[this.compairKey]);
            this.selectedItems = this.selectedItems.filter(obj => obj[this.levelKey] !== item[this.compairKey]);
            this.emitSelectedItems();
        },
        addItem() {
            this.newItem = this.newItem.trim();
            if(this.newItem === null || this.newItem === '')
                return;
            if (!this.allItems.find(obj => obj[this.optionLabel] === this.newItem)) {
                const newObj = {};
                const createdDateTime = new Date().toISOString().replace('T', ' ').substr(0, 19);

                const userId = parseInt(localStorage.getItem("userId"));

                newObj['createdBy'] = userId;
                newObj['createdDateTime'] = createdDateTime;
                newObj[this.addOptionFlag] = false;
                newObj[this.compairKey] = this.generatedId--;
                newObj[this.optionLabel] = this.newItem;
                newObj[this.removeOptionFlag] = true;
                newObj['onFlyEntryCreatedBy'] = userId;
                newObj['onlyNumeric']= false;
                newObj[this.levelKey] = null;
                newObj['tumorSiteId']= this.addFieldTumorSiteId;
                newObj['updatedBy'] = userId;
                newObj['updatedDateTime'] = createdDateTime; 

                if(this.createdChildOptions){
                    this.createdChildOptions.forEach((option, index) => {
                        const newChildObj = {};
                        newChildObj['createdBy'] = userId;
                        newChildObj['createdDateTime'] = createdDateTime;
                        newChildObj[this.addOptionFlag] = false;
                        newChildObj[this.compairKey] = -1 - index;
                        newChildObj[this.optionLabel] = option;
                        newChildObj[this.removeOptionFlag] = false;
                        newChildObj['onFlyEntryCreatedBy'] = userId;
                        newChildObj['onlyNumeric'] = false;
                        newChildObj[this.levelKey] = newObj[this.compairKey];
                        newChildObj['tumorSiteId'] = this.addFieldTumorSiteId;
                        newChildObj['updatedBy'] = userId;
                        newChildObj['updatedDateTime'] = createdDateTime;

                        this.createdChild.push(newChildObj);
                    });
                }

                if(this.isFreetextChild){
                    const newChildObj = {};
                    newChildObj['createdBy'] = userId;
                    newChildObj['createdDateTime'] = createdDateTime;
                    newChildObj[this.addOptionFlag] = true;
                    newChildObj[this.compairKey] = -1 - this.createdChildOptions?.length;
                    newChildObj[this.optionLabel] = 'Other (free text)';
                    newChildObj[this.removeOptionFlag] = false;
                    newChildObj['onFlyEntryCreatedBy'] = userId;
                    newChildObj['onlyNumeric'] = false;
                    newChildObj[this.levelKey] = newObj[this.compairKey];
                    newChildObj['tumorSiteId'] = this.addFieldTumorSiteId;
                    newChildObj['updatedBy'] = userId;
                    newChildObj['updatedDateTime'] = createdDateTime;

                    this.createdChild.push(newChildObj);
                }

                if(this.selectionType == this.selectionMode[0]){
                    this.selectedItems.push(newObj);
                }
                else if(this.selectionType == this.selectionMode[1]){
                    if (this.selectedItems.find(obj => obj[this.optionLabel] === this.newItem)) {
                        this.selectedItems = [];
                    } else{
                        this.selectedItems = [newObj];
                    }
                }
                this.newItem = '';
                this.isEnableAddField=false;
            }
            this.emitSelectedItems();
        },
        emitSelectedItems(){
            this.isItemValidate = this.isItemValidate.filter(obj =>
                this.selectedItems.some(item => item[this.compairKey] === obj.id) 
            );

            this.$emit('selected-items', JSON.parse(JSON.stringify(this.selectedItems)));
        },
        sortedItemsAsc(items) {
            const normalItems = items.filter(item => !item.onFlyEntry); // Filtering out normal items
            const onFlyEntryItems = items.filter(item => item.onFlyEntry); // Filtering out items with onFlyEntry true
            const freeTextOnFlyEntryItems = onFlyEntryItems.filter(item => typeof item[this.optionLabel] === 'string');
            const sortedNormalItems = normalItems.sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
            const sortedFreeTextOnFlyEntryItems = freeTextOnFlyEntryItems.sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
            const result = [...sortedNormalItems, ...sortedFreeTextOnFlyEntryItems, ...onFlyEntryItems.filter(item => typeof item[this.optionLabel] !== 'string')];
            return result;
        },
        getValueCascadeSelection(mainObj){
            return mainObj.filter(obj => obj.parentId === null)
                .map(objParent => {
                    var fullString = mainObj.filter(objChild => objChild.parentId === objParent.id)
                        .map(obj => obj.name).join('; ');

                    if(fullString){
                        return objParent.name + " (" + fullString + ")";
                    }else{
                        return objParent.name;
                    }                
                }).join('; ');
        },
        isValidationErrorChild(payload, item){
            if(!this.isNotCheckChildValidation){
                var selectItemValidate = this.isItemValidate.find(obj => obj.id === item[[this.compairKey]]);
                if(selectItemValidate){
                    selectItemValidate.error = payload[0].error;
                }else{
                    this.isItemValidate.push({
                        id:item[this.compairKey],
                        error: payload[0].error,
                    });
                }
            }
        },
        emitValidation(){
            const allItemValidate = (this.selectedItemsParentChild.map(item => {
                const itemValidater = this.isItemValidate.find(itemV => itemV.id === item.id);
                if(itemValidater){
                    return itemValidater;
                }else{
                    return {id :item.id, error: false}
                }
            }));

            this.$emit('is-validation-error', allItemValidate.length>0 ? 
                allItemValidate: [{ id: '', error: true }]);
        },
        handleItemClick(item) {
            if(item){
                this.selectedtab = item.name;            
            }else{
                this.selectedtab = null;
            }
        },
    }
}
</script>

<style scoped>
.bg-primary {
    background-color: #007bff !important;
}

.text-white {
    color: #fff !important;
}

.btn{
    text-align: left;
}

.btn-outline{
    background-color: #ffffff;
    border: 1px solid #ced4da;
}

.show > .btn-outline:not(:disabled):not(.disabled):active:focus, .btn-outline:not(:disabled):not(.disabled).active:focus, 
.show > .btn-outline.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.dropdown-toggle{
    font-size: 14px !important;
}

.dropdown-toggle::after {
    display: none;
}

.multiselect-trigger-icon{
    padding-top: 11%;
}
.multiselect-trigger{
    background: transparent;
    color: #6c757d;
    width: 20px;
    height: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: auto;
}

.selected-items{
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-menu li {
    position: relative;
}

.dropdown-submenu {
    padding: 0;
    display: none;
    position: absolute;
    left: 100%;
    top: -11px;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu > li:hover, li.select-tab > .dropdown-submenu {
    display: block;
}
</style>