<template>
     <div id="app">
    <div class="container bg-light text-center pt-80">
      <div class="d-block text-center">
        <div>
          <h1>400</h1>
          <p class="text-dark w-100 fs-18">404 Not Found</p>
          <div class="text-center mt-20">
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name:"app",
    data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters({
      loader: 'getLoaderStatus',
      sessionData: 'getSessionData',
    }),
    hasData() {
      return this.sessionData && typeof this.sessionData === 'object'
        ? JSON.parse(JSON.stringify(this.sessionData))
        : {};
    },
  },
  methods: {
    retry() {
      window.location.reload();
    },
  },
}
</script>
<style scoped lang="scss">
@import "./assets/scss/main.scss";
</style>