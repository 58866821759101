import { isArray, values } from 'lodash';
import premissions from '../models/permissions.json';

const checkActions = (module, currentAction) => {
  let action = false;
  if (typeof currentAction === 'string') {
    action = module[currentAction];
  } else if (isArray(currentAction)) {
    action = values(module).includes(true);
  }
  return action;
};

const canAccess = ({
  currentModule,
  currentAction,
  userRole,
}) => {
  const role = premissions[userRole];
  if (!role) return false;
  const module = role[currentModule];
  if (!module) return false;
  return checkActions(module, currentAction);
};

export default {
  canAccess,
};
