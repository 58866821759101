<template>
  <div class="subscription-storage">
    <div v-if="isUnlimited">{{ consumedLabel }} used</div>
    <div v-else>
      <b-progress class="mt-2 mb-1" v-if="$totalStorage" :max="total">
        <b-progress-bar
          aria-label="progress-bar" aria-labelledby="progress-bar"
          :value="consumed"
          :variant="progressVariant"
        ></b-progress-bar>
      </b-progress>
      <h6 class="text-muted">
        {{ consumedLabel }} of <span class="fw-700">{{ totalLabel }}</span> used
      </h6>
    </div>
    <div v-if="indicators && indicators">
      <div v-for="item in indicators" :key="item.key" class="my-30">
        <span class="px-10 mx-10 rounded" :class="`bg-${item.variant}`"></span>
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatFileSize } from '../../helpers/utilities';

export default {
  name: 'subscription-storage',
  data() {
    return {};
  },
  computed: {
    consumedLabel() {
      if (this.value) {
        return this.value === 'none' ? '0 Bytes' : formatFileSize(this.value);
      }
      return this.$storageLabel.consumed;
    },
    totalLabel() {
      return this.max
        ? formatFileSize(this.max, 'none')
        : this.$storageLabel.total;
    },
    consumed() {
      if (this.value) {
        return this.value === 'none' ? 0 : this.value;
      }
      return this.$storageConsumed;
    },
    total() {
      return this.max || this.$totalStorage;
    },
    progressVariant() {
      return this.consumed >= this.total ? 'danger' : 'primary';
    },
  },
  props: {
    isUnlimited: Boolean,
    max: {
      type: Number,
    },
    value: {
      type: [Number, String],
    },
    indicators: Array,
  },
};
</script>

<style scoped lang="scss">
</style>
