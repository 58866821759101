
export const COMMON_EDITORIALBOX_COLUMNS = [
  'Case view page',
  'List Mode',
  'Preview Mode',
  'Case Number',
  'Tumor Site',
  'Tumor Type',
  'Tumor Subtype',
  'Specific Cohort',
  'Region',
  'Editor',
  'Review Status',
  'By Cohort',
  'By Region',
  'By Editor',
  'By Status',
  'Reset Search/Filter',
  'View histology and case info',
  'Comments'
];


export const EDITORIALBOX_ROLES = {
  ADVISOR: COMMON_EDITORIALBOX_COLUMNS,
  EDITOR: COMMON_EDITORIALBOX_COLUMNS,
  CHIEFEDITOR: [
    ...COMMON_EDITORIALBOX_COLUMNS,
    'Change review status','Edit','Move back for revision','Delete'
  ],
  CHIEFEDITORDIFFREGION:[
    ...COMMON_EDITORIALBOX_COLUMNS,
  ],
  CHAIRPERSON: [
    ...COMMON_EDITORIALBOX_COLUMNS,
    'Change review status','Edit','Move back for revision','Move to Pre-publication box','Delete'
  ],  
  PROJECTADMIN: [
    ...COMMON_EDITORIALBOX_COLUMNS,
    'Change review status','Edit','Move back for revision','Move to Pre-publication box','Delete'
  ],  
  TENANTADMIN: [
    ...COMMON_EDITORIALBOX_COLUMNS,
    'Change review status','Edit','Move back for revision','Move to Pre-publication box','Delete'
  ],    
};


export const COMMON_MYFOLDER_COLUMNS = [
  'Case view page',
  'Slides view page',
  'List Mode',
  'Preview Mode',
  'Case Number',
  'Tumor Site',
  'Tumor Type',
  'Tumor Subtype',
  'Specific Cohort',
  'Region',
  'Editor',
  'Review Status',
  'By Cohort',               
  'By Status',   
  'View histology and case info',
  'View slides info',
  'Comments',
  'Edit',
  'Delete',
  'Reset Search/Filter',  
  'Back to case view',  
];

export const MYFOLDER_ROLES = {
  GUEST: [
    ...COMMON_MYFOLDER_COLUMNS,
    'Move to Editor box','Delete'
  ],
  GUESTSHARED: [
    ...COMMON_MYFOLDER_COLUMNS,
    'Move to Editorial Board box','Move back for revision'
  ],
  EDITOR: [
    ...COMMON_MYFOLDER_COLUMNS,
    'Move to Editorial Board box'
  ],
  CHIEFEDITOR: [
    ...COMMON_MYFOLDER_COLUMNS,    
    'Move to Editorial Board box'
  ],
  CHAIRPERSON: [
    ...COMMON_MYFOLDER_COLUMNS,    
    'Move to Editorial Board box'
  ],      
};


export const COMMON_PUBLICANDPREPUBLICATION_COLUMNS = [
  'Case view page',
  'List Mode',
  'Preview Mode',
  'Case Number',
  'Tumor Site',
  'Tumor Type',
  'Tumor Subtype',
  'Specific Cohort',
  'Region',
  'Editor',
  'Review Status',
  'By Cohort',
  'By Region',
  'By Status',
  'Reset Search/Filter',
  'View histology and case info',
  'Comments',
  'Move back to Editorial Board box'
];


export const PUBLICACCESS_ROLES = {  
  CHAIRPERSON: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,
  ],  
  PROJECTADMIN: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,    
  ],  
  TENANTADMIN: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,    
  ],    
};


export const PREPUBLICATION_ROLES = {  
  CHAIRPERSON: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,
    'Move to Public Access box'
  ],  
  PROJECTADMIN: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,    
    'Move to Public Access box'
  ],  
  TENANTADMIN: [
    ...COMMON_PUBLICANDPREPUBLICATION_COLUMNS,    
    'Move to Public Access box'
  ],
  EDITOR: ['View histology and case info','Comments'],
  CHIEFEDITOR: ['View histology and case info','Comments'],
};

export const RECYCLE_ROLES = {  
  EDITOR: ['View histology and case info','View slides info','Delete','Restore'],
  CHAIRPERSON: ['View histology and case info','View slides info','Delete','Restore'],
  CHIEFEDITOR: ['View histology and case info','View slides info','Delete','Restore'],  
};

export const VISIBLE_COLUMNS = {
  CASEVIEW : 'Case view page',
  SLIDEVIEW :'Slides view page',
  LISTMODE :'List Mode',
  PREVIEWMODE :'Preview Mode',
  CASENUMBER :'Case Number',
  TUMORSITE :'Tumor Site',
  TUMOTTYPE :'Tumor Type',
  TUMPRSUBTYPE :'Tumor Subtype',
  SPER :'Specific Cohort',
  REGION :'Region',
  EDITOR :'Editor',
  REVIEWSTATUS :'Review Status',
  BYCOHORT :'By Cohort',
  BYREGION :'By Region',
  BYEDITOR :'By Editor',
  BYREVIEWSTATUS:'By Status',
  VIEWHISTOLOGYANDCASEINFO:'View histology and case info',
  VIEWSLIDESINFO:'View slides info',
  COMMENTS:'Comments',
  CHANGEREVIEWSTATUS:'Change review status',
  EDIT:'Edit',
  MOVETOPUBLICACCESSBOX:'Move to Public Access box',
  MOVETOPREPUBLICATIONBOX:'Move to Pre-publication box',
  MOVETOEDITORIALBOARDBOX:'Move to Editorial Board box',
  MOVETOEDITORBOX:'Move to Editor box',
  DELETE:'Delete',
  MOVEBACKFORREVISION:'Move back for revision',
  MOVEBACKTOEDITORIALBOARDBOX:'Move back to Editorial Board box',   
  UPLOADFILES:'Upload Files',
  // BACKTOCASEVIEW:'Back to case view',
  RESETSEARCHFILTER:'Reset Search/Filter',  
  RESTORE:'Restore'
}
