import axios from 'axios';
import urls from './urls';
import { getUserUrl } from './api-services';
import { API_POST, API_DELETE, API_PUT } from '../constants/constants-api';

export const updateUserProfileFact = data => axios.put(getUserUrl('/update/user'), data);

export const getProfileMetadataFact = () => axios.get(getUserUrl(`${urls.profile}${urls.metadata}`));

export const profileCredentialsResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getUserUrl(`${urls.credentials}${urls.user}`),
        config.data,
        config,
      );
    case API_PUT:
      return axios.put(
        getUserUrl(`${urls.credentials}${urls.user}`),
        config.data,
        config,
      );
    case API_DELETE:
      return axios.delete(getUserUrl(`${urls.credentials}${urls.user}/${config.path.id}`));
    default:
  }
  return false;
};

export const profileprofessionResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getUserUrl(`${urls.profession}${urls.user}`),
        config.data,
        config,
      );
    case API_PUT:
      return axios.put(
        getUserUrl(`${urls.profession}${urls.user}`),
        config.data,
        config,
      );
    case API_DELETE:
      return axios.delete(getUserUrl(`${urls.profession}${urls.user}/${config.path.id}`));
    default:
  }
  return false;
};

export const profileSpecialityResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getUserUrl(`${urls.speciality}${urls.user}`),
        config.data,
        config,
      );
    case API_PUT:
      return axios.put(
        getUserUrl(`${urls.speciality}${urls.user}`),
        config.data,
        config,
      );
    case API_DELETE:
      return axios.delete(getUserUrl(`${urls.speciality}${urls.user}/${config.path.id}`));
    default:
  }
  return false;
};

export const profileSubSpecialityResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getUserUrl(`${urls.subSpeciality}${urls.user}`),
        config.data,
        config,
      );
    case API_PUT:
      return axios.put(
        getUserUrl(`${urls.subSpeciality}${urls.user}`),
        config.data,
        config,
      );
    case API_DELETE:
      return axios.delete(getUserUrl(`${urls.subSpeciality}${urls.user}/${config.path.id}`));
    default:
  }
  return false;
};

export const profileSocialResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getUserUrl(`${urls.social}${urls.links}`),
        config.data,
        config,
      );
    case API_PUT:
      return axios.put(
        getUserUrl(`${urls.social}${urls.links}`),
        config.data,
        config,
      );
    case API_DELETE:
      return axios.delete(getUserUrl(`${urls.social}${urls.links}`), config.config);
    default:
  }
  return false;
};
