import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as en from './english/labels-english';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: {
    en,
  },
});

export default i18n;
