export default {
  ok: 'Ok',
  all: 'All',

  // events
  add: 'Add',
  save: 'Save',
  copy: 'Copy',
  edit: 'Edit',
  view: 'View',
  info: 'Info',
  next: 'Next',
  back: 'Back',
  undo: 'Undo',
  share: 'Share',
  title: 'Title',
  title_sm: 'title',
  enter: 'Enter',
  close: 'Close',
  create: 'Create',
  invite: 'Invite',
  assign: 'Assign',
  cancel: 'Cancel',
  reset: 'Reset',
  import: 'Import',
  rename: 'Rename',
  delete: 'Delete',
  remove: 'Remove',
  select: 'Select',
  upload: 'Upload',
  logout: 'Logout',
  update: 'Update',
  setHomepage : 'Set Homepage',
  up: 'Up',
  down: 'Down',
  submit: 'Submit',
  search: 'Search',
  qrCode: 'QR Code',
  allList: 'All List',
  confirm: 'Confirm',
  actions: 'Actions',
  upload_sm: 'upload',
  update_sm: 'update',
  download: 'Download',
  continue: 'Continue',
  copyLink: 'Copy Link',
  downloadQr: 'Download QR',
  addAComment: 'Add a comment',
  saveAsImage: 'Save as image',
  case: 'Case',
  activate: 'Activate',
  deactivate: 'Deactivate',
  selectAllCases: 'Select All Filtered Cases',
  unselectAllCases: 'Unselect All Filtered Cases',
  moveSelectedCaseToEditorialBoardBox: 'Move Back to Editorial Board Box',
  moveSelectedCaseToPublicBox: 'Move Cases to Public Access Box',
  FreeCrop: 'Free crop',
  Crop32: '3:2 crop',
  UseForThumbnail: 'Use for thumbnail',

  addItem: 'Add {item}',
  newItem: 'New {item}',
  editItem: 'Edit {item}',
  shareItem: 'Share {item}',
  deleteItem: 'Delete {item}',
  updateItem: 'Update {item}',
  createItem: 'Create {item}',
  restoreItem: 'Restore {item}',
  renameItem: 'Rename {item}',
  viewItem: 'View {item}',

  // WTR labels
  tumorSite: 'Tumor Site',
  tumorType: 'Tumor Type',
  tumorTypeCategory: 'Category',
  tumorSubType: 'Tumor Subtype',
  geographicRegion: 'Geographic Region',
  country:'Country',
  ethnicity:'Ethnicity',
  familialDiseases:'Familial Disease',
  environmentalSettings:'Environmental Setting',
  stage:'Stage',
  mCategory:'M Category',
  nCategory:'N Category',
  tCategory:'T Category',
  cytology:'Cytology',
  immunoHistoChemistries:'Immuno/histochemistry',
  genetics:'Genetics',
  editor:'Editor',
  age:'Age',
  unknown:'Unknown',
  geograpicRegion: 'Geograpic Region',
  caseNumber:'Case Number',
  stateProvince:'State/Province',
  caseNotes:'Case Notes',
  totalFiles:'Total Files',  
  moveToEditorialBox:'Move to Editorial Board box',
  moveToSlideLibrary:'Move To Slide Library',
  sharedToEditor:'Share To Editor',
  sendBackToEditor:'Send Back To Editor',
  caseReviewComment: 'Comments',
  specificCohort: 'Specific Cohort',
  region: 'Region',
  tsv: 'Export as table',
  moveBackToEditorialBox:'Move Back To Editorial Box',
  moveBackToEditorialBoardBoxIcon:'Move back to Editorial Board box',
  reviewStatus: 'Review Status',
  moveToPrePublication:'Move to pre-Publication box',
  changeReviewStatus: 'Change review status',
  changeStatus: 'Change status',
  moveBackForRevision:'Move back for revision',
  project: 'Project',
  editorAssigned: 'Editor Assigned',

  // labels
  female: 'Female',
  male: 'Male',
  name: 'Name',
  slideName: 'Slide Name',
  slidePreview: 'Slide Preview',
  role: 'Role',
  user: 'User',
  users: 'Users',
  contributors: 'Contributors',
  about: 'About',
  email: 'Email',
  color: 'Color',
  slides: 'Slides',
  status: 'Status',
  section: 'Section',
  summary: 'Summary',
  validTo: 'Valid To',
  emailId: 'Email Id',
  password: 'Password',
  settings: 'Settings',
  lastName: 'Last Name',
  userName: 'User Name',
  addUsers: 'Add Users',
  timeLeft: 'Time Left',
  thumbnail: 'Thumbnail',
  diagnosis: 'Diagnosis',
  firstName: 'First Name',
  createdOn: 'Created On',
  completedOn: 'Completed On',
  createdBy: 'Created By',
  fillColor: 'Fill Color',
  slideType: 'Slide Type',
  validFrom: 'Valid From',
  nameMandatory: 'Name *',
  emailMandatory: 'Email *',
  userStatus: 'User Status',
  attachments: 'Attachments',
  description: 'Description',
  information: 'Information',
  strokeWidth: 'Stroke width',
  moreDetails: 'More Details',
  transparency: 'Transparency',
  conversionStatus: 'Conversion Status',
  cutOffPercentage: 'Cut-off percentage',

  option: 'Option',
  options: 'Options',

  you: 'You',
  note: 'Note',
  slide: 'Slide',
  expired: 'Expired',
  firefox: 'Firefox',
  version: 'Version',
  browser: 'Browser',
  phoneNumber: 'Phone Number',

  // modules
  myGroups: 'My Groups',
  slideBox: 'Slide Box',
  dashboard: 'Dashboard',
  myProfile: 'My Profile',
  slideLibrary: 'Slide Library',
  administration: 'Administration',
  publicLibrary: 'Public Slide Library',

  reviewApproveContributors: 'Can review and approve contributors case request?',
  approveOrMoveSlide: 'Can approve slide / move to slide library?',

  loading: 'Loading ...',
  googleChrome: 'Google Chrome',
  operatingSystem: 'Operating System',

  // search
  searchFiles: 'Search {fileType}',

  active: 'Active',
  inactive: 'Inactive',

  // common components
  help: 'Help',
  grid: 'Preview Mode',
  list: 'List Mode',
  perPage: 'Per Page',
  selected: 'Selected',
  addImage: 'Add Image',
  addSlide: 'Add Slide',
  selectSlides: 'Select Slides',
  stopRecording: 'Stop Recording',
  startRecording: 'Start Recording',
  registeredUserEmail: 'Registered user email',
  registeredUserGroups: 'Registered user groups',
  nonRegisteredEmails: 'Non-registered user emails',
  slideView: 'Slide View',
  caseView: 'Case View',
  backToCaseView: 'Back To Case View',

  annotation: 'Annotation',
  annotations: 'Annotations',

  message: 'Message',
  messages: 'Messages',
  amount: 'Amount',
  contact: 'Contact',
  address: 'Address',

  tenants: 'Tenants',
  tenant: 'Tenant',

  services: 'Services',
  resources: 'Resources',

  remove_sm: 'remove',
  delete_sm: 'delete',
  submit_sm: 'submit',
  searchByEmail: 'Search By Email',
  finish: 'Finish',
  viewer: 'Viewer',
  preview: 'Preview',
  // Helpers
  workInProgress: 'Work in progress',
  unauthorizedAccess: 'Unauthorized access, please login to continue.',
  unableToProcess: 'Sorry! we are unable to process your request.',
  fillProfileDetailsToContinue:
    'Please complete your profile details to continue.',
  sessionExpired: 'Your session is being expired.',
  thankyouForUsingPP: 'Thank you for using World Tumor Registry.',
  estimatedLessThanMinute: 'Estimated:  Less than a minute',
  estimatedTime: 'Estimated: {h}h {m}m.',
  imageIsNotAvailable: 'Image is not avilable',

  // validation messages
  lowercaseValidationMsg: 'Atleast one lower case character is needed',
  uppercaseValidationMsg: 'Atleast one upper case character is needed',
  numbercaseValidationMsg: 'Atleast one number is needed',
  specialcaseValidationMsg: 'Atleast one special character is needed',
  emailValidationMsg: '{field} format is invalid',
  urlValidationMsg: 'The link entered is invalid',
  // validation messages - END

  take: 'take',
  type: 'Type',
  system: 'System',
  species: 'Species',
  gender: 'Gender',
  sex: 'Sex',
  guests:'Contributor Requests',
  regionName:'Region Name',
  requestDate:'Request Date',
  contentPage:'Content',
  subscription: 'Subscription',
  subscriptionName: 'Subscription Name',
  uploadLimit: 'Upload Limit (GB)',
  subscriptions: 'Subscriptions',
  selectSubscription: 'Subscription',
  is_default : 'Default',
  stainStudy: 'Stain/Study',
  tumorConfiguration: 'Tumor Configuration',
  tumor: 'Tumor',
  overview: 'Overview',
  banner: 'Banner',
  updateTumorType: 'Update Tumor Type',
  myFolder:'My folders',
  moveToEditorBox: 'Move to Editor box',
  viewSlideInfo: 'View slide info',
  viewCaseInfo: 'View case images and info',
  moveToPublicAccessBox:'Move to Public Access box',
  caseMovedToEB: 'Case moved to Editorial Board Box',
  copyNewCaseNumber : 'Copy new Case Number',
  termsOfUse: 'Terms of Use',
  tumorSize:'Tumor Size (mm)',
  typeWord:'Type a word'
}