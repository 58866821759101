export default [{
  id: 0,
  name: 'My Slide Box',
  children: [],
  root: true,
  opened: true,
  parentId: 0,
  viewStatus: 'ACTIVE',
  recycle: false,
},
{
  id: 0,
  name: 'Recycle Bin',
  children: [],
  opened: false,
  parentId: 0,
  root: true,
  dropDisabled: true,
  recycle: true,
},
];
