const administration = () => import('./AdministrationModule.vue');
const tenantUsers = () => import('./components/users/users.vue');
const guestUsers = () => import('./components/users/guestUsers.vue');
const configureContent = () => import('./components/content/configureContent.vue');
const tumorConfiguration = () => import('./components/tumor-configuration/tumorConfiguration.vue');
const specificCohort = () => import('./components/specific-cohort/specificCohort.vue');
const subscriptions = () => import('./components/subscription/subscription.vue');


export default {
  path: 'administration',
  name: 'project admin',
  component: administration,
  children: [
    {
      path: 'users',
      name: 'tenant-users',
      component: tenantUsers,
    },
    {
      path: 'contributor-request',
      name: 'contributor-request',
      component: guestUsers,
    },
    {
      path: 'subscriptions',
      name: 'subscriptions',
      component: subscriptions,
    },
    {
      path: 'configure-content',
      name: 'configure-content',
      component: configureContent,
    },
    {
      path: 'tumor-configuration',
      name: 'tumor-configuration',
      component: tumorConfiguration,
    },
    {
      path: 'specific-cohort',
      name: 'specific-cohort',
      component: specificCohort,
    },
  ],
};
