const getItem = name => {
  const val = localStorage.getItem(name);
  if (val) {
    return val.indexOf('{') < 0 ? val : JSON.parse(val);
  }
  return val;
};

const removeItem = name => {
  localStorage.removeItem(name);
};

const removeAll = () => {
  localStorage.clear();
};

const setItem = (name, data) => {
  const dataToSave = typeof data !== 'object' ? data : JSON.stringify(data);
  localStorage.setItem(name, dataToSave);
};

export default {
  getItem,
  removeItem,
  removeAll,
  setItem,
};
