<template>
  <div>
    <b-form-group>
      <label class="form-label">{{placeholder}}</label>
      <b-form-tags
        class="border-none p-0"
        no-outer-focus
      >
        <template v-slot="{ disabled }">
          <b-dropdown
            :size="size"
            right
            variant="light"
            block
            :text="`Select ${placeholder}`"
            class="text-left"
            menu-class="max-h-px-25"
            toggle-class="text-left text-truncate"
          >
            <b-dropdown-form>
              <b-form-group :disabled="disabled">
                <b-form-input aria-label="Search" aria-labelledby="Search"
                  :value="searchValue"
                  @input="onSearchInput"
                  placeholder="search"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-item-button
              v-for="option in options"
              :key="option.id"
              :disabled="isSelected(option)"
              @click="onOptionClick(option)"
            >
              <div
                class="text-truncate"
                :title="option.name"
              >
                {{ option.name }}
              </div>
            </b-dropdown-item-button>
            <b-dropdown-text v-if="options.length === 0">
              <div
                class="text-truncate"
                title="There are no tags available to select"
              >
              There are no options available to select
              </div>
            </b-dropdown-text>
          </b-dropdown>
          <!-- <label class="text-muted fs-12">Check all that apply</label> -->
          <slot name="tags"></slot>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>
</template>

<script>
import { map } from 'lodash';

export default {
  name: 'wtr-multiselect',
  data() {
    return {};
  },
  computed: {
    mappedSelected() {
      return map(this.selected, 'name');
    },
  },
  components: {},
  props: {
    options: Array,
    selected: Array,
    searchValue: String,
    placeholder: String,
    variant: String,
    size: String,
  },
  methods: {
    isSelected({ name }) {
      return this.mappedSelected.includes(name);
    },
    onSearchInput(val) {
      this.$emit('search-input', val);
    },
    onOptionClick(option) {
      this.$emit('on-select', option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
