import axios from 'axios';
import { $session } from '@/helpers';
import urls from './urls';
import { getSlideUrl, getUserUrl } from './api-services';
import { getGLobalRepoUrl } from "./api-services";

const encryptAuthKey = payload => {
  if (!payload) return '';
  let encrypted = '';
  try {
    encrypted = window.btoa(payload);
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
  return encrypted;
};

export const userLoginFact = data => {
  const url = `${urls.login}/new`;
  const payload = `${data.email}:${data.password}`;
  const tenantData = $session.getTenantData();
  const siteData = $session.getSiteData();
  return axios.post(
    getUserUrl(url),
    {},
    {
      headers: {
        'X-TenantID': tenantData.id,
        'X-SiteID': siteData.id,
        'X-Auth': encryptAuthKey(payload),
      },
    },
  );
};

export const tenantIdFact = () => {
  const dns = {
    dnsName: process.env.VUE_APP_FRONTEND,
  };
  const url = urls.tenant + urls.fetch;
  return axios.post(getUserUrl(url), dns);
};

export const tenantMetadataFact = token => {
  const url = urls.tenant + urls.metadata;
  const dnsName = window.location.hostname;
  // Testing links
  // const dnsName = 'dev-admin.pathpresenter.net';
  // const dnsName = 'dev-public.pathpresenter.net';
  // const dnsName = 'dev-tenant2.pathpresenter.net';
  // const dnsName = 'staging-public.pathpresenter.net';
  // const dnsName = 'uat-public.pathpresenter.net';
  // const dnsName = 'pathpresenter.net';
  // const dnsName = 'osu.pathpresenter.net';
  // const dnsName = 'mskcc.pathpresenter.net';
  // const dnsName = 'isdp.pathpresenter.net';
  return axios.post(getUserUrl(url), {
    token: token ? `Bearer ${token}` : null,
    getPublicTokens: true,
    dnsName,
  });
};

export const getOHIFTokenFact = (params, token) => axios.get(getSlideUrl('/hmac'), {
  params,
  headers: {
    authorization: token ? `Bearer ${token}` : null,
  },
});

export const forgotPasswordFact = data => {
  const url = urls.auth + urls.forgotPassword;
  const tenantData = $session.getTenantData();
  return axios.post(getUserUrl(url), data, {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const validateOtpFact = data => {
  const url = urls.auth + urls.validateOtp;
  const tenantData = $session.getTenantData();
  return axios.post(getUserUrl(url), data, {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const resetPasswordFact = data => {
  const url = urls.auth + urls.changePassword;
  return axios.put(getUserUrl(url), data);
};

export const createPasswordFact = (data, token) => {
  const url = urls.auth + urls.resetPassword;
  const tenantData = $session.getTenantData();
  return axios.post(getUserUrl(url), data, {
    headers: {
      authorization: token,
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const resetPasswordLinkFact = (data, link) => {
  const url = urls.inviteuser + urls.reset;
  return axios.post(getGLobalRepoUrl(url), data, {
    params: {
      link,
    },
  });
};
