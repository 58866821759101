export default {
  sidebar: {
    show: true,
    full: true,
    variant: null,
  },
  loader: false,
  currentSidebar: '',
  routes: [],
  crumbs: null,
  dashboardHeight: window.innerHeight - 90,
  dashboardWidth: window.innerWidth,
  modalData: {
    show: false,
    route: null,
    data: null,
    title: null,
    size: null,
    class: null,
  },
};
