import { getGLobalRepoUrl } from "./api-services";
import urls from './urls';
import axios from 'axios';
import { $session } from '@/helpers';

export const saveSharedToEditorFact = data => axios.post(getGLobalRepoUrl(`${urls.cases}${urls.saveSharedToEditor}`), data);

export const getSlidesByCaseSlideIdsFact = (data, params) =>
{ 
    const tenantData =$session.getTenantData();
    return axios.get(getGLobalRepoUrl(`${urls.cases}${urls.caseslides}/${data}`),  {
    headers: {
        'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params,
});
}

export const getSlideImagesByCaseSlideIdsFact = data => 
{
    const tenantData =$session.getTenantData();
    return axios.post(getGLobalRepoUrl(`${urls.cases}${urls.caseSlideImages}`), data, {
    headers: {
        'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
});
}

export const getAllSharedToEditorFoldersFact = () => axios.get(getGLobalRepoUrl(`${urls.cases}${urls.allSharedToEditorFolders}`));

export const getAllCaseReviewStatusFact = () => axios.get(getGLobalRepoUrl(`${urls.cases}${urls.allCaseReviewStatus}`));

export const addCaseDataFact = data => axios.post(getGLobalRepoUrl(`${urls.casedata}${urls.addCasedata}`), data);

export const moveCaseBackForRevisionFact = id => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.moveBackForRevision}/${id}`));

export const moveCaseToPrepublicationBoxFact = id => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.moveToPrepublicationBox}/${id}`));

export const changeStatusFact = params => axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.changeStatus}`),
{
  params,
});

// export const getExportDataFact = (params) => axios.get(getGLobalRepoUrl(`${urls.cases}${urls.getExportData}`), {
//     params,
//   });

export const getExportDataFact = (params) => axios.get(getGLobalRepoUrl(`${urls.cases}/exportTSVRequest`), {
    params,
  });