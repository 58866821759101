import { concat } from 'lodash';
import api from '../api';

const { getSasTokenFact } = api;
let saasTokens;

export default tenantData => {
  saasTokens = [];
  return new Promise((resolve, reject) => {
    getSasTokenFact(true, tenantData).then(
      res => {
        saasTokens = concat(saasTokens, res.data.payload);
        if (!tenantData.public && !tenantData.config) {
          getSasTokenFact(false, tenantData).then(
            ress => {
              saasTokens = concat(saasTokens, ress.data.payload);
              resolve(saasTokens);
            },
            errr => {
              reject(errr);
            },
          );
        } else {
          resolve(saasTokens);
        }
      },
      err => {
        reject(err);
      },
    );
  });
};
