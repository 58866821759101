import {
  each,
  assign,
} from 'lodash';
import { mapActions } from 'vuex';
import paper from 'paper';
import {
  SLIDE_DIGITALSLIDE, SLIDE_ZOOMIFY, SLIDE_IMAGE, SLIDE_RADIOLOGY,
} from '../constants/constants';
import sassService from '../helpers/sass-init';
import OpenSeadragon from '../plugins/openseadragon';
import '../plugins/openseadragon-filtering';
import '../plugins/openseadragon-scalebar';
import noDataFound from '../components/common/no-data-found.vue';

export default {
  name: 'viewer',
  data() {
    return {
      scalebarText: '',
      paperElement: 'draw_canvas',
      viewerElement: 'openseadragon',
      fullScreenElement: 'fullscreen',
      // Toolbar state
      viewer: null,
      rotation: 0,
      zoom: 0,
      lastAngle: 0,
      minZoom: 0,
      maxZoom: 1,
      // Paper js state
      paperScope: null,
      layer: null,
      tool: null,
      canvas: null,
      path: null,
      paths: [],
      // Annotations State
      annotation: null,
      checkedAnnotations: [],
      annotations: [],
      // Flags
      fullScreen: false,
      showAll: false,
      navShown: true,
      showSettings: false,
      showInfo: false,
      enableAnnotations: false,
      filterValues: {},
      imageFail: false,
      tileLoading: false,
      slideData: null,
      hideRotationSlider: false,
      hideNavigator: false,
      selectedZoomButton: 0,
    };
  },
  components: {
    noDataFound,
  },
  computed: {
    useCanvas() {
      return !(
        this.hideRotationSlider
        && this.slideData.fileType === SLIDE_IMAGE
        && this.$userAgent.os === 'ios'
      );
    },
    isDicom() {
      return this.slideData && this.slideData.fileType === SLIDE_RADIOLOGY;
    },
    isViewer() {
      return (
        this.slideData
        && (this.slideData.fileType === SLIDE_DIGITALSLIDE
          || this.slideData.fileType === SLIDE_IMAGE)
      );
    },
    canvasContainer() {
      return this.viewer && this.viewer.viewport
        ? this.viewer.viewport.containerSize
        : {
          x: 0,
          y: 0,
        };
    },
  },
  methods: {
    ...mapActions(['sessionDataAction']),
    // Toolbar methods
    rotateLeft() {
      this.rotation -= 45;
      this.rotation = this.rotation < 0 ? this.rotation + 360 : this.rotation;
      this.rotate(this.rotation);
    },
    rotateRight() {
      this.rotation += 45;
      this.rotation = this.rotation > 360 ? this.rotation - 360 : this.rotation;
      this.rotate(this.rotation);
    },
    rotate(val) {
      this.rotation = Number(val);
      this.viewer.viewport.setRotation(this.rotation, true);
    },
    toggleNavigator(hide) {
      if (hide) {
        this.navShown = false;
      } else {
        this.navShown = !this.navShown;
      }
      this.viewer.navigator.element.style.display = this.navShown
        ? 'inline-block'
        : 'none';
    },
    zoomButtonTo(x) {
      if (!x) {
        this.viewer.viewport.zoomTo(this.minZoom || 0.1);
      } else {
        const m = x / (this.slideData.mdAppmag || 20);
        this.viewer.viewport.zoomTo(this.viewer.viewport.imageToViewportZoom(m));
      }
      setTimeout(() => {
        this.selectedZoomButton = x;
      }, 100);
    },
    zoomViewer(zoom) {
      this.zoom = zoom;
      this.viewer.viewport.zoomTo(
        zoom,
        this.viewer.viewport.getCenter(true),
        false,
      );
    },
    zoomTo(level) {
      let zoomLevel = level * this.viewer.viewport.getMinZoom();
      if (zoomLevel > this.viewer.viewport.getMaxZoom()) {
        zoomLevel = this.viewer.viewport.getMaxZoom();
      }
      this.zoomViewer(zoomLevel);
    },
    setHomeDefault() {
      this.rotate(0);
      this.setFilters({
        brightness: 0,
        contrast: 1,
        greyscale: 0,
        invert: 0,
      });
      this.zoomTo(1);
      this.navShown = false;
      this.toggleNavigator(this.hideNavigator);
      this.resetDrawing();
    },
    exitFullScreenElement() {
      this.fullScreen = false;
      this.$refs[this.fullScreenElement].exit();
    },
    enterFullScreen() {
      this.fullScreen = true;
      this.$fullscreen.enter(document.body, {
        wrap: false,
        // callback: this.fullscreenUpdate,
      });
    },
    exitFullScreen() {
      this.fullScreen = false;
      this.$fullscreen.exit(document.body, {
        wrap: false,
        // callback: this.fullscreenUpdate,
      });
    },
    fullscreenUpdate(fullscreen) {
      this.fullScreen = fullscreen;
    },
    setFilters(filters) {
      this.filterValues = filters;
      if (!(this.viewer && this.viewer.drawer.canvas)) return;
      const filterCSS = [];
      each(filters, (val, key) => {
        if (key === 'contrast' || key === 'brightness') {
          filterCSS.push(OpenSeadragon.Filters[key.toUpperCase()](val));
        } else if ((key === 'greyscale' || key === 'invert') && Number(val)) {
          filterCSS.push(OpenSeadragon.Filters[key.toUpperCase()]());
        }
      });
      this.viewer.setFilterOptions({
        filters: {
          processors: filterCSS,
        },
      });
    },
    // Viewer and paper js initialisation methods
    resize() {
      this.canvas.width = this.viewer.viewport.containerSize.x;
      this.canvas.height = this.viewer.viewport.containerSize.y;
      this.paperScope.view.viewSize = new this.paperScope.Size(
        this.canvas.width,
        this.canvas.height,
      );
      this.paperScope.view.zoom = this.canvas.width * this.viewer.viewport.getZoom(true);
      this.paperScope.view.center = new this.paperScope.Size(
        this.viewer.viewport.getCenter(true).x,
        this.viewer.viewport.getCenter(true).y,
      );
      this.lastAngle = this.paperScope.view.rotation;
      this.paperScope.view.update(true);
    },
    onRotate(event) {
      this.rotatePaper(event.degrees);
      this.changeTextAngles();
      this.resize();
    },
    rotatePaper(degrees) {
      if (this.paperScope && this.paperScope.view) {
        this.paperScope.view.matrix.rotate(
          degrees - this.lastAngle,
          this.paperScope.view.center,
        );
        this.lastAngle = degrees;
      }
    },
    changeTextAngles() {
      const reqItems = []; // getDrawingItems('text') || [];
      each(reqItems, item => {
        item.rotate(-item.rotation);
        item.rotate(-paper.view.rotation);
      });
    },
    /* eslint-disable */
    takeSnapShot() {},
    initPaperCanvas(flag) {
      if (flag) {
        this.canvas = null;
        this.canvas = document.createElement("canvas");
        window
          .$(this.canvas)
          .attr("tabindex", "0")
          .mousedown(function() {
            window.$(this).focus();
          });
        this.canvas.id = this.paperElement;
        this.canvas.width = this.viewer.viewport.containerSize.x;
        this.canvas.height = this.viewer.viewport.containerSize.y;
        this.viewer.canvas.appendChild(this.canvas);
        this.viewer.addHandler("animation", this.resize);
        this.viewer.addHandler("open", () => {
          this.tileLoading = false;
          this.resize();
        });
        this.viewer.addHandler("open-failed", () => {
        });
        this.viewer.addHandler("animation-finish", this.resize);
        this.viewer.addHandler("update-viewport", this.resize);
        this.viewer.addHandler("resize", this.resize);
        this.viewer.addHandler("rotate", this.onRotate);
        this.viewer.addHandler("constrain", this.resize);
        this.viewer.addHandler("canvas-scroll", () => {});
        if (this.paperScope) {
          this.paperScope.project.clear();
          this.paperScope.tool = null;
          this.paperScope = null;
        }
        this.paperScope = new paper.PaperScope();
        this.paperScope.setup(this.canvas);
        const transform = "translate(0px,0px)";
        this.canvas.style.WebkitTransform = transform;
        this.canvas.style.msTransform = transform;
        this.canvas.style.transform = transform;
        this.canvas.style.marginLeft = "0px";
        this.canvas.style.marginTop = "0px";
        this.resize();
        this.initDrawing();
        return this.canvas;
      }
      this.canvas = window.$(`#${this.paperElement}`)[0]; //eslint-disable-line
      if (this.canvas) {
        this.viewer.canvas.removeChild(this.canvas);
        this.canvas = null;
        this.paperScope.tool = null;
        this.paperScope = null;
        this.viewer.panHorizontal = true;
        this.viewer.panVertical = true;
        this.viewer.removeAllHandlers("animation");
        this.viewer.removeAllHandlers("open");
        this.viewer.removeAllHandlers("animation-finish");
        this.viewer.removeAllHandlers("update-viewport");
        this.viewer.removeAllHandlers("resize");
        this.viewer.removeAllHandlers("rotate");
        this.viewer.removeAllHandlers("constrain");
        this.viewer.removeAllHandlers("canvas-drag");
      }
    },
    /* eslint-disable */
    createCompareSlide(imageSource, dziSource, zoomifySource) {
      const tileSources = [];
      tileSources.push(imageSource || dziSource || zoomifySource);
      if (this.slideData.comparedDziPath && this.slideData.comparedDziPath.includes('.dzi')) {
        tileSources.push(this.$getResource(this.slideData.comparedDziPath, this.slideData.comparedResourceId, this.slideData.comparedContainerName));
      } else if (this.slideData.comparedDziPath) {
        tileSources.push({
          type: 'zoomifytileservice',
          width: this.slideData.comparedImgWidth,
          height: this.slideData.comparedImgHeight,
          tilesUrl: this.$getResource(this.slideData.comparedDziPath, this.slideData.comparedResourceId, this.slideData.comparedContainerName),
        });
      } else if (this.slideData.comparedPath) {
        tileSources.push({
          type: "image",
          url: this.$getResource(
            this.slideData.comparedPath,
            this.slideData.comparedResourceId,
            this.slideData.comparedContainerName
          ),
          crossOriginPolicy: "Anonymous",
        });
      }
      return tileSources;
    },
    getScalebarValue(scalebarText) {
      if (!scalebarText || scalebarText.includes('NaN')) return;
      this.slideData = {
        ...this.slideData,
        scalebarText,
      };
    },
    viewerInit(cb) {
      this.tileLoading = true;
      if (this.viewer) {
        this.viewer.destroy();
        this.viewer = null;
        this.paths = [];
      }
      this.hideRotationSlider = false;
      const hasImage = this.slideData.fileType === SLIDE_IMAGE && this.slideData.path;
      const hasDzi = this.slideData.fileType === SLIDE_DIGITALSLIDE && this.slideData.dziPath && this.slideData.dziPath.includes('.dzi');
      const hasZoomify = this.slideData.fileType === SLIDE_ZOOMIFY && this.slideData.dziPath && !this.slideData.dziPath.includes('.dzi');
      const imgSource = hasImage
        ? {
            type: 'image',
            url: this.$getResource(this.slideData.path, this.slideData.resourceId, this.slideData.containerName),
            crossOriginPolicy: 'Anonymous',
          }
        : null;
      const dziSource = hasDzi ? this.$getResource(this.slideData.dziPath, this.slideData.resourceId, this.slideData.containerName) : null;
          const zoomifySource = hasZoomify
          ? {
              type: 'zoomifytileservice',
              width: this.slideData.imgWidth,
              height: this.slideData.imgHeight,
              tilesUrl: this.$getResource(this.slideData.dziPath, this.slideData.resourceId, this.slideData.containerName),
            }
          : null;
      let tileSources = null;
      let openseaOptions = {};
      if (!this.slideData.comparedPath && !this.slideData.comparedDziPath) {
        tileSources = [];
        if (hasImage) {
          this.hideRotationSlider =
            this.annoSource && this.annoSource === "PRESENTATION";
          tileSources = imgSource;
        } else if (hasDzi) {
          tileSources = [];
          tileSources.push(dziSource);
        } else if (hasZoomify) {
          tileSources = [];
          tileSources.push(zoomifySource);
        }
      } else {
        const compareSources = this.createCompareSlide(imgSource, dziSource, zoomifySource);
        if (compareSources) {
          openseaOptions = assign(openseaOptions, {
            collectionMode: true,
            collectionRows: 1,
          });
          tileSources = compareSources;
        }
      }
      openseaOptions = assign(openseaOptions, {
        id: this.viewerElement,
        zoomInButton: "zoom-in",
        zoomOutButton: "zoom-out",
        crossOriginPolicy: "Anonymous",
        showNavigator: true,
        navigatorPosition: "BOTTOM_LEFT",
        navigatorAutoFade: false,
        showNavigationControl: false,
        useCanvas: this.useCanvas,
        tileSources,
        gestureSettingsMouse: {
          pinchRotate: true,
        },
        gestureSettingsTouch: {
          pinchRotate: true,
        },
        gestureSettingsPen: {
          pinchRotate: true,
        },
        gestureSettingsUnknown: {
          pinchRotate: true,
        },
      });
      this.viewer = OpenSeadragon(openseaOptions);
      if (this.slideData.fileType === SLIDE_DIGITALSLIDE && !!(this.slideData.mdMpp)) {
        const pixelsPerMeter = 1000000 / Number(this.slideData.mdMpp);
        try {
          setTimeout(() => {
            this.viewer.scalebar({
              onUpdate: this.getScalebarValue,
              pixelsPerMeter,
              xOffset: 10,
              yOffset: 10,
              barThickness: 3,
              color: '#555555',
              fontColor: '#333333',
              location: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            });
          }, 2000);
        } catch (err) {
          console.log(err); // eslint-disable-line
        }
      }
      this.viewer.addHandler("tile-load-failed", (evt) => {
        this.onTileFail();
      });
      this.viewer.addHandler("open-failed", (evt) => {
        this.tileLoading = true;
      });
      this.initUtil(cb);
      this.initPaperCanvas(true);
    },
    initUtil(cb) {
      this.viewer.navigator.element.style.display = this.navShown
        ? "inline-block"
        : "none";
      this.zoom = this.viewer.viewport.getZoom(true);
      this.rotation = 0;
      this.viewer.addHandler("rotate", (event) => {
        this.rotation = event.degrees;
      });
      this.viewer.addHandler("zoom", (event) => {
        this.zoom = event.zoom;
        this.selectedZoomButton = 0;
        this.minZoom = this.viewer.viewport.getMinZoom(true);
        this.maxZoom = this.viewer.viewport.getMaxZoom(true);
        this.showInfo = false;
      });
      this.setFilters(this.filterValues);
      if (cb) {
        setTimeout(() => {
          cb();
        }, 500);
      }
    },
    // Drawing initialisation methods
    initDrawing() {
      this.layer = new this.paperScope.Layer();
      this.paperScope.project.addLayer(this.layer);
    },
    resetDrawing() {
      this.paperScope.project.clear();
      if (this.layer) {
        this.layer.remove();
        this.layer = null;
      }
      this.showAll = false;
      this.annotation = null;
      this.paths = [];
      this.showSettings = false;
      this.showInfo = false;
      this.initDrawing();
    },
    // Utilities
    slideInfo() {
      this.$setModalAction({
        show: true,
        route: "slide-info",
        title: this.$t("slidelibrary.slideInfo"),
        size: "lg",
        data: this.slideData,
        static: this.fullScreen,
      });
    },
    frameLoaded(oldTiledImage, path) {
      this.viewer.addTiledImage({
        tileSource: this.$getResource(
          path,
          this.slideData.resourceId,
          this.slideData.containerName
        ),
        index: 0,
        opacity: 0,
        preload: true,
        success: (event) => {
          var tiledImage = event.item;
          const ready = () => {
            this.viewer.world.removeItem(oldTiledImage);
            tiledImage.setOpacity(1);
            this.imageFail = false;
          };
          if (tiledImage.getFullyLoaded()) {
            ready();
          } else {
            tiledImage.addOnceHandler("fully-loaded-change", ready);
          }
        },
      });
    },
    onTileFail() {
      if (!this.imageFail) {
        sassService(this.$tenantData).then(
          (sasTokens) => {
            const sessionData = this.$session.updateSessionData({
              sasTokens,
            });
            this.sessionDataAction(sessionData);
            this.frameLoaded(
              this.viewer.world.getItemAt(0),
              this.slideData.dziPath
            );
          },
          (error) => {
            this.imageFail = true;
            this.$handleError(error);
          }
        );
      }
      this.imageFail = true;
    },
    panViewer(x, y) {
      const point = new OpenSeadragon.Point(x, y);
      this.viewer.viewport.panTo(point, true);
    },
  },
};
