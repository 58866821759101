<template>
    <div>
        <b-modal 
            v-model="$modalData.show" :size="'lg'" 
            :title="$modalData.title"
            hide-footer
        >
            <div class="card case-review-chat">
                <div class="card-body overflow-auto" style="height: 50vh">
                    <div v-if="comments.length">
                        <div v-for="comment in comments" :key="comment.id" class="mb-3">
                            <div class="d-flex align-items-center">
                                <span class="card-title chat-title">
                                    <span class="chat-fullname">{{ comment.fullname }}</span>
                                    &nbsp;<small class="text-muted">({{ comment.role }})</small>
                                </span>
                                ,&nbsp;&nbsp;<small class="text-muted">{{ formatDate(comment.createdDateTime) }}</small>:
                            </div>
                            <div class="card-text">{{ comment.message }}</div>
                        </div>
                    </div>
                    <div v-if="!comments.length" class="h-100">
                        <div class="h-100 d-flex justify-content-center align-items-center">
                            <div style="opacity: 0.6;">No Comments</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fs-14 mt-2 mb-0">Leave a Comment</div>
            <div class="form-group">
                <textarea class="form-control fs-14" v-model="newComment" maxlength="300" height="63px" placeholder="Max 300 characters" style="resize: none;"></textarea>
                <div class="d-flex justify-content-end">
                    <small class="text-muted">{{ newComment ? newComment.length : 0 }}/300 characters</small>
                </div>
            </div>

            <div class="text-right pb-0 pr-0">
                <b-btn
                    aria-label="save" aria-labelledby="save"
                    variant="primary" size="sm"
                    @click="submitComment()"
                    :disabled="newComment.trim().length === 0"
                >
                    <span> 
                        <i class="fa fa-comment"></i>&nbsp;&nbsp;Post a comment
                    </span>
                </b-btn>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "case-review-chat",
    props: {
        modalDataInfo: Object,
    },
    data() {
        return {
            comments: [],
            chatObj: {
                caseDetailId: 0,
                roleId: 0,
                userId: 0,
                message: '',
            },
            params: {
                caseDetailId: 0,
            },
            newComment: '',
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if(this.modalDataInfo?.caseDetailId){
                this.params.caseDetailId = this.modalDataInfo.caseDetailId;
                this.chatObj.caseDetailId = this.params.caseDetailId;
            }

            if(this.params){
                this.getCaseReviewComment(this.params);
            }

            this.chatObj.userId = parseInt(localStorage.getItem("userId"));
            this.$api.getUserFact(this.chatObj.userId).then(
                (response) => {
                    if(response){
                        const data = response.data;
                        // const jobRole = data.jobRole;

                        // this.chatObj.roleId = data.roles.find(role => role.tags === jobRole).id;
                        this.chatObj.roleId = data.roles[0].id;
                    }
                }
            );
        },
        getCaseReviewComment(params) {
            this.$api.getCaseReviewCommentFact(params).then(
                (response) => {
                    if (response.data.payload) {
                        this.comments = response.data.payload;
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        addCaseReviewComment(data) {
            this.$api.addCaseReviewCommentFact(data).then(
                (response) => {
                    if (response.data.payload) {
                        this.newComment = '';
                        this.getCaseReviewComment(this.params);
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        formatDate(timestamp,format) {
            const date = this.$moment
            .utc(timestamp)
            .local()
            .format(format || 'MM/DD/YYYY hh:mm:ss A');
            const localdate = new Date(date);
            const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'};
            return localdate.toLocaleDateString(undefined, options);
        },
        submitComment(){
            if(this.newComment.trim().length && this.chatObj.userId && this.chatObj.roleId && 
                (this.chatObj.caseDetailId)){
                this.chatObj.message = this.newComment.trim();
                this.addCaseReviewComment(this.chatObj);
            }
        }
    },
}
</script>
