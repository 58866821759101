<template>
  <div>
    <b-modal
      v-model="$modalData.show" :size="'sm'" 
      hide-header
      hide-footer
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      centered
      :modal-class="$modalData.class"
      :hide-header-close="$modalData.hideCloseIcon"
      >
      <div class="b-modal-close p-2">
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </div>
      <div class="pt-1" style="margin-top: -8px;">
        <div class="pb-1">            
          <label class="form-label text-primary m-0 fw-800">Case moved to Editorial Board Box</label><br>
        </div>
        <div class="pb-1">            
          <label class="form-label text-primary m-0 fw-500">Submission Case Number:</label><br>
          <span class="fs-14"> {{ modalDataInfo.caseNumber }} </span>
        </div>
        <div class="pb-1">            
          <label class="form-label text-primary m-0 fw-500">WTR Case Number:</label><br>
          <span class="fs-14"> {{ modalDataInfo.systemCaseNumber }} </span>
        </div>      
        <div class="pb-0 pr-0 text-center">            
          <b-btn
            aria-label="copy case number"
            aria-labelledby="copy case number"
            variant="primary" size="sm"
            v-clipboard:copy="modalDataInfo.systemCaseNumber"
            v-clipboard:success="copyCaseNumber">
            <span> 
              <i class="fa fa-copy"></i>&nbsp;&nbsp;Copy WTR Case Number
            </span>
          </b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "case-copy",
  data() {
    return {
    };
  },
  props: {
    modalDataInfo: Object,
    params: Object,
  },
  computed: {
  },
  mounted() {
  },
  components: {
  },
  methods: {
    copyCaseNumber() {
      this.$toastr('info', `Case number "${this.modalDataInfo.systemCaseNumber}" is copied to the clipboard.`);
      this.$setModalAction({ show: false });
    },
    closeModal() {
      this.$modalData.show = false; 
    },
  },
};
</script>

<style lang="scss" scoped>  
.b-modal-close {
  cursor: pointer!important;
    top: 0;
    position: absolute;
    right: 0;
}
</style>
