<template>
  <div v-if="sasToken && fileData">
    <b-card header-tag="header">
      <div slot="header">
        <b-row class="mx-0">
          <b-col
            cols="6"
            sm="6"
            md="8"
            lg="9"
            xl="9"
            class="px-10 pt-5 text-light"
          >
            <span v-if="uploadingFiles.length > 0">
              {{$t('slidebox.uploading')}} {{ uploadingFiles.length }} File{{
                uploadingFiles.length > 1 ? "s" : ""
              }}...
            </span>
            <span v-else-if="cancelledFiles && cancelledFiles.length > 0">
              {{ cancelledFiles.length }} {{$t('slidebox.file')}}{{
                cancelledFiles.length > 1 ? "s" : ""
              }}
              {{$t('slidebox.gotCancelled')}}
              <i class="fa fa-times-circle-o text-danger fs-20"></i>
            </span>
            <span v-else>
              {{ fileData.length }} File{{ fileData.length > 1 ? "s" : "" }}
              {{$t('slidebox.uploaded')}}...
              <i class="fa fa-check-circle-o text-success fs-20"></i>
            </span>
          </b-col>
          <b-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            xl="3"
            class="p-0 pr-5 text-right"
          >
            <b-btn
              variant="link"
              size="sm"
              title="minimize"
              v-on:click="minimize = !minimize"
            >
              <i
                class="text-light fs-18"
                :class="{
                  'fa fa-angle-down': !minimize,
                  'fa fa-angle-up': minimize
                }"
              >
              </i>
            </b-btn>
            <b-btn
              variant="link"
              size="sm"
              title="close"
              v-on:click="closeUploader"
            >
              <i class="fa fa-times text-light fs-16"></i>
            </b-btn>
          </b-col>
        </b-row>
      </div>
      <b-card-body
        class="upload-container"
        v-if="!minimize"
      >
        <div
          class="row m-0 my-5 py-5 pl-0 border border-2"
          v-for="file in fileData"
          :key="file.id"
        >
          <div
            v-if="
              !browserBlock &&
                !file.inQueue &&
                !file.success &&
                !file.terminated
            "
            class="col-12 fs-12 fw-900 pt-5 pl-5 text-truncate"
          >
            <span v-if="file.loaded">
              <b-badge
                class="px-10 py-5"
                variant="light"
              >
                <span v-show="!browserBlock">{{ file.loaded }} / </span>{{ file.size }}
              </b-badge>
            </span>
            <div v-if="file.time">
              {{ estimatedTime(file.time) }}
            </div>
            <span
              class="mx-10"
              v-if="file.speed"
            >
              <b-badge
                class="px-10 py-5"
                variant="light"
              >{{
                file.speed
              }}</b-badge>
            </span>
          </div>
          <div
            class="col-5 fs-12 pt-5 text-truncate"
            :title="file.fileName"
          >
            {{ file.fileName }}
          </div>
          <div class="col-7 px-0 fs-14 pt-5 text-right">
            <span
              class="text-primary fs-12"
              v-if="file.terminated"
            >{{$t('slidebox.uploadCancelled')}}</span>
            <div v-else-if="file.inQueue">
              <div class="fs-12 text-muted">
                {{$t('slidebox.inQueue')}} ..
              </div>
              <b-progress
                animated
                :label="'In Queue'"
                :value="100"
                :max="100"
                variant="secondary"
              ></b-progress>
            </div>
            <i
              class="fa fa-check-circle-o text-success fs-16"
              v-else-if="file.success"
            ></i>
            <i
              class="fa fa-times-circle-o text-danger fs-16"
              v-else-if="file.serverFail"
            ></i>
            <span v-else-if="file.fileStatus < 100 && file.fileStatus !== -1">
              <b-progress
                animated
                v-if="browserBlock"
                :label="`${file.fileStatus} %`"
                :value="100"
                :max="100"
                variant="success"
              ></b-progress>
              <b-progress
                :value="file.fileStatus"
                v-else
                :label="`${file.fileStatus} %`"
                :max="100"
                show-progress
                size="sm"
                variant="success"
              ></b-progress>
            </span>
            <i
              v-else-if="file.dataStatus < 100 && file.dataStatus !== -1"
              class="fa fa-cog fa-spin fs-16"
            ></i>
          </div>
          <div class="col-12 text-right">
            <b-btn
              variant="link"
              class="text-primary"
              size="sm"
              v-if="file.serverFail"
              title="Server Error"
              v-b-popover.hover="'Sorry unable to upload this file'"
            >
              <i class="fa fa-info fs-16"></i> Info
            </b-btn>
            <b-btn
              variant="link"
              class="text-primary"
              size="sm"
              v-else-if="file.terminated"
              title="Upload"
              v-on:click="reUpload(file.id)"
            >
              <i class="fa fa-repeat fs-16"></i> Re upload
            </b-btn>
            <b-btn
              variant="link"
              class="text-primary"
              size="sm"
              v-else-if="!file.success"
              title="Cancel"
              v-on:click="abortSingleFile(file.id)"
            >
              <i class="fa fa-times fs-16"></i> Cancel
            </b-btn>
            <b-btn
              aria-label="delete"
              aria-labelledby="delete"
              variant="link"
              class="text-primary"
              size="sm"
              v-if="!file.success"
              title="Remove"
              v-on:click="deleteFile(file.id)"
            >
              <i class="fa fa-trash fs-16"></i> Remove
            </b-btn>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  forEach,
  maxBy,
  assign,
  findIndex,
  find,
  filter,
  sortBy,
  slice,
  remove,
  cloneDeep,
} from "lodash";
import { getEstimatedTime, formatFileSize } from "../../../helpers/utilities";
import {
  slideUploader,
  setOnFileSelect,
  getOnFolderChange,
} from "../../../helpers/slide-upload";
import { SLIDE_REQUEST_APPROVED } from "../../../constants/constants-slide";

export default {
  name: "upload-loader",
  data() {
    return {
      minimize: false,
      fileData: null,
      queue: [],
      unSubscribe: null,
      offLineFiles: [],
      cancelToken: null,
      sasToken: null,
    };
  },
  computed: {
    uploadingFiles() {
      return this.fileData
        ? filter(
            this.fileData,
            (item) => !item.success && !item.serverFail && !item.terminated
          )
        : null;
    },
    ongoingFiles() {
      return this.fileData
        ? filter(
            this.fileData,
            (item) =>
              !item.success &&
              !item.serverFail &&
              !item.terminated &&
              !item.terminated &&
              !item.inQueue
          )
        : null;
    },
    cancelledFiles() {
      return this.fileData
        ? filter(this.fileData, (item) => item.serverFail || item.terminated)
        : null;
    },
    browserBlock() {
      return ["ie", "edge"].includes(this.$userAgent.browser);
    },
  },
  props: {
    options: Object,
  },
  mounted() {
    this.initLoader();
  },
  beforeDestroy() {},
  methods: {
    deleteFile(id) {
      const payload = this.getFileData(id);
      this.abortSingleFile(id);
       this.$updateSubscriptionStorageData(
         this.$storageConsumed - payload.data.fileSize
       );
      const filesCopy = this.fileData;
      const queueCopy = this.queue;
      remove(filesCopy, (item) => item.id === id);
      remove(queueCopy, (item) => item.id === id);
      this.fileData = cloneDeep(filesCopy);
      this.queue = cloneDeep(queueCopy);
    },
    initLoader() {
      setOnFileSelect(({ fileData, cb }) => {
        if (cb) cb();
        if (!this.sasToken) {
          this.initSasToken(fileData);
        } else {
          const canStartUpload = this.queue.length <= 0;
          this.constructFileData(fileData, canStartUpload);
        }
      });
    },
    initSasToken(data) {
      this.$api.getSasTokenWriteFact(this.$tenantData.cName).then(
        (response) => {
          if (!this.$handleResponse(response)) return;
          this.sasToken =
            response.data.payload && response.data.payload.length
              ? response.data.payload[0].sasToken
              : null;
          if (!this.sasToken) {
            this.$modalAlert("error", this.$t("slidebox.tokenNotFound"));
          }
          window.addEventListener("beforeunload", this.onPageReload);
          window.addEventListener("offline", this.onPageOffline);
          window.addEventListener("online", this.onPageOnline);
          this.fileData = [];
          this.minimize = this.$mq === "sm";
          this.constructFileData(data, true);
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    onPageOffline() {
      this.offLineFiles = filter(this.fileData, (item) => !item.success);
    },
    onPageOnline() {},
    estimatedTime(time) {
      return getEstimatedTime(time);
    },
    onPageReload(evt) {
      evt.returnValue = "Are you sure you want to close?"; //eslint-disable-line
    },
    getMaxOrder() {
      return this.fileData && this.fileData.length > 0
        ? maxBy(this.fileData, (o) => o.id).id + 1
        : 1;
    },
    getFileIndex(id) {
      return findIndex(this.fileData, (item) => item.id === id);
    },
    getFileData(id) {
      return find(this.fileData, (item) => item.id === id);
    },
    constructFileData(data, canStartUpload) {
      forEach(data, (item) => {
        const payload = assign(item, {
          // push
          id: this.getMaxOrder(),
          fileStatus: 0,
          fileName: item.file.name,
          size: formatFileSize(item.file.size, 1),
          rawSize: item.file.size,
          dataStatus: 0,
          request: null,
          terminated: false,
          serverFail: false,
          success: false,
          inQueue: true,
          time: 0,
          reUpload: !!item.data.id,
          loaded: 0,
          cancelToken: { cancel: false },
          metadata:item.data.metadata,
        });
        this.fileData.push(cloneDeep(payload));
        this.queue.push(cloneDeep(payload));
      });
      this.queue = sortBy(this.queue, "rawSize");
      this.fileData = sortBy(this.fileData, "rawSize");
      if (canStartUpload) {
        this.startUploadProcess();
      }
    },
    reUpload(id) {
      const file = this.updateFileData(id, {
        terminated: false,
        fileStatus: 0,
        dataStatus: 0,
        request: null,
        reUpload: true,
        inQueue: true,
      });
      if (!file) return;
      this.queue.push(file);
      this.queue = sortBy(this.queue, "rawSize");
      if (this.ongoingFiles.length < 3) {
        this.initiateQueue(file);
      }
    },
    startUploadProcess() {
      const queue = slice(this.queue, 0, 3);
      this.$emit("uploading", true);
      let isFirstFileProcessed = false;
      forEach(queue, (file) => {
        this.initiateQueue(file, !isFirstFileProcessed);
        isFirstFileProcessed = true; 
      });
    },
    initiateQueue(file,isUpdateCaseMetaData) {
      if (!file) return;
      this.updateFileData(file.id, {
        fileStatus: 0,
        dataStatus: 0,
        inQueue: false,
      });
      this.uploadFile(file.id,isUpdateCaseMetaData);
      const index = findIndex(this.queue, (item) => item.id === file.id);
      this.queue.splice(index, 1);
    },
    onFileProgress(id, data) {
      this.updateFileData(id, data);
    },
    onDataProgress(id, data) {
      this.updateFileData(id, data);
    },
    onFileCancel(id, request) {
      this.updateFileData(id, {
        request,
      });
    },
    uploadSlide(fileData,isUpdateCaseMetaData) {
      const uri = `https://${this.$tenantData.aName}.blob.core.windows.net`;
      const extension = fileData.data.fileExtension
        ? `.${fileData.data.fileExtension}`
        : "";
      const filePrefix = `${new Date().getTime()}${this.$tenantData.id}${
        this.$userData.id
      }`;
      const fileName = `${filePrefix}${extension}`;
      const thumbnailPath = `${filePrefix}_thumbnail.png`;
      const payload = {
        uri,
        sasToken: this.sasToken,
        file: fileData.file,
        data: {
          ...fileData.data,
          adminStatus: this.$canAccess("myslidebox", "autoApprove")
            ? SLIDE_REQUEST_APPROVED
            : fileData.data.adminStatus,
        },
        id: fileData.id,
        container: this.$tenantData.cName,
        blobname: fileName,
        path: `https://${this.$tenantData.aName}.blob.core.windows.net/${this.$tenantData.cName}/${fileName}`,
        thumbnailPath: `https://${this.$tenantData.aName}.blob.core.windows.net/${this.$tenantData.cName}/${thumbnailPath}`,
        onFileProgress: this.onFileProgress,
        onDataProgress: this.onDataProgress,
        onFileCancel: this.onFileCancel,
        folderData: fileData.folderData,
        cancelToken: fileData.cancelToken,
        isUpdateCaseMetaData,
      };
      slideUploader(payload).then(
        (response) => {
          const data = this.updateFileData(response.id, {
            data: response.data,
            success: true,
            inQueue: false,
          });
          //intialize new metadata to insert in globalrepo db
          const metadata = fileData.metadata;
          metadata.slideRefId = response.data.id; //set new inserted slide id
          metadata.folderId=fileData.folderData.id;  //set folder id
          metadata.isUpdateCaseMetaData = isUpdateCaseMetaData;
          //add metadata in globalrepo database
          this.$api.addMetadataFact(metadata).then(
            () => {
            },
            (error) => {
              this.$handleError(error);
            }
          );
          this.$emit("uploading", this.uploadingFiles.length > 0);
          const folderChange = getOnFolderChange();
          if (
            folderChange &&
            data.folderData.id === Number(fileData.folderData.id)
          ) {
            folderChange(data.folderData,true);
          }
          this.initiateQueue(this.queue[0], false); 
        },
        (error) => {
          this.uploadCrash(payload.id, error);
        }
      );
    },
    resetStorageInfo() {
      let size = 0;
      forEach(this.fileData, (item) => {
        if (item.success) return;
        if (item.request) {
          item.terminated = true; //eslint-disable-line
          if (typeof item.request === "function") item.request();
        }
        size += item.data.fileSize;
      });
      this.$updateSubscriptionStorageData(this.$storageConsumed - size);
    },
    updateFileData(id, updatedFile) {
      const index = this.getFileIndex(id);
      if (index === -1) return null;
      const file = assign(this.fileData[index], updatedFile);
      this.fileData.splice(index, 1, file);
      return file;
    },
    uploadFile(id,isUpdateCaseMetaData) {
      this.uploadSlide(this.getFileData(id),isUpdateCaseMetaData);
    },
    closeUploader() {
      if (this.uploadingFiles.length <= 0) {
        this.resetLoader();
      } else {
        this.$deleteConfirmation(
          this.$t("slidebox.onGoingUploadSlidesMsg"),
          () => {
            this.resetLoader();
          }
        );
      }
    },
    resetLoader() {
      this.resetStorageInfo();
      this.sasToken = null;
      this.fileData = [];
      window.removeEventListener("beforeunload", this.onPageReload);
    },
    updateSlideStatus(data, cb) {
      this.$api.updateFileFact(data).then(
        (response) => {
          if (!this.$handleResponse(response) || !cb) return;
          cb();
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    uploadCrash(id, error) {
      this.$handleError(JSON.parse(error.data));
      this.updateFileData(id, {
        fileStatus: -1,
        dataStatus: -1,
        request: null,
        serverFail: true,
        inQueue: false,
      });
      this.$emit("uploading", this.uploadingFiles.length > 0);
      this.initiateQueue(this.queue[0]);
      if (this.$gtag && this.$gtag.event) {
        this.$gtag.event("error", {
          event_category: "slidebox",
          event_label: "upload-failed",
          value: error,
        });
      }
    },
    abortSingleFile(id) {
      const fileObj = this.getFileData(id);
      if (
        (fileObj.fileStatus < 100 && fileObj.fileStatus !== -1) ||
        (fileObj.dataStatus < 100 && fileObj.dataStatus !== -1) ||
        fileObj.inQueue
      ) {
        remove(this.queue, (item) => item.id === id);
        if (fileObj.request && typeof fileObj.request === "function") {
          fileObj.request();
        }
        this.updateFileData(id, {
          fileStatus: -1,
          dataStatus: -1,
          request: null,
          terminated: true,
          time: 0,
          loaded: 0,
          inQueue: false,
          cancelToken: { cancel: true },
        });
        this.initiateQueue(this.queue[0]);
      }
    },
  },
};
</script>
<style>
.upload-loader {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 25% !important;
}
</style>
<style scoped lang="scss">
.card-header {
  padding: 5px;
  background-color: #333;
  color: #ffff;
}
.card-body {
  padding: 5px;
}
.upload-container {
  max-height: 9rem;
  overflow-y: auto;
}
.zIndex {
  z-index: 1; //this is added to make the loader view on top of pagination active item
}
</style>
