import axios from 'axios';
import { $session } from '@/helpers';
import urls from './urls';
import { getUserUrl } from './api-services';
import { getGLobalRepoUrl } from "./api-services";

export const inviteUserFact = (data, type, notify) => {
  if (type === 'invite') {
    return axios.post(getUserUrl(urls.inviteuser), data, {
      params: {
        notify,
      },
    });
  }
  return axios.put(getUserUrl(urls.inviteuser), data);
};

export const getUserRolesFact = () => axios.get(getUserUrl(urls.userRole));

export const getUsersForAdminFact = params => axios.get(getGLobalRepoUrl(urls.getAllUsers), {
  params,
});

export const getExportUserDataFact = (params) => axios.get(getGLobalRepoUrl(`${urls.user}${urls.getExportData}`), {
  params,
});

export const getUsersFact = params => axios
  .get(getGLobalRepoUrl(urls.getUsers), {
    params,
  })
  .then(_response => {
    const users = _response.data.payload.content.map(item => item.user);
    const response = _response;
    response.data.payload.content = users;
    return response;
  });

export const updateUserFact = data => axios.put(getGLobalRepoUrl(`${urls.update}${urls.admin}${urls.user}`), data);

export const updateUserProfileFact = data => axios.put(getUserUrl('/update/user'), data);

export const getUserStatusFact = token => {
  const tenantData = $session.getTenantData();
  return axios.get(getUserUrl(`${urls.user}${urls.status}`), {
    headers: {
      authorization: token,
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const getUserFact = id => axios.get(getUserUrl(`${urls.user}/${id}`));

export const getUserPasswordFact = id => axios.post(
  getUserUrl(`${urls.reset}/${urls.byTenantAdmin}`),
  {},
  {
    params: {
      id,
    },
  },
);

export const getUserByTokenFact = authorization => axios.get(getUserUrl(urls.userByToken), {
  headers: {
    authorization,
  },
});

export const resendOtpFact = data => {
  const tenantData = $session.getTenantData();
  return axios.post(getUserUrl(`${urls.auth}${urls.resendOtp}`), data, {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const getQRCodeFact = (data, type, isPublic) => axios.post(getUserUrl(`${urls.publicUserAccess}/${type}`), data, {
  params: {
    type: isPublic ? 'public' : 'private',
  },
});


export const getPublicUrlFact = shortCode => {
  const tenantData = $session.getTenantData();
  return axios.get(getUserUrl(`${urls.shorturl}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params: { shortCode },
  });
};

export const shareGroupResource = (apiType, config) => {
  switch (apiType) {
    case 'GET':
      return axios.get(getUserUrl(`${urls.shareGroup}/${config.path.id}`));
    case 'GET-ALL':
      return axios.get(getUserUrl(`${urls.shareGroup}${urls.all}`), {
        params: config,
      });
    case 'POST':
      return axios.post(getUserUrl(`${urls.shareGroup}`), config.data);
    case 'ARCHIVE':
      return axios.post(
        getUserUrl(`${urls.shareGroup}${urls.archive}/${config.path.id}`),
        config.data,
      );
    case 'COPY_ANNOTATIONS':
      return axios.post(
        getUserUrl(
          `${urls.shareGroup}${urls.archive}${urls.annotations}${urls.copy}/${config.path.id}`,
        ),
        config.data,
      );
    case 'PUT':
      return axios.put(getUserUrl(`${urls.shareGroup}`), config.data);
    case 'DELETE':
      return axios.delete(getUserUrl(`${urls.shareGroup}`), { data: config });
    default:
  }
  return false;
};

export const getProfileMetadataFact = () => axios.get(getUserUrl(`${urls.profile}${urls.metadata}`));

export const uploadProfileLogoFact = logoData => axios.patch(getUserUrl('/user/logo/upload'), logoData);

export const inviteUserDetailsFact = (data) => {
  return axios.post(getGLobalRepoUrl(urls.inviteuser), data);
};

export const getUserDetailFact = id => axios.get(getGLobalRepoUrl(`${urls.inviteuser + urls.user}/${id}`));

export const updateUserDetailsFact = data => axios.put(getGLobalRepoUrl(`${urls.inviteuser}`), data);

export const saveGuestDetailsFact = (data, link) => {
  const tenantData =$session.getTenantData();
  return axios.post(getGLobalRepoUrl(urls.saveguest), data, {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params: {
      link,
    },
  });
};

export const getGuestDetailByIdFact = (id, link) =>
{
    const tenantData =$session.getTenantData();
    return axios.get(getGLobalRepoUrl(`${urls.guest}/${id}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params: {
      link,
    },
  });
}

export const getGuestsFact = params => axios.get(getGLobalRepoUrl(urls.getGuests),
  {
    params,
  });


export const updateGuestRequestFact = (id, type) => axios.put(getGLobalRepoUrl(`${urls.guest}/${id}`), type, {
  params: {
    type,
  },
});

export const getAllUserDetailFact = () => axios.get(getGLobalRepoUrl(urls.userAll));

export const removeBulkUsers = data => axios.delete(getUserUrl('/inactive/bulk'), { data });

export const getMetaDataListFact = (params, data) => 
{
  const tenantData = $session.getTenantData();
  return axios.post(getGLobalRepoUrl(`/${urls.searchMetaData}`), data,
  {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params
  });
}

export const sendEmailFact = (data) => {
  const tenantData = $session.getTenantData();
  return axios.post(getGLobalRepoUrl(urls.contactUs), data, {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
};


export const getSubscriptionsFact = params => axios.get(getGLobalRepoUrl(urls.getSubscriptions),
  {
    params,
  });

export const deleteSubscriptionsFact = id => axios.delete(getGLobalRepoUrl(`${urls.subscription}/${id}`));

export const saveSubscriptionFact = (data) => {
  return axios.post(getGLobalRepoUrl(urls.subscription), data, {
  });
};

export const updateSubscriptionFact = (data) => {
  return axios.put(getGLobalRepoUrl(urls.subscription), data, {
  });
};

export const getEditorRegionListFact = (params) =>
{
  const tenantData = $session.getTenantData();
  return axios.get(getGLobalRepoUrl(`/${urls.userListData}`),
  {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params
  });
}
  
export const updateUserDetailFact = (data) => {
  return axios.put(getGLobalRepoUrl(urls.updateUser), data, {
  });
};

  export const sendEmailCorrespondenceFact = (data) => {
    const tenantData = $session.getTenantData();
    return axios.post(getGLobalRepoUrl(urls.submitCorrespondence), data, {
      headers: {
        'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
      }
    });
  };
  
  export const resendInvitationFact = (data) => {
    return axios.put(getGLobalRepoUrl(urls.inviteuser + urls.resend), data);
  };

export const getAllEditorByTumorSiteFact = id =>
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.user}${urls.allEditorByTumorSite}/${id}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}
export const getAllChairPersonUsersFact = () =>
{  
  return axios.get(getGLobalRepoUrl(`/${urls.chairpersonListData}`));  
}

export const getAllEditorListFact = regionid => axios.get(getGLobalRepoUrl(`${urls.user}${urls.allEditorList}/${regionid}`));
export const getUserDetailsByTumorSiteact = id => axios.get(getGLobalRepoUrl(`${urls.user}${urls.userDetailsByTumorSite}/${id}`));


export const getTermsDetailsByUserIdFact = token => {
  const tenantData = $session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.user}${urls.terms}`), {
    headers: {
      authorization: token,
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const updateTermsFact = token => {
  const tenantData = $session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.user}${urls.terms}/${urls.update}`), {
    headers: {
      authorization: token,
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const processExcelFact = (token) => {
  return axios.get(getGLobalRepoUrl(`/process-excel/${token}`));
};


