import AzureStorage from '@/plugins/azure-storage-blob.min';

const onCancel = (token, id, cb) => ({
  handle: (requestOptions, next) => {
    if (token.cancel) {
      cb(id);
      return;
    }
    if (next) {
      next(requestOptions, (returnObject, finalCallback, nextPostCallback) => {
        if (token.cancel) {
          cb(id);
          return;
        }
        if (nextPostCallback) {
          nextPostCallback(returnObject);
        } else if (finalCallback) {
          finalCallback(returnObject);
        }
      });
    }
  },
});

export default {
  getRetryOptions: () => new AzureStorage.LinearRetryPolicyFilter(), //eslint-disable-line
  createBlobServiceWithSas: (blobUri, sasToken) => AzureStorage.createBlobServiceWithSas(blobUri, sasToken), //eslint-disable-line
  createBlobService: ({
    blobUri, sasToken, onFileCancel, blockSize, cName, blobName, id,
  }) => {
    let service = AzureStorage.createBlobServiceWithSas(blobUri, sasToken); //eslint-disable-line
    const retryOperations = new AzureStorage.LinearRetryPolicyFilter(); //eslint-disable-line
    const cancelToken = {
      cancel: false,
    };
    if (onFileCancel) {
      service = service.withFilter(onCancel(cancelToken, id, onFileCancel));
      onFileCancel(id, () => {
        cancelToken.cancel = true;
        service.abortCopyBlob(cName, blobName, {}, () => {});
        service = null;
      });
    }
    service = service.withFilter(retryOperations);
    service.singleBlobPutThresholdInBytes = blockSize;
    return service;
  },
  getBlockSize: size => (size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512),
  start: (service, {
    cName, blobName, blockSize, file, onError, onSuccess, onProgress,
  }) => {
    const progressService = service.createBlockBlobFromBrowserFile(
      cName,
      blobName,
      file,
      {
        blockSize,
      },
      error => {
        if (error) {
          onError(error);
        } else {
          onSuccess();
        }
      },
    );
    progressService.on('progress', () => {
      const process = progressService.getCompletePercent();
      if (!onProgress) return;
      const metaData = {
        fileStatus: Number(process.replace('%', '')),
        loaded: progressService.getCompleteSize(),
        speed: progressService.getSpeed(),
      };
      onProgress(metaData);
    });
    return progressService;
  },
};
