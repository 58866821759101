import state from './dashboard-state';
import mutations from './dashboard-mutations';
import getters from './dashboard-getters';
import actions from './dashboard-actions';

const home = {
  state,
  mutations,
  actions,
  getters,
};

export default home;
