// Users
export const USER_ACTIVE = 'Active';
export const USER_INACTIVE = 'InActive';
export const USER_PENDING = 'Pending';
export const USER_INVITED = 'Invited';

export const USER_LINKS = {
  FACEBOOK: 'FACEBOOK',
  LINKEDIN: 'LINKEDIN',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
};
