import Vue from 'vue';
import Vuex from 'vuex';

// modules
import auth from './modules/auth-store';
import dashboard from './modules/dashboard/dashboard-state-index';
import slideBox from './modules/slidebox-store';

Vue.use(Vuex);

const createStore = () => new Vuex.Store({
  modules: {
    auth,
    dashboard,
    slideBox,
  },
});

export default createStore();
