import { assign } from 'lodash';
import {
  formatFileSize,
  getSubscriptionVariants,
} from '@/helpers/utilities';

export default {
  computed: {
    $storageConsumed() {
      if (!this.$subscriptionData) return 0;
      return Number(this.$subscriptionData.totalSizeConsumed || 0);
    },
    $storageAvailable() {
      if (!this.$subscriptionData) return 0;
      return Number(
        this.$subscriptionData.size
          - this.$subscriptionData.totalSizeConsumed,
      );
    },
    $totalStorage() {
      if (!this.$subscriptionData) return 0;
      const { totalSizeAllocated } = this.$subscriptionData || {};
      const { size } = this.$subscriptionPlan || {};
      return Number(totalSizeAllocated || size);
    },
    $stopUpload() {
      return this.$isStorageExceeded;
    },
    $isStorageExceeded() {
      if (!this.$isPublicTenant) return false;
      if (!this.$subscriptionData) return true;
      if (this.$subscriptionPlan.isUnlimited) return false;
      return this.$storageConsumed >= this.$totalStorage;
    },
    $storageLabel() {
      return {
        total: formatFileSize(this.$totalStorage, 'none'),
        consumed: formatFileSize(this.$storageConsumed),
        available: formatFileSize(this.$storageAvailable),
      };
    },
    $subscriptionPlan() {
      if (!this.$subscriptionData) return 0;
      const subscriptionPlan = this.$subscriptionData;
      const { label, variant } = getSubscriptionVariants(
        subscriptionPlan.label,
      );
      const isUnlimited = subscriptionPlan.label === 'UNLIMITED';
      const isFree = subscriptionPlan.label === 'FREE';
      return {
        ...subscriptionPlan,
        isFree,
        isUnlimited,
        label,
        variant,
        sizeLabel: formatFileSize(subscriptionPlan.size),
      };
    },
    $showSubscription() {
      return (this.$subscriptionData
        && !this.$subscriptionPlan.isUnlimited
      );
    },
  },
  methods: {
    $updateSubscriptionStorageData(totalSizeConsumed) {
      const data = this.$session.updateSessionData({
        subscriberInformation: assign(this.$subscriptionData, {
          totalSizeConsumed,
        }),
      });
      this.sessionDataAction(data);
    },
    $checkStorage(fileSize) {
      return (
        !this.$subscriptionPlan.isUnlimited
        && fileSize > this.$totalStorage
      );
    },
  },
};
