import { startCase } from 'lodash';
import {
  validateLowerCase, validateUpperCase, validateNumberCase, validateSpecialCase, isEmailCase, isTwitterCase, isFacebookCase, isLinkedinCase,
} from './validations';
import i18n from './internationalization';

export default [
  {
    key: 'one-lowercase',
    validation: {
      getMessage: () => i18n.t('common.lowercaseValidationMsg'),
      validate: value => validateLowerCase(value),
    },
  },
  {
    key: 'one-uppercase',
    validation: {
      getMessage: () => i18n.t('common.uppercaseValidationMsg'),
      validate: value => validateUpperCase(value),
    },
  },
  {
    key: 'one-numbercase',
    validation: {
      getMessage: () => i18n.t('common.numbercaseValidationMsg'),
      validate: value => validateNumberCase(value),
    },
  },
  {
    key: 'one-specialcase',
    validation: {
      getMessage: () => i18n.t('common.specialcaseValidationMsg'),
      validate: value => validateSpecialCase(value),
    },
  },
  {
    key: 'valid-email',
    validation: {
      getMessage: field => i18n.t('common.emailValidationMsg', { field: startCase(field) }),
      validate: value => isEmailCase(value),
    },
  },
  {
    key: 'valid-twitter-url',
    validation: {
      getMessage: () => i18n.t('common.urlValidationMsg'),
      validate: value => isTwitterCase(value),
    },
  },
  {
    key: 'valid-facebook-url',
    validation: {
      getMessage: () => i18n.t('common.urlValidationMsg'),
      validate: value => isFacebookCase(value),
    },
  },
  {
    key: 'valid-linkedin-url',
    validation: {
      getMessage: () => i18n.t('common.urlValidationMsg'),
      validate: value => isLinkedinCase(value),
    },
  },
];
