import { render, staticRenderFns } from "./no-data-found.vue?vue&type=template&id=dd369840&scoped=true&"
import script from "./no-data-found.vue?vue&type=script&lang=js&"
export * from "./no-data-found.vue?vue&type=script&lang=js&"
import style0 from "./no-data-found.vue?vue&type=style&index=0&id=dd369840&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd369840",
  null
  
)

export default component.exports