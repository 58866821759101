import axios from 'axios';
import _ from 'lodash';
import { $i18n, $session } from '@/helpers';
import * as login from './api-login';
import * as users from './api-users';
import * as tenants from './api-tenants';
import * as slidebox from './api-slidebox';
import * as metadata from './api-metadata';
import * as metadatalist from './api-metadatalist';
import * as cases from './api-case';
import * as fileUpload from './api-file-upload';
import * as profile from './api-profile';

const api = _.assign(
    login,
    users,
    tenants,
    slidebox,
    metadata,
    metadatalist,
    cases,
    fileUpload,
    profile
);

export const handleError = (
    error,
    { onBadRequest, onUnauthorize, onError },
) => {
    const hasError = typeof error === 'object' && error.response && error.response
        ? error.response
        : null;
    let message = null;
    let status = 0;
    if (hasError && hasError.data) {
        message = hasError.data.message || hasError.data.statusMessage;
        status = Number(hasError.data.status || hasError.data.statusMessage);
    } else if (hasError && hasError.status && hasError.message) {
        message = hasError.message; // eslint-disable-line
        status = Number(hasError.status);
    }
    if ([401, 403].includes(status)) {
        if (onUnauthorize) onUnauthorize();
    } else if ([400].includes(status)) {
        if (onBadRequest) {
            onBadRequest(message || $i18n.t('common.unableToProcess'));
        }
    } else {
        const errorMessage = error && error.message;
        if (onError) {
            onError(message || errorMessage || $i18n.t('notes.serverIssue'));
        }
    }
};

export const handleResponse = (response, onError) => {
    const hasResponse = response && response.data;
    const statusCheck = hasResponse && response.data.status;
    const responseData = statusCheck ? response.data : null;
    if (responseData) return responseData;
    const message = hasResponse && response.data.statusMessage
        ? response.data.statusMessage
        : $i18n.t('notes.serverIssue');
    if (onError) onError(message);
    return false;
};

// Axios Interceptor functions
const requestIncerceptor = data => {
    const configuration = data;
    const sessionToken = $session.getToken();
    const hasTenantId = configuration.headers && configuration.headers['X-TenantID'];
    const hasAuthorization = configuration.headers && configuration.headers.authorization;
    if (sessionToken && !hasTenantId && !hasAuthorization) {
        configuration.headers = _.assign(configuration.headers, {
            authorization: sessionToken,
        });
    } else if (hasAuthorization && configuration.headers.authorization === 'custom') {
        configuration.headers = _.omit(configuration.headers, 'authorization');
    }
    return configuration;
};

const responseInterceptor = response => response;

// Axios Interceptors

export const initializeAxios = () => {
    // For Every ajax call request below function will be called
    axios.interceptors.request.use(
        request => requestIncerceptor(request),
        error => Promise.reject(error),
    );

    // For Every ajax call response below function will be called
    axios.interceptors.response.use(
        response => responseInterceptor(response),
        error => Promise.reject(error),
    );

    axios.defaults.baseURL = process.env.VUE_APP_URL; //eslint-disable-line
};

export default api;
