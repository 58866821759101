const SlideSearchModule = () => import("./SlideModule.vue");
const TumorCollectionSearchComponent = () => import("./components/Tumor-Collection-Search.vue");
const SubmitCaseComponent = () => import("./components/Submit-Case.vue");
const SearchResultComponent = () => import("./components/SearchResult.vue");
const SelectViewSpecificCohortsComponent = () => import("./components/SelectViewSpecificCohorts.vue");
const SelectViewMicroscopicFeatureComponent = () => import("./components/SelectViewMicroscopicFeature.vue");
const DiagnosisComponent = () => import("./components/Diagnosis.vue");

export default {
  path: "/",
  component: SlideSearchModule,
  name:"Search",
  children: [
    {
      path: "search/:tumorId",
      component: TumorCollectionSearchComponent,
      name: "Search",
    },
    {
      path: "submit-case/:tumorId",
      component: SubmitCaseComponent,
      name: "home",
    },
    {
      path: "search-result/:tumorId",
      component: SearchResultComponent,
      name: "Search-Result",
    },
    {
      path: "search-cohot/:tumorId",
      component: SelectViewSpecificCohortsComponent,
      name: "Search-Cohot",
    },    
    {
      path: "search-microscopic/:tumorId",
      component: SelectViewMicroscopicFeatureComponent,
      name: "Search-Microscopic",
    },
    {
      path: "thyroid",
      component: DiagnosisComponent,
      name: "Thyroid",
    },        
    {
      path: "/",
      redirect: {
        name: "Search",
      },
    },
  ],
};
