export const BASIC_DETAILS = [
  {
    type: 'text',
    placeholder: 'First Name',
    validate: 'required|max:255|min:2',
    key: 'firstName',
    label: 'First Name',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
  {
    type: 'text',
    placeholder: 'Last Name',
    validate: 'required|max:255',
    key: 'lastName',
    label: 'Last Name',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
  {
    type: 'text',
    placeholder: 'Region',
    validate: '',
    key: 'region',
    label: 'Region',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
    disabled: true
  },
  {
    type: 'select',
    placeholder: 'Select Country',
    validate: 'required',
    key: 'country',
    label: 'Country',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
  {
    type: 'select',
    placeholder: 'Select State',
    validate: '',
    key: 'state',
    label: 'State',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
  {
    type: 'text',
    placeholder: 'City Name',
    validate: 'required|max:255',
    key: 'cityName',
    label: 'City',
    class: 'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12',
  }
];

export const WORK_DETAILS = [
  {
    type: 'multi-select',
    placeholder: 'Academic Degree/Credentials',
    validate: '',
    key: 'qualification',
    label: 'Academic Degree/Credentials',
    class: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
    options: [],
  },
  {
    type: 'text',
    placeholder: 'Institution or Affiliation',
    validate: '',
    key: 'institution',
    label: 'Institution or Affiliation or Employer',
    class: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
  {
    type: 'text',
    placeholder: 'role',
    key: 'jobRole',
    label: 'Job Title/Role',
    validate: '',
    class: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
  },
];
