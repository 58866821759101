// // Plugins
import BootstrapVue from 'bootstrap-vue';
import fullscreen from 'vue-fullscreen';
import ToggleSwitch from 'vue-js-toggle-button';
import Vue2Filters from 'vue2-filters';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';
import veeValidate, { Validator } from 'vee-validate';
import { forEach, assign } from 'lodash';
import VueScrollTo from 'vue-scrollto';
import VueMoment from 'vue-moment';
import VueMq from 'vue-mq';
import jQuery from 'jquery';
import Sortable, { MultiDrag } from 'sortablejs';
import api from '@/api';
import * as icons from '@/assets/icons/icons';
import * as helpers from '@/helpers';
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import VueSimpleAlert from "vue-simple-alert";
import VueCookies from 'vue-cookies';

// Global Components
import WTRImage from '@/components/common/wtr-img.vue';
import WTRLoader from '@/components/common/wtr-loader.vue';
import WTRBtn from '@/components/common/wtr-btn.vue';

// Global Mixins
import mixin from '@/mixins/wtr-mixin';

// Global Directives
import directives from '@/directives/directives';

import vueValidations from '@/helpers/vue-validations';
import validatorCustomMsgs from '@/models/custom-messages.json';
import './jquery.watermark';
import Element from 'element-ui';
import VueYoutube from 'vue-youtube';

const validatorConfig = {
  fieldsBagName: '$fields',
  validity: true,
};
const mediaConfig = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

VueClipboard.config.autoSetContainer = true;
window.$ = jQuery;
Sortable.mount(new MultiDrag());

// Validator custom messages
Validator.localize('en', validatorCustomMsgs);
forEach(vueValidations, item => {
  Validator.extend(item.key, item.validation);
});

export default {
  install(Vue) {
    Vue.use(BootstrapVue);
    Vue.use(fullscreen);
    Vue.use(ToggleSwitch);
    Vue.use(VueMq, mediaConfig);
    Vue.use(veeValidate, validatorConfig);
    Vue.use(Vue2Filters);
    Vue.use(VueScrollTo);
    Vue.use(VueMoment);
    Vue.use(VueClipboard);
    Vue.use(PortalVue);
    Vue.use(Element)
    Vue.mixin(mixin);
    Vue.component('wtr-img', WTRImage);
    Vue.component('wtr-loader', WTRLoader);
    Vue.component('wtr-btn', WTRBtn);
    Vue.use(PrimeVue);
    Vue.component('p-dropdown', Dropdown);
    Vue.use(VueYoutube);
    Vue.use(VueCookies);

    Vue.prototype = assign( // eslint-disable-line
      Vue.prototype,
      helpers,
      { $api: api },
      { $icons: icons },
    );
    forEach(directives, item => {
      Vue.directive(item.name, item.data);
    });

    Vue.use(VueSimpleAlert);
  },
};
