import Vue from "vue";
import VueRouter from "vue-router";
import { $session } from '@/helpers';
import store from '@/vuex/store';
import AuthRouter from "../admin-side/auth/auth-router";
import AdminRouter from "../admin-side/admin-layout/admin-router";
import UserRouter from "../user-side/user-layout/user-router";
import _ from 'lodash';

Vue.use(VueRouter);

const routes = [
  AuthRouter,
  AdminRouter,
  UserRouter,
  {
    path: '/display/:id/:caseDetailId/:movedToEditorialBox/:movedToSlideLibrary/:sharedToEditor/:isPublicSite/:caseSlideIds/:selectedFolderView/:selectedFolderUserRole/:selectedFolderUserRegion/:selectedCaseRegion',
    name: 'display-viewer',
    component: () => import('../views/viewer/display-viewer.vue'),
  },
  {
    path: '*',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/terms/terms.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/logout.vue'),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Router Functions
const beforeEveryRoute = (to, from, next) => {
  store.dispatch('$loaderAction', true);
  const token = $session.getToken();
  const validRoute = $session.openRoutes.includes(to.name);
  if(to.params.isPublicSite === undefined)
  {
    to.params.isPublicSite = $session.encryptData(false);
  }  
  const isPublicSite = $session.decryptData(to.params.isPublicSite);
  if (validRoute) {
    next();
  } else if (!token) {
    const fromLogout = from.name === 'logout';
    if(
       to.name === 'home' || to.name === 'email-verification' || to.name === 'create-password' ||
       to.name === 'verify-otp'  || to.name === 'forgot-password' || to.name === 'about' ||
       to.name === 'news-details' || to.name === 'news-all'  || to.name === 'mission-and-overview'  || to.name === 'financials' || to.name === 'our-sponsors' ||
       to.name === 'faqs' || to.name === 'contact-us' || to.name === 'terms-of-use' || to.name === 'leadership' || to.name === 'tss-experts' || to.name === 'sitemap' ||
       to.name === 'news-and-events' || to.name === 'news-and-events-details' || to.name === 'donate' || to.name === 'video-series'|| to.name === 'brochure' || to.name === 'video-series-details' ||
       to.name === 'Search' ||  to.name === 'Search-Result' || to.name === 'Search-Cohot' || to.name === 'Search-Microscopic' || to.name === 'Submit Case' 
       || to.name === 'Thyroid' || to.name === 'terms' || (to.name === 'display-viewer' && isPublicSite))
    {
      next();
      return;
    }
    next({
      name: 'login',
      query: !fromLogout
        ? {
          redirectTo: JSON.stringify({
            name: to.name,
            params: _.mapValues(to.params, item => {
              if (to.name.includes('subscription') && item.length < 50) return item; // userid is added from backend without encryption
              return $session.decryptData(item);
            }),
            query: to.query,
          }),
        }
        : '',
    });
  } else {
    next();
  }
};

const afterEveryRoute = () => {
  store.dispatch('$loaderAction', false);
};

// Routes configuration
router.beforeEach(beforeEveryRoute);
router.afterEach(afterEveryRoute);

export default router;
