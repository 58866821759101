const AdminModule = () => import("./AdminModule.vue");
import dashboard from '../dashboard/dashboard-router';
import slideBox from '../my-slide-box/slide-box-router';
import administration from '../administration/administration-router';
import slideLibrary from '../slide-library/slide-library-router';
import profile from '../profile/profile-router';

export default {
  path: "/",
  component: AdminModule,
  children: [
    dashboard,
    slideBox,
    administration,
    slideLibrary,
    profile
  ],
};
