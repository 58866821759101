<template>
  <div v-if="slideData">
    <div class="border-bottom py-20 mb-15">
      <b-row>
        <b-col
          xl="9"
          lg="9"
          md="9"
          sm="9"
          class="fs-16 fw-700 text-wrap text-break"
        >{{slideData.name}}</b-col>
        <b-col
          xl="3"
          lg="3"
          md="3"
          sm="3"
          class="text-right"
        >
          <b-btn
            aria-label="reset"
            aria-labelledby="reset"
            variant="link"
            size="lg"
            @click="$emit('close')"
          >
            <i class="fa fa-times"></i>
          </b-btn>
        </b-col>
      </b-row>
    </div>
    <b-row
      class="text-left"
      v-if="slideData.metadata"
    >
      <b-col class="col-12">
        <b-media class="fs-12">
          <wtr-img
            slot="aside"
            :src="$getSlideThumbnail(slideData).src"
            :altImage="$getSlideThumbnail(slideData).alt"
            :resourceId="slideData.resourceId"
            :containerName="slideData.containerName"
            width="100px"
            height="100px"
            alt="Thumbnail"
            aria-label="Thumbnail"
            arialabelledby="Thumbnail"
          />
          <div>
            <div  v-if="slideData.metadata.tumorSite" class="fs-14 fw-600">
              {{$t('common.tumorSite')}}:
              <b-badge class="fs-12"> {{slideData.metadata.tumorSite.name}}</b-badge>
            </div>
            <div v-if="slideData.metadata.tumorType" class="fs-14 fw-600">
              {{$t('common.tumorType')}}:
              {{slideData.metadata.tumorType.name}}
            </div>
            <div v-if="slideData.metadata.tumorSubType" class="fs-14 fw-600">
              {{$t('common.tumorSubType')}}:
              {{slideData.metadata.tumorSubType.name}}
            </div>
          </div>
          <div class="fs-14 fw-600 py-10">
            {{$t('common.slideType')}}:
            <b-badge
              :variant="getFileTypeClass(slideData.fileType).variant"
              class="fs-12"
            >{{getFileTypeClass(slideData.fileType).label}}</b-badge>
          </div>
        </b-media>
      </b-col>
      <b-col
        v-if="slideMetadata"
        class="mt-10"
      >
        <b-row class="border mx-0 px-0 py-10">
          <b-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            class="fs-14 fw-600"
          >
            <span>{{$getTitleCase($t('common.name'))}}</span>
          </b-col>
          <b-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            class="fs-14"
          >
            <div>{{slideData.metadata.name}}</div>
          </b-col>
        </b-row>
        <b-row class="border mx-0 px-0 py-10">
          <b-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            class="fs-14 fw-600"
          >
            <span>{{$getTitleCase($t('common.caseNumber'))}}</span>
          </b-col>
          <b-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            class="fs-14"
          >
            <div>{{slideData.metadata.systemCaseNumber?slideData.metadata.systemCaseNumber:slideData.metadata.caseNumber}}</div>
          </b-col>
        </b-row>
        <b-row class="border mx-0 px-0 py-10">
          <b-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            class="fs-14 fw-600"
          >
            <span>{{$getTitleCase($t('common.caseNotes'))}}</span>
          </b-col>
          <b-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            class="fs-14"
          >
            <div>{{slideData.metadata.caseNotes}}</div>
          </b-col>
        </b-row>
        <b-row
          class="border mx-0 px-0 py-10"
          v-for="(value, key)  in slideMetadata"
          :key="key"
        >
          <b-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            class="fs-14 fw-600"
          >
            <span>{{$getTitleCase(key)}}</span>
          </b-col>
          <b-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            class="fs-14"
          >
            <div>{{ displayValue(value) }}</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { omit } from "lodash";
import slideMixin from "../../mixins/slides-mixin";

export default {
  name: "slide-info",
  data() {
    return {};
  },
  props: {
    slideData: Object,
    hideDiagnosis: Boolean,
    metadata: Object,
  },
  computed: {
    slideMetadata() {
      var metadata = this.slideData.metadata;
      const unwantedKeys = [
        "id",
        "slideRefId",
        "systemCaseNumber",
        "contributorId",
        "editorId",
        "tumorSite",
        "tumorSiteId",
        "tumorType",
        "tumorTypeId",
        "tumorSubType",
        "tumorSubTypeId",
        "geographicRegionId",
        "tenantId",
        "folderId",
        "section",
        "name",
        "caseNumber",
        "caseNotes",
        "slide",
        "isMovedToEditorialBox",
        "isMovedToSlideLibrary",
        "isSharedToEditor",
        "isDelete",
        "isRecycle",        
      ];
      metadata = omit(metadata, unwantedKeys);
      return omit({
        ...metadata,
        stage: metadata.stage? metadata.stage.name:'',
        tCategory: metadata.tCategory? metadata.tCategory.name: '',
        nCategory: metadata.nCategory? metadata.nCategory.name: '',
        mCategory: metadata.mCategory? metadata.mCategory.name: '',
        cytology: metadata.cytology? metadata.cytology.name: '',
        environmentalSettings: metadata.environmentalSettings? this.getValueCascadeSelection(metadata.environmentalSettings): '',
        familialDiseases: metadata.familialDiseases? this.getValueCascadeSelection(metadata.familialDiseases): '',
        immunoHistoChemistries: metadata.immunoHistoChemistries? this.getValueCascadeSelection(metadata.immunoHistoChemistries): '',
        genetics: metadata.genetics? metadata.genetics.map(obj => obj.name).join('; '): '',
        geographicRegion: metadata.geographicRegion? metadata.geographicRegion.name:'',
        specificCohort: metadata.specificCohort? metadata.specificCohort.name:'',
      });
    },
  },
  methods: {
    getValueCascadeSelection(mainObj){
      return mainObj.filter(obj => obj.parentId === null)
        .map(objParent => {
            var fullString = mainObj.filter(objChild => objChild.parentId === objParent.id)
                .map(obj => obj.name).join('; ');

            if(fullString){
                return objParent.name + " (" + fullString + ")";
            }else{
                return objParent.name;
            }                
        }).join('; ');
    },
    displayValue(value) {
      if (typeof value === 'number')
      {
        return value === 0 ? 'Under 1' :  value === -1 ? 'Unknown' : value === 90 ? '89+' : value;
      }
      else if (typeof value === 'string')
      {
        return value ? value : '-';
      }
      else
      {
        return '-';
      }
    }
  },
  mixins: [slideMixin],
};
</script>
<style scoped lang="scss">
.card {
  .card-header {
    padding: 10px 10px;
  }
  .card-body {
    padding: 20px 10px;
  }
}
</style>
