import {
  osName,
  browserName,
  osVersion,
  browserVersion,
  isMobileOnly,
  isMobile,
  isSafari,
} from 'mobile-device-detect';

export default {
  os: osName.toLowerCase().split(' ').join(''),
  osClass: `os-${osName.toLowerCase().split(' ').join('')}`.split(' ').join('-'),
  browser: browserName.toLowerCase(),
  browserClass: `browser-${browserName.toLowerCase()}`.split(' ').join('-'),
  osVersion,
  browserVersion,
  isMobileOnly,
  isMobile,
  isSafari,
};
