import { filter } from 'lodash';

export default {
  getSidebar: state => state.sidebar,
  getCurrentSidebar: state => state.currentSidebar,
  getLoaderStatus: state => state.loader,
  getModalData: state => state.modalData,
  getSidebarRoutes: (state, store) => {
    let { routes } = state;
    const user = store.getUserData;
    routes = filter(routes, item => user && user.userAppAccess && user.userAppAccess[item.key]);
    return routes;
  },
  getLandingRoutes: (state, store) => filter(state.routes, item => {
    const user = store.getUserData;
    return user && user.userAppAccess && user.userAppAccess[item.key] && item.description;
    // return user && item.description;
  }),
  getCrumbs: state => state.crumbs,
  getDashboardHeight: state => state.dashboardHeight,
  getDashboardWidth: state => state.dashboardWidth,
};
