<template>
  <div class="my-slide-box">
    <div>
      <portal to="slidebox-sidebar" class="position-relative">
        <div v-if="foldersLoading" class="position-absolute d-flex justify-content-center py-20 w-100">
          <wtr-loader class="d-block" variant="primary" addText></wtr-loader>
        </div>
        <div :class="{ 'fetch-class': foldersLoading }">
          <folders
            v-if="folders"
            :selectedFolder="selectedFolder"
            :activeFolder="activeFolder"
            :foldersLoading="foldersLoading"
            :rootFolder="rootFolder"
            :folders="folders"
            :copiedFolder="copiedFolder"
            :disableActions="true"
            @selected="folderSelected"
            @cut-action="cutAction"
            @get-folders="getFolders()"
            @reset="resetFolders()"
            @temp-selected="selectedFolder = $event;"
          ></folders>
        </div>
      </portal>
      <b-card no-body hide-footer>
        <div slot="header" class="fs-16 text-light" style="padding: 6px 10px;">
          <div class="d-flex justify-content-between">
            <span>
              <breadcrumbs v-if="typeof crumbs === 'object'" :items="crumbs"/>
              <span v-else>{{crumbs}}</span>
            </span>
            <span v-if="totalCaseElements && !showCaseSlides">
              Total Cases: {{totalCaseElements }}
            </span>
            <span v-if="totalElements && showCaseSlides">
              Total Slides: {{totalElements }}
            </span>
          </div>
        </div>
        <b-card-body class="position-relative">
          <div v-if="dataLoading" class="position-absolute d-flex justify-content-center py-30 w-100">
            <wtr-loader class="d-block" variant="primary" addText></wtr-loader>
          </div>
          <slide-library-search 
            v-if="isPublicPrepublicationView"
            :canSelect="{
              select: false,
            }" 
            :paramsUtil="paramsUtil"
            :listLoading="listLoading"
            :allCases="allCases"
            :totalCasePages="totalCasePages"
            :totalCaseElements="totalCaseElements"
            :hideActions="false" 
            :showPublicSlides="false"
            :isMovedToEditorialBox="isMovedToEditorialBox"
            :isSharedToEditor="isSharedToEditor"
            :isMovedToSlideLibrary="isMovedToSlideLibrary"
            :folderData="folderData"
            @get-cases="getAllPublicBoxCases"
            @sort-cases="sortPublicBoxCases"
            @export-cases="getExportData"
          ></slide-library-search>
          <slide-box
            v-if="!isPublicPrepublicationView"
            :canUpload="true"
            :navigate="true"
            :recycle="isfolderDeleted"
            :listLoading="listLoading"
            :totalPages="totalPages"
            :totalElements="totalElements"
            :paramsUtil="paramsUtil"
            :selectedSlides="selectedSlides"
            :mapSelectedSlides="mapSelectedSlides"
            :slideData="slideData"
            :folderData="folderData"
            :slides="slides"
            :allCases="allCases"
            :totalCasePages="totalCasePages"
            :totalCaseElements="totalCaseElements"
            :isMovedToEditorialBox="isMovedToEditorialBox"
            :isSharedToEditor="isSharedToEditor"
            :isMovedToSlideLibrary="isMovedToSlideLibrary"
            @clear-selected="clearSelected"
            @get-slides="getSlides"
            @sort-slides="sortSlides"           
            @re-upload="reuploadSlide"
            @view-slide="viewSlide"
            @update-slides="updateSlideData"
            @update-slide-status="updateFileStatus"
            @get-cases="getCases"
            @sort-cases="sortCases"
            @view-case-slides="viewCaseSlides"
            @export-cases="getExportData"
          ></slide-box>
        </b-card-body>
      </b-card>
    </div>
    <div class="upload-loader zIndex" :style="{ width: getLoaderWidth }">
      <upload-loader
        @uploading="setSlidesUploadStatus"
        key="upload-slide-loader"
      ></upload-loader>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
// Mixins
import { FoldersMixin, SlideboxMixin } from "../../../mixins/mixins";
// Components
import BreadcrumbsComponent from "../../../components/common/breadcrumbs.vue";
import FoldersComponent from "../../my-slide-box/components/folders.vue";
import SlideBoxComponent from "../../my-slide-box/components/slide-box.vue";
import SlideLibrarySearch from '../../slide-library/components/slide-library-search.vue';
import * as roleTypes from "../../../constants/constants-role";
import uploadLoader from '../../my-slide-box/components/upload-loader.vue';
import { setIsUploading } from '../../../helpers/idle-timeout';

export default {
  name: "my-slide-box",
  data() {
    return {
      ...roleTypes,
      isSlideUploading: false,
      isFoldersLoaded: false,
      listLoading: false,
      dataLoading: false,
      paramsUtil: {},
    };
  },
  components: {
    "slide-box": SlideBoxComponent,
    'slide-library-search': SlideLibrarySearch,
    folders: FoldersComponent,
    breadcrumbs: BreadcrumbsComponent,
    'upload-loader': uploadLoader,
  },
  mixins: [FoldersMixin, SlideboxMixin],
  computed: {
    ...mapGetters({
      sidebar: "getSidebar",
      crumbs: "getCrumbs",
      selectedFolderView: "getSelectedFolderView",
    }),
    getLoaderWidth() {
      return '100%';
    },
    cardBodyStyles() {
      const height = this.$dashboardHeight - 60;
      return this.$getCardBodyStyle(height);
    },
    folderId() {
      return this.$pathParams.id || 0;
    },
    getSelectedCaseSlideIds()
    {
      return this.selectedCaseSlideIds;
    },
    isPublicPrepublicationView() {
            return (
                this.selectedFolderView == this.FOLDER_PUBLICBOX ||
                this.selectedFolderView == this.FOLDER_PREPUBLICATIONBOX
            );
    },    
    exportTSVFields() {
    return [
        {
          key: "caseNumber",
        },
        {
          key: "contributor",
        },
        {
          key: "tumorSite",
        },
        {
          key: "tumorSubType",
        },
        {
          key: "tumorType",
        },
        {
          key: "specificCohort",
        },    
        {
          key: "geographicRegion",
        },
        {
          key: "editor",
        },    
        {
          key: "sex",
        },
        {
          key: "age",
        },
        {
          key: "caseNotes",
        },    
        {
          key: "country",
        },
        {
          key: "state",
        },
        {
          key: "cytology",
        },
        {
          key: "environmentalSetting",
        },
        {
          key: "familialDisease",
        },
        {
          key: "genetic",
        },
        {
          key: "immunohistochemistry",
        },
        
        {
          key: "tCategory",
        },
        {
          key: "mCategory",
        },
        {
          key: "nCategory",
        },
        {
          key: "reviewStatus",
        },   
        {
          key: "stage",
        },
        {
          key: "state",
        },
        {
          key: "tumorSize",
        }           
     ];
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    if(!this.listLoading){
      this.dataLoading = false;
    }
  },
  methods: {
    ...mapActions([
      "renderSidebarAction",
      "sidebarAction",
    ]),
    init() {
      if (this.$canAccess("myslidebox", "canAccessModule")) {
        this.renderSidebarAction("slide-box-sidebar");
        this.sidebarAction({ show: true, full: true });
        this.$setHeaderAction(this.$t("slidebox.slideBox"));
        this.getFolders();
      } else {
        this.$changeRoute(this.$redirectTo());
      }
    },
    getFolders() {
      const cb = this.folderSelected;
      const options = {
        activeFolderID: this.folderId,
        hideRecycleBin: false,
        hideSharedByOthers: false,
        userId: localStorage.getItem("userId")
      };
      this.getAllFolders(options, cb);
    },
    folderSelected(node) {
      this.dataLoading = true;
      
      if (this.$routeName !== "my-slide-box") return;
      this.$changeRoute({
        name: "my-slide-box",
        params: {
          id: node.id,
        },
        query: node.id
          ? null
          : {
              email: node.email,
            },
      });
      //to open first folder first time for advisor and contributor
      if(!this.isFoldersLoaded &&this.folders[0]!=null &&this.folders[0].children[0]!=null)   
      {
        const loginUserRole = localStorage.getItem("userRole");
        if(loginUserRole === this.ROLE_ADVISOR ||loginUserRole === this.ROLE_GUEST)
        {
          this.folders[0].opened=true;
          node=this.folders[0].children[0];
          this.isFoldersLoaded=true;
        }
      }
      this.activeFolder = node;
      this.$setHeaderAction(node.name);
      this.folderChange(node);
    },
    async getExportData() {
      this.listLoading = true;     
        // this.paramsUtil = {
        //   movedToSlideLibrary: this.isMovedToSlideLibrary,
        //   movedToPrePublication: this.isMovedToPrePublication,
        //   movedToEditorialBox: this.isMovedToEditorialBox,
        // };      
      
      this.$api.getExportDataFact(this.paramsUtil).then(
        (response) => {
          this.listLoading = false;          
          if(response.data.payload!=null)
          {
            this.$toastr("success", response.data.payload); 
          }
          else {
            //this.exportData = null;
            this.$toastr("error", "Something went wrong while exporting TSV");
          }
        },
        (error) => {
          this.listLoading = false;
          this.$handleError(error);
        }
      );
    },
    // async handleExport() {
    //   if(this.exportData!=null)
    //     {
    //       let tsvContent = '';

    //     // Get the maximum width for each column
    //     const columnWidths = this.exportTSVFields.filter(field => field.key !== 'actions').map(field => field.key.length);
    //     this.exportData.forEach(caseItem => {
    //         const rowData = this.exportTSVFields.filter(field => field.key !== 'actions').map(field => String(caseItem[field.key]));
    //         rowData.forEach((value, index) => {
    //         columnWidths[index] = Math.max(columnWidths[index], value.length);
    //         });
    //     });
       
    //     // Add column headers
    //     const columnHeaders = this.exportTSVFields.filter(field => field.key !== 'actions').map((field, index) => field.key.padEnd(columnWidths[index]));
    //     tsvContent += columnHeaders.join('\t') + '\n';        

    //     // Add data rows
    //     this.exportData.forEach(caseItem => {
    //         const rowData = this.exportTSVFields.filter(field => field.key !== 'actions').map((field, index) => String(caseItem[field.key]).padEnd(columnWidths[index]));
    //         tsvContent += rowData.join('\t') + '\n';
    //     });

    //     // Generate a unique file name
    //     const fileName = 'data.tsv';

    //     // Create a Blob from the TSV content
    //     const blob = new Blob([tsvContent], { type: 'text/tab-separated-values' });

    //     // Create a URL for the Blob
    //     const url = URL.createObjectURL(blob);

    //     // Create a link element
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = fileName;

    //     // Simulate a click event to trigger the file download
    //     link.click();

    //     // Cleanup
    //     URL.revokeObjectURL(url);
    //   }
    //   },
    setSlidesUploadStatus(enable) {
      this.isSlideUploading = enable;
      setIsUploading(enable);
      if(!enable)
      {this.folderSelected(this.activeFolder);}   //refresh folder after uploading completes
    },
    // selectSlides(item) {
    //   if (this.selectedSlides.includes(item)) {
    //     this.selectedSlides.splice(
    //       findIndex(this.selectedSlides, (o) => o.id === item.id),
    //       1
    //     );
    //   } else {
    //     this.selectedSlides.push(item);
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.card {
  position: initial !important;
}

.upload-loader {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
</style>
