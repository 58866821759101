<template>
  <b-container
    class="py-20 wtr-profile"
    fluid
  >
    <div class="d-flex position-relative">
      <div class="w-100" :class="{ 'fetch-class': isLoading }">
        <b-row v-if="user">
          <b-col class="col-xl-3 col-lg-4 col-md-12 col-12">
            <b-card class="mb-15">
              <wtr-image-upload
                :img="user.logo || $icons.PROFILE_DEFAULT_LOGO"
                :resourceId="user.resourceId"
                :containerName="user.containerName"
                :altImage="$icons.NO_THUMBNAIL_FOUND"
                :validate="$isImage"
                @uploaded="logoUploaded"
                @uploading="isImageUploading = $event"
              ></wtr-image-upload>
              <b-btn
                variant="outline-primary"
                size="sm"
                class="btn-block mt-10" aria-label="Change Password" aria-labelledby="Change Password"
                v-on:click="openModal"
                :title="$t('administration.changePassword')"
              >{{$t('administration.changePassword')}}</b-btn>
              <div class="py-10 text-center">
                <b-badge
                  class="px-20 py-5 d-block text-center text-wrap text-primary"
                  variant="light"
                >
                  {{user.email}}
                </b-badge>
              </div>
              <div class="py-10 text-center">
                <b-badge
                  class="px-20 py-5"
                  variant="primary"
                >
                  {{ $userRole == 'GUEST' ? 'CONTRIBUTOR' : $userRole }}
                </b-badge>
              </div>
              <div class="pt-20">
                <b-btn
                  type="button"
                  variant="primary"
                  size="sm" aria-label="Update" aria-labelledby="Update"
                  class="btn-block"
                  v-on:click="validateData(user)"
                  :isLoading="isLoading"
                  :disabled="isLoading || isImageUploading"
                >
                  <i class="fa fa-pencil"></i> Update Profile
                </b-btn>
              </div>
            </b-card>
          </b-col>
          <b-col class="col-xl-9 col-lg-8 col-md-12 col-12">
            <b-card class="profile-info w-100 mb-2">
              <div class="profile-title">
                Basic Information 
                <a variant="link" size="md" class="px-5" 
                  @click="basicExpanded = !basicExpanded"
                >
                  <i :class="`fa fs-20 fa-${basicExpanded ? 'angle-up' : 'angle-down'}`"></i>
                </a>
              </div>
              <b-collapse v-model="basicExpanded">
                <b-form v-on:submit.stop.prevent="validateData(user)" >
                  <b-row>
                    <b-col cols="12" v-for="(label, labelIndex) in labels" :key="labelIndex"
                      :class="label.key ==='region' ? !!user[label.key] ? label.class : 'd-none' : label.class"
                    >
                      <b-form-group :state="!errors.has(label.label)" :invalid-feedback="errors.first(label.label)"
                        v-if="label.key ==='region' ? !!user[label.key] : true"
                      >
                        <label>{{label.label}}<span class="error-text" v-if="label.validate.includes('required')">*</span>
                        </label>
                        <b-form-input
                          v-if="label.type === 'text' || label.type === 'email'"
                          :type="label.type"
                          :name="label.label"
                          v-validate="label.validate"
                          size="sm"
                          :disabled="label.disabled"
                          :placeholder="label.placeholder"
                          v-model="user[label.key]"
                        ></b-form-input>
                        <b-form-textarea
                          aria-label="about"
                          aria-labelledby="about"
                          title="about"
                          v-if="label.type === 'text-area'"
                          :type="label.type"
                          :name="label.label"
                          rows="3"
                          v-validate="label.validate"
                          size="sm"
                          :disabled="label.disabled"
                          :placeholder="label.placeholder"
                          v-model="user[label.key]"
                        ></b-form-textarea>
                        <b-form-select
                          size="sm"
                          aria-label="country" aria-labelledby="country"
                          v-else-if="label.type === 'select' && label.key==='country'"
                          v-model="user[label.key]"
                          :name="label.label"
                          v-validate="label.validate"                      
                        >
                          <option :value="null" selected >{{label.placeholder}}</option>
                          <option :value="country.country" v-for="(country, countryIndex) in metadata.countries" :key="countryIndex" >{{country.country}}</option>
                        </b-form-select>
                        <b-form-select
                          aria-label="state"
                          aria-labelledby="state"
                          size="sm"
                          v-else-if="canShowState(label)"
                          v-model="user[label.key]"
                          :name="label.label"
                          v-validate="label.validate"
                        >
                          <option
                            :value="null"
                            selected
                          >{{label.placeholder}}</option>
                          <option
                            :value="state.name"
                            v-for="state in metadata.states"
                            :key="state.id"
                          >{{state.name}}</option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div
                    class="text-right"
                    v-if="$userData.id === user.id"
                  >
                  </div>
                </b-form>
              </b-collapse>
            </b-card>
            <b-card class="profile-info w-100 mb-2">
              <div class="profile-title">
                About
                <a variant="link" size="md" class="px-5"  @click="aboutExpanded = !aboutExpanded">
                  <i :class="`fs-20 fa fa-${aboutExpanded ? 'angle-up' : 'angle-down'}`"></i>
                </a>
              </div>
              <b-collapse v-model="aboutExpanded">
                <b-row>
                  <b-col class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <profile-credentials
                      :options="metadata.credentials"
                      @updated="onCredentialsUpdate"
                      :selected="user.userCredentials"
                    ></profile-credentials>
                  </b-col>
                  <b-col class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <wtr-save-input
                      :value="user.institution"
                      :params="{
                        name: 'Institution',
                        label: 'Institution or Affiliation'
                      }"
                      :copyValue="$userData.institution"
                      @on-save="updateInstitution(user.institution)"
                      @input="user.institution = $event"
                    ></wtr-save-input>
                  </b-col>
                  <b-col class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <wtr-save-input
                      :value="user.jobRole"
                      :params="{
                        name: 'Job Title/Role',
                        label: 'Job Title/Role'
                      }"
                      :copyValue="$userData.jobRole"
                      @on-save="updateJobRole(user.jobRole)"
                      @input="user.jobRole = $event"
                    ></wtr-save-input>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="isLoading"
        class="w-100 h-100 position-absolute align-items-center d-flex justify-content-center"
      >
        <wtr-loader 
          class="text-primary" style="top: 250px; position: absolute;"
          loadingText="Updating User Profile..." addText 
        ></wtr-loader>
      </div>
    </div>
    
    <b-modal
      scrollable
      v-if="$modalData.route === 'reset-password'"
      v-model="$modalData.show"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$modalData.title"
      hide-footer
      :size="$modalData.size || ''"
      :modal-class="$modalData.class"
      :hide-header-close="$modalData.hideCloseIcon"
    >
      <reset-password :options="$modalData.data"></reset-password>
    </b-modal>
  </b-container>
</template>

<script>
import { cloneDeep, filter, map } from 'lodash';
import { mapActions } from 'vuex';
import resetPassword from '../../admin-side/auth/views/CreatePassword.vue';
import WTRImageUpload from './wtr-image-upload.vue';
import { BASIC_DETAILS } from '../../forms/form-profile';
import profileCredentialsVue from '../../admin-side/profile/components/profile-credentials.vue';
import WTRSaveInputVue from './wtr-save-input.vue';
import countryList from '../../models/model-countries.js';
import axios from "axios";

export default {
  name: 'profile',
  data() {
    return {
      isLoading: false,
      basicExpanded: true,
      aboutExpanded: true,
      socialLinksExpanded: true,
      user: null,
      userDetails:null,
      metadata: {
        countries: [],
        speciality: [],
        profession: [],
        credentials: [],
        states: [],
        subSpeciality: [],
      },
      roles: [],
      isImageUploading: false
    };
  },
  props: {
    userId: Number,
    isSuperAdmin: Boolean,
  },
  components: {
    'reset-password': resetPassword,
    'wtr-image-upload': WTRImageUpload,
    'profile-credentials': profileCredentialsVue,
    'wtr-save-input': WTRSaveInputVue,
  },
  computed: {
    labels() {
      const labels = cloneDeep(BASIC_DETAILS);
      if (this.isUnitedState(this.user.country)) {
        return labels;
      }
      return filter(labels, item => item.key !== 'state');
    },
    hasSubspeciality() {
      return this.$hasSubSpeciality(this.user);
    },
  },
  mounted() {
    this.getUser(this.userId);
  },
  methods: {
    ...mapActions(['sessionDataAction']),
    canShowState(label) {
      return label.type === 'select' && label.key === 'state';
    },
    hasCustomQualification(qualification) {
      if (!qualification) return false;
      const qualificationsList = map(this.qualificationsList, 'name');
      return !qualificationsList.includes(qualification);
    },
    isUnitedState(country) {
      const countries = [
        'United States',
        'United States Minor Outlying Islands',
      ];
      return countries.includes(country);
    },
    openModal() {
      this.$setModalAction({
        show: true,
        title: this.$t('administration.resetPassword'),
        route: 'reset-password',
        data: {
          modal: this.$t('administration.resetPassword'),
          id: this.user.id,
        },
      });
    },
    logoUploaded(e) {
      const logoData = {
        id: this.user.id,
        logo: e.response.uri,
        thumbnailPath: e.response.thumbnailPath,
        resourceId: e.response.resourceId,
        containerName: e.response.containerName,
      };
      this.$api.uploadProfileLogoFact(logoData).then(response => {
        if (!this.$handleResponse(response)) return;
        this.user = {
          ...this.user,
          ...logoData,
        };
        this.updateUserToSession(this.user);
      }, error => {
        this.$handleError(error);
      });
    },
    getUser(id) {
      this.isLoading = true;
      this.$api.getUserFact(id).then(
        response => {
          this.user = response.data;
          this.getProfileMetadata();
          this.$emit('on-load', this.validator);
          this.isLoading = false;          
        },
        error => {
          this.$handleError(error);
          this.isLoading = false;
        },
      );
    },   
    updateInstitution(institution) {
      this.user = {
        ...this.user,
        institution,
      };
      this.updateUser(this.user);
    },
    updateJobRole(jobRole) {
      this.user = {
        ...this.user,
        jobRole,
      };
      this.updateUser(this.user);
    },
    updateCollaborate() {
      this.user = {
        ...this.user,
        canCollaborate: !this.user.canCollaborate,
      };
      this.updateUser(this.user);
    },
    updateAdvice() {
      this.user = {
        ...this.user,
        canAdvise: !this.user.canAdvise,
      };
      this.updateUser(this.user);
    },
    onProfessionUpdate(professions) {
      this.user = {
        ...this.user,
        professions,
      };
      this.updateUserToSession(this.user);
    },
    onCredentialsUpdate(userCredentials) {
      this.user = {
        ...this.user,
        userCredentials,
      };
      this.updateUserToSession(this.user);
    },
    onSpecialityUpdate(specialities) {
      this.user = {
        ...this.user,
        specialities,
      };
      this.updateUserToSession(this.user);
    },
    onSubSpecialityUpdate(subSpecialities) {
      this.user = {
        ...this.user,
        subSpecialities,
      };
      this.updateUserToSession(this.user);
    },
    onSocialLinksUpdate(socialLinks) {
      this.user = {
        ...this.user,
        socialLinks,
      };
      this.updateUserToSession(this.user);
    },
    updateUserToSession(_payload) {
      const payload = {
        ..._payload,
        acceptCookiePolicy: this.$userData.acceptCookiePolicy,
      };
      this.user = payload;
      const sessionData = this.$session.updateSessionData({
        userData: payload,
      });
      this.$toastr(
        'success',
        this.$t('notes.updateSuccessMsg', {
          text: this.$t('common.user'),
        }),
      );
      this.sessionDataAction(sessionData);
    },
    updateUser(user) {
      this.isLoading = true;
      const payload = {
        ...user,
        acceptCookiePolicy: this.$userData.acceptCookiePolicy,
        state: this.isUnitedState(this.user.country) ? this.user.state : null,
      };
      this.$api.updateUserProfileFact(payload).then(
        () => {
          this.isLoading = false;         
          // const loginUserRole = localStorage.getItem("userRole");
          // if(loginUserRole === 'EDITOR' || loginUserRole === 'CHIEF EDITOR' || loginUserRole === 'CHAIRPERSON')
          // {
          //   this.getLatLong(user.cityName,payload);            
          // }
          // else
          // {
            this.updateCityName(user.cityName,null,null);
            this.updateUserToSession(payload);
          // }          
        },
        error => {
          this.isLoading = false;
          this.$handleError(error);
        },
      );
    },
    getLatLong(cityName,payload)
    {
      var url = "https://nominatim.openstreetmap.org/search?q="+ cityName + "," + this.user.country +"&format=json&limit=1";
      axios.get(url).then(
        (res) => {
           if(res.data.length > 0)
           {       
             console.log('Result Data',res.data);
             this.updateCityName(cityName,res.data[0].lat,res.data[0].lon);
             this.updateUserToSession(payload);
           }
           else
           {
            console.log('Result',res);
            this.invalidCityOrCountry(payload);
           }          
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    invalidCityOrCountry(payload)
    {
       this.$toastr('error', this.$t('notes.checkCountryAndCityExists'));
        payload.cityName= null;
        this.user = payload;
        this.$session.updateSessionData({userData: payload});
        return;
    },
    updateCityName(cityName,lat,lng)
    {
      let updateUserObject = null;
      updateUserObject = cloneDeep({
        cityName: cityName,
        latitude: lat,
        longitude: lng,
      });

      this.$api.updateUserDetailFact(updateUserObject).then(
        () => {                    
        },
        (error) => {
          this.$handleError(error);
        }
      );
    },
    validator(successCb, errorCb) {
      this.$validator.validate().then(result => {
        if (result) {
          if (successCb) {
            successCb({
              ...this.user,
              state: this.isUnitedState(this.user.country)
                ? this.user.state
                : null,
            });
          }
        } else if (errorCb) errorCb(this.user);
      });
    },
    validateData(data) {
      this.validator(
        () => {
          this.updateUser(data);
        },
        () => {
          this.$toastr('error', this.$t('notes.fillAllValidDetails'));
        },
        true,
      );
    },
    getProfileMetadata() {
      this.$api.getProfileMetadataFact().then(
        response => {
          if (!this.$handleResponse(response)) return;
          this.metadata = response.data.payload;
          this.getUserDetails(this.userId);
        },
        error => {
          this.$handleError(error);
        },
      );
    },
    getUserDetails(id) {
      this.isLoading = true;
      this.$api.getUserDetailFact(id).then(
        (response) => {
          this.userDetails = response.data;          
          //get countries by user region
          if(this.userDetails.regionid>0){
            this.metadata.countries = filter(countryList, (item) => item.regionId == this.userDetails.regionid);
          }
          this.user.cityName =  this.userDetails.cityName;
          this.getRegions();
        },
        (error) => {
          this.$handleError(error);
          this.isLoading = false;
        }
      );
    },
    getRegions() {
      this.$api.getAllGeographicregionsFact().then(
        (response) => {
          if (response && response.data.status) {
            const regions = response.data.payload;
            this.user.region = regions.find(item => item.id === this.userDetails.regionid)?.name;
          }
          this.isLoading = false; 
        },
        (error) => {
          this.isLoading = false; 
          this.$handleError(error);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
select {
  background-position: right 0.5rem center !important;
}
</style>