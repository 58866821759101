<template>
  <b-button-group>
    <a
      v-if="listIcon && listIcon.show"
      :variant="listIcon.variant || 'link text-primary'"
      :title="listIcon.title || $t('common.allList')"
      size="sm"
      v-on:click.prevent.stop="$emit('list')"
    >
      <i
        :class="`${listIcon.icon || 'fa fa-list text-primary'}`"
        style="padding: 6px 7px 0px 7px;"
        aria-hidden="true"
      ></i> {{listIcon.label}}
    </a>
    <a
      v-if="viewCaseIcon && viewCaseIcon.show"
      :variant="viewCaseIcon.variant || 'link text-primary'"
      :title="viewCaseIcon.title || $t('common.viewCaseInfo')"
      size="sm"
      v-on:click.prevent.stop="$emit('viewCaseInfo')"
    >
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_ViewCaseImageAndInfo.svg')" alt="Icon" />
      {{viewCaseIcon.label}}
    </a>
    <a
      v-if="viewIcon && viewIcon.show"
      :variant="viewIcon.variant || 'link text-primary'"
      :title="viewIcon.title || $t('common.viewCaseInfo')"
      size="sm"
      v-on:click.prevent.stop="$emit('view')"
    >
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_ViewCaseImageAndInfo.svg')" alt="Icon" />
      {{viewIcon.label}}
    </a>
     <a
      v-if="viewSlideIcon && viewSlideIcon.show"
      :variant="viewSlideIcon.variant || 'link text-primary'"
      :title="viewSlideIcon.title || $t('common.viewSlideInfo')"
      size="sm"
      v-on:click.prevent.stop="$emit('view')"
    >      
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_ViewSlidesInfo.svg')" alt="Icon" />     
      {{viewSlideIcon.label}}
    </a> 
    <a
      v-if="caseReviewCommentIcon && caseReviewCommentIcon.show"
      :variant="caseReviewCommentIcon.variant || 'link text-primary'"
      :title="caseReviewCommentIcon.title || $t('common.caseReviewComment')"
      size="sm"
      v-on:click.prevent.stop="$emit('caseReviewComment')"
    >     
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_Comments.svg')" alt="Icon" />
       {{caseReviewCommentIcon.label}}
    </a>
    <a
      v-if="reviewStatusIcon && reviewStatusIcon.show"
      :variant="reviewStatusIcon.variant  || 'link text-success'"
      :title="reviewStatusIcon.title || $t('common.changeReviewStatus')"
      size="sm"
      v-on:click.prevent.stop="$emit('reviewStatus')"
    >
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_ChangeReviewStatus.svg')" alt="Icon" />
       {{reviewStatusIcon.label}}
    </a>
    <a aria-label="save" aria-labelledby="save"
      v-if="editIcon && editIcon.show"
      :variant="editIcon.variant  || 'link text-primary'"
      :title="editIcon.title || $t('common.edit')"
      size="sm"
      v-on:click.prevent.stop="$emit('edit')"
    >     
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_Edit.svg')" alt="Icon" />
       {{editIcon.label}}
    </a>
    <a
      v-if="moveBackToEditorialBoardBoxIcon && moveBackToEditorialBoardBoxIcon.show"
      :variant="moveBackToEditorialBoardBoxIcon.variant  || 'link text-success'"
      :title="moveBackToEditorialBoardBoxIcon.title || $t('common.moveBackToEditorialBoardBoxIcon')"
      size="sm"
      v-on:click.prevent.stop="$emit('moveToEditorialBox')"
    >
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveBackToEditorialBoardBox.svg')" alt="Icon" />
       {{moveBackToEditorialBoardBoxIcon.label}}
    </a>
    <a
      v-if="moveToSlideLibraryIcon && moveToSlideLibraryIcon.show"
      :variant="moveToSlideLibraryIcon.variant  || 'link text-success'"
      :title="moveToSlideLibraryIcon.title || $t('common.moveToPublicAccessBox')"
      size="sm"
      v-on:click.prevent.stop="$emit('moveToSlideLibrary')"
    >
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveToPublicAccessBox.svg')" alt="Icon" />
      {{moveToSlideLibraryIcon.label}}
    </a>  
    <a
      v-if="moveToEditorialBoxIcon && moveToEditorialBoxIcon.show"
      :variant="moveToEditorialBoxIcon.variant  || 'link text-success'"
      :title="moveToEditorialBoxIcon.title || $t('common.moveToEditorialBox')"
      size="sm"
      v-on:click.prevent.stop="$emit('moveToEditorialBox')"
    >
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveToEditorialBoardBox.svg')" alt="Icon" />
       {{moveToEditorialBoxIcon.label}}
    </a> 
    <a
      v-if="moveBackForRevisionIcon && moveBackForRevisionIcon.show"
      :variant="moveBackForRevisionIcon.variant  || 'link text-success'"
      :title="moveBackForRevisionIcon.title || $t('common.moveBackForRevision')"
      size="sm"
      v-on:click.prevent.stop="$emit('moveBackForRevision')"
    >
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveBackForRevision.svg')" alt="Icon" />
       {{moveBackForRevisionIcon.label}}
    </a>
    <a
      v-if="moveToPrePublicationIcon && moveToPrePublicationIcon.show"
      :variant="moveToPrePublicationIcon.variant  || 'link text-success'"
      :title="moveToPrePublicationIcon.title || $t('common.moveToPrePublication')"
      size="sm"
      v-on:click.prevent.stop="$emit('moveToPrePublication')"
    >
       <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveToPrepublicationBox.svg')" alt="Icon" />
       {{moveToPrePublicationIcon.label}}
    </a>
    <a
      v-if="moveToEditorBoxIcon && moveToEditorBoxIcon.show"
      :variant="moveToEditorBoxIcon.variant  || 'link text-success'"
      :title="moveToEditorBoxIcon.title || $t('common.moveToEditorBox')"
      size="sm"
      v-on:click.prevent.stop="$emit('sharedToEditor')"
    >  
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_MoveToEditorBox.svg')" alt="Icon" />
       {{moveToEditorBoxIcon.label}}
    </a>
    <a
      v-if="restoreIcon && restoreIcon.show"
      :variant="restoreIcon.variant  || 'link text-primary'"
      :title="restoreIcon.title || $t('slidebox.restore')"
      size="sm"
      v-on:click.prevent.stop="$emit('restore')"
    >
      <i
        :class="`table-icon-size ${restoreIcon.icon || 'fa fa-repeat'}`"
        aria-hidden="true"
      ></i> {{restoreIcon.label}}
    </a>  
    <a aria-label="delete" aria-labelledby="delete"
      v-if="deleteIcon && deleteIcon.show"
      :variant="deleteIcon.variant  || 'link text-primary'"
      :title="deleteIcon.title || $t('common.delete')"
      size="sm"
      v-on:click.prevent.stop="$emit('delete')"
    >     
      <img class="table-icon-size" :src="require('@/assets//icons-new/Icons_Delete.svg')" alt="Icon" />
       {{deleteIcon.label}}
    </a>
    <button type="button" aria-label="activate" aria-labelledby="activate"
      v-if="activateUserButton && activateUserButton.show"
      :class="activateUserButton.variant  || 'btn-danger'"
      :title="activateUserButton.title || $t('common.deactivate')"
      class="btn btn-sm active-btn"
      v-on:click.prevent.stop="$emit('deactivateUser')"
    >     
      {{activateUserButton.label}}
  </button>
  <a aria-label="resend" aria-labelledby="resend"
      v-if="resendIcon && resendIcon.show"
      :variant="resendIcon.variant  || 'link text-primary'"
      :title="resendIcon.title || $t('administration.resendInvitation')"
      size="sm"
      v-on:click.prevent.stop="$emit('resend')"
    >     
      <i aria-hidden="true" class="fa fa-envelope-o"></i>
       {{resendIcon.label}}
    </a>
    <a :aria-label="setHomepageIcon.title" :aria-labelledby="setHomepageIcon.title"
      v-if="setHomepageIcon && setHomepageIcon.show"
      :variant="setHomepageIcon.variant  || 'link text-primary'"
      :title="setHomepageIcon.title || $t('administration.resendInvitation')"
      size="sm"
      v-on:click.prevent.stop="$emit('select')"
      class="text-primary"
    >     
      <i aria-hidden="true" class="fa fs-13 px-2 fa-map-pin"></i>
       {{setHomepageIcon.label}}
    </a>
    <a :aria-label="upIcon.title" :aria-labelledby="upIcon.title"
      v-if="upIcon && upIcon.show"
      :variant="upIcon.variant  || 'link text-primary'"
      :title="upIcon.title || $t('administration.resendInvitation')"
      size="sm"
      v-on:click.prevent.stop="$emit('eventupIcon')"
      class="text-primary"
    >     
      <i aria-hidden="true" class="fa fs-13 px-2 fa-arrow-up"></i>
       {{upIcon.label}}
    </a>
    <a :aria-label="downIcon.title" :aria-labelledby="downIcon.title"
      v-if="downIcon && downIcon.show"
      :variant="downIcon.variant  || 'link text-primary'"
      :title="downIcon.title || $t('administration.resendInvitation')"
      size="sm"
      v-on:click.prevent.stop="$emit('eventdownIcon')"
      class="text-primary"
    >     
      <i aria-hidden="true" class="fa fs-13 px-2 fa-arrow-down"></i>
       {{downIcon.label}}
    </a>
  </b-button-group>
</template>

<script>
export default {
  name: 'table-actions',
  props: {
    viewIcon: Object,
    editIcon: Object,
    deleteIcon: Object,
    restoreIcon: Object,
    moveToEditorBoxIcon:Object,
    moveToEditorialBoxIcon:Object,
    moveToSlideLibraryIcon:Object,
    listIcon: Object,
    caseReviewCommentIcon: Object,
    viewSlideIcon: Object,
    viewCaseIcon: Object,
    moveBackForRevisionIcon: Object,
    moveToPrePublicationIcon: Object,
    reviewStatusIcon: Object,
    moveBackToEditorialBoardBoxIcon: Object,
    activateUserButton: Object,
    resendIcon : Object,
    setHomepageIcon : Object,
    upIcon : Object,
    downIcon : Object,
    folderData: {
    type: Object,
    default: null
    }
  },
};
</script>

<style scoped>
.table-icon-size{
  height: 16px!important;
  padding: 0 4px;
}
.active-btn{
  padding: 0.25em 0.4em !important;
  font-size: 12px;
}
</style>