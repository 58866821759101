export default {
  viewSlide: 'View Slide',
  shareSlides: 'Share Slides',
  slideInfo: 'Slide Information',
  selectProcessedSlides: 'Please select processed slides',
  uploadingFailed: 'Selected slide failed while uploading.',
  processingFailed: 'Selected slide failed while processing.',
  slideLibraryRequests: 'Slide Library Requests',
  slideStatusChangedSuccessfully: 'Slide status changed successfully',
  slideLibraryNote: 'Every effort has been made to present you with the most accurate diagnosis and information. However because of the vast variations that can occur for every diagnosis, PathPresenter cannot be held responsible or accountable for the accuracy of the content. PathPresenter assumes no liability for errors or omissions on the website. Users should independently verify the accuracy, completeness and relevance of the diagnosis and other information',
  institutionalSlides: 'Institutional Slides',
  publicSlides: 'Public Slides',
};
