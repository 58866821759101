const types = {
  SESSION_DATA: 'SESSION_DATA',
  LANDING_PAGE_DATA: 'LANDING_PAGE_DATA',
};

export default {
  state: {
    sessionData: null,
    landingPageData: null,
  },
  mutations: {
    [types.SESSION_DATA](state, data) {
      state.sessionData = data; //eslint-disable-line
    },
    [types.LANDING_PAGE_DATA](state, data) {
      state.landingPageData = data; //eslint-disable-line
    },
  },
  actions: {
    sessionDataAction({ commit }, data) {
      commit(types.SESSION_DATA, data);
    },
    landingPageDataAction({ commit }, data) {
      commit(types.LANDING_PAGE_DATA, data);
    },
  },
  getters: {
    getSessionData: state => state.sessionData,
    getUserData: state => (state.sessionData ? state.sessionData.userData : {}),
    getHomeData: state => (state.sessionData ? state.sessionData.homeData : null),
    getUserRole: state => (state.sessionData && state.sessionData.userData ? state.sessionData.userData.roles[0].role : ''),
    getAuthToken: state => (state.sessionData ? state.sessionData.token : ''),
    getTenantData: state => (state.sessionData && state.sessionData.tenantData ? state.sessionData.tenantData : {}),
    getSiteData: state => (state.sessionData && state.sessionData.siteData ? state.sessionData.siteData : {}),
    getLoginConfigurations: state => (state.sessionData && state.sessionData.apiConfiguration ? state.sessionData.apiConfiguration.singleSignOn : null),
    getPermissions: state => (state.sessionData && state.sessionData.permissions ? state.sessionData.permissions.userManagement : null),
    getTenantConfigData: state => (state.sessionData && state.sessionData.tenantConfigData ? state.sessionData.tenantConfigData : null),
    getUserAgent: state => (state.sessionData && state.sessionData.userAgent ? state.sessionData.userAgent : null),
    getSassTokens: state => (state.sessionData && state.sessionData.sasTokens ? state.sessionData.sasTokens : null),
    getLandingContactData: state => (state.landingPageData && state.landingPageData.contact ? state.landingPageData.contact : null),
    getLandingPageData: state => (state.landingPageData ? state.landingPageData : null),
    getSubscriptionData: state => (state.sessionData && state.sessionData.subscriberInformation ? state.sessionData.subscriberInformation : null),
  },
};