import axios from 'axios';
import { $session } from '@/helpers';
import urls from './urls';
import { getUserUrl } from './api-services';
import { getGLobalRepoUrl } from "./api-services";


export const getLandingPageData = () => {
  const tenantData = $session.getTenantData();
  const authorization = $session.getToken();
  return axios.get(getUserUrl(`${urls.webdata}`), {
    headers: {
      authorization,
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
};

export const newsResource = (apiType, config,params) => {
  const tenantData = $session.getTenantData();
  switch (apiType) {
    case 'GET-ALL':
      return axios.get(getGLobalRepoUrl(`${urls.news}${urls.all}`), {
          headers: {
            'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
          },
          params
        });
    case 'GET-ALL-HOME':
      return axios.get(getGLobalRepoUrl(`${urls.news}${urls.all}${urls.home}`), {
          headers: {
            'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
          },
          params
      });    
    case 'GET':
      return axios.get(getGLobalRepoUrl(`${urls.news}${urls.get}`), {
          headers: {
            'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
          },
          params
        });
    case 'POST':
      return axios.post(getGLobalRepoUrl(`${urls.news}`), config.data);
    case 'PUT':
      return axios.put(getGLobalRepoUrl(`${urls.news}`), config.data);
    case 'DELETE':
      return axios.delete(getGLobalRepoUrl(`${urls.news}/${config.id}`));
    case 'PUT-NEWSEVENT-ORDER':
        return axios.put(getGLobalRepoUrl(`${urls.news}${urls.order}`), config.data);
    default:
  }
  return false;
};

export const sponsorsResource = (apiType, config) => {
  const tenantData = $session.getTenantData();
  switch (apiType) {
    case 'GET-ALL':
      return axios.get(getGLobalRepoUrl(`${urls.sponsor}${urls.all}`), {
          headers: {
            'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
          },
        });
    case 'POST':
      return axios.post(getGLobalRepoUrl(`${urls.sponsor}`), config.data);
    case 'PUT':
      return axios.put(getGLobalRepoUrl(`${urls.sponsor}`), config.data);
    case 'DELETE':
      return axios.delete(getGLobalRepoUrl(`${urls.sponsor}/${config.id}`));
    default:
  }
  return false;
};
