export default {
  cut: 'Cut',
  file: 'File',
  mrxs: 'MRXS',
  move: 'Move',
  view: 'View',
  share: 'Share',
  paste: 'Paste',
  moved: 'moved',
  video: 'Video',
  image: 'Image',
  toAdd: 'To add',
  folder: 'Folder',
  folder_sm: 'folder',
  copied: 'copied',
  search: 'Search',
  images: 'Images',
  videos: 'Videos',
  unShare: 'Unshare',
  restore: 'Restore',
  restore_sm: 'restore',
  uploaded: 'uploaded',
  slideBox: 'Slide Box',
  invalidEmails: 'Invalid Email{prular}',
  sharedSuccessfully: 'Shared successfully',
  alreadyShared: 'You have already shared with these users',
  fileNotSupported: '{format} format is not supported',
  digitalSlide: 'Digital Slide',
  document: 'Document',
  radiology: 'Radiology',
  unknown: 'Unknown',
  pending: 'Pending',
  inQueue: 'In Queue',
  sharedBy: 'Shared By',
  toUpload: 'To upload',
  uploading: 'Uploading',
  updating: 'Updating',
  uploading_sm: 'uploading',
  updating_sm: 'updating',
  processed: 'Processed',
  copyOrMove: 'Copy/Move',
  myFolders: 'My Folders',
  newFolder: 'New Folder',
  sharedWith: 'Shared With',
  deepSearch: 'Deep Search',
  uploadTime: 'Upload Time',
  chooseFile: 'Choose File',
  uploadFiles: 'Upload Files',
  processing: 'Processing...',
  gotCancelled: 'got cancelled',
  processingStatus: 'Processing',
  uploadFailed: '*Upload Failed',
  stainORstudy: 'Stain OR Study',
  strainORbreed: 'Strain OR Breed',
  digitalSlides: 'Digital Slides',
  zoomify: 'Zoomify',
  copyOrMoveSlide: 'Copy/Move Slide',
  uploadFailedStatus: 'Upload Failed',
  radiologyImages: 'Radiology Images',
  uploadCancelled: 'Upload Cancelled',
  copyOrMoveSlides: 'Copy/Move Slides',
  MoveRecycleBin: 'Move to Recycle Bin',
  processingFailed: '*Processing Failed',
  clickToViewSlide: 'Click to view Slide',
  permanentlyDelete: 'Permanently Delete',
  MovedRecyclebin: 'Moved to recycle bin',
  processingFailedStatus: 'Processing Failed',
  createFolderInto: 'Create folder/sub-folder into',
  noSlidesAvailable: 'There are no slides available',
  formatNotSupported: '{ext} format is not supported',
  selectProcessedFiles: 'Please select processed slides',
  restoredFolder: 'Restored folder successfully',
  PermanentlyDeletedSlides: 'Permanently deleted the slides',
  PermanentlyDeletedSlide: 'Permanently deleted the slide',
  PermanentlyDeletedFolder: 'Permanently deleted the folder',
  agreeToTheTerms: 'Please agree to the terms before {event}',
  failedWhileUploading: 'Selected slide failed while uploading',
  tokenNotFound: 'Token not found to write file to blob storage',
  failedWhileProcessing: 'Selected slide failed while processing',
  selectFileBeforeUploading: 'Please select file before uploading',
  slideUploadAgreeLabelMsg: 'I agree to the terms to {event} files',
  cutOrCopyBeforePasting: 'Please cut or copy a folder before pasting',
  cannotMoveOrCopyToSameFolder: 'You cannot move/copy into same folder.',
  selectedSlidesWarningMsg:
    'Are you sure you want to {event} the selected slides',
  selectedCaseWarningMsg:
  'Are you sure you want to {event} the selected case',
  uploadFoldersInsideMySlidebox:
    'You can only upload folders inside My Slide Box',
  selectAtleastOneSlide:
    'Please select atleast one slide before performing action',
  cannotMoveToSameFolder:
    'You cannot move slide to the same folder where it currently resides.',
  cannotMoveOrCopyToChildFolder:
    'You cannot move/copy parent folder into child folders.',
  slidePermanentDeleteMsg:
    'Please confirm you want to delete the slide permanently',
  slidesPermanentDeleteMsg:
    'Please confirm you want to delete the slides permanently',
  foldersPermanentDeleteMsg:
    'Please confirm you want to delete the folder permanently',
  onGoingUploadSlidesMsg:
    'File uploads in progress. Are you sure you want to terminate',
  stillYetToBeProcessed:
    'Selected files are still yet to be processed or failed uploading/processing',
  attachments: 'Attachments',
  caseMoveToEditorialBoxConfirmMsg:
    'Please confirm you want to move case to editorial board box',
  caseMoveBackToEditorialBoxConfirmMsg:
    'Please confirm you want to move case back to editorial box',
  caseMoveToSlideLibraryConfirmMsg:
    'Please confirm you want to move case to Public Access box',
  caseSharedToEditorConfirmMsg:
    'Please confirm you want to move case to editor box',
  caseShareBackToEditorConfirmMsg:
    'Please confirm you want to share case back to editor',
  caseMoveToEditorialBoxSuccessMsg: 'Case moved to editorial box',
  caseMoveToSlideLibrarySuccessMsg: 'Case moved to slide library',
  caseSharedToEditorSuccessMsg: 'Case share to editor',
  casePermanentDeleteMsg:'Please confirm you want to delete the case permanently',  
  PermanentlyDeletedCase: 'Permanently deleted the case',  
  caseMoveBackForRevisionConfirmMsg:'Please confirm you want to move case back for revision',
  caseMoveToPrePublicationBoxConfirmMsg:'Please confirm you want to move case to pre-publication box',
  statusChangeSuccessMessage:'Case status changed successfully',
};