const HomeModule = () => import("./HomeModule.vue");
const HomeComponent = () => import("./views/Home.vue");
const MissionOverviewComponent = () => import("./views/Mission-Overview.vue");
const FinancialsComponent = () => import("./views/Financials.vue");
const DonateComponent = () => import("./views/Donate.vue");
const OurSponsorsComponent = () => import("./views/OurSponsors.vue");
const ContactUsComponent = () => import("./views/ContactUs.vue");
const FaqsComponent = () => import("./views/Faqs.vue");
const LeadershipComponent = () => import("./views/Leadership.vue");
const TSSExpertsComponent = () => import("./views/TSS-Experts.vue");
const NewsEventsComponent = () => import("./views/News-Events.vue");
const NewsEventsDetailsComponent = () => import("./views/News-Events-Details.vue");
const SitemapComponent = () => import("./views/Sitemap.vue");
const TermsOfUseComponent = () => import("./views/TermsOfUse.vue");
const VideoSeriesComponent = () => import("./views/Video-Series.vue");
const VideoSeriesDetailsComponent = () => import("./views/Video-Series-Details.vue");
const BrochureComponent = () => import("./views/OurBrochure.vue");
export default {
  path: "/",
  name: "home",
  component: HomeModule,
  children: [
    {
      path: "home",
      name: "home",
      component: HomeComponent,
    },
    {
      path: "mission-and-overview",
      name: "mission-and-overview",
      component: MissionOverviewComponent,
    },       
    {
      path: "financials",
      name: "financials",
      component: FinancialsComponent,
    },
    {
      path: "donate",
      name: "donate",
      component: DonateComponent,
    }, 
    {
      path: "our-sponsors",
      name: "our-sponsors",
      component: OurSponsorsComponent,
    }, 
    {
      path: "contact-us",
      name: "contact-us",
      component: ContactUsComponent,
    }, 
    {
      path: "faqs",
      name: "faqs",
      component: FaqsComponent,
    }, 
    {
      path: "leadership",
      name: "leadership",
      component: LeadershipComponent,
    }, 
    {
      path: "tss-experts",
      name: "tss-experts",
      component: TSSExpertsComponent,
    }, 
    {
      path: "news-and-events",
      name: "news-and-events",
      component: NewsEventsComponent,
    }, 
    {
      path: "news-and-events-details/:id",
      name: "news-and-events-details",
      component: NewsEventsDetailsComponent,
    },
    {
      path: "sitemap",
      name: "sitemap",
      component: SitemapComponent,
    },
    {
      path: "terms-of-use",
      name: "terms-of-use",
      component: TermsOfUseComponent,
    },
    {
      path: "video-series",
      name: "video-series",
      component: VideoSeriesComponent,
    }, 
    {
      path: "video-series-details/:id",
      name: "video-series-details",
      component: VideoSeriesDetailsComponent,
    },
    {
      path: "brochure",
      name: "brochure",
      component: BrochureComponent,
    },
  ],
};
