<template>
  <div>
    <ul
      v-if="tags.length > 0"
      class="list-inline d-inline-block mb-2"
    >
      <li
        v-for="tag in tags"
        :key="tag.id"
        class="list-inline-item"
      >
        <b-badge
          variant="info"
          class="pl-10 py-5 fs-12 border text-primary"
        >
          <div class="d-flex align-items-center">
            <div
              :title="tag.name"
              class="text-left w-200 text-truncate"
            >
              {{tag.name}}
            </div>
            <div class="text-right pl-10">
              <span
                class="fa fa-pencil cursor-pointer"
                v-if="tag.hasCustomvalue"
                @click="editTag(tag)"
              >
              </span>
              <span
                class="fa fa-times cursor-pointer mx-5"
                @click="removeTag(tag)"
              ></span>
            </div>
          </div>
        </b-badge>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'wtr-tags',
  data() {
    return {};
  },
  props: {
    focus: Boolean,
    tags: Array,
  },
  methods: {
    editTag(tag) {
      if (!tag.hasCustomvalue) return;
      this.$emit('on-edit', tag);
    },
    removeTag(tag) {
      this.$deleteConfirmation(
        'Are you sure you want to remove this item?',
        () => {
          this.$emit('on-remove', tag);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.w-200 {
  width: 100px;
}
</style>
