import _ from 'lodash';
import toastr from 'toastr';
import swal from 'sweetalert';
import i18n from './internationalization';
import { capitalize } from './utilities';

toastr.options = _.assign(toastr.options, {
  closeButton: true,
  timeOut: 10000,
  showMethod: 'slideDown',
  hideMethod: 'slideUp',
  closeMethod: 'slideUp',
  positionClass: 'toast-top-center',
});

export const $toastr = (type, msg) => {
  toastr.remove();
  toastr[type](msg);
};

export const $modalAlert = (type, text, cb) => {
  const alert = {
    title: capitalize(type),
    text,
    icon: type,
    button: {
      text: i18n.t('common.ok'),
      className: 'btn btn-outline-primary px-50',
    },
  };
  setTimeout(() => {
    window.$('.swal-overlay').find('.swal-modal').attr('aria-label', 'alertmodel');
  }, 100);
  swal(alert).then(() => {
    if (!cb) return;
    cb();
  });
};

export const $workInProgress = () => {
  $toastr('info', i18n.t('common.workInProgress'));
};

export const $deleteConfirmation = (title, cb, text, buttons) => {
  setTimeout(() => {
    window.$('.swal-overlay').find('.swal-modal').attr('aria-label', 'alertmodal');
  }, 100);
  swal({
    title,
    text,
    icon: 'warning',
    buttons: buttons || true,
    dangerMode: true,
  }).then(result => {
    if (result) {
      cb(result);
    }
  });
};

export default {
  toastr: $toastr,
  modalAlert: $modalAlert,
  deleteConfirmation: $deleteConfirmation,
  workInProgress: $workInProgress,
};
