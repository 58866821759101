const UserModule = () => import("./UserModule.vue");
import home from '../home/home-router';
import slideSearch from '../slide-search/slide-router';

export default {
  path: "/",
  component: UserModule,
  children: [
    home,
    slideSearch
  ],
};
