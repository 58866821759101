<template>
    <div class="w-100 h-100" :title="`${placeholder}`">
        <button class="btn btn-outline dropdown-toggle d-flex align-items-center w-100 h-100 p-dropdown-custom" 
            :class="checkValidation?'invalid':''"
            type="button" data-toggle="dropdown" aria-expanded="false"
            v-if="!cascadeMode"
        >
            <template v-if="selectedItems.length === 0">
                <span class="p-dropdown-label p-placeholder">{{ placeholder }}</span>
            </template>
            <template v-else>
                <span class="selected-items">{{selectedItems.map(item => item[optionLabel]).join('; ')}}</span>
            </template>
            <div class="multiselect-trigger">
                <span class="multiselect-trigger-icon pi pi-chevron-down"></span>
            </div>
        </button>
        <div class="dropdown-menu" :class="{ 'show': cascadeMode, [dropdownClass]: true}" >
            <div class="dropdown-item" id="all-item-field-selection-dropdown" v-for="item in sortedItemsAsc(allOptions)" :key="item[optionLabel]" @click.stop="toggleItem(item)" style="cursor: default;">
                <div class="form-check d-flex">
                    <input 
                        class="form-check-input cursor-pointer" :type=selectionType :name="groupName"
                        style="width: 14px; height: 14px;"
                        :checked="selectedItems.find(obj => obj[optionLabel] === item[optionLabel])"
                        @click.stop="toggleItem(item)"
                    >
                    <span class="form-check-label pl-1 fs-14">
                        {{ item[optionLabel] }}
                    </span>
                    <a class="ml-auto pl-3 fs-14" title="Delete" 
                        v-if="item[removeOptionFlag] === true"
                        @click.stop="removeItem(item)"
                    >
                        <i class="pi pi-trash text-danger"></i>
                    </a>
                </div>
            </div>
            <div class="dropdown-divider" v-if="addOption"></div>
            <div class="dropdown-item" id="add-item-field-selection-dropdown" v-if="addOption">
                <div 
                    v-if="!isEnableAddField" 
                    class="cursor-pointer"
                    @click.stop="EnableAddField()" 
                >
                    <span><i class="pi pi-plus-circle mr-2 fs-14"></i></span>
                    <span class="fs-14">{{addOptionObj[optionLabel]}}</span>
                </div>
                <div v-if="isEnableAddField" class="d-flex align-items-center">
                    <span class="input-group">
                        <input type="text" 
                            @input="validateNumericInput(addOptionObj)"
                            v-model="newItem" class="form-control border-right-0 form-control-sm width-1"
                            :placeholder= "addOptionObj[optionLabel]"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-outline border-left-0 d-flex align-items-center" type="button" title= "Add"
                                @click.stop="addItem"
                            >
                                <i class="pi pi-plus-circle fs-14"></i>
                            </button>
                        </div>
                    </span>
                    <span class="pl-2 text-right" v-if="dropdownName !== 'genetic'">
                        <a @click.stop="() => isEnableAddField = false" title="Close">
                            <i class="pi pi-times"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'wtr-selection-dropdown',
    data() {
        return {
            selectedItems: [],
            newItem: '',
            isEnableAddField: false,
            selectionMode: ['checkbox','radio'],
            selectionType: '',   
            createdOptions: [],        
        }
    },
    props: {
        options: Array,
        optionLabel: String,
        addOptionFlag: String,
        removeOptionFlag: String,
        compairKey: String,
        placeholder: String,
        onlyNumericFlag: String,
        addFieldTumorSiteId: Number,
        isCheckValidation: Boolean,
        dropdownClass: String,
        selectedFields: Array,
        selection: String,
        cascadeMode: {
            type: Boolean,
            default: false
        },
        createdChildOptions : Array,
        parentId: Number,
        dropdownName: String
    },
    watch: {
        selectedFields(newVal) {
            if(newVal){
                this.selectedItems = newVal;
            }else{
                this.selectedItems = [];
            }
        }
    },
    mounted() {
        if(this.selectedFields){
            this.selectedItems = [...this.selectedFields];
        }
        if(this.selection=='multiple'){
            this.selectionType = this.selectionMode[0];
        }else if(this.selection=='single'){
            this.selectionType = this.selectionMode[1];
        }

        const userId = parseInt(localStorage.getItem("userId"));
        const createdDateTime = new Date().toISOString().replace('T', ' ').substr(0, 19);

        if(this.createdChildOptions){
            this.createdChildOptions.forEach((option, index) => {
                const newChildObj = {};
                newChildObj['createdBy'] = userId;
                newChildObj['createdDateTime'] = createdDateTime;
                newChildObj[this.addOptionFlag] = false;
                newChildObj[this.compairKey] = -1 - index;
                newChildObj[this.optionLabel] = option;
                newChildObj[this.removeOptionFlag] = false;
                newChildObj['onFlyEntryCreatedBy'] = userId;
                newChildObj['onlyNumeric'] = false;
                newChildObj[this.levelKey] = this.parentId;
                newChildObj['tumorSiteId'] = this.addFieldTumorSiteId;
                newChildObj['updatedBy'] = userId;
                newChildObj['updatedDateTime'] = createdDateTime;

                this.createdOptions.push(newChildObj);
            });
        }

        if(this.dropdownName === 'genetic')
            this.isEnableAddField = true;        
    },
    updated() {
        if(this.isCheckValidation){
            this.emitValidation();
        }
    },
    computed: {
        checkValidation(){
            return this.isCheckValidation ? this.selectedItems.length===0 : false;
        },
        allItems(){
            const map = new Map();
            [...this.options, ...this.selectedItems, ...this.createdOptions].forEach((obj) => {
                if (!map.has(obj[this.optionLabel])) {
                    map.set(obj[this.optionLabel], obj);
                }
            });

            return Array.from(map.values());
        },
        allOptions(){
            let result = this.allItems;
  
            if (this.addOptionFlag !== undefined) {
                result = result.filter(obj => obj[this.addOptionFlag] === false);
            }
            
            return result;
        },
        addOption(){
            return this.allItems.some(obj => obj[this.addOptionFlag] === true);
        },
        addOptionObj(){
            return this.allItems.find(obj => obj[this.addOptionFlag] === true);
        },
        groupName() {
            return 'group-' + Math.random().toString(36).substr(2, 9);
        },
    },
    methods: {
        validateNumericInput(item) {
            if(item[this.onlyNumericFlag]){
                // Remove any non-numeric characters except +, -, ., and %
                if(this.dropdownName === 'tumorSize')
                {
                    this.newItem = this.newItem.replace(/[^0-9]/g, "");
                }
                else
                {
                    this.newItem = this.newItem.replace(/[^0-9+-.%]/g, "");
                }
            }
        },
        EnableAddField(){
            this.isEnableAddField=true;
        },
        toggleItem(item) {
            if(this.selectionType == this.selectionMode[0]){
                if (this.selectedItems.find(obj => obj[this.optionLabel] === item[this.optionLabel])) {
                    this.selectedItems = this.selectedItems.filter(obj => obj[this.optionLabel] !== item[this.optionLabel]);
                } else {
                    this.selectedItems.push(item);
                }
            }
            else if(this.selectionType == this.selectionMode[1]){
                if (this.selectedItems.find(obj => obj[this.optionLabel] === item[this.optionLabel])) {
                    this.selectedItems = [];
                } else{
                    this.selectedItems = [item];
                }
            }
            this.emitSelectedItems();
        },
        removeItem(item) {
            this.selectedItems = this.selectedItems.filter(obj => obj[this.optionLabel] !== item[this.optionLabel]);
            this.emitSelectedItems();
        },
        addItem() {
            this.newItem = this.newItem.trim();
            if(this.newItem === null || this.newItem === '')
                return;

            if (!this.allItems.find(obj => obj[this.optionLabel] === this.newItem)) {
                const newObj = {};
                const createdDateTime = new Date().toISOString().replace('T', ' ').substr(0, 19);

                const userId = parseInt(localStorage.getItem("userId"));
                if(this.onlyNumericFlag)
                {
                    if(this.dropdownName === 'tumorSize')
                    {
                        if (!this.newItem.toString().includes("mm"))
                        {
                            this.newItem = this.newItem + " (mm)";
                        }
                    }
                    else
                    {
                        if (!this.newItem.toString().includes("%"))
                        {
                            this.newItem = this.newItem + "%";
                        }
                    }
                }

                newObj['createdBy'] = userId;
                newObj['createdDateTime'] = createdDateTime;
                newObj[this.addOptionFlag] = false;
                newObj[this.compairKey] = 0;
                newObj[this.optionLabel] = this.newItem;
                newObj[this.removeOptionFlag] = true;
                newObj['onFlyEntryCreatedBy'] = userId;
                newObj['onlyNumeric']= false;
                newObj['tumorSiteId']= this.addFieldTumorSiteId;
                newObj['updatedBy'] = userId;
                newObj['updatedDateTime'] = createdDateTime;                
                
                if(this.selectionType == this.selectionMode[0]){
                    this.selectedItems.push(newObj);
                }
                else if(this.selectionType == this.selectionMode[1]){
                    if (this.selectedItems.find(obj => obj[this.optionLabel] === this.newItem)) {
                        this.selectedItems = [];
                    } else{
                        this.selectedItems = [newObj];
                    }
                }
                this.newItem = '';
                
                if(this.dropdownName === 'genetic')
                    this.isEnableAddField = true;  
                else
                    this.isEnableAddField=false;
            }
            this.emitSelectedItems();
        },
        emitSelectedItems(){
            this.$emit('selected-items', JSON.parse(JSON.stringify(this.selectedItems)));
            this.emitValidation();
        },
        emitValidation(){
            this.$emit('is-validation-error', [{ 
                error: this.checkValidation
            }]);
        },
        sortedItemsAsc(items)
        {
            let result;
            if(this.dropdownName !== 'stainStudy')
            {
                const normalItems = items.filter(item => !item.onFlyEntry); // Filtering out normal items
                const onFlyEntryItems = items.filter(item => item.onFlyEntry); // Filtering out items with onFlyEntry true
                const freeTextOnFlyEntryItems = onFlyEntryItems.filter(item => typeof item[this.optionLabel] === 'string');
                const sortedNormalItems = normalItems.sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
                const sortedFreeTextOnFlyEntryItems = freeTextOnFlyEntryItems.sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
                result = [...sortedNormalItems, ...sortedFreeTextOnFlyEntryItems, ...onFlyEntryItems.filter(item => typeof item[this.optionLabel] !== 'string')];
            }
            else
            {
                const set1Values = [
                    'AE1/AE3',
                    'Beta-catenin',
                    'BRAFV600E',
                    'Calcitonin',
                    'CEA',
                    'Chromogranin A',
                    'CK19',
                    'HBME-1',
                    'Ki67',
                    'PAX8',
                    'Thyroglobulin',
                    'TP53',
                    'TTF1'
                ];
                const set2Values = [
                    'Alcian Blue','Congo Red','Mucicarmine','PAS','PAS-D','Tricrhome'
                ];
                
                const set1 = items.filter(item => set1Values.includes(item.name));
                const set2 = items.filter(item => set2Values.includes(item.name));
                const set3 = items.filter(item => (item.parentId !== null && item.onFlyEntry === true) || item.name == 'Other (free text)');
                result = set1.concat(set2).concat(set3);
            }
            return result;
        },
    }
}
</script>
  
<style scoped>
.bg-primary {
    background-color: #007bff !important;
}

.text-white {
    color: #fff !important;
}

.btn{
    text-align: left;
}

.btn-outline{
    background-color: #ffffff;
    border: 1px solid #ced4da;
}

.show > .btn-outline:not(:disabled):not(.disabled):active:focus, .btn-outline:not(:disabled):not(.disabled).active:focus, 
.show > .btn-outline.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.dropdown-menu{
    max-height: 40vh;
    overflow: auto;
}

.dropdown-toggle{
    font-size: 14px !important;
}

.dropdown-toggle::after {
    display: none;
}

.multiselect-trigger-icon{
    padding-top: 11%;
}

.multiselect-trigger{
    background: transparent;
    color: #6c757d;
    width: 20px;
    height: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: auto;
}

.selected-items{
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>