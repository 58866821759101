<template>
  <b-container>
    <b-row>
      <b-col>
        <wtr-profile
          v-if="userId"
          :userId="Number(userId)"
          :isSuperAdmin="isSuperAdmin"
          @on-load="loaded"
          @on-update="updated"
          @on-cancel="cancelled"
        ></wtr-profile>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import WTRProfile from '../../../components/common/wtr-profile.vue';
import localStorage from '../../../helpers/localStorage';

export default {
  name: 'profile',
  data() {
    return {
      userId: null,
      user: null,
      isValid: false,
      validateForm: null,
    };
  },
  components: {
    'wtr-profile': WTRProfile,
  },
  computed: {
    isSuperAdmin() {
      return this.$canAccess('administration', 'canCreateTenants');
    },
  },
  mounted() {
    this.userId = this.$pathParams.id;
    this.renderSidebarAction(this.isSuperAdmin ? 'admin-sidebar' : 'dashboard-sidebar');
  },
  beforeRouteLeave(to, from, next) {
    const forLogout = localStorage.getItem('logout');
    if (forLogout) {
      next();
    } else if (this.validateForm) {
      this.validateForm(
        user => {
          this.onValidationSuccess(user, next);
        },
        () => {
          this.$toastr('error', this.$t('notes.fillAllValidDetails'));
        },
      );
    }
  },
  methods: {
    ...mapActions(['renderSidebarAction']),
    loaded(validateForm) {
      this.validateForm = validateForm;
    },
    updateUser(user, next) {
      this.$api.updateUserProfileFact({
        ...user,
        profileUpdated: true,
      }).then(
        () => {
          next();
        },
        () => {
          next();
        },
      );
    },
    onValidationSuccess(user, next) {
      if (!this.$isProfileCompleted(this.$userData)) {
        this.updateUser(user, next);
      } else {
        next();
      }
    },
    updated() {
      this.$changeRoute({ name: this.isSuperAdmin ? 'tenants' : 'landing' });
    },
    cancelled() {
      this.$changeRoute({ name: this.isSuperAdmin ? 'tenants' : 'landing' });
    },
  },
};
</script>
