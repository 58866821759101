<template>
  <div>
    <b-form @submit.prevent="validateData">
      <b-form-group
        :state="!errors.has(options.name || 'input')"
        :invalid-feedback="errors.first(options.name || 'input')"
      >
        <label class="form-label">{{options.label || 'Input'}}<span
            v-if="options.required"
            class="error-text"
          > *</span></label>
        <b-form-input
          :value="value"
          trim
          v-validate="options.validate"
          @focus="onFocus"
          @input="onInput"
          :name="options.name || 'input'"
          :size="options.size || 'sm'"
          :type="options.formType || 'text'"
          :placeholder="options.placeholder || 'type here'"
        ></b-form-input>
      </b-form-group>
      <!-- <div
        v-if="focus"
        class="text-right"
      >
        <b-btn
          aria-label="Re-upload" aria-labelledby="Re-upload"
          variant="link"
          size="sm"
          v-on:click="onCancel"
        ><i class="fa fa-times"></i></b-btn>
        <b-btn
          aria-label="Re-upload" aria-labelledby="Re-upload"
          type="submit"
          variant="link"
          size="sm"
          class="ml-10"
          :disabled="errors.has(options.name || 'input')"
        >
          <i class="fa fa-check"></i>
        </b-btn>
      </div> -->
    </b-form>
  </div>
</template>

<script>
import { trim } from 'lodash';

export default {
  name: 'wtr-save-input',
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    options() {
      return this.params || {};
    },
  },
  props: {
    value: [String, Number],
    copyValue: [String, Number],
    params: {
      type: Object,
      formType: String,
      placeholder: String,
      label: String,
      name: String,
      styles: Object,
      classNames: String,
      size: String,
      cancelButton: Object,
      saveButton: Object,
    },
  },
  methods: {
    onInput(val) {
      const trimmed = trim(val);
      this.$emit('input', trimmed);
    },
    onCancel() {
      this.onInput(this.copyValue);
      this.$emit('on-cancel');
      this.focus = false;
    },
    onFocus() {
      this.focus = true;
    },
    validateData() {
      const trimmed = trim(this.value);
      if (!trimmed) return;
      this.$validator.validate().then(result => {
        if (result) {
          this.focus = false;
          this.$emit('on-save');
        } else {
          this.$toastr('error', this.$t('notes.fillAllValidDetails'));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
