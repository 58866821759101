import _ from 'lodash';
import folderData from '../models/model-folders';
import * as folderTypes from '../constants/constants-folder';

export default {
  data() {
    return {
      ...folderTypes,
      foldersLoading: false,
      folders: [],
      selectedFolder: null,
      activeFolder: null,
      copiedFolder: null,
      rootFolder: null,
    };
  },
  mounted() {
    window.addEventListener("setFolderLoadingStart", this.setFolderLoadingStart);
    window.addEventListener("setFolderLoadingStop", this.setFolderLoadingStop);
  },
  beforeDestroy() {
    window.removeEventListener('setFolderLoadingStart',this.setFolderLoadingStart);
    window.removeEventListener('setFolderLoadingStop',this.setFolderLoadingStop);
  },
  methods: {
    getAllFolders(options, onNodeSelected) {
      const responseCallBack = response => {
        this.buildFolders(response, onNodeSelected);
        this.foldersLoading = false;
      };
      const errorCallBack = folders => {
        this.buildFolders({
          folders,
        });
        this.foldersLoading = false;
      };
      this.foldersLoading = true;
      this.folders = null;
      this.fetchFolders(options).then(responseCallBack, errorCallBack);
    },
    fetchFolders(_params) {
      const { userId } = _params;
      let folders = _.cloneDeep(folderData);
      return new Promise((resolve, reject) => {
        this.$api.getFoldersFact({ userId }).then(
          response => {
            if (!this.$handleResponse(response)) {
              resolve({
                folders,
              });
            } else {
              const data = _.cloneDeep(response.data.payload);
              folders=data.myslideBox;  
              folders = _.filter(folders, o => o.name !== 'Recycle Bin');
              const activeFolder = data.activeFolder || null;
              
              resolve({
                folders,
                activeFolder,
              });
            }
          },
          error => {
            this.$handleError(error);
            reject(folders);
          },
        );
      });
    },
    buildFolders({ activeFolder, folders }, onNodeSelected) {
      this.folders = _.cloneDeep(folders);
      this.rootFolder = _.cloneDeep(this.folders[0]);
      let active = null;
      if (this.sharedEmail) {
        active = _.find(this.folders, { email: this.sharedEmail });
        if (active) {
          active.opened = true; //eslint-disable-line
        } else {
          active = this.folders[0]; //eslint-disable-line
        }
      } else if (activeFolder && activeFolder.length) {
        active = activeFolder[0]; //eslint-disable-line
        active.children = _.filter(activeFolder, (item, index) => index);
      } else {
        active = this.folders[0]; //eslint-disable-line
      }
      
      if (onNodeSelected) onNodeSelected(active);
    },
    resetFolders() {
      this.copiedFolder = null;
      this.selectedFolder = _.cloneDeep(this.rootFolder);
    },
    cutAction(item) {
      this.selectedFolder = item;
      this.copiedFolder = this.selectedFolder;
    },
    setFolderLoadingStart(data)
    {
      this.foldersLoading = data.detail.foldersLoading;
    },
    setFolderLoadingStop(data)
    {
      this.foldersLoading = data.detail.foldersLoading;
    },
  },
};
