import { omit } from 'lodash';
import uploader from '@/plugins/azure-file-upload';
import { SLIDE_DOCUMENT, SLIDE_IMAGE } from '@/constants/constants';
import api, { handleResponse } from '@/api';
import { $modalAlert } from './alerts';

const THUMBNAILS = [SLIDE_DOCUMENT, SLIDE_IMAGE];

const uploadEvents = {
  onFileSelect: null,
  onFolderChange: null,
  onUploading: null,
};

const uploadThumbnail = payload => {
  api.uploadThumbnailFact(payload);
};

const onCancel = (token, id, cb) => ({
  handle: (requestOptions, next) => {
    if (token.cancel) {
      cb(id);
      return;
    }
    if (next) {
      next(requestOptions, (returnObject, finalCallback, nextPostCallback) => {
        if (token.cancel) {
          cb(id);
          return;
        }
        if (nextPostCallback) {
          nextPostCallback(returnObject);
        } else if (finalCallback) {
          finalCallback(returnObject);
        }
      });
    }
  },
});

export const setOnFileSelect = cb => {
  uploadEvents.onFileSelect = cb;
};

export const getOnFileSelect = () => uploadEvents.onFileSelect;

export const setOnFolderChange = cb => {
  uploadEvents.onFolderChange = cb;
};

export const getOnFolderChange = () => uploadEvents.onFolderChange;

export const setOnUploading = cb => {
  uploadEvents.onUploading = cb;
};

export const getOnUploading = () => uploadEvents.onUploading;

export const slideUploader = ({
  uri,
  sasToken,
  file,
  data,
  thumbnailPath,
  id,
  blobname,
  path,
  onFileProgress,
  onDataProgress,
  onFileCancel,
  folderData,
  container,
}) => new Promise((resolve, reject) => {
  const blobUri = uri;
  let blobService = uploader.createBlobServiceWithSas(blobUri, sasToken);
  const cancelToken = {
    cancel: false,
  };
  const retryOperations = uploader.getRetryOptions();
  blobService = blobService.withFilter(onCancel(cancelToken, id, onFileCancel));
  blobService = blobService.withFilter(retryOperations);
  const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
  blobService.singleBlobPutThresholdInBytes = customBlockSize;
  const progressService = blobService.createBlockBlobFromBrowserFile(
    container,
    blobname,
    file,
    {
      blockSize: customBlockSize,
    },
    error => {
      if (error) {
        $modalAlert(
          'error',
          error && error.message ? error.message : 'Unable to upload file to blob storage',
        );
        reject(
          new Error(
            JSON.stringify({
              uploadFail: false,
              addEditFail: true,
              data: error,
            }),
          ),
        );
      } else {
        const CAN_CREATE_THUMBNAIL = THUMBNAILS.includes(data.fileType);
        const payload = {
          ...data,
          path,
          thumbnailPath: CAN_CREATE_THUMBNAIL ? thumbnailPath : null,
          folder: omit(folderData, 'children'),
          rawFileName: blobname,
        };
        if (CAN_CREATE_THUMBNAIL) {
          uploadThumbnail({
            sasToken: `?${sasToken}`,
            blobUrl: path,
            type: data.fileType,
          });
        }
        const apikey = payload.id ? 'updateFileFact' : 'saveFileFact';
        api[apikey](
          payload,
          (dataStatus, index) => {
            onDataProgress(index, {
              dataStatus,
            });
          },
          id,
        ).then(
          dataResponse => {
            const hasError = !handleResponse(dataResponse, {
              onError: message => {
                $modalAlert('error', message);
              },
            });
            if (hasError) {
              reject(
                new Error(
                  JSON.stringify({
                    uploadFail: false,
                    addEditFail: true,
                    data: dataResponse,
                  }),
                ),
              );
              return;
            }
            resolve({
              id,
              file,
              data: dataResponse.data.payload,
              success: true,
            });
          },
          err => {
            reject(
              new Error(
                JSON.stringify({
                  uploadFail: false,
                  addEditFail: true,
                  data: err,
                }),
              ),
            );
          },
        );
      }
    },
  );
  progressService.on('progress', () => {
    const process = progressService.getCompletePercent();
    onFileProgress(id, {
      fileStatus: Number(process.replace('%', '')),
      loaded: progressService.getCompleteSize(),
      speed: progressService.getSpeed(),
    });
  });
  function cancelFile() {
    cancelToken.cancel = true;
    blobService.abortCopyBlob('files', blobname, {}, () => {});
    progressService.removeAllListeners();
  }
  onFileCancel(id, cancelFile);
});
