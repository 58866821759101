export const NEWS_TYPE = {
  NEWS: 2,
  EVENTS: 1,
  VIDEOS: 4,
  NEWSRELEASE: 3,
};

export const VideoCategory = {
  WTR: 2,
  THYROID: 1,
};


