<template>
  <div :class="containerClass" aria-hidden="true" aria-modal="true">
   <div v-if="!isThumbnailUpdate"
      class="pp-logo-box"
      :class="imgContainerClass"
      :style="{ 'height': imgHeight }"
    >
      <wtr-loader
        v-if="uploading"
        variant="secondary"
        addText
        loadingText="Uploading..."
      ></wtr-loader>
      <wtr-img
        objectFit="contain"
        v-else
        disableLazy
        width="100%"
        height="100%"
        aria-label="pp Logo"
        aria-labelledby="pp Logo"
        alt="pp Logo"
        :resourceId="resourceId"
        :containerName="containerName"
        :src="img || altImage"
      />
    </div>
    <b-btn
      aria-label="upload"
      aria-labelledby="upload"
      v-if="!hideUpload"
      :title="btnTitle"
      :disabled="disableUpload"
      size="sm"
      block
      variant="outline-primary"
      class="file btn-file mt-20 cursor-pointer mb-2"
      :class="isThumbnailUpdate ? 'border-0': 'border-1'" 
    >
    <i v-if="isThumbnailUpdate" class="fa fa-upload" aria-hidden="true"></i>
      <!-- {{label}} -->
      <template v-else>{{label}}</template>
      <input
        aria-label="upload"
        aria-labelledby="upload"
        type="file"
        name="file"
        :disabled="disableUpload"
        class="cursor-pointer"
        v-on:change="uploadLogo"
      />
    </b-btn>
  </div>
</template>

<script>
import { getRandomId } from '../../helpers/utilities';

export default {
  name: 'image-upload',
  data() {
    return {
      cancel: null,
      progress: 0,
      uploading: false,
    };
  },
  props: {    
    slide: Object,
    resourceId: Number,
    containerName: String,
    img: {
      type: String,
      defualt: () => null,
    },
    label: {
      type: String,
      default: () => 'Upload',
    },
    altImage: String,
    containerClass: String,
    imgContainerClass: {
      type: String,
      default: () => '',
    },
    validate: {
      type: Function,
      default: () => () => true,
    },
    btnTitle: {
      type: String,
      default: () => '',
    },
    imgHeight: {
      type: String,
      default: () => '15rem',
    },
    hideUpload: {
      type: Boolean,
      deafault: () => false,
    },
    disableUpload: {
      type: Boolean,
      deafault: () => false,
    },
     isThumbnailUpdate: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onFileProgress(id, { fileStatus }) {
      this.progress = fileStatus;
    },
    onFileCancel(request) {
      this.cancel = request;
    },
    uploadLogo(e) {
      this.uploading = true;
      this.$emit('uploading', true);
      if (!e.target.files || !e.target.files.length) return;
      const file = e.target.files[0];
      const extension = this.$getFileExtension(file.name);
      const target = e.target || e.srcElement;
      if (!this.validate(extension, file.type)) {
        this.uploading = false;
        this.$emit('uploading', false);
        this.$toastr(
          'error',
          this.$t('notes.fileNotSupported', { type: extension }),
        );
        return;
      }
      this.$uploadLogoFact({
        file,
        onFileProgress: this.onFileProgress,
        onFileCancel: this.onFileCancel,
        fileName: file.name,
        id: getRandomId(),
        isThumbnailUpdate : this.isThumbnailUpdate,
        originalFileName: this.slide ? this.slide.name : ""
      })
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            target.value = ""; //eslint-disable-line
            this.$emit('uploaded', { slide: this.slide, response: response.data.payload,status: this.progress });  // Emit slide with response
            this.uploading = false;
            this.$emit('uploading', false);
          },
          error => {
            this.$handleError(error);
            this.uploading = false;
            this.$emit('uploading', false);
            target.value = ""; //eslint-disable-line
          },
        );
    },
  },
};
</script>

<style scoped lang="scss">
.pp-logo-box {
  border: 1px solid #eee;
}
.btn-file {
  position: relative;
  overflow: hidden;
  input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
  }
}
</style>
