import _ from 'lodash';
import downloadjs from 'downloadjs';
import i18n from './internationalization';
import {
  USER_ACTIVE,
  USER_INACTIVE,
  USER_INVITED,
  SLIDE_DIGITALSLIDE,
  SLIDE_ZOOMIFY,
  SLIDE_IMAGE,
  SLIDE_RADIOLOGY,
  SLIDE_VIDEO,
  SLIDE_DOCUMENT,
  SLIDE_PROCESSED,
  SLIDE_PROCESSING,
  SLIDE_PENDING,
  SLIDE_PROCESSING_FAILED,
  SLIDE_CONVERTING,
  SLIDE_SYNCING,
  SLIDE_UPLOAD_FAILED,
  SLIDE_READY_TO_PROCESS,
  SUBSCRIPTION_FREE,
  SUBSCRIPTION_BRONZE,
  SUBSCRIPTION_SILVER,
  SUBSCRIPTION_GOLD,
  SUBSCRIPTION_PLATINUM,
  USER_LINKS,
} from '../constants/constants';

export const download = downloadjs;

export const capitalize = text => {
  const data = text.toLowerCase();
  return data.charAt(0).toUpperCase() + data.slice(1);
};

export const removeNullValues = obj => _.pickBy(obj, _.identity);

export const formChange = (data, dataTemp) => JSON.stringify(removeNullValues(data))
  === JSON.stringify(removeNullValues(dataTemp));

export const updateList = (list, data, identifier) => {
  const state = list;
  const id = identifier || 'id';
  if (data.type === 'add') {
    return [...state, data.data];
  } if (data.type === 'update') {
    return state.map((item => {
      if (item[id] === data.data[id]) {
        return data.data;
      }
      return item;
    }));
  } if (data.type === 'delete') {
    return state.filter(item => item[id] !== data.data[id]);
  }
  return state;
};

export const getSlideProcessingStatus = ({
  dziStatus,
  initialProcessingProgress,
  finalProcessingProgress,
}) => {
  switch (dziStatus) {
    case SLIDE_PENDING:
      return {
        label: i18n.t('slidebox.pending'),
        variant: 'info',
      };
    case SLIDE_PROCESSED:
      return {
        label: i18n.t('slidebox.processed'),
        variant: 'success',
      };
    case SLIDE_PROCESSING:
      return {
        label: initialProcessingProgress
          ? `${initialProcessingProgress}% ${i18n.t('slidebox.processing')}`
          : i18n.t('slidebox.processing'),
        variant: 'warning',
      };
    case SLIDE_READY_TO_PROCESS:
      return {
        label: 'Ready To Process',
        variant: 'warning',
      };
    case SLIDE_CONVERTING:
      return {
        label: 'Ready to Sync ...',
        variant: 'info-light',
      };
    case SLIDE_SYNCING:
      return {
        label: `${finalProcessingProgress || 0}% Syncing ...`,
        variant: 'success-light',
      };
    case SLIDE_PROCESSING_FAILED:
      return {
        label: i18n.t('slidebox.processingFailed'),
        variant: 'danger',
      };
    case SLIDE_UPLOAD_FAILED:
      return {
        label: i18n.t('slidebox.uploadFailed'),
        variant: 'danger',
      };
    default:
      return {
        label: i18n.t('slidebox.pending'),
        variant: 'info',
      };
  }
};

export const getLabel= typeId => {
  switch (typeId) {
    case 2:
      return 'NEWS';
    case 1:
      return 'EVENT';
    case 4:
      return 'VIDEO';
    case 3:
      return 'NEWS RELEASE';
    default:
      return 'UNKNOWN';
  }
}

export const getSlideTypeStyle = type => {
  switch (type) {
    case SLIDE_DIGITALSLIDE:
      return {
        label: i18n.t('slidebox.digitalSlide'),
        variant: 'light',
      };
    case SLIDE_ZOOMIFY:
      return {
        label: i18n.t('slidebox.zoomify'),
        variant: 'light',
      };
    case SLIDE_IMAGE:
      return {
        label: i18n.t('slidebox.image'),
        variant: 'light',
      };
    case SLIDE_VIDEO:
      return {
        label: i18n.t('slidebox.video'),
        variant: 'light',
      };
    case SLIDE_DOCUMENT:
      return {
        label: i18n.t('slidebox.document'),
        variant: 'light',
      };
    case SLIDE_RADIOLOGY:
      return {
        label: i18n.t('slidebox.radiology'),
        variant: 'light',
      };
    default:
      return {
        label: i18n.t('slidebox.unknown'),
        variant: 'light',
      };
  }
};

export const getUserClasses = ({ status }) => {
  switch (status) {
    case USER_INVITED:
      return {
        variant: 'info',
      };
    case USER_ACTIVE:
      return {
        variant: 'success',
      };
    case USER_INACTIVE:
      return {
        variant: 'danger',
      };
    default:
      return {
        variant: 'secondary',
      };
  }
};

export function getUserStyles({ status }) {
  switch (status) {
    case USER_INVITED:
      return {
        label: i18n.t('administration.resendInvitation'),
        actionClass: 'outline-primary',
      };
    case USER_ACTIVE:
      return {
        label: i18n.t('administration.deactivate'),
        actionClass: 'outline-danger',
      };
    case USER_INACTIVE:
      return {
        label: i18n.t('administration.activate'),
        actionClass: 'outline-success',
      };
    default:
      return {
        label: i18n.t('administration.resendInvitation'),
        actionClass: 'outline-primary',
      };
  }
}

export function getSocialStyles({ type }) {
  switch (type) {
    case USER_LINKS.FACEBOOK:
      return {
        icon: 'fa fa-facebook',
        className: 'text-info',
      };
    case USER_LINKS.LINKEDIN:
      return {
        icon: 'fa fa-linkedin',
        className: 'text-info',
      };
    case USER_LINKS.INSTAGRAM:
      return {
        icon: 'fa fa-instagram',
        className: 'text-danger',
      };
    case USER_LINKS.TWITTER:
      return {
        icon: 'fa fa-twitter',
        className: 'text-info',
      };
    default:
      return {
        icon: 'fa fa-question',
        className: 'text-secondary',
      };
  }
}

export const formatFileSize = (bytes, decimalPoint) => {
  if (!bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimalPoint === 'none' ? 0 : decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat(bytes / Math.pow(k, i)).toFixed(dm)} ${sizes[i]}`; //eslint-disable-line
};

export const formatFileSizeGB = (gb,decimalPoint) => {
  if (!gb) return '0 gigabytes';
  const bytes = 1073741824;
  const dm = decimalPoint === 'none' ? 0 : decimalPoint || 2;
  return `${parseFloat(bytes * gb).toFixed(dm)}`;

};

export const getEstimatedTime = n => {
  const num = Math.floor(n / 60);
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const msg = !rminutes && !rhours
    ? i18n.t('common.estimatedLessThanMinute')
    : i18n.t('common.estimatedTime', { h: rhours, m: rminutes });
  return msg;
};

export const getDuration = duration => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? `${hours}` : hours;
  minutes = minutes < 10 ? `${minutes}` : minutes;

  return {
    label: `${hours}h ${minutes}m`,
    hours,
    minutes,
  };
};

export const getRandomId = () => {
  const val = Math.random() * 100;
  return Math.floor(val + 1);
};

export const getSubscriptionVariants = type => {
  switch (type) {
    case SUBSCRIPTION_FREE:
      return {
        subscriptionName: 'FREE',
        variant: 'success',
      };
    case SUBSCRIPTION_BRONZE:
      return {
        subscriptionName: 'BRONZE',
        variant: 'primary',
      };
    case SUBSCRIPTION_SILVER:
      return {
        subscriptionName: 'SILVER',
        variant: 'info',
      };
    case SUBSCRIPTION_GOLD:
      return {
        subscriptionName: 'GOLD',
        variant: 'warning',
      };
    case SUBSCRIPTION_PLATINUM:
        return {
          subscriptionName: 'PLATINUM',
          variant: 'danger',
        };
    default:
      return {
        subscriptionName: 'UNKNOWN',
        variant: 'secondary',
      };
  }
};

export const getDaysBetween = (end, start) => {
  if (start > end) return 0;
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const DIFFRENCE = Math.abs(end - start);
  return Math.round(DIFFRENCE / ONE_DAY);
};

export const getMouseMoveDirection = ({ clientX, cleintY }, { x, y }) => {
  let direction = '';
  const diff = x - clientX;
  if (clientX > x && cleintY === y) {
    direction = 'RIGHT';
  } else if (clientX === x && cleintY > y) {
    direction = 'DOWN';
  } else if (clientX === x && cleintY < y) {
    direction = 'UP';
  } else if (clientX < x && cleintY === y) {
    direction = 'LEFT';
  }
  return {
    direction,
    x: clientX,
    y: cleintY,
    diff,
  };
};

export const getVideoSize = (bytes, decimalPoint) => {
  if (!bytes || bytes <= 104857600) return null;
  const k = 1024;
  const dm = decimalPoint === 'none' ? 0 : decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat(bytes / Math.pow(k, i)).toFixed(dm)} ${sizes[i]}`; //eslint-disable-line
};
