export default {
  home: 'Home',
  terms: 'Terms',
  logIn: 'Login',
  resend: 'Resend',
  signIn: 'Sign In',
  register: 'Register',
  activated: 'Activated',
  viewerType: '{type} viewer',
  registration: 'Registration',
  emailAddress: 'Email address',
  yourTextHere: 'YOUR TEXT HERE',
  forgotPassword: 'Forgot Password',
  otherPlatforms: 'OUR OTHER PLATFORMS',
  copyRight: 'Copyright © {year} PathPresenter. All Rights Reserved.',
  // Create Password
  oneNumber: 'At least 1 number',
  passwordsMatch: 'Passwords Match',
  createPassword: 'Create Password',
  min8chars: 'Minimum of 8 characters',
  addOldPassword: 'Please add old password',
  oneUppercase: 'At least 1 uppercase character',
  oneLowercase: 'At least 1 lowercase character',
  oneSpecialChar: 'At least 1 special character (e.g @#$%)',
  passwordChangedSuccessMsg: 'Password changed successfully',
  // Create Password - END

  contact: 'Contact',
  aboutUs: 'ABOUT US',
  features: 'Features',
  comments: 'Comments',
  beSocial: 'BE SOCIAL',
  getInTouch: 'GET IN TOUCH',
  contactWithUs: 'CONTACT WITH US',
  productFeatures: 'PRODUCT FEATURES',
  addSlide: 'Add {slideType} slide',
  donate: 'Donate',
  serverIssueTryAfterSomeTime: 'Some server issue, Please try after some time.',
  expired: 'Expired',
};
