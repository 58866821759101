<template>
  <div>
    <b-modal
      v-model="$modalData.show" :size="'sm'" 
      :title="$modalData.title"
      hide-footer
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      centered
      :modal-class="$modalData.class"
      :hide-header-close="$modalData.hideCloseIcon"
      >
      <form>
          <div class="form-group">
            <select aria-label="Review Status" v-model="reviewStatus" aria-labelledby="Review Status"
              class="p-dropdown-custom form-control form-control-sm"              
            >
              <option value="" disabled>Select new status</option>
              <option value=8>Advisory Council review</option>
              <option value=9>Editorial Board review</option>
              <option value=7>Approved for publication</option>
            </select>
          </div>
          <div class="w-100 text-right">
            <wtr-btn aria-label="save"  aria-labelledby="save" variant="primary" type="submit"
              :text="$t('common.changeStatus') "  @click="changeStatus()"/>
          </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "review-status",
  data() {
    return {
      reviewStatus : ""
    };
  },
  props: {
    modalDataInfo: Object,
    params: Object,
  },
  computed: {
  },
  mounted() {
  },
  components: {
  },
  methods: {
    changeStatus()
    {
      const request = {
        caseDetailId: this.modalDataInfo.caseDetailId,
        statusType: Number(this.reviewStatus),
      };
      if( Number(this.reviewStatus) <= 0)
      {
        return;
      }
      this.$api.changeStatusFact(request).then(
        () => {
          this.$modalAlert("success",this.$t("slidebox.statusChangeSuccessMessage"));
          this.$setModalAction({ show: false });          
          const eventSend = new CustomEvent("callGetAllCases", {
          detail: {
            filtertype: null,
            filtervalue: null,
            page: 0,
            size:8,
            sort:"id,desc",
            searchEditorId: this.params.searchEditorId,
            searchGeographicRegionId: this.params.searchGeographicRegionId,
            searchReviewStatusId: this.params.searchReviewStatusId,
            searchSpecificCohortId: this.params.searchSpecificCohortId,
            search: this.params.search,
            }
          });
          window.dispatchEvent(eventSend);
        },
        (error) => {
          this.$handleError(error);
        }
      );
    }
  },
};
</script>

<style lang="scss">
</style>
