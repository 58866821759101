import { forEach } from 'lodash';
import types from './dashboard-mutation-types';

export default {
  sidebarAction({ commit }, data) {
    commit(types.SIDEBAR, data);
  },
  renderSidebarAction({ commit }, data) {
    commit(types.CURRENT_SIDEBAR, data);
  },
  $loaderAction({ commit }, data) {
    commit(types.LOADER, data);
  },
  setRoutesAction({ commit }, data) {
    commit(types.SET_ROUTES, data);
  },
  $setModalAction({ commit }, data) {
    commit(types.SET_MODAL, data);
  },
  $setDashboardHeightAction({ commit }, data) {
    commit(types.SET_DASHBOARD_HEIGHT, data);
  },
  $setDashboardWidthAction({ commit }, data) {
    commit(types.SET_DASHBOARD_WIDTH, data);
  },
  $setHeaderAction({ commit }, data) {
    if (typeof data === 'object') {
      const send = [];
      forEach(data, item => {
        send.push({
          text: item.text,
          to: {
            name: item.route,
            params: item.params,
            query: item.query,
          },
        });
      });
      commit(types.CRUMBS, send);
    } else {
      commit(types.CRUMBS, data);
    }
  },
};
