import axios from 'axios';
import { cloneDeep } from 'lodash';
import { $session } from '@/helpers';
import { API_POST, API_PUT } from '@/constants/constants-api';
import urls from './urls';
import { getSlideUrl, getUserUrl,getGLobalRepoUrl } from './api-services';

const onProgress = (cb, index) => ({
  onUploadProgress: progressEvent => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    if (!cb) return;
    cb(percentCompleted, index);
  },
});

export const getSasTokenFact = (getPublicTokens, tenantData) => {
  const tenant = tenantData || $session.getTenantData();
  return axios.post(getUserUrl(urls.sasToken), { getPublicTokens }, {
    headers: {
      'X-TenantID': tenant && tenant.id ? tenant.id : null,
    },
  });
};

export const getSasTokenWriteFact = containerName => axios.get(getSlideUrl(`${urls.sasToken}${urls.filter}`), {
  params: {
    containerName,
    readonly: false,
    expiry: 1.728e8,
  },
});

export const getFilesFact = (id, params) => axios.get(getGLobalRepoUrl(`${urls.slides}${urls.folderSlides}/${id}`), {
  params,
});

export const getFilesByEmailFact = params => axios.get(getSlideUrl(`${urls.sharedDocs}${urls.search}`), {
  params,
});

export const getRecycleFilesFact = data => axios.get(getSlideUrl(`${urls.slides}${urls.recycle}`), {
  params: data,
});

export const getFileFact = (id, isPublic) =>
{ 
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.slides}/${id}`), {
   headers: {
    'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
  },
  params: {
    type: isPublic ? 'public' : 'private',
  },
});
}

export const saveFileFact = (data, cb, cancelToken, index) => axios.post(
  getSlideUrl(`${urls.slides}`),
  data,
  onProgress(cb, cancelToken, index),
);

export const updateFileFact = (data, cb, cancelToken, index) => axios.put(
  getSlideUrl(`${urls.slides}`),
  data,
  onProgress(cb, cancelToken, index),
);


export const getFoldersFact = params => axios.get(getGLobalRepoUrl(`${urls.folders}${urls.all}`), {
  params,
});

export const createFolderFact = data => axios.post(getSlideUrl(`${urls.folders}`), data);

export const updateFolderFact = (data, params) => axios.put(getGLobalRepoUrl(`${urls.folders}`), data, {
  params,
});

export const deleteFolderFact = (id, status) => axios.delete(getSlideUrl(`${urls.folders}/${id}/${status}`));

export const deleteFilesFact = (data, status) => axios.delete(getSlideUrl(`${urls.slides}${urls.multiple}/${status}`), {
  data,
});

export const getSlideSectionsFact = () => axios.get(getSlideUrl(`${urls.caseSection}`));

export const moveFilesFact = data => axios.post(getSlideUrl(`${urls.slides}${urls.move}`), data);

export const copyFilesFact = data => axios.post(getSlideUrl(`${urls.slides}${urls.copy}`), data);

export const copyFoldersFact = params => axios.post(getSlideUrl(`${urls.folders}${urls.copy}`), null, {
  params,
});

export const moveFoldersFact = data => axios.post(getSlideUrl(`${urls.folders}${urls.move}`), null, {
  params: data,
});

// slide library calls

export const getLibraryRequestsFact = (data) => {
  let params = cloneDeep(data);
  return axios.get(
    getGLobalRepoUrl(`${urls.slides}${urls.all}${urls.slideLibrary}`),
    {
      params,
    },
  );
};

export const shareSlideFact = data => axios.post(getSlideUrl(`${urls.shareDocs}`), data);

export const shareBulkSlidesFact = (resourceId, emails, shareUri) => axios.post(
  getSlideUrl(`${urls.shareDocs}${urls.multiMember}/${resourceId}`),
  null,
  {
    params: {
      emails,
      share_uri: shareUri,
    },
  },
);

export const getSlideSharedMembers = (type, id, sharedByUserId) => axios.get(getSlideUrl(`${urls.shareDocs}/${type.toLowerCase()}/${id}`), {
  params: {
    sharedByUserId,
  },
});

export const updateSlideSharedMember = (id, data, path) => axios.post(getSlideUrl(`${urls.shareDocs}${urls.member}/${id}`), data, {
  params: {
    share_uri: path,
  },
});

export const slideSharedGroupResource = (apiType, config) => {
  switch (apiType) {
    case API_POST:
      return axios.post(
        getSlideUrl(`${urls.shareDocs}${urls.group}/${config.id}`),
        config.data,
        config.config,
      );
    case API_PUT:
      return axios.put(
        getSlideUrl(`${urls.shareDocs}${urls.group}/${config.id}`),
        config.data,
        config.config,
      );
    default:
  }
  return false;
};

export const deleteSlideSharedMembers = data => axios.delete(getSlideUrl(`${urls.shareDocs}`), {
  data,
});
export const deleteSlideSharedGroup = (id, data) => axios.delete(getSlideUrl(`${urls.shareDocs}${urls.group}/${id}`), {
  data,
});

export const getParentsPathFact = id => axios.get(getSlideUrl(`${urls.parentFolders}/${id}`), {});

export const getSlideAttachmentsFact = (id, token) => {
  let header = null;
  if (token) {
    header = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }
  return axios.get(
    getSlideUrl(`${urls.slides}${urls.attach}${urls.slide}/${id}`),
    header,
  );
};

export const saveSlideAttachmentsFact = (id, attachments) => axios.post(getSlideUrl(`${urls.slides}${urls.attach}`), {
  attachments,
});

export const removeSlideAttachmentsFact = id => axios.delete(getSlideUrl(`${urls.slides}${urls.attach}/${id}`));

