import sassService from '@/helpers/sass-init';
import setIdleTimeout from '@/helpers/idle-timeout';
import { formatFileSizeGB } from "/src/helpers/utilities";

export default {
  data() {
    return {
      appLoading: true,
      hasError: null,
    };
  },
  methods: {
    checkHashRouter() {
      const { hash } = window.location;
      if (hash) {
        window.location.href = `${window.location.origin}${hash.replace('#', '')}`;
      }
    },
    initFavIconAndTitle({ url, resourceId, containerName }, title) {
      const link = document.createElement('link');
      window.document.title = title;
      link.rel = 'icon';
      link.href = this.$session.getResource(url, resourceId, containerName);
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    getSassData(tenant) {
      sassService(tenant).then(
        sasTokens => {
          const data = this.$session.updateSessionData({
            sasTokens,
          });
          this.$store.dispatch('sessionDataAction', data);
        },
        () => {
          throw new Error('Unable to get sass tokens');
        },
      );
    },
    appService() {
      return new Promise((resolve, reject) => {
        const token = this.$session.checkSession();
        const api = token ? this.$api.getUserByTokenFact : this.$api.tenantIdFact;
        api(token)
          .then(
            response => {
              const data = response && response.data ? response.data : null;
              if (!data) reject(new Error('Not a valid Tenant'));
              let payload = {};
              // let homeData = null;
              if (data.payload) {
                payload = data.payload;
              } else if (!token) {
                payload = data.userTenantDTO;
                //homeData = data.webHomeDto;
              } else {
                payload = data;
              }
              if (payload.user) {
                setIdleTimeout(() => {
                  this.$logout(true, true, false);
                });
              }
              const send = {
                userData: payload.user,
                tenantData: payload.tenant,
                siteData: payload.site,
                // homeData,
                tenantConfigData: payload.tenantConfiguration || {
                  domain: 'HUMAN',
                },
                apiConfiguration: payload.apiConfiguration,
                subscriberInformation: this.$session.getSubscriptionData(),
                permissions: payload.permissions,
              };
              return send;
            },
            error => {
              const hasData = error && error.response && error.response.data;
              const errorData = hasData ? error.response.data : null;
              const statusCode = errorData && errorData.statusCode ? Number(errorData.statusCode) : null;
              if ([401, 403].includes(statusCode)) {
                this.$session.logout();
                reject({
                  logout: true,
                  error,
                  message: 'Session Expired, Please wait we are navigating to login page',
                });
              } else {
                reject(error);
              }
            },
          )
          .then(
            response => {
              if (response && response.tenantData && response.tenantData.id) {
                sassService(response.tenantData).then(
                  sasTokens => {
                    const sessionData = {
                      ...response,
                      sasTokens,
                    };
                    resolve(sessionData);
                  },
                  error => {
                    reject(error);
                  },
                );
              } else {
                reject(new Error('Something wrong with tenant details, please reload the page and try again'));
              }
            },
            error => {
              reject(error);
            },
          );
      });
    },
    alertError(error) {
      const message = error && error.message
        ? error.message
        : 'There is some issue, please reload the page and try again. If you still see this error message, please try after sometime';
      this.$modalAlert('error', message);
      this.hasError = error;
    },
    initializeApp() {
      let isPublicSite = '';
      let isPublicSiteWithQuery = '';

      const token = this.$session.checkSession();
      if(token != null)
      {
        const isExpired = this.isTokenExpired(token);
        const currentPath = window.location.pathname;
        
       const publicPaths = ['/', '/home', '/email-verification', '/create-password', '/verify-otp', '/forgot-password', '/about', '/news-details',
           '/news-all', '/mission-and-overview', '/financials', '/our-sponsors', '/faqs', '/contact-us', '/terms-of-use', '/leadership', '/tss-experts', '/sitemap', '/news-and-events', '/news-and-events-details', '/donate', '/brochure', '/video-series', '/video-series-details', 'thyroid'];
       const publicPathsWithQuery = ['/search', '/submitcase', '/search-result', '/search-cohot', '/search-microscopic'];
  
       isPublicSite = publicPaths.includes(currentPath);
       isPublicSiteWithQuery = publicPathsWithQuery.some(path => currentPath.startsWith(path));

        if(isExpired && (isPublicSite || isPublicSiteWithQuery))
        {
          this.$session.logout();
        }
      }

      const userId = localStorage.getItem("userId");
      if(userId != null && !(isPublicSite || isPublicSiteWithQuery))
      {
         this.getSubscriptionDetails();
      }

      this.checkHashRouter();
      const { updateSessionData } = this.$session;

      this.appService().then(
        sessionData => {
          this.appLoading = false;
          try {
            const data = updateSessionData(sessionData);
            this.$store.dispatch('sessionDataAction', data);
            const { tenantData } = data;
            const sasExpiry = tenantData && tenantData.sasExpiry ? tenantData.sasExpiry - 60000 : null;
            if (sasExpiry) {
              window.setInterval(() => {
                this.getSassData(tenantData);
              }, sasExpiry);
            }
            this.initFavIconAndTitle(
              {
                url: tenantData.logo,
                resourceId: tenantData.resourceId,
                containerName: tenantData.containerName,
              },
              'WTR - World Tumor Registry'
            );
          } catch (error) {
            this.alertError(error);
          }
        },
        error => {
          if (error && error.logout) {
            this.$session.logout();
            this.$toastr('error', error.message);
            this.initializeApp();
          } else {
            this.appLoading = false;
            this.alertError(error);
            this.$store.dispatch('sessionDataAction', error);
          }
        },
      );
    },
    getSubscriptionDetails() {
      this.$api.checkUserSubscriptionFact(localStorage.getItem("userId")).then(
        (response) => {
          if (!this.$handleResponse(response)) return;
          const data = response.data.payload;
          if (data.size == null) {
            data.size = 0;
            data.totalSizeConsumed = 0;
            data.label = "";
            data.id = 0;
          }
          else
          {
            const sizeLabel = formatFileSizeGB(data.size, "none");
            data.size = sizeLabel;
          }
          const subScriptiondata = this.$session.updateSessionData({
            subscriberInformation : data,
          });
          this.$store.dispatch('sessionDataAction', subScriptiondata);
        },
        (err) => {
          this.$handleError(err);
        }
      );
    },
    isTokenExpired(token) {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenPayload.exp;
      const currentTime = Math.floor(Date.now() / 1000);
    
      return currentTime >= expirationTime;
    },
  }  
};