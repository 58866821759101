import { getGLobalRepoUrl } from "./api-services";
import urls from './urls';
import axios from 'axios';
import { $session } from '@/helpers';

export const getAllMetadataBySlideIdsFact = data => 
{
    const tenantData =$session.getTenantData();
    return axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.getAllMetadataBySlideIds}`), data,{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
}

export const addMetadataFact = data => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.addMetadata}`), data);

export const updateMetadataFact = data => axios.put(getGLobalRepoUrl(`${urls.metadata}${urls.updateMetadata}`), data);

export const deleteMetadataFact =(id,params)  => axios.delete(getGLobalRepoUrl(`${urls.metadata}${urls.deleteMetadata}/${id}`), {
  params,
});

export const getAllCasesFact = (folderId, params) => axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.getAllCases}/${folderId}`), {
    params,
  });

  export const getAllSlideLibraryCasesFact = (params) => axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.getAllSlideLibraryCases}`), {
    params,
  });

export const getSlideIdsByCaseDetailIdFact = (caseDetailId, params) => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.getSlideIdsByCaseDetailId}/${caseDetailId}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
    params,
  });
}

export const movedToEditorialBoxFact = data => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.movedToEditorialBox}`), data);

export const movedToSlideLibraryFact = data => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.movedToSlideLibrary}`), data);

export const shareBackToEditorFact = data => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.shareBackToEditor}`), data);

export const checkUserSubscriptionFact = userId => axios.get(getGLobalRepoUrl(`${urls.subscriptionPlan}${urls.byUserId}`), {
  params: {
    userId,
  },
});

export const getContributorEditorByIdFact = id => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.getContributorEditor}/${id}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    },
  });
}

export const restoreMetadataFact = id => axios.put(getGLobalRepoUrl(`${urls.metadata}${urls.restoreMetadata}/${id}`), {});

export const getAllCaseDetailsByIdFact = id => axios.get(getGLobalRepoUrl(`${urls.metadata}${urls.getAllCaseDetailsById}/${id}`));

export const deleteCasedataFact =(id,params)  => axios.delete(getGLobalRepoUrl(`${urls.casedata}${urls.deleteCasedata}/${id}`), {
  params,
});

export const restoreCasedataFact = id => axios.put(getGLobalRepoUrl(`${urls.casedata}${urls.restoreCasedata}/${id}`), {});

export const deleteMetadataFromRecycleFact = data => axios.post(getGLobalRepoUrl(`${urls.metadata}${urls.deleteMetadataFromRecycle}`), data);

export const updateThumbnailFact = (id,thumbnailpath) => axios.put(getGLobalRepoUrl(`${urls.slides}${urls.updatethumbnail}/${id}`),{thumbnailpath: thumbnailpath});

